@charset "UTF-8";
/*
Template Name: Hyper - Responsive Bootstrap 4 Admin Dashboard
Version: 1.6.0
Author: CoderThemes
Email: support@coderthemes.com
File: Icons Css File
*/
@font-face {
  font-family: "unicons";
  src: url("../fonts/unicons.eot?34404611");
  src: url("../fontsunicons.eot?34404611#iefix") format("embedded-opentype"), url("../fonts/unicons.woff2?34404611") format("woff2"), url("../fonts/unicons.woff?34404611") format("woff"), url("../fonts/unicons.ttf?34404611") format("truetype"), url("../fonts/unicons.svg?34404611#unicons") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'unicons';
    src: url('../font/unicons.svg?34404611#unicons') format('svg');
  }
}
*/
[class^=uil-]:before, [class*=" uil-"]:before {
  font-family: "unicons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.uil-0-plus:before {
  content: "\e800";
} /* '' */
.uil-10-plus:before {
  content: "\e801";
} /* '' */
.uil-12-plus:before {
  content: "\e802";
} /* '' */
.uil-13-plus:before {
  content: "\e803";
} /* '' */
.uil-16-plus:before {
  content: "\e804";
} /* '' */
.uil-17-plus:before {
  content: "\e805";
} /* '' */
.uil-18-plus:before {
  content: "\e806";
} /* '' */
.uil-21-plus:before {
  content: "\e807";
} /* '' */
.uil-3-plus:before {
  content: "\e808";
} /* '' */
.uil-500px:before {
  content: "\e809";
} /* '' */
.uil-6-plus:before {
  content: "\e80a";
} /* '' */
.uil-abacus:before {
  content: "\e80b";
} /* '' */
.uil-accessible-icon-alt:before {
  content: "\e80c";
} /* '' */
.uil-adjust-alt:before {
  content: "\e80d";
} /* '' */
.uil-adjust-circle:before {
  content: "\e80e";
} /* '' */
.uil-adjust-half:before {
  content: "\e80f";
} /* '' */
.uil-adjust:before {
  content: "\e810";
} /* '' */
.uil-adobe:before {
  content: "\e811";
} /* '' */
.uil-airplay:before {
  content: "\e812";
} /* '' */
.uil-align-alt:before {
  content: "\e813";
} /* '' */
.uil-align-center-alt:before {
  content: "\e814";
} /* '' */
.uil-align-center-h:before {
  content: "\e815";
} /* '' */
.uil-align-center-justify:before {
  content: "\e816";
} /* '' */
.uil-align-center-v:before {
  content: "\e817";
} /* '' */
.uil-align-center:before {
  content: "\e818";
} /* '' */
.uil-align-justify:before {
  content: "\e819";
} /* '' */
.uil-align-left-justify:before {
  content: "\e81a";
} /* '' */
.uil-align-left:before {
  content: "\e81b";
} /* '' */
.uil-align-letter-right:before {
  content: "\e81c";
} /* '' */
.uil-align-right-justify:before {
  content: "\e81d";
} /* '' */
.uil-align-right:before {
  content: "\e81e";
} /* '' */
.uil-align:before {
  content: "\e81f";
} /* '' */
.uil-ambulance:before {
  content: "\e820";
} /* '' */
.uil-analysis:before {
  content: "\e821";
} /* '' */
.uil-analytics:before {
  content: "\e822";
} /* '' */
.uil-anchor:before {
  content: "\e823";
} /* '' */
.uil-android-phone-slash:before {
  content: "\e824";
} /* '' */
.uil-angle-double-down:before {
  content: "\e825";
} /* '' */
.uil-angle-double-left:before {
  content: "\e826";
} /* '' */
.uil-angle-double-right:before {
  content: "\e827";
} /* '' */
.uil-angle-double-up:before {
  content: "\e828";
} /* '' */
.uil-angle-down:before {
  content: "\e829";
} /* '' */
.uil-angle-left:before {
  content: "\e82a";
} /* '' */
.uil-angle-right-b:before {
  content: "\e82b";
} /* '' */
.uil-angle-right:before {
  content: "\e82c";
} /* '' */
.uil-angle-up:before {
  content: "\e82d";
} /* '' */
.uil-angry:before {
  content: "\e82e";
} /* '' */
.uil-ankh:before {
  content: "\e82f";
} /* '' */
.uil-annoyed-alt:before {
  content: "\e830";
} /* '' */
.uil-annoyed:before {
  content: "\e831";
} /* '' */
.uil-apple:before {
  content: "\e832";
} /* '' */
.uil-apps:before {
  content: "\e833";
} /* '' */
.uil-archive-alt:before {
  content: "\e834";
} /* '' */
.uil-archive:before {
  content: "\e835";
} /* '' */
.uil-archway:before {
  content: "\e836";
} /* '' */
.uil-arrow-break:before {
  content: "\e837";
} /* '' */
.uil-arrow-circle-down:before {
  content: "\e838";
} /* '' */
.uil-arrow-circle-left:before {
  content: "\e839";
} /* '' */
.uil-arrow-circle-right:before {
  content: "\e83a";
} /* '' */
.uil-arrow-circle-up:before {
  content: "\e83b";
} /* '' */
.uil-arrow-compress-h:before {
  content: "\e83c";
} /* '' */
.uil-arrow-down-left:before {
  content: "\e83d";
} /* '' */
.uil-arrow-down-right:before {
  content: "\e83e";
} /* '' */
.uil-arrow-down:before {
  content: "\e83f";
} /* '' */
.uil-arrow-from-right:before {
  content: "\e840";
} /* '' */
.uil-arrow-from-top:before {
  content: "\e841";
} /* '' */
.uil-arrow-growth:before {
  content: "\e842";
} /* '' */
.uil-arrow-left:before {
  content: "\e843";
} /* '' */
.uil-arrow-random:before {
  content: "\e844";
} /* '' */
.uil-arrow-resize-diagonal:before {
  content: "\e845";
} /* '' */
.uil-arrow-right:before {
  content: "\e846";
} /* '' */
.uil-arrow-to-bottom:before {
  content: "\e847";
} /* '' */
.uil-arrow-to-right:before {
  content: "\e848";
} /* '' */
.uil-arrow-up-left:before {
  content: "\e849";
} /* '' */
.uil-arrow-up-right:before {
  content: "\e84a";
} /* '' */
.uil-arrow-up:before {
  content: "\e84b";
} /* '' */
.uil-arrows-h-alt:before {
  content: "\e84c";
} /* '' */
.uil-arrows-h:before {
  content: "\e84d";
} /* '' */
.uil-arrows-left-down:before {
  content: "\e84e";
} /* '' */
.uil-arrows-maximize:before {
  content: "\e84f";
} /* '' */
.uil-arrows-merge:before {
  content: "\e850";
} /* '' */
.uil-arrows-resize-h:before {
  content: "\e851";
} /* '' */
.uil-arrows-resize-v:before {
  content: "\e852";
} /* '' */
.uil-arrows-resize:before {
  content: "\e853";
} /* '' */
.uil-arrows-right-down:before {
  content: "\e854";
} /* '' */
.uil-arrows-shrink-h:before {
  content: "\e855";
} /* '' */
.uil-arrows-shrink-v:before {
  content: "\e856";
} /* '' */
.uil-arrows-up-right:before {
  content: "\e857";
} /* '' */
.uil-arrows-v-alt:before {
  content: "\e858";
} /* '' */
.uil-arrows-v:before {
  content: "\e859";
} /* '' */
.uil-assistive-listening-systems:before {
  content: "\e85a";
} /* '' */
.uil-asterisk:before {
  content: "\e85b";
} /* '' */
.uil-at:before {
  content: "\e85c";
} /* '' */
.uil-atm-card:before {
  content: "\e85d";
} /* '' */
.uil-atom:before {
  content: "\e85e";
} /* '' */
.uil-auto-flash:before {
  content: "\e85f";
} /* '' */
.uil-award-alt:before {
  content: "\e860";
} /* '' */
.uil-award:before {
  content: "\e861";
} /* '' */
.uil-baby-carriage:before {
  content: "\e862";
} /* '' */
.uil-backpack:before {
  content: "\e863";
} /* '' */
.uil-backspace:before {
  content: "\e864";
} /* '' */
.uil-backward:before {
  content: "\e865";
} /* '' */
.uil-bag-alt:before {
  content: "\e866";
} /* '' */
.uil-bag-slash:before {
  content: "\e867";
} /* '' */
.uil-bag:before {
  content: "\e868";
} /* '' */
.uil-balance-scale:before {
  content: "\e869";
} /* '' */
.uil-ball:before {
  content: "\e86a";
} /* '' */
.uil-ban:before {
  content: "\e86b";
} /* '' */
.uil-bars:before {
  content: "\e86c";
} /* '' */
.uil-basketball-hoop:before {
  content: "\e86d";
} /* '' */
.uil-basketball:before {
  content: "\e86e";
} /* '' */
.uil-battery-bolt:before {
  content: "\e86f";
} /* '' */
.uil-battery-empty:before {
  content: "\e870";
} /* '' */
.uil-bed-double:before {
  content: "\e871";
} /* '' */
.uil-bed:before {
  content: "\e872";
} /* '' */
.uil-behance:before {
  content: "\e873";
} /* '' */
.uil-bell-school:before {
  content: "\e874";
} /* '' */
.uil-bell-slash:before {
  content: "\e875";
} /* '' */
.uil-bell:before {
  content: "\e876";
} /* '' */
.uil-bill:before {
  content: "\e877";
} /* '' */
.uil-bitcoin-circle:before {
  content: "\e878";
} /* '' */
.uil-bitcoin:before {
  content: "\e879";
} /* '' */
.uil-black-berry:before {
  content: "\e87a";
} /* '' */
.uil-bluetooth-b:before {
  content: "\e87b";
} /* '' */
.uil-bold:before {
  content: "\e87c";
} /* '' */
.uil-bolt-alt:before {
  content: "\e87d";
} /* '' */
.uil-bolt-slash:before {
  content: "\e87e";
} /* '' */
.uil-bolt:before {
  content: "\e87f";
} /* '' */
.uil-book-alt:before {
  content: "\e880";
} /* '' */
.uil-book-medical:before {
  content: "\e881";
} /* '' */
.uil-book-open:before {
  content: "\e882";
} /* '' */
.uil-book-reader:before {
  content: "\e883";
} /* '' */
.uil-book:before {
  content: "\e884";
} /* '' */
.uil-bookmark-full:before {
  content: "\e885";
} /* '' */
.uil-bookmark:before {
  content: "\e886";
} /* '' */
.uil-books:before {
  content: "\e887";
} /* '' */
.uil-boombox:before {
  content: "\e888";
} /* '' */
.uil-border-alt:before {
  content: "\e889";
} /* '' */
.uil-border-bottom:before {
  content: "\e88a";
} /* '' */
.uil-border-clear:before {
  content: "\e88b";
} /* '' */
.uil-border-horizontal:before {
  content: "\e88c";
} /* '' */
.uil-border-inner:before {
  content: "\e88d";
} /* '' */
.uil-border-left:before {
  content: "\e88e";
} /* '' */
.uil-border-out:before {
  content: "\e88f";
} /* '' */
.uil-border-right:before {
  content: "\e890";
} /* '' */
.uil-border-top:before {
  content: "\e891";
} /* '' */
.uil-border-vertical:before {
  content: "\e892";
} /* '' */
.uil-box:before {
  content: "\e893";
} /* '' */
.uil-briefcase-alt:before {
  content: "\e894";
} /* '' */
.uil-briefcase:before {
  content: "\e895";
} /* '' */
.uil-bright:before {
  content: "\e896";
} /* '' */
.uil-brightness-empty:before {
  content: "\e897";
} /* '' */
.uil-brightness-half:before {
  content: "\e898";
} /* '' */
.uil-brightness-low:before {
  content: "\e899";
} /* '' */
.uil-brightness-minus:before {
  content: "\e89a";
} /* '' */
.uil-brightness-plus:before {
  content: "\e89b";
} /* '' */
.uil-brightness:before {
  content: "\e89c";
} /* '' */
.uil-bring-bottom:before {
  content: "\e89d";
} /* '' */
.uil-bring-front:before {
  content: "\e89e";
} /* '' */
.uil-brush-alt:before {
  content: "\e89f";
} /* '' */
.uil-bug:before {
  content: "\e8a0";
} /* '' */
.uil-building:before {
  content: "\e8a1";
} /* '' */
.uil-bullseye:before {
  content: "\e8a2";
} /* '' */
.uil-bus-alt:before {
  content: "\e8a3";
} /* '' */
.uil-bus-school:before {
  content: "\e8a4";
} /* '' */
.uil-bus:before {
  content: "\e8a5";
} /* '' */
.uil-calcualtor:before {
  content: "\e8a6";
} /* '' */
.uil-calculator-alt:before {
  content: "\e8a7";
} /* '' */
.uil-calendar-alt:before {
  content: "\e8a8";
} /* '' */
.uil-calendar-slash:before {
  content: "\e8a9";
} /* '' */
.uil-calender:before {
  content: "\e8aa";
} /* '' */
.uil-calling:before {
  content: "\e8ab";
} /* '' */
.uil-camera-change:before {
  content: "\e8ac";
} /* '' */
.uil-camera-plus:before {
  content: "\e8ad";
} /* '' */
.uil-camera-slash:before {
  content: "\e8ae";
} /* '' */
.uil-camera:before {
  content: "\e8af";
} /* '' */
.uil-cancel:before {
  content: "\e8b0";
} /* '' */
.uil-capsule:before {
  content: "\e8b1";
} /* '' */
.uil-capture:before {
  content: "\e8b2";
} /* '' */
.uil-car-sideview:before {
  content: "\e8b3";
} /* '' */
.uil-car-slash:before {
  content: "\e8b4";
} /* '' */
.uil-car-wash:before {
  content: "\e8b5";
} /* '' */
.uil-car:before {
  content: "\e8b6";
} /* '' */
.uil-card-atm:before {
  content: "\e8b7";
} /* '' */
.uil-caret-right:before {
  content: "\e8b8";
} /* '' */
.uil-cart:before {
  content: "\e8b9";
} /* '' */
.uil-cell:before {
  content: "\e8ba";
} /* '' */
.uil-celsius:before {
  content: "\e8bb";
} /* '' */
.uil-chart-bar-alt:before {
  content: "\e8bc";
} /* '' */
.uil-chart-bar:before {
  content: "\e8bd";
} /* '' */
.uil-chart-down:before {
  content: "\e8be";
} /* '' */
.uil-chart-growth-alt:before {
  content: "\e8bf";
} /* '' */
.uil-chart-growth:before {
  content: "\e8c0";
} /* '' */
.uil-chart-line:before {
  content: "\e8c1";
} /* '' */
.uil-chart-pie-alt:before {
  content: "\e8c2";
} /* '' */
.uil-chart-pie:before {
  content: "\e8c3";
} /* '' */
.uil-chart:before {
  content: "\e8c4";
} /* '' */
.uil-chat-bubble-user:before {
  content: "\e8c5";
} /* '' */
.uil-chat-info:before {
  content: "\e8c6";
} /* '' */
.uil-chat:before {
  content: "\e8c7";
} /* '' */
.uil-check-circle:before {
  content: "\e8c8";
} /* '' */
.uil-check-square:before {
  content: "\e8c9";
} /* '' */
.uil-check:before {
  content: "\e8ca";
} /* '' */
.uil-circle-layer:before {
  content: "\e8cb";
} /* '' */
.uil-circle:before {
  content: "\e8cc";
} /* '' */
.uil-circuit:before {
  content: "\e8cd";
} /* '' */
.uil-clapper-board:before {
  content: "\e8ce";
} /* '' */
.uil-clipboard-alt:before {
  content: "\e8cf";
} /* '' */
.uil-clipboard-blank:before {
  content: "\e8d0";
} /* '' */
.uil-clipboard-notes:before {
  content: "\e8d1";
} /* '' */
.uil-clipboard:before {
  content: "\e8d2";
} /* '' */
.uil-clock-eight:before {
  content: "\e8d3";
} /* '' */
.uil-clock-five:before {
  content: "\e8d4";
} /* '' */
.uil-clock-nine:before {
  content: "\e8d5";
} /* '' */
.uil-clock-seven:before {
  content: "\e8d6";
} /* '' */
.uil-clock-ten:before {
  content: "\e8d7";
} /* '' */
.uil-clock-three:before {
  content: "\e8d8";
} /* '' */
.uil-clock-two:before {
  content: "\e8d9";
} /* '' */
.uil-clock:before {
  content: "\e8da";
} /* '' */
.uil-closed-captioning:before {
  content: "\e8db";
} /* '' */
.uil-cloud-block:before {
  content: "\e8dc";
} /* '' */
.uil-cloud-bookmark:before {
  content: "\e8dd";
} /* '' */
.uil-cloud-check:before {
  content: "\e8de";
} /* '' */
.uil-cloud-computing:before {
  content: "\e8df";
} /* '' */
.uil-cloud-data-connection:before {
  content: "\e8e0";
} /* '' */
.uil-cloud-database-tree:before {
  content: "\e8e1";
} /* '' */
.uil-cloud-download:before {
  content: "\e8e2";
} /* '' */
.uil-cloud-drizzle:before {
  content: "\e8e3";
} /* '' */
.uil-cloud-exclamation:before {
  content: "\e8e4";
} /* '' */
.uil-cloud-hail:before {
  content: "\e8e5";
} /* '' */
.uil-cloud-heart:before {
  content: "\e8e6";
} /* '' */
.uil-cloud-info:before {
  content: "\e8e7";
} /* '' */
.uil-cloud-lock:before {
  content: "\e8e8";
} /* '' */
.uil-cloud-meatball:before {
  content: "\e8e9";
} /* '' */
.uil-cloud-moon-hail:before {
  content: "\e8ea";
} /* '' */
.uil-cloud-moon-meatball:before {
  content: "\e8eb";
} /* '' */
.uil-cloud-moon-rain:before {
  content: "\e8ec";
} /* '' */
.uil-cloud-moon-showers:before {
  content: "\e8ed";
} /* '' */
.uil-cloud-moon:before {
  content: "\e8ee";
} /* '' */
.uil-cloud-question:before {
  content: "\e8ef";
} /* '' */
.uil-cloud-rain-sun:before {
  content: "\e8f0";
} /* '' */
.uil-cloud-rain:before {
  content: "\e8f1";
} /* '' */
.uil-cloud-redo:before {
  content: "\e8f2";
} /* '' */
.uil-cloud-set:before {
  content: "\e8f3";
} /* '' */
.uil-cloud-share:before {
  content: "\e8f4";
} /* '' */
.uil-cloud-shield:before {
  content: "\e8f5";
} /* '' */
.uil-cloud-showers-alt:before {
  content: "\e8f6";
} /* '' */
.uil-cloud-showers-heavy:before {
  content: "\e8f7";
} /* '' */
.uil-cloud-showers:before {
  content: "\e8f8";
} /* '' */
.uil-cloud-slash:before {
  content: "\e8f9";
} /* '' */
.uil-cloud-sun-hail:before {
  content: "\e8fa";
} /* '' */
.uil-cloud-sun-meatball:before {
  content: "\e8fb";
} /* '' */
.uil-cloud-sun-rain-alt:before {
  content: "\e8fc";
} /* '' */
.uil-cloud-sun-rain:before {
  content: "\e8fd";
} /* '' */
.uil-cloud-sun-tear:before {
  content: "\e8fe";
} /* '' */
.uil-cloud-sun:before {
  content: "\e8ff";
} /* '' */
.uil-cloud-times:before {
  content: "\e900";
} /* '' */
.uil-cloud-unlock:before {
  content: "\e901";
} /* '' */
.uil-cloud-upload:before {
  content: "\e902";
} /* '' */
.uil-cloud-wifi:before {
  content: "\e903";
} /* '' */
.uil-cloud-wind:before {
  content: "\e904";
} /* '' */
.uil-cloud:before {
  content: "\e905";
} /* '' */
.uil-clouds:before {
  content: "\e906";
} /* '' */
.uil-club:before {
  content: "\e907";
} /* '' */
.uil-code:before {
  content: "\e908";
} /* '' */
.uil-coffee:before {
  content: "\e909";
} /* '' */
.uil-cog:before {
  content: "\e90a";
} /* '' */
.uil-coins:before {
  content: "\e90b";
} /* '' */
.uil-columns:before {
  content: "\e90c";
} /* '' */
.uil-comment-alt-block:before {
  content: "\e90d";
} /* '' */
.uil-comment-alt-chart-lines:before {
  content: "\e90e";
} /* '' */
.uil-comment-alt-check:before {
  content: "\e90f";
} /* '' */
.uil-comment-alt-dots:before {
  content: "\e910";
} /* '' */
.uil-comment-alt-download:before {
  content: "\e911";
} /* '' */
.uil-comment-alt-edit:before {
  content: "\e912";
} /* '' */
.uil-comment-alt-exclamation:before {
  content: "\e913";
} /* '' */
.uil-comment-alt-heart:before {
  content: "\e914";
} /* '' */
.uil-comment-alt-image:before {
  content: "\e915";
} /* '' */
.uil-comment-alt-info:before {
  content: "\e916";
} /* '' */
.uil-comment-alt-lines:before {
  content: "\e917";
} /* '' */
.uil-comment-alt-lock:before {
  content: "\e918";
} /* '' */
.uil-comment-alt-medical:before {
  content: "\e919";
} /* '' */
.uil-comment-alt-message:before {
  content: "\e91a";
} /* '' */
.uil-comment-alt-notes:before {
  content: "\e91b";
} /* '' */
.uil-comment-alt-plus:before {
  content: "\e91c";
} /* '' */
.uil-comment-alt-question:before {
  content: "\e91d";
} /* '' */
.uil-comment-alt-redo:before {
  content: "\e91e";
} /* '' */
.uil-comment-alt-search:before {
  content: "\e91f";
} /* '' */
.uil-comment-alt-share:before {
  content: "\e920";
} /* '' */
.uil-comment-alt-shield:before {
  content: "\e921";
} /* '' */
.uil-comment-alt-slash:before {
  content: "\e922";
} /* '' */
.uil-comment-alt-upload:before {
  content: "\e923";
} /* '' */
.uil-comment-alt-verify:before {
  content: "\e924";
} /* '' */
.uil-comment-alt:before {
  content: "\e925";
} /* '' */
.uil-comment-block:before {
  content: "\e926";
} /* '' */
.uil-comment-chart-line:before {
  content: "\e927";
} /* '' */
.uil-comment-check:before {
  content: "\e928";
} /* '' */
.uil-comment-dots:before {
  content: "\e929";
} /* '' */
.uil-comment-download:before {
  content: "\e92a";
} /* '' */
.uil-comment-edit:before {
  content: "\e92b";
} /* '' */
.uil-comment-exclamation:before {
  content: "\e92c";
} /* '' */
.uil-comment-heart:before {
  content: "\e92d";
} /* '' */
.uil-comment-image:before {
  content: "\e92e";
} /* '' */
.uil-comment-info-alt:before {
  content: "\e92f";
} /* '' */
.uil-comment-info:before {
  content: "\e930";
} /* '' */
.uil-comment-lines:before {
  content: "\e931";
} /* '' */
.uil-comment-lock:before {
  content: "\e932";
} /* '' */
.uil-comment-medical:before {
  content: "\e933";
} /* '' */
.uil-comment-message:before {
  content: "\e934";
} /* '' */
.uil-comment-notes:before {
  content: "\e935";
} /* '' */
.uil-comment-plus:before {
  content: "\e936";
} /* '' */
.uil-comment-question:before {
  content: "\e937";
} /* '' */
.uil-comment-redo:before {
  content: "\e938";
} /* '' */
.uil-comment-search:before {
  content: "\e939";
} /* '' */
.uil-comment-share:before {
  content: "\e93a";
} /* '' */
.uil-comment-shield:before {
  content: "\e93b";
} /* '' */
.uil-comment-slash:before {
  content: "\e93c";
} /* '' */
.uil-comment-upload:before {
  content: "\e93d";
} /* '' */
.uil-comment-verify:before {
  content: "\e93e";
} /* '' */
.uil-comment:before {
  content: "\e93f";
} /* '' */
.uil-comments-alt:before {
  content: "\e940";
} /* '' */
.uil-comments:before {
  content: "\e941";
} /* '' */
.uil-commnet-alt-slash:before {
  content: "\e942";
} /* '' */
.uil-compact-disc:before {
  content: "\e943";
} /* '' */
.uil-compass:before {
  content: "\e944";
} /* '' */
.uil-compress-alt-left:before {
  content: "\e945";
} /* '' */
.uil-compress-alt:before {
  content: "\e946";
} /* '' */
.uil-compress-arrows:before {
  content: "\e947";
} /* '' */
.uil-compress-lines:before {
  content: "\e948";
} /* '' */
.uil-compress-point:before {
  content: "\e949";
} /* '' */
.uil-compress-v:before {
  content: "\e94a";
} /* '' */
.uil-compress:before {
  content: "\e94b";
} /* '' */
.uil-computer-mouse:before {
  content: "\e94c";
} /* '' */
.uil-confused:before {
  content: "\e94d";
} /* '' */
.uil-constructor:before {
  content: "\e94e";
} /* '' */
.uil-copy-alt:before {
  content: "\e94f";
} /* '' */
.uil-copy-landscape:before {
  content: "\e950";
} /* '' */
.uil-copy:before {
  content: "\e951";
} /* '' */
.uil-copyright:before {
  content: "\e952";
} /* '' */
.uil-corner-down-left:before {
  content: "\e953";
} /* '' */
.uil-corner-down-right-alt:before {
  content: "\e954";
} /* '' */
.uil-corner-down-right:before {
  content: "\e955";
} /* '' */
.uil-corner-left-down:before {
  content: "\e956";
} /* '' */
.uil-corner-right-down:before {
  content: "\e957";
} /* '' */
.uil-corner-up-left-alt:before {
  content: "\e958";
} /* '' */
.uil-corner-up-left:before {
  content: "\e959";
} /* '' */
.uil-corner-up-right-alt:before {
  content: "\e95a";
} /* '' */
.uil-corner-up-right:before {
  content: "\e95b";
} /* '' */
.uil-creative-commons-pd-alt:before {
  content: "\e95c";
} /* '' */
.uil-creative-commons-pd:before {
  content: "\e95d";
} /* '' */
.uil-crockery:before {
  content: "\e95e";
} /* '' */
.uil-crop-alt-rotate-left:before {
  content: "\e95f";
} /* '' */
.uil-crop-alt-rotate-right:before {
  content: "\e960";
} /* '' */
.uil-crop-alt:before {
  content: "\e961";
} /* '' */
.uil-crosshair-alt:before {
  content: "\e962";
} /* '' */
.uil-crosshair:before {
  content: "\e963";
} /* '' */
.uil-crosshairs:before {
  content: "\e964";
} /* '' */
.uil-cube:before {
  content: "\e965";
} /* '' */
.uil-dashboard:before {
  content: "\e966";
} /* '' */
.uil-data-sharing:before {
  content: "\e967";
} /* '' */
.uil-database-alt:before {
  content: "\e968";
} /* '' */
.uil-database:before {
  content: "\e969";
} /* '' */
.uil-desert:before {
  content: "\e96a";
} /* '' */
.uil-desktop-alt-slash:before {
  content: "\e96b";
} /* '' */
.uil-desktop-alt:before {
  content: "\e96c";
} /* '' */
.uil-desktop-cloud-alt:before {
  content: "\e96d";
} /* '' */
.uil-desktop-slash:before {
  content: "\e96e";
} /* '' */
.uil-desktop:before {
  content: "\e96f";
} /* '' */
.uil-dialpad-alt:before {
  content: "\e970";
} /* '' */
.uil-dialpad:before {
  content: "\e971";
} /* '' */
.uil-diamond:before {
  content: "\e972";
} /* '' */
.uil-diary-alt:before {
  content: "\e973";
} /* '' */
.uil-diary:before {
  content: "\e974";
} /* '' */
.uil-dice-five:before {
  content: "\e975";
} /* '' */
.uil-dice-four:before {
  content: "\e976";
} /* '' */
.uil-dice-one:before {
  content: "\e977";
} /* '' */
.uil-dice-six:before {
  content: "\e978";
} /* '' */
.uil-dice-three:before {
  content: "\e979";
} /* '' */
.uil-dice-two:before {
  content: "\e97a";
} /* '' */
.uil-direction:before {
  content: "\e97b";
} /* '' */
.uil-directions:before {
  content: "\e97c";
} /* '' */
.uil-dizzy-meh:before {
  content: "\e97d";
} /* '' */
.uil-dna:before {
  content: "\e97e";
} /* '' */
.uil-document-layout-center:before {
  content: "\e97f";
} /* '' */
.uil-document-layout-left:before {
  content: "\e980";
} /* '' */
.uil-document-layout-right:before {
  content: "\e981";
} /* '' */
.uil-document:before {
  content: "\e982";
} /* '' */
.uil-dollar-alt:before {
  content: "\e983";
} /* '' */
.uil-dollar-sign-alt:before {
  content: "\e984";
} /* '' */
.uil-dollar-sign:before {
  content: "\e985";
} /* '' */
.uil-down-arrow:before {
  content: "\e986";
} /* '' */
.uil-download-alt:before {
  content: "\e987";
} /* '' */
.uil-dribbble:before {
  content: "\e988";
} /* '' */
.uil-drill:before {
  content: "\e989";
} /* '' */
.uil-dropbox:before {
  content: "\e98a";
} /* '' */
.uil-dumbbell:before {
  content: "\e98b";
} /* '' */
.uil-ear:before {
  content: "\e98c";
} /* '' */
.uil-edit-alt:before {
  content: "\e98d";
} /* '' */
.uil-edit:before {
  content: "\e98e";
} /* '' */
.uil-ellipsis-h:before {
  content: "\e98f";
} /* '' */
.uil-ellipsis-v:before {
  content: "\e990";
} /* '' */
.uil-emoji:before {
  content: "\e991";
} /* '' */
.uil-enter:before {
  content: "\e992";
} /* '' */
.uil-entry:before {
  content: "\e993";
} /* '' */
.uil-envelope-add:before {
  content: "\e994";
} /* '' */
.uil-envelope-alt:before {
  content: "\e995";
} /* '' */
.uil-envelope-block:before {
  content: "\e996";
} /* '' */
.uil-envelope-bookmark:before {
  content: "\e997";
} /* '' */
.uil-envelope-check:before {
  content: "\e998";
} /* '' */
.uil-envelope-download-alt:before {
  content: "\e999";
} /* '' */
.uil-envelope-download:before {
  content: "\e99a";
} /* '' */
.uil-envelope-edit:before {
  content: "\e99b";
} /* '' */
.uil-envelope-exclamation:before {
  content: "\e99c";
} /* '' */
.uil-envelope-heart:before {
  content: "\e99d";
} /* '' */
.uil-envelope-info:before {
  content: "\e99e";
} /* '' */
.uil-envelope-lock:before {
  content: "\e99f";
} /* '' */
.uil-envelope-minus:before {
  content: "\e9a0";
} /* '' */
.uil-envelope-open:before {
  content: "\e9a1";
} /* '' */
.uil-envelope-question:before {
  content: "\e9a2";
} /* '' */
.uil-envelope-receive:before {
  content: "\e9a3";
} /* '' */
.uil-envelope-redo:before {
  content: "\e9a4";
} /* '' */
.uil-envelope-search:before {
  content: "\e9a5";
} /* '' */
.uil-envelope-send:before {
  content: "\e9a6";
} /* '' */
.uil-envelope-share:before {
  content: "\e9a7";
} /* '' */
.uil-envelope-shield:before {
  content: "\e9a8";
} /* '' */
.uil-envelope-star:before {
  content: "\e9a9";
} /* '' */
.uil-envelope-times:before {
  content: "\e9aa";
} /* '' */
.uil-envelope-upload-alt:before {
  content: "\e9ab";
} /* '' */
.uil-envelope-upload:before {
  content: "\e9ac";
} /* '' */
.uil-envelope:before {
  content: "\e9ad";
} /* '' */
.uil-envelopes:before {
  content: "\e9ae";
} /* '' */
.uil-equal-circle:before {
  content: "\e9af";
} /* '' */
.uil-euro-circle:before {
  content: "\e9b0";
} /* '' */
.uil-euro:before {
  content: "\e9b1";
} /* '' */
.uil-exchange-alt:before {
  content: "\e9b2";
} /* '' */
.uil-exchange:before {
  content: "\e9b3";
} /* '' */
.uil-exclamation-circle:before {
  content: "\e9b4";
} /* '' */
.uil-exclamation-octagon:before {
  content: "\e9b5";
} /* '' */
.uil-exclamation-triangle:before {
  content: "\e9b6";
} /* '' */
.uil-exclude:before {
  content: "\e9b7";
} /* '' */
.uil-exit:before {
  content: "\e9b8";
} /* '' */
.uil-expand-alt:before {
  content: "\e9b9";
} /* '' */
.uil-expand-arrows-alt:before {
  content: "\e9ba";
} /* '' */
.uil-expand-arrows:before {
  content: "\e9bb";
} /* '' */
.uil-expand-from-corner:before {
  content: "\e9bc";
} /* '' */
.uil-expand-left:before {
  content: "\e9bd";
} /* '' */
.uil-expand-right:before {
  content: "\e9be";
} /* '' */
.uil-export:before {
  content: "\e9bf";
} /* '' */
.uil-exposure-alt:before {
  content: "\e9c0";
} /* '' */
.uil-exposure-increase:before {
  content: "\e9c1";
} /* '' */
.uil-external-link-alt:before {
  content: "\e9c2";
} /* '' */
.uil-eye-slash:before {
  content: "\e9c3";
} /* '' */
.uil-eye:before {
  content: "\e9c4";
} /* '' */
.uil-facebook-f:before {
  content: "\e9c5";
} /* '' */
.uil-facebook-messenger:before {
  content: "\e9c6";
} /* '' */
.uil-facebook:before {
  content: "\e9c7";
} /* '' */
.uil-fahrenheit:before {
  content: "\e9c8";
} /* '' */
.uil-fast-mail-alt:before {
  content: "\e9c9";
} /* '' */
.uil-fast-mail:before {
  content: "\e9ca";
} /* '' */
.uil-favorite:before {
  content: "\e9cb";
} /* '' */
.uil-feedback:before {
  content: "\e9cc";
} /* '' */
.uil-file-alt:before {
  content: "\e9cd";
} /* '' */
.uil-file-blank:before {
  content: "\e9ce";
} /* '' */
.uil-file-block-alt:before {
  content: "\e9cf";
} /* '' */
.uil-file-bookmark-alt:before {
  content: "\e9d0";
} /* '' */
.uil-file-check-alt:before {
  content: "\e9d1";
} /* '' */
.uil-file-check:before {
  content: "\e9d2";
} /* '' */
.uil-file-contract-dollar:before {
  content: "\e9d3";
} /* '' */
.uil-file-copy-alt:before {
  content: "\e9d4";
} /* '' */
.uil-file-download-alt:before {
  content: "\e9d5";
} /* '' */
.uil-file-download:before {
  content: "\e9d6";
} /* '' */
.uil-file-edit-alt:before {
  content: "\e9d7";
} /* '' */
.uil-file-exclamation-alt:before {
  content: "\e9d8";
} /* '' */
.uil-file-exclamation:before {
  content: "\e9d9";
} /* '' */
.uil-file-heart:before {
  content: "\e9da";
} /* '' */
.uil-file-info-alt:before {
  content: "\e9db";
} /* '' */
.uil-file-landscape-alt:before {
  content: "\e9dc";
} /* '' */
.uil-file-landscape:before {
  content: "\e9dd";
} /* '' */
.uil-file-lanscape-slash:before {
  content: "\e9de";
} /* '' */
.uil-file-lock-alt:before {
  content: "\e9df";
} /* '' */
.uil-file-medical-alt:before {
  content: "\e9e0";
} /* '' */
.uil-file-medical:before {
  content: "\e9e1";
} /* '' */
.uil-file-minus-alt:before {
  content: "\e9e2";
} /* '' */
.uil-file-minus:before {
  content: "\e9e3";
} /* '' */
.uil-file-network:before {
  content: "\e9e4";
} /* '' */
.uil-file-plus-alt:before {
  content: "\e9e5";
} /* '' */
.uil-file-plus:before {
  content: "\e9e6";
} /* '' */
.uil-file-question-alt:before {
  content: "\e9e7";
} /* '' */
.uil-file-question:before {
  content: "\e9e8";
} /* '' */
.uil-file-redo-alt:before {
  content: "\e9e9";
} /* '' */
.uil-file-search-alt:before {
  content: "\e9ea";
} /* '' */
.uil-file-share-alt:before {
  content: "\e9eb";
} /* '' */
.uil-file-shield-alt:before {
  content: "\e9ec";
} /* '' */
.uil-file-slash:before {
  content: "\e9ed";
} /* '' */
.uil-file-times-alt:before {
  content: "\e9ee";
} /* '' */
.uil-file-times:before {
  content: "\e9ef";
} /* '' */
.uil-file-upload-alt:before {
  content: "\e9f0";
} /* '' */
.uil-file-upload:before {
  content: "\e9f1";
} /* '' */
.uil-file:before {
  content: "\e9f2";
} /* '' */
.uil-files-landscapes-alt:before {
  content: "\e9f3";
} /* '' */
.uil-files-landscapes:before {
  content: "\e9f4";
} /* '' */
.uil-film:before {
  content: "\e9f5";
} /* '' */
.uil-filter-slash:before {
  content: "\e9f6";
} /* '' */
.uil-filter:before {
  content: "\e9f7";
} /* '' */
.uil-flask-potion:before {
  content: "\e9f8";
} /* '' */
.uil-flask:before {
  content: "\e9f9";
} /* '' */
.uil-flip-h-alt:before {
  content: "\e9fa";
} /* '' */
.uil-flip-h:before {
  content: "\e9fb";
} /* '' */
.uil-flip-v-alt:before {
  content: "\e9fc";
} /* '' */
.uil-flip-v:before {
  content: "\e9fd";
} /* '' */
.uil-flower:before {
  content: "\e9fe";
} /* '' */
.uil-focus-add:before {
  content: "\e9ff";
} /* '' */
.uil-focus-target:before {
  content: "\ea00";
} /* '' */
.uil-focus:before {
  content: "\ea01";
} /* '' */
.uil-folder-check:before {
  content: "\ea02";
} /* '' */
.uil-folder-download:before {
  content: "\ea03";
} /* '' */
.uil-folder-exclamation:before {
  content: "\ea04";
} /* '' */
.uil-folder-heart:before {
  content: "\ea05";
} /* '' */
.uil-folder-info:before {
  content: "\ea06";
} /* '' */
.uil-folder-lock:before {
  content: "\ea07";
} /* '' */
.uil-folder-medical:before {
  content: "\ea08";
} /* '' */
.uil-folder-minus:before {
  content: "\ea09";
} /* '' */
.uil-folder-network:before {
  content: "\ea0a";
} /* '' */
.uil-folder-plus:before {
  content: "\ea0b";
} /* '' */
.uil-folder-question:before {
  content: "\ea0c";
} /* '' */
.uil-folder-slash:before {
  content: "\ea0d";
} /* '' */
.uil-folder-times:before {
  content: "\ea0e";
} /* '' */
.uil-folder-upload:before {
  content: "\ea0f";
} /* '' */
.uil-folder:before {
  content: "\ea10";
} /* '' */
.uil-food:before {
  content: "\ea11";
} /* '' */
.uil-football-american:before {
  content: "\ea12";
} /* '' */
.uil-football-ball:before {
  content: "\ea13";
} /* '' */
.uil-football:before {
  content: "\ea14";
} /* '' */
.uil-forecastcloud-moon-tear:before {
  content: "\ea15";
} /* '' */
.uil-forwaded-call:before {
  content: "\ea16";
} /* '' */
.uil-forward:before {
  content: "\ea17";
} /* '' */
.uil-frown:before {
  content: "\ea18";
} /* '' */
.uil-game-structure:before {
  content: "\ea19";
} /* '' */
.uil-game:before {
  content: "\ea1a";
} /* '' */
.uil-gift:before {
  content: "\ea1b";
} /* '' */
.uil-github-alt:before {
  content: "\ea1c";
} /* '' */
.uil-github:before {
  content: "\ea1d";
} /* '' */
.uil-glass-martini-alt-slash:before {
  content: "\ea1e";
} /* '' */
.uil-glass-martini-alt:before {
  content: "\ea1f";
} /* '' */
.uil-glass-martini:before {
  content: "\ea20";
} /* '' */
.uil-glass-tea:before {
  content: "\ea21";
} /* '' */
.uil-glass:before {
  content: "\ea22";
} /* '' */
.uil-globe:before {
  content: "\ea23";
} /* '' */
.uil-gold:before {
  content: "\ea24";
} /* '' */
.uil-google-drive-alt:before {
  content: "\ea25";
} /* '' */
.uil-google-drive:before {
  content: "\ea26";
} /* '' */
.uil-google-hangouts:before {
  content: "\ea27";
} /* '' */
.uil-google-play:before {
  content: "\ea28";
} /* '' */
.uil-google:before {
  content: "\ea29";
} /* '' */
.uil-graduation-hat:before {
  content: "\ea2a";
} /* '' */
.uil-graph-bar:before {
  content: "\ea2b";
} /* '' */
.uil-grid:before {
  content: "\ea2c";
} /* '' */
.uil-grids:before {
  content: "\ea2d";
} /* '' */
.uil-grin-tongue-wink-alt:before {
  content: "\ea2e";
} /* '' */
.uil-grin-tongue-wink:before {
  content: "\ea2f";
} /* '' */
.uil-grin:before {
  content: "\ea30";
} /* '' */
.uil-grip-horizontal-line:before {
  content: "\ea31";
} /* '' */
.uil-hdd:before {
  content: "\ea32";
} /* '' */
.uil-headphones-alt:before {
  content: "\ea33";
} /* '' */
.uil-headphones:before {
  content: "\ea34";
} /* '' */
.uil-heart-alt:before {
  content: "\ea35";
} /* '' */
.uil-heart-medical:before {
  content: "\ea36";
} /* '' */
.uil-heart-rate:before {
  content: "\ea37";
} /* '' */
.uil-heart-sign:before {
  content: "\ea38";
} /* '' */
.uil-heart:before {
  content: "\ea39";
} /* '' */
.uil-heartbeat:before {
  content: "\ea3a";
} /* '' */
.uil-history-alt:before {
  content: "\ea3b";
} /* '' */
.uil-history:before {
  content: "\ea3c";
} /* '' */
.uil-home-alt:before {
  content: "\ea3d";
} /* '' */
.uil-home:before {
  content: "\ea3e";
} /* '' */
.uil-horizontal-align-center:before {
  content: "\ea3f";
} /* '' */
.uil-horizontal-align-left:before {
  content: "\ea40";
} /* '' */
.uil-horizontal-align-right:before {
  content: "\ea41";
} /* '' */
.uil-horizontal-distribution-center:before {
  content: "\ea42";
} /* '' */
.uil-horizontal-distribution-left:before {
  content: "\ea43";
} /* '' */
.uil-horizontal-distribution-right:before {
  content: "\ea44";
} /* '' */
.uil-hunting:before {
  content: "\ea45";
} /* '' */
.uil-image-alt-slash:before {
  content: "\ea46";
} /* '' */
.uil-image-block:before {
  content: "\ea47";
} /* '' */
.uil-image-broken:before {
  content: "\ea48";
} /* '' */
.uil-image-check:before {
  content: "\ea49";
} /* '' */
.uil-image-download:before {
  content: "\ea4a";
} /* '' */
.uil-image-edit:before {
  content: "\ea4b";
} /* '' */
.uil-image-lock:before {
  content: "\ea4c";
} /* '' */
.uil-image-minus:before {
  content: "\ea4d";
} /* '' */
.uil-image-plus:before {
  content: "\ea4e";
} /* '' */
.uil-image-question:before {
  content: "\ea4f";
} /* '' */
.uil-image-redo:before {
  content: "\ea50";
} /* '' */
.uil-image-resize-landscape:before {
  content: "\ea51";
} /* '' */
.uil-image-resize-square:before {
  content: "\ea52";
} /* '' */
.uil-image-search:before {
  content: "\ea53";
} /* '' */
.uil-image-share:before {
  content: "\ea54";
} /* '' */
.uil-image-shield:before {
  content: "\ea55";
} /* '' */
.uil-image-slash:before {
  content: "\ea56";
} /* '' */
.uil-image-times:before {
  content: "\ea57";
} /* '' */
.uil-image-upload:before {
  content: "\ea58";
} /* '' */
.uil-image-v:before {
  content: "\ea59";
} /* '' */
.uil-image:before {
  content: "\ea5a";
} /* '' */
.uil-images:before {
  content: "\ea5b";
} /* '' */
.uil-incoming-call:before {
  content: "\ea5c";
} /* '' */
.uil-info-circle:before {
  content: "\ea5d";
} /* '' */
.uil-instagram-alt:before {
  content: "\ea5e";
} /* '' */
.uil-instagram:before {
  content: "\ea5f";
} /* '' */
.uil-intercom:before {
  content: "\ea60";
} /* '' */
.uil-invoice:before {
  content: "\ea61";
} /* '' */
.uil-italic:before {
  content: "\ea62";
} /* '' */
.uil-jackhammer:before {
  content: "\ea63";
} /* '' */
.uil-java-script:before {
  content: "\ea64";
} /* '' */
.uil-kayak:before {
  content: "\ea65";
} /* '' */
.uil-key-skeleton-alt:before {
  content: "\ea66";
} /* '' */
.uil-key-skeleton:before {
  content: "\ea67";
} /* '' */
.uil-keyboard-alt:before {
  content: "\ea68";
} /* '' */
.uil-keyboard-hide:before {
  content: "\ea69";
} /* '' */
.uil-keyboard-show:before {
  content: "\ea6a";
} /* '' */
.uil-keyboard:before {
  content: "\ea6b";
} /* '' */
.uil-keyhole-circle:before {
  content: "\ea6c";
} /* '' */
.uil-keyhole-square-full:before {
  content: "\ea6d";
} /* '' */
.uil-keyhole-square:before {
  content: "\ea6e";
} /* '' */
.uil-kid:before {
  content: "\ea6f";
} /* '' */
.uil-label-alt:before {
  content: "\ea70";
} /* '' */
.uil-label:before {
  content: "\ea71";
} /* '' */
.uil-lamp:before {
  content: "\ea72";
} /* '' */
.uil-laptop-cloud:before {
  content: "\ea73";
} /* '' */
.uil-laptop:before {
  content: "\ea74";
} /* '' */
.uil-laughing:before {
  content: "\ea75";
} /* '' */
.uil-layer-group-slash:before {
  content: "\ea76";
} /* '' */
.uil-layer-group:before {
  content: "\ea77";
} /* '' */
.uil-layers-alt:before {
  content: "\ea78";
} /* '' */
.uil-layers-slash:before {
  content: "\ea79";
} /* '' */
.uil-layers:before {
  content: "\ea7a";
} /* '' */
.uil-left-arrow-from-left:before {
  content: "\ea7b";
} /* '' */
.uil-left-arrow-to-left:before {
  content: "\ea7c";
} /* '' */
.uil-left-indent-alt:before {
  content: "\ea7d";
} /* '' */
.uil-left-indent:before {
  content: "\ea7e";
} /* '' */
.uil-left-to-right-text-direction:before {
  content: "\ea7f";
} /* '' */
.uil-life-ring:before {
  content: "\ea80";
} /* '' */
.uil-lightbulb-alt:before {
  content: "\ea81";
} /* '' */
.uil-lightbulb:before {
  content: "\ea82";
} /* '' */
.uil-line-alt:before {
  content: "\ea83";
} /* '' */
.uil-line-spacing:before {
  content: "\ea84";
} /* '' */
.uil-line:before {
  content: "\ea85";
} /* '' */
.uil-link-alt:before {
  content: "\ea86";
} /* '' */
.uil-link-broken:before {
  content: "\ea87";
} /* '' */
.uil-link-h:before {
  content: "\ea88";
} /* '' */
.uil-link:before {
  content: "\ea89";
} /* '' */
.uil-linkedin-alt:before {
  content: "\ea8a";
} /* '' */
.uil-linkedin:before {
  content: "\ea8b";
} /* '' */
.uil-list-ui-alt:before {
  content: "\ea8c";
} /* '' */
.uil-list-ul:before {
  content: "\ea8d";
} /* '' */
.uil-location-arrow-alt:before {
  content: "\ea8e";
} /* '' */
.uil-location-arrow:before {
  content: "\ea8f";
} /* '' */
.uil-location-pin-alt:before {
  content: "\ea90";
} /* '' */
.uil-location-point:before {
  content: "\ea91";
} /* '' */
.uil-location:before {
  content: "\ea92";
} /* '' */
.uil-lock-access:before {
  content: "\ea93";
} /* '' */
.uil-lock-alt:before {
  content: "\ea94";
} /* '' */
.uil-lock-open-alt:before {
  content: "\ea95";
} /* '' */
.uil-lock-slash:before {
  content: "\ea96";
} /* '' */
.uil-lock:before {
  content: "\ea97";
} /* '' */
.uil-mailbox-alt:before {
  content: "\ea98";
} /* '' */
.uil-mailbox:before {
  content: "\ea99";
} /* '' */
.uil-map-marker-alt:before {
  content: "\ea9a";
} /* '' */
.uil-map-marker-edit:before {
  content: "\ea9b";
} /* '' */
.uil-map-marker-info:before {
  content: "\ea9c";
} /* '' */
.uil-map-marker-minus:before {
  content: "\ea9d";
} /* '' */
.uil-map-marker-plus:before {
  content: "\ea9e";
} /* '' */
.uil-map-marker-question:before {
  content: "\ea9f";
} /* '' */
.uil-map-marker-shield:before {
  content: "\eaa0";
} /* '' */
.uil-map-marker-slash:before {
  content: "\eaa1";
} /* '' */
.uil-map-marker:before {
  content: "\eaa2";
} /* '' */
.uil-map-pin-alt:before {
  content: "\eaa3";
} /* '' */
.uil-map-pin:before {
  content: "\eaa4";
} /* '' */
.uil-map:before {
  content: "\eaa5";
} /* '' */
.uil-mars:before {
  content: "\eaa6";
} /* '' */
.uil-maximize-left:before {
  content: "\eaa7";
} /* '' */
.uil-medal:before {
  content: "\eaa8";
} /* '' */
.uil-medical-drip:before {
  content: "\eaa9";
} /* '' */
.uil-medical-square-full:before {
  content: "\eaaa";
} /* '' */
.uil-medical-square:before {
  content: "\eaab";
} /* '' */
.uil-medical:before {
  content: "\eaac";
} /* '' */
.uil-medium-m:before {
  content: "\eaad";
} /* '' */
.uil-medkit:before {
  content: "\eaae";
} /* '' */
.uil-meeting-board:before {
  content: "\eaaf";
} /* '' */
.uil-meh-alt:before {
  content: "\eab0";
} /* '' */
.uil-meh-closed-eye:before {
  content: "\eab1";
} /* '' */
.uil-meh:before {
  content: "\eab2";
} /* '' */
.uil-message:before {
  content: "\eab3";
} /* '' */
.uil-microphone-slash:before {
  content: "\eab4";
} /* '' */
.uil-microphone:before {
  content: "\eab5";
} /* '' */
.uil-minus-circle:before {
  content: "\eab6";
} /* '' */
.uil-minus-path:before {
  content: "\eab7";
} /* '' */
.uil-minus-square-full:before {
  content: "\eab8";
} /* '' */
.uil-minus-square:before {
  content: "\eab9";
} /* '' */
.uil-minus:before {
  content: "\eaba";
} /* '' */
.uil-missed-call:before {
  content: "\eabb";
} /* '' */
.uil-mobey-bill-slash:before {
  content: "\eabc";
} /* '' */
.uil-mobile-android-alt:before {
  content: "\eabd";
} /* '' */
.uil-mobile-android:before {
  content: "\eabe";
} /* '' */
.uil-mobile-vibrate:before {
  content: "\eabf";
} /* '' */
.uil-modem:before {
  content: "\eac0";
} /* '' */
.uil-money-bill-stack:before {
  content: "\eac1";
} /* '' */
.uil-money-bill:before {
  content: "\eac2";
} /* '' */
.uil-money-insert:before {
  content: "\eac3";
} /* '' */
.uil-money-stack:before {
  content: "\eac4";
} /* '' */
.uil-money-withdraw:before {
  content: "\eac5";
} /* '' */
.uil-money-withdrawal:before {
  content: "\eac6";
} /* '' */
.uil-moneybag-alt:before {
  content: "\eac7";
} /* '' */
.uil-moneybag:before {
  content: "\eac8";
} /* '' */
.uil-monitor-heart-rate:before {
  content: "\eac9";
} /* '' */
.uil-monitor:before {
  content: "\eaca";
} /* '' */
.uil-moon-eclipse:before {
  content: "\eacb";
} /* '' */
.uil-moon:before {
  content: "\eacc";
} /* '' */
.uil-moonset:before {
  content: "\eacd";
} /* '' */
.uil-mountains-sun:before {
  content: "\eace";
} /* '' */
.uil-mountains:before {
  content: "\eacf";
} /* '' */
.uil-mouse-alt:before {
  content: "\ead0";
} /* '' */
.uil-mouse:before {
  content: "\ead1";
} /* '' */
.uil-multiply:before {
  content: "\ead2";
} /* '' */
.uil-music-note:before {
  content: "\ead3";
} /* '' */
.uil-music-tune-slash:before {
  content: "\ead4";
} /* '' */
.uil-music:before {
  content: "\ead5";
} /* '' */
.uil-n-a:before {
  content: "\ead6";
} /* '' */
.uil-navigator:before {
  content: "\ead7";
} /* '' */
.uil-nerd:before {
  content: "\ead8";
} /* '' */
.uil-newspaper:before {
  content: "\ead9";
} /* '' */
.uil-ninja:before {
  content: "\eada";
} /* '' */
.uil-no-entry:before {
  content: "\eadb";
} /* '' */
.uil-notebooks:before {
  content: "\eadc";
} /* '' */
.uil-notes:before {
  content: "\eadd";
} /* '' */
.uil-object-group:before {
  content: "\eade";
} /* '' */
.uil-object-ungroup:before {
  content: "\eadf";
} /* '' */
.uil-octagon:before {
  content: "\eae0";
} /* '' */
.uil-outgoing-call:before {
  content: "\eae1";
} /* '' */
.uil-package:before {
  content: "\eae2";
} /* '' */
.uil-padlock:before {
  content: "\eae3";
} /* '' */
.uil-paint-tool:before {
  content: "\eae4";
} /* '' */
.uil-palette:before {
  content: "\eae5";
} /* '' */
.uil-panorama-h-alt:before {
  content: "\eae6";
} /* '' */
.uil-panorama-h:before {
  content: "\eae7";
} /* '' */
.uil-panorama-v:before {
  content: "\eae8";
} /* '' */
.uil-paperclip:before {
  content: "\eae9";
} /* '' */
.uil-paragraph:before {
  content: "\eaea";
} /* '' */
.uil-parcel:before {
  content: "\eaeb";
} /* '' */
.uil-parking-square:before {
  content: "\eaec";
} /* '' */
.uil-pathfinder-unite:before {
  content: "\eaed";
} /* '' */
.uil-pathfinder:before {
  content: "\eaee";
} /* '' */
.uil-pause-circle:before {
  content: "\eaef";
} /* '' */
.uil-pause:before {
  content: "\eaf0";
} /* '' */
.uil-paypal:before {
  content: "\eaf1";
} /* '' */
.uil-pen:before {
  content: "\eaf2";
} /* '' */
.uil-pentagon:before {
  content: "\eaf3";
} /* '' */
.uil-phone-alt:before {
  content: "\eaf4";
} /* '' */
.uil-phone-pause:before {
  content: "\eaf5";
} /* '' */
.uil-phone-slash:before {
  content: "\eaf6";
} /* '' */
.uil-phone-times:before {
  content: "\eaf7";
} /* '' */
.uil-phone-volume:before {
  content: "\eaf8";
} /* '' */
.uil-phone:before {
  content: "\eaf9";
} /* '' */
.uil-picture:before {
  content: "\eafa";
} /* '' */
.uil-plane-arrival:before {
  content: "\eafb";
} /* '' */
.uil-plane-departure:before {
  content: "\eafc";
} /* '' */
.uil-plane-fly:before {
  content: "\eafd";
} /* '' */
.uil-plane:before {
  content: "\eafe";
} /* '' */
.uil-play-circle:before {
  content: "\eaff";
} /* '' */
.uil-play:before {
  content: "\eb00";
} /* '' */
.uil-plug:before {
  content: "\eb01";
} /* '' */
.uil-plus-circle:before {
  content: "\eb02";
} /* '' */
.uil-plus-square:before {
  content: "\eb03";
} /* '' */
.uil-plus:before {
  content: "\eb04";
} /* '' */
.uil-podium:before {
  content: "\eb05";
} /* '' */
.uil-polygon:before {
  content: "\eb06";
} /* '' */
.uil-post-stamp:before {
  content: "\eb07";
} /* '' */
.uil-postcard:before {
  content: "\eb08";
} /* '' */
.uil-pound-circle:before {
  content: "\eb09";
} /* '' */
.uil-pound:before {
  content: "\eb0a";
} /* '' */
.uil-power:before {
  content: "\eb0b";
} /* '' */
.uil-prescription-bottle:before {
  content: "\eb0c";
} /* '' */
.uil-presentation-check:before {
  content: "\eb0d";
} /* '' */
.uil-presentation-edit:before {
  content: "\eb0e";
} /* '' */
.uil-presentation-line:before {
  content: "\eb0f";
} /* '' */
.uil-presentation-lines-alt:before {
  content: "\eb10";
} /* '' */
.uil-presentation-minus:before {
  content: "\eb11";
} /* '' */
.uil-presentation-play:before {
  content: "\eb12";
} /* '' */
.uil-presentation-plus:before {
  content: "\eb13";
} /* '' */
.uil-presentation-times:before {
  content: "\eb14";
} /* '' */
.uil-presentation:before {
  content: "\eb15";
} /* '' */
.uil-previous:before {
  content: "\eb16";
} /* '' */
.uil-pricetag-alt:before {
  content: "\eb17";
} /* '' */
.uil-print-slash:before {
  content: "\eb18";
} /* '' */
.uil-print:before {
  content: "\eb19";
} /* '' */
.uil-processor:before {
  content: "\eb1a";
} /* '' */
.uil-pump:before {
  content: "\eb1b";
} /* '' */
.uil-puzzle-piece:before {
  content: "\eb1c";
} /* '' */
.uil-question-circle:before {
  content: "\eb1d";
} /* '' */
.uil-raddit-alien-alt:before {
  content: "\eb1e";
} /* '' */
.uil-rainbow:before {
  content: "\eb1f";
} /* '' */
.uil-raindrops-alt:before {
  content: "\eb20";
} /* '' */
.uil-raindrops:before {
  content: "\eb21";
} /* '' */
.uil-receipt-alt:before {
  content: "\eb22";
} /* '' */
.uil-receipt:before {
  content: "\eb23";
} /* '' */
.uil-record-audio:before {
  content: "\eb24";
} /* '' */
.uil-redo:before {
  content: "\eb25";
} /* '' */
.uil-refresh:before {
  content: "\eb26";
} /* '' */
.uil-registered:before {
  content: "\eb27";
} /* '' */
.uil-repeat:before {
  content: "\eb28";
} /* '' */
.uil-restaurant:before {
  content: "\eb29";
} /* '' */
.uil-right-indent-alt:before {
  content: "\eb2a";
} /* '' */
.uil-right-to-left-text-direction:before {
  content: "\eb2b";
} /* '' */
.uil-robot:before {
  content: "\eb2c";
} /* '' */
.uil-rope-way:before {
  content: "\eb2d";
} /* '' */
.uil-rotate-360:before {
  content: "\eb2e";
} /* '' */
.uil-rss-alt:before {
  content: "\eb2f";
} /* '' */
.uil-rss-interface:before {
  content: "\eb30";
} /* '' */
.uil-rss:before {
  content: "\eb31";
} /* '' */
.uil-ruler-combined:before {
  content: "\eb32";
} /* '' */
.uil-ruler:before {
  content: "\eb33";
} /* '' */
.uil-sad-cry:before {
  content: "\eb34";
} /* '' */
.uil-sad-crying:before {
  content: "\eb35";
} /* '' */
.uil-sad-dizzy:before {
  content: "\eb36";
} /* '' */
.uil-sad-squint:before {
  content: "\eb37";
} /* '' */
.uil-sad:before {
  content: "\eb38";
} /* '' */
.uil-scaling-left:before {
  content: "\eb39";
} /* '' */
.uil-scaling-right:before {
  content: "\eb3a";
} /* '' */
.uil-scenery:before {
  content: "\eb3b";
} /* '' */
.uil-schedule:before {
  content: "\eb3c";
} /* '' */
.uil-science:before {
  content: "\eb3d";
} /* '' */
.uil-screw:before {
  content: "\eb3e";
} /* '' */
.uil-scroll-h:before {
  content: "\eb3f";
} /* '' */
.uil-scroll:before {
  content: "\eb40";
} /* '' */
.uil-search-alt:before {
  content: "\eb41";
} /* '' */
.uil-search-minus:before {
  content: "\eb42";
} /* '' */
.uil-search-plus:before {
  content: "\eb43";
} /* '' */
.uil-search:before {
  content: "\eb44";
} /* '' */
.uil-selfie:before {
  content: "\eb45";
} /* '' */
.uil-server-alt:before {
  content: "\eb46";
} /* '' */
.uil-server-connection:before {
  content: "\eb47";
} /* '' */
.uil-server-network-alt:before {
  content: "\eb48";
} /* '' */
.uil-server-network:before {
  content: "\eb49";
} /* '' */
.uil-server:before {
  content: "\eb4a";
} /* '' */
.uil-servers:before {
  content: "\eb4b";
} /* '' */
.uil-servicemark:before {
  content: "\eb4c";
} /* '' */
.uil-share-alt:before {
  content: "\eb4d";
} /* '' */
.uil-shield-check:before {
  content: "\eb4e";
} /* '' */
.uil-shield-exclamation:before {
  content: "\eb4f";
} /* '' */
.uil-shield-question:before {
  content: "\eb50";
} /* '' */
.uil-shield-slash:before {
  content: "\eb51";
} /* '' */
.uil-shield:before {
  content: "\eb52";
} /* '' */
.uil-ship:before {
  content: "\eb53";
} /* '' */
.uil-shop:before {
  content: "\eb54";
} /* '' */
.uil-shopping-basket:before {
  content: "\eb55";
} /* '' */
.uil-shopping-cart-alt:before {
  content: "\eb56";
} /* '' */
.uil-shopping-trolley:before {
  content: "\eb57";
} /* '' */
.uil-shovel:before {
  content: "\eb58";
} /* '' */
.uil-shrink:before {
  content: "\eb59";
} /* '' */
.uil-shuffle:before {
  content: "\eb5a";
} /* '' */
.uil-shutter-alt:before {
  content: "\eb5b";
} /* '' */
.uil-shutter:before {
  content: "\eb5c";
} /* '' */
.uil-sick:before {
  content: "\eb5d";
} /* '' */
.uil-sigma:before {
  content: "\eb5e";
} /* '' */
.uil-sign-alt:before {
  content: "\eb5f";
} /* '' */
.uil-sign-in-alt:before {
  content: "\eb60";
} /* '' */
.uil-sign-left:before {
  content: "\eb61";
} /* '' */
.uil-sign-out-alt:before {
  content: "\eb62";
} /* '' */
.uil-sign-right:before {
  content: "\eb63";
} /* '' */
.uil-signal-alt-3:before {
  content: "\eb64";
} /* '' */
.uil-signal-alt:before {
  content: "\eb65";
} /* '' */
.uil-signal:before {
  content: "\eb66";
} /* '' */
.uil-silence:before {
  content: "\eb67";
} /* '' */
.uil-silent-squint:before {
  content: "\eb68";
} /* '' */
.uil-sim-card:before {
  content: "\eb69";
} /* '' */
.uil-sitemap:before {
  content: "\eb6a";
} /* '' */
.uil-skip-forward-alt:before {
  content: "\eb6b";
} /* '' */
.uil-skip-forward-circle:before {
  content: "\eb6c";
} /* '' */
.uil-skip-forward:before {
  content: "\eb6d";
} /* '' */
.uil-slack-alt:before {
  content: "\eb6e";
} /* '' */
.uil-slack:before {
  content: "\eb6f";
} /* '' */
.uil-sliders-v-alt:before {
  content: "\eb70";
} /* '' */
.uil-sliders-v:before {
  content: "\eb71";
} /* '' */
.uil-smile-beam:before {
  content: "\eb72";
} /* '' */
.uil-smile-dizzy:before {
  content: "\eb73";
} /* '' */
.uil-smile-squint-wink-alt:before {
  content: "\eb74";
} /* '' */
.uil-smile-squint-wink:before {
  content: "\eb75";
} /* '' */
.uil-smile-wink-alt:before {
  content: "\eb76";
} /* '' */
.uil-smile-wink:before {
  content: "\eb77";
} /* '' */
.uil-smile:before {
  content: "\eb78";
} /* '' */
.uil-snapchat-ghost:before {
  content: "\eb79";
} /* '' */
.uil-snapchat-square:before {
  content: "\eb7a";
} /* '' */
.uil-snow-flake:before {
  content: "\eb7b";
} /* '' */
.uil-snowflake-alt:before {
  content: "\eb7c";
} /* '' */
.uil-snowflake:before {
  content: "\eb7d";
} /* '' */
.uil-sort-amount-down:before {
  content: "\eb7e";
} /* '' */
.uil-sort-amount-up:before {
  content: "\eb7f";
} /* '' */
.uil-sort:before {
  content: "\eb80";
} /* '' */
.uil-sorting:before {
  content: "\eb81";
} /* '' */
.uil-space-key:before {
  content: "\eb82";
} /* '' */
.uil-spade:before {
  content: "\eb83";
} /* '' */
.uil-sperms:before {
  content: "\eb84";
} /* '' */
.uil-spin:before {
  content: "\eb85";
} /* '' */
.uil-sport:before {
  content: "\eb86";
} /* '' */
.uil-square-full:before {
  content: "\eb87";
} /* '' */
.uil-square-shape:before {
  content: "\eb88";
} /* '' */
.uil-square:before {
  content: "\eb89";
} /* '' */
.uil-squint:before {
  content: "\eb8a";
} /* '' */
.uil-star-half-alt:before {
  content: "\eb8b";
} /* '' */
.uil-star:before {
  content: "\eb8c";
} /* '' */
.uil-step-backward-alt:before {
  content: "\eb8d";
} /* '' */
.uil-step-backward-circle:before {
  content: "\eb8e";
} /* '' */
.uil-step-backward:before {
  content: "\eb8f";
} /* '' */
.uil-step-forward:before {
  content: "\eb90";
} /* '' */
.uil-stop-circle:before {
  content: "\eb91";
} /* '' */
.uil-stopwatch-slash:before {
  content: "\eb92";
} /* '' */
.uil-stopwatch:before {
  content: "\eb93";
} /* '' */
.uil-store-alt:before {
  content: "\eb94";
} /* '' */
.uil-store:before {
  content: "\eb95";
} /* '' */
.uil-streering:before {
  content: "\eb96";
} /* '' */
.uil-stretcher:before {
  content: "\eb97";
} /* '' */
.uil-subject:before {
  content: "\eb98";
} /* '' */
.uil-subway-alt:before {
  content: "\eb99";
} /* '' */
.uil-subway:before {
  content: "\eb9a";
} /* '' */
.uil-suitcase-alt:before {
  content: "\eb9b";
} /* '' */
.uil-suitcase:before {
  content: "\eb9c";
} /* '' */
.uil-sun:before {
  content: "\eb9d";
} /* '' */
.uil-sunset:before {
  content: "\eb9e";
} /* '' */
.uil-surprise:before {
  content: "\eb9f";
} /* '' */
.uil-swatchbook:before {
  content: "\eba0";
} /* '' */
.uil-swimmer:before {
  content: "\eba1";
} /* '' */
.uil-symbol:before {
  content: "\eba2";
} /* '' */
.uil-sync-exclamation:before {
  content: "\eba3";
} /* '' */
.uil-sync-slash:before {
  content: "\eba4";
} /* '' */
.uil-sync:before {
  content: "\eba5";
} /* '' */
.uil-syringe:before {
  content: "\eba6";
} /* '' */
.uil-table:before {
  content: "\eba7";
} /* '' */
.uil-tablet:before {
  content: "\eba8";
} /* '' */
.uil-tablets:before {
  content: "\eba9";
} /* '' */
.uil-tachometer-fast:before {
  content: "\ebaa";
} /* '' */
.uil-tag-alt:before {
  content: "\ebab";
} /* '' */
.uil-tag:before {
  content: "\ebac";
} /* '' */
.uil-tape:before {
  content: "\ebad";
} /* '' */
.uil-taxi:before {
  content: "\ebae";
} /* '' */
.uil-tear:before {
  content: "\ebaf";
} /* '' */
.uil-technology:before {
  content: "\ebb0";
} /* '' */
.uil-telescope:before {
  content: "\ebb1";
} /* '' */
.uil-temperature-empty:before {
  content: "\ebb2";
} /* '' */
.uil-temperature-half:before {
  content: "\ebb3";
} /* '' */
.uil-temperature-minus:before {
  content: "\ebb4";
} /* '' */
.uil-temperature-plus:before {
  content: "\ebb5";
} /* '' */
.uil-temperature-quarter:before {
  content: "\ebb6";
} /* '' */
.uil-temperature-three-quarter:before {
  content: "\ebb7";
} /* '' */
.uil-temperature:before {
  content: "\ebb8";
} /* '' */
.uil-text-fields:before {
  content: "\ebb9";
} /* '' */
.uil-text-size:before {
  content: "\ebba";
} /* '' */
.uil-text-strike-through:before {
  content: "\ebbb";
} /* '' */
.uil-text:before {
  content: "\ebbc";
} /* '' */
.uil-th-large:before {
  content: "\ebbd";
} /* '' */
.uil-th-slash:before {
  content: "\ebbe";
} /* '' */
.uil-th:before {
  content: "\ebbf";
} /* '' */
.uil-thermometer:before {
  content: "\ebc0";
} /* '' */
.uil-thumbs-down:before {
  content: "\ebc1";
} /* '' */
.uil-thumbs-up:before {
  content: "\ebc2";
} /* '' */
.uil-thunderstorm-moon:before {
  content: "\ebc3";
} /* '' */
.uil-thunderstorm-sun:before {
  content: "\ebc4";
} /* '' */
.uil-thunderstorm:before {
  content: "\ebc5";
} /* '' */
.uil-ticket:before {
  content: "\ebc6";
} /* '' */
.uil-times-circle:before {
  content: "\ebc7";
} /* '' */
.uil-times-square:before {
  content: "\ebc8";
} /* '' */
.uil-times:before {
  content: "\ebc9";
} /* '' */
.uil-toggle-off:before {
  content: "\ebca";
} /* '' */
.uil-toggle-on:before {
  content: "\ebcb";
} /* '' */
.uil-top-arrow-from-top:before {
  content: "\ebcc";
} /* '' */
.uil-top-arrow-to-top:before {
  content: "\ebcd";
} /* '' */
.uil-tornado:before {
  content: "\ebce";
} /* '' */
.uil-trademark-circle:before {
  content: "\ebcf";
} /* '' */
.uil-trademark:before {
  content: "\ebd0";
} /* '' */
.uil-traffic-barrier:before {
  content: "\ebd1";
} /* '' */
.uil-trash-alt:before {
  content: "\ebd2";
} /* '' */
.uil-trash:before {
  content: "\ebd3";
} /* '' */
.uil-trees:before {
  content: "\ebd4";
} /* '' */
.uil-triangle:before {
  content: "\ebd5";
} /* '' */
.uil-trophy:before {
  content: "\ebd6";
} /* '' */
.uil-trowel:before {
  content: "\ebd7";
} /* '' */
.uil-truck-case:before {
  content: "\ebd8";
} /* '' */
.uil-truck-loading:before {
  content: "\ebd9";
} /* '' */
.uil-truck:before {
  content: "\ebda";
} /* '' */
.uil-tumblr-square:before {
  content: "\ebdb";
} /* '' */
.uil-tumblr:before {
  content: "\ebdc";
} /* '' */
.uil-tv-retro-slash:before {
  content: "\ebdd";
} /* '' */
.uil-tv-retro:before {
  content: "\ebde";
} /* '' */
.uil-twitter:before {
  content: "\ebdf";
} /* '' */
.uil-umbrella:before {
  content: "\ebe0";
} /* '' */
.uil-unamused:before {
  content: "\ebe1";
} /* '' */
.uil-underline:before {
  content: "\ebe2";
} /* '' */
.uil-unlock-alt:before {
  content: "\ebe3";
} /* '' */
.uil-unlock:before {
  content: "\ebe4";
} /* '' */
.uil-upload-alt:before {
  content: "\ebe5";
} /* '' */
.uil-upload:before {
  content: "\ebe6";
} /* '' */
.uil-usd-circle:before {
  content: "\ebe7";
} /* '' */
.uil-usd-square:before {
  content: "\ebe8";
} /* '' */
.uil-user-check:before {
  content: "\ebe9";
} /* '' */
.uil-user-circle:before {
  content: "\ebea";
} /* '' */
.uil-user-exclamation:before {
  content: "\ebeb";
} /* '' */
.uil-user-hard-hat:before {
  content: "\ebec";
} /* '' */
.uil-user-minus:before {
  content: "\ebed";
} /* '' */
.uil-user-plus:before {
  content: "\ebee";
} /* '' */
.uil-user-square:before {
  content: "\ebef";
} /* '' */
.uil-user-times:before {
  content: "\ebf0";
} /* '' */
.uil-user:before {
  content: "\ebf1";
} /* '' */
.uil-users-alt:before {
  content: "\ebf2";
} /* '' */
.uil-utensils-alt:before {
  content: "\ebf3";
} /* '' */
.uil-utensils:before {
  content: "\ebf4";
} /* '' */
.uil-vector-square-alt:before {
  content: "\ebf5";
} /* '' */
.uil-vector-square:before {
  content: "\ebf6";
} /* '' */
.uil-venus:before {
  content: "\ebf7";
} /* '' */
.uil-vertical-align-bottom:before {
  content: "\ebf8";
} /* '' */
.uil-vertical-align-center:before {
  content: "\ebf9";
} /* '' */
.uil-vertical-align-top:before {
  content: "\ebfa";
} /* '' */
.uil-vertical-distribute-bottom:before {
  content: "\ebfb";
} /* '' */
.uil-vertical-distribution-center:before {
  content: "\ebfc";
} /* '' */
.uil-vertical-distribution-top:before {
  content: "\ebfd";
} /* '' */
.uil-video-slash:before {
  content: "\ebfe";
} /* '' */
.uil-video:before {
  content: "\ebff";
} /* '' */
.uil-visual-studio:before {
  content: "\ec00";
} /* '' */
.uil-voicemail-rectangle:before {
  content: "\ec01";
} /* '' */
.uil-voicemail:before {
  content: "\ec02";
} /* '' */
.uil-volleyball:before {
  content: "\ec03";
} /* '' */
.uil-volume-down:before {
  content: "\ec04";
} /* '' */
.uil-volume-mute:before {
  content: "\ec05";
} /* '' */
.uil-volume-off:before {
  content: "\ec06";
} /* '' */
.uil-volume-up:before {
  content: "\ec07";
} /* '' */
.uil-volume:before {
  content: "\ec08";
} /* '' */
.uil-vuejs-alt:before {
  content: "\ec09";
} /* '' */
.uil-vuejs:before {
  content: "\ec0a";
} /* '' */
.uil-wall:before {
  content: "\ec0b";
} /* '' */
.uil-wallet:before {
  content: "\ec0c";
} /* '' */
.uil-watch-alt:before {
  content: "\ec0d";
} /* '' */
.uil-watch:before {
  content: "\ec0e";
} /* '' */
.uil-water-drop-slash:before {
  content: "\ec0f";
} /* '' */
.uil-water-glass:before {
  content: "\ec10";
} /* '' */
.uil-water:before {
  content: "\ec11";
} /* '' */
.uil-web-grid-alt:before {
  content: "\ec12";
} /* '' */
.uil-web-grid:before {
  content: "\ec13";
} /* '' */
.uil-web-section-alt:before {
  content: "\ec14";
} /* '' */
.uil-web-section:before {
  content: "\ec15";
} /* '' */
.uil-webcam:before {
  content: "\ec16";
} /* '' */
.uil-weight:before {
  content: "\ec17";
} /* '' */
.uil-whatsapp:before {
  content: "\ec18";
} /* '' */
.uil-wheel-barrow:before {
  content: "\ec19";
} /* '' */
.uil-wheelchair-alt:before {
  content: "\ec1a";
} /* '' */
.uil-wheelchair:before {
  content: "\ec1b";
} /* '' */
.uil-wifi-router:before {
  content: "\ec1c";
} /* '' */
.uil-wifi-slash:before {
  content: "\ec1d";
} /* '' */
.uil-wifi:before {
  content: "\ec1e";
} /* '' */
.uil-wind:before {
  content: "\ec1f";
} /* '' */
.uil-window-grid:before {
  content: "\ec20";
} /* '' */
.uil-window-maximize:before {
  content: "\ec21";
} /* '' */
.uil-window-restore:before {
  content: "\ec22";
} /* '' */
.uil-window-section:before {
  content: "\ec23";
} /* '' */
.uil-window:before {
  content: "\ec24";
} /* '' */
.uil-windsock:before {
  content: "\ec25";
} /* '' */
.uil-wrap-text:before {
  content: "\ec26";
} /* '' */
.uil-wrench:before {
  content: "\ec27";
} /* '' */
.uil-yellow:before {
  content: "\ec28";
} /* '' */
.uil-yen-circle:before {
  content: "\ec29";
} /* '' */
.uil-yen:before {
  content: "\ec2a";
} /* '' */
.uil-youtube-alt:before {
  content: "\ec2b";
} /* '' */
.uil-youtube:before {
  content: "\ec2c";
} /* '' */
@font-face {
  font-family: "dripicons-v2";
  src: url("../fonts/dripicons-v2.eot");
  src: url("../fonts/dripicons-v2.eot?#iefix") format("embedded-opentype"), url("../fonts/dripicons-v2.woff") format("woff"), url("../fonts/dripicons-v2.ttf") format("truetype"), url("../fonts/dripicons-v2.svg#dripicons-v2") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "dripicons-v2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=dripicons-]:before,
[class*=" dripicons-"]:before {
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
}

.dripicons-alarm:before {
  content: "a";
}

.dripicons-align-center:before {
  content: "b";
}

.dripicons-align-justify:before {
  content: "c";
}

.dripicons-align-left:before {
  content: "d";
}

.dripicons-align-right:before {
  content: "e";
}

.dripicons-anchor:before {
  content: "f";
}

.dripicons-archive:before {
  content: "g";
}

.dripicons-arrow-down:before {
  content: "h";
}

.dripicons-arrow-left:before {
  content: "i";
}

.dripicons-arrow-right:before {
  content: "j";
}

.dripicons-arrow-thin-down:before {
  content: "k";
}

.dripicons-arrow-thin-left:before {
  content: "l";
}

.dripicons-arrow-thin-right:before {
  content: "m";
}

.dripicons-arrow-thin-up:before {
  content: "n";
}

.dripicons-arrow-up:before {
  content: "o";
}

.dripicons-article:before {
  content: "p";
}

.dripicons-backspace:before {
  content: "q";
}

.dripicons-basket:before {
  content: "r";
}

.dripicons-basketball:before {
  content: "s";
}

.dripicons-battery-empty:before {
  content: "t";
}

.dripicons-battery-full:before {
  content: "u";
}

.dripicons-battery-low:before {
  content: "v";
}

.dripicons-battery-medium:before {
  content: "w";
}

.dripicons-bell:before {
  content: "x";
}

.dripicons-blog:before {
  content: "y";
}

.dripicons-bluetooth:before {
  content: "z";
}

.dripicons-bold:before {
  content: "A";
}

.dripicons-bookmark:before {
  content: "B";
}

.dripicons-bookmarks:before {
  content: "C";
}

.dripicons-box:before {
  content: "D";
}

.dripicons-briefcase:before {
  content: "E";
}

.dripicons-brightness-low:before {
  content: "F";
}

.dripicons-brightness-max:before {
  content: "G";
}

.dripicons-brightness-medium:before {
  content: "H";
}

.dripicons-broadcast:before {
  content: "I";
}

.dripicons-browser:before {
  content: "J";
}

.dripicons-browser-upload:before {
  content: "K";
}

.dripicons-brush:before {
  content: "L";
}

.dripicons-calendar:before {
  content: "M";
}

.dripicons-camcorder:before {
  content: "N";
}

.dripicons-camera:before {
  content: "O";
}

.dripicons-card:before {
  content: "P";
}

.dripicons-cart:before {
  content: "Q";
}

.dripicons-checklist:before {
  content: "R";
}

.dripicons-checkmark:before {
  content: "S";
}

.dripicons-chevron-down:before {
  content: "T";
}

.dripicons-chevron-left:before {
  content: "U";
}

.dripicons-chevron-right:before {
  content: "V";
}

.dripicons-chevron-up:before {
  content: "W";
}

.dripicons-clipboard:before {
  content: "X";
}

.dripicons-clock:before {
  content: "Y";
}

.dripicons-clockwise:before {
  content: "Z";
}

.dripicons-cloud:before {
  content: "0";
}

.dripicons-cloud-download:before {
  content: "1";
}

.dripicons-cloud-upload:before {
  content: "2";
}

.dripicons-code:before {
  content: "3";
}

.dripicons-contract:before {
  content: "4";
}

.dripicons-contract-2:before {
  content: "5";
}

.dripicons-conversation:before {
  content: "6";
}

.dripicons-copy:before {
  content: "7";
}

.dripicons-crop:before {
  content: "8";
}

.dripicons-cross:before {
  content: "9";
}

.dripicons-crosshair:before {
  content: "!";
}

.dripicons-cutlery:before {
  content: '"';
}

.dripicons-device-desktop:before {
  content: "#";
}

.dripicons-device-mobile:before {
  content: "$";
}

.dripicons-device-tablet:before {
  content: "%";
}

.dripicons-direction:before {
  content: "&";
}

.dripicons-disc:before {
  content: "'";
}

.dripicons-document:before {
  content: "(";
}

.dripicons-document-delete:before {
  content: ")";
}

.dripicons-document-edit:before {
  content: "*";
}

.dripicons-document-new:before {
  content: "+";
}

.dripicons-document-remove:before {
  content: ",";
}

.dripicons-dot:before {
  content: "-";
}

.dripicons-dots-2:before {
  content: ".";
}

.dripicons-dots-3:before {
  content: "/";
}

.dripicons-download:before {
  content: ":";
}

.dripicons-duplicate:before {
  content: ";";
}

.dripicons-enter:before {
  content: "<";
}

.dripicons-exit:before {
  content: "=";
}

.dripicons-expand:before {
  content: ">";
}

.dripicons-expand-2:before {
  content: "?";
}

.dripicons-experiment:before {
  content: "@";
}

.dripicons-export:before {
  content: "[";
}

.dripicons-feed:before {
  content: "]";
}

.dripicons-flag:before {
  content: "^";
}

.dripicons-flashlight:before {
  content: "_";
}

.dripicons-folder:before {
  content: "`";
}

.dripicons-folder-open:before {
  content: "{";
}

.dripicons-forward:before {
  content: "|";
}

.dripicons-gaming:before {
  content: "}";
}

.dripicons-gear:before {
  content: "~";
}

.dripicons-graduation:before {
  content: "\\";
}

.dripicons-graph-bar:before {
  content: "\e000";
}

.dripicons-graph-line:before {
  content: "\e001";
}

.dripicons-graph-pie:before {
  content: "\e002";
}

.dripicons-headset:before {
  content: "\e003";
}

.dripicons-heart:before {
  content: "\e004";
}

.dripicons-help:before {
  content: "\e005";
}

.dripicons-home:before {
  content: "\e006";
}

.dripicons-hourglass:before {
  content: "\e007";
}

.dripicons-inbox:before {
  content: "\e008";
}

.dripicons-information:before {
  content: "\e009";
}

.dripicons-italic:before {
  content: "\e00a";
}

.dripicons-jewel:before {
  content: "\e00b";
}

.dripicons-lifting:before {
  content: "\e00c";
}

.dripicons-lightbulb:before {
  content: "\e00d";
}

.dripicons-link:before {
  content: "\e00e";
}

.dripicons-link-broken:before {
  content: "\e00f";
}

.dripicons-list:before {
  content: "\e010";
}

.dripicons-loading:before {
  content: "\e011";
}

.dripicons-location:before {
  content: "\e012";
}

.dripicons-lock:before {
  content: "\e013";
}

.dripicons-lock-open:before {
  content: "\e014";
}

.dripicons-mail:before {
  content: "\e015";
}

.dripicons-map:before {
  content: "\e016";
}

.dripicons-media-loop:before {
  content: "\e017";
}

.dripicons-media-next:before {
  content: "\e018";
}

.dripicons-media-pause:before {
  content: "\e019";
}

.dripicons-media-play:before {
  content: "\e01a";
}

.dripicons-media-previous:before {
  content: "\e01b";
}

.dripicons-media-record:before {
  content: "\e01c";
}

.dripicons-media-shuffle:before {
  content: "\e01d";
}

.dripicons-media-stop:before {
  content: "\e01e";
}

.dripicons-medical:before {
  content: "\e01f";
}

.dripicons-menu:before {
  content: "\e020";
}

.dripicons-message:before {
  content: "\e021";
}

.dripicons-meter:before {
  content: "\e022";
}

.dripicons-microphone:before {
  content: "\e023";
}

.dripicons-minus:before {
  content: "\e024";
}

.dripicons-monitor:before {
  content: "\e025";
}

.dripicons-move:before {
  content: "\e026";
}

.dripicons-music:before {
  content: "\e027";
}

.dripicons-network-1:before {
  content: "\e028";
}

.dripicons-network-2:before {
  content: "\e029";
}

.dripicons-network-3:before {
  content: "\e02a";
}

.dripicons-network-4:before {
  content: "\e02b";
}

.dripicons-network-5:before {
  content: "\e02c";
}

.dripicons-pamphlet:before {
  content: "\e02d";
}

.dripicons-paperclip:before {
  content: "\e02e";
}

.dripicons-pencil:before {
  content: "\e02f";
}

.dripicons-phone:before {
  content: "\e030";
}

.dripicons-photo:before {
  content: "\e031";
}

.dripicons-photo-group:before {
  content: "\e032";
}

.dripicons-pill:before {
  content: "\e033";
}

.dripicons-pin:before {
  content: "\e034";
}

.dripicons-plus:before {
  content: "\e035";
}

.dripicons-power:before {
  content: "\e036";
}

.dripicons-preview:before {
  content: "\e037";
}

.dripicons-print:before {
  content: "\e038";
}

.dripicons-pulse:before {
  content: "\e039";
}

.dripicons-question:before {
  content: "\e03a";
}

.dripicons-reply:before {
  content: "\e03b";
}

.dripicons-reply-all:before {
  content: "\e03c";
}

.dripicons-return:before {
  content: "\e03d";
}

.dripicons-retweet:before {
  content: "\e03e";
}

.dripicons-rocket:before {
  content: "\e03f";
}

.dripicons-scale:before {
  content: "\e040";
}

.dripicons-search:before {
  content: "\e041";
}

.dripicons-shopping-bag:before {
  content: "\e042";
}

.dripicons-skip:before {
  content: "\e043";
}

.dripicons-stack:before {
  content: "\e044";
}

.dripicons-star:before {
  content: "\e045";
}

.dripicons-stopwatch:before {
  content: "\e046";
}

.dripicons-store:before {
  content: "\e047";
}

.dripicons-suitcase:before {
  content: "\e048";
}

.dripicons-swap:before {
  content: "\e049";
}

.dripicons-tag:before {
  content: "\e04a";
}

.dripicons-tag-delete:before {
  content: "\e04b";
}

.dripicons-tags:before {
  content: "\e04c";
}

.dripicons-thumbs-down:before {
  content: "\e04d";
}

.dripicons-thumbs-up:before {
  content: "\e04e";
}

.dripicons-ticket:before {
  content: "\e04f";
}

.dripicons-time-reverse:before {
  content: "\e050";
}

.dripicons-to-do:before {
  content: "\e051";
}

.dripicons-toggles:before {
  content: "\e052";
}

.dripicons-trash:before {
  content: "\e053";
}

.dripicons-trophy:before {
  content: "\e054";
}

.dripicons-upload:before {
  content: "\e055";
}

.dripicons-user:before {
  content: "\e056";
}

.dripicons-user-group:before {
  content: "\e057";
}

.dripicons-user-id:before {
  content: "\e058";
}

.dripicons-vibrate:before {
  content: "\e059";
}

.dripicons-view-apps:before {
  content: "\e05a";
}

.dripicons-view-list:before {
  content: "\e05b";
}

.dripicons-view-list-large:before {
  content: "\e05c";
}

.dripicons-view-thumb:before {
  content: "\e05d";
}

.dripicons-volume-full:before {
  content: "\e05e";
}

.dripicons-volume-low:before {
  content: "\e05f";
}

.dripicons-volume-medium:before {
  content: "\e060";
}

.dripicons-volume-off:before {
  content: "\e061";
}

.dripicons-wallet:before {
  content: "\e062";
}

.dripicons-warning:before {
  content: "\e063";
}

.dripicons-web:before {
  content: "\e064";
}

.dripicons-weight:before {
  content: "\e065";
}

.dripicons-wifi:before {
  content: "\e066";
}

.dripicons-wrong:before {
  content: "\e067";
}

.dripicons-zoom-in:before {
  content: "\e068";
}

.dripicons-zoom-out:before {
  content: "\e069";
}

@font-face {
  font-family: "Material Design Icons";
  src: url("../fonts/materialdesignicons-webfont.eot?v=4.9.95");
  src: url("../fonts/materialdesignicons-webfont.eot?#iefix&v=4.9.95") format("embedded-opentype"), url("../fonts/materialdesignicons-webfont.woff2?v=4.9.95") format("woff2"), url("../fonts/materialdesignicons-webfont.woff?v=4.9.95") format("woff"), url("../fonts/materialdesignicons-webfont.ttf?v=4.9.95") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.mdi:before, .mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdi-ab-testing::before {
  content: "\f001c";
}

.mdi-abjad-arabic::before {
  content: "\f0353";
}

.mdi-abjad-hebrew::before {
  content: "\f0354";
}

.mdi-abugida-devanagari::before {
  content: "\f0355";
}

.mdi-abugida-thai::before {
  content: "\f0356";
}

.mdi-access-point::before {
  content: "\f002";
}

.mdi-access-point-network::before {
  content: "\f003";
}

.mdi-access-point-network-off::before {
  content: "﮽";
}

.mdi-account::before {
  content: "\f004";
}

.mdi-account-alert::before {
  content: "\f005";
}

.mdi-account-alert-outline::before {
  content: "שּׁ";
}

.mdi-account-arrow-left::before {
  content: "שּׂ";
}

.mdi-account-arrow-left-outline::before {
  content: "אַ";
}

.mdi-account-arrow-right::before {
  content: "אָ";
}

.mdi-account-arrow-right-outline::before {
  content: "אּ";
}

.mdi-account-badge::before {
  content: "ﶃ";
}

.mdi-account-badge-alert::before {
  content: "ﶄ";
}

.mdi-account-badge-alert-outline::before {
  content: "ﶅ";
}

.mdi-account-badge-horizontal::before {
  content: "ﷰ";
}

.mdi-account-badge-horizontal-outline::before {
  content: "ﷱ";
}

.mdi-account-badge-outline::before {
  content: "ﶆ";
}

.mdi-account-box::before {
  content: "\f006";
}

.mdi-account-box-multiple::before {
  content: "盧";
}

.mdi-account-box-multiple-outline::before {
  content: "\f002c";
}

.mdi-account-box-outline::before {
  content: "\f007";
}

.mdi-account-cancel::before {
  content: "\f030a";
}

.mdi-account-cancel-outline::before {
  content: "\f030b";
}

.mdi-account-card-details::before {
  content: "\f5d2";
}

.mdi-account-card-details-outline::before {
  content: "ﶇ";
}

.mdi-account-cash::before {
  content: "\f00c2";
}

.mdi-account-cash-outline::before {
  content: "\f00c3";
}

.mdi-account-check::before {
  content: "\f008";
}

.mdi-account-check-outline::before {
  content: "﮾";
}

.mdi-account-child::before {
  content: "愈";
}

.mdi-account-child-circle::before {
  content: "憎";
}

.mdi-account-child-outline::before {
  content: "\f00f3";
}

.mdi-account-circle::before {
  content: "\f009";
}

.mdi-account-circle-outline::before {
  content: "בּ";
}

.mdi-account-clock::before {
  content: "גּ";
}

.mdi-account-clock-outline::before {
  content: "דּ";
}

.mdi-account-cog::before {
  content: "\f039b";
}

.mdi-account-cog-outline::before {
  content: "\f039c";
}

.mdi-account-convert::before {
  content: "\f00a";
}

.mdi-account-convert-outline::before {
  content: "\f032c";
}

.mdi-account-details::before {
  content: "\f631";
}

.mdi-account-details-outline::before {
  content: "\f039d";
}

.mdi-account-edit::before {
  content: "\f6bb";
}

.mdi-account-edit-outline::before {
  content: "\f001d";
}

.mdi-account-group::before {
  content: "\f848";
}

.mdi-account-group-outline::before {
  content: "הּ";
}

.mdi-account-heart::before {
  content: "\f898";
}

.mdi-account-heart-outline::before {
  content: "﮿";
}

.mdi-account-key::before {
  content: "\f00b";
}

.mdi-account-key-outline::before {
  content: "﯀";
}

.mdi-account-lock::before {
  content: "\f0189";
}

.mdi-account-lock-outline::before {
  content: "\f018a";
}

.mdi-account-minus::before {
  content: "\f00d";
}

.mdi-account-minus-outline::before {
  content: "﫫";
}

.mdi-account-multiple::before {
  content: "\f00e";
}

.mdi-account-multiple-check::before {
  content: "\f8c4";
}

.mdi-account-multiple-check-outline::before {
  content: "\f0229";
}

.mdi-account-multiple-minus::before {
  content: "\f5d3";
}

.mdi-account-multiple-minus-outline::before {
  content: "﯁";
}

.mdi-account-multiple-outline::before {
  content: "\f00f";
}

.mdi-account-multiple-plus::before {
  content: "\f010";
}

.mdi-account-multiple-plus-outline::before {
  content: "\f7ff";
}

.mdi-account-multiple-remove::before {
  content: "\f0235";
}

.mdi-account-multiple-remove-outline::before {
  content: "\f0236";
}

.mdi-account-network::before {
  content: "\f011";
}

.mdi-account-network-outline::before {
  content: "﯂";
}

.mdi-account-off::before {
  content: "\f012";
}

.mdi-account-off-outline::before {
  content: "﯃";
}

.mdi-account-outline::before {
  content: "\f013";
}

.mdi-account-plus::before {
  content: "\f014";
}

.mdi-account-plus-outline::before {
  content: "\f800";
}

.mdi-account-question::before {
  content: "וּ";
}

.mdi-account-question-outline::before {
  content: "זּ";
}

.mdi-account-remove::before {
  content: "\f015";
}

.mdi-account-remove-outline::before {
  content: "﫬";
}

.mdi-account-search::before {
  content: "\f016";
}

.mdi-account-search-outline::before {
  content: "老";
}

.mdi-account-settings::before {
  content: "\f630";
}

.mdi-account-settings-outline::before {
  content: "\f00f4";
}

.mdi-account-star::before {
  content: "\f017";
}

.mdi-account-star-outline::before {
  content: "﯄";
}

.mdi-account-supervisor::before {
  content: "慠";
}

.mdi-account-supervisor-circle::before {
  content: "懲";
}

.mdi-account-supervisor-outline::before {
  content: "\f0158";
}

.mdi-account-switch::before {
  content: "\f019";
}

.mdi-account-tie::before {
  content: "ﲿ";
}

.mdi-account-tie-outline::before {
  content: "\f00f5";
}

.mdi-account-tie-voice::before {
  content: "\f0333";
}

.mdi-account-tie-voice-off::before {
  content: "\f0335";
}

.mdi-account-tie-voice-off-outline::before {
  content: "\f0336";
}

.mdi-account-tie-voice-outline::before {
  content: "\f0334";
}

.mdi-accusoft::before {
  content: "\f849";
}

.mdi-adjust::before {
  content: "\f01a";
}

.mdi-adobe::before {
  content: "蘆";
}

.mdi-adobe-acrobat::before {
  content: "ﾽ";
}

.mdi-air-conditioner::before {
  content: "\f01b";
}

.mdi-air-filter::before {
  content: "ﴟ";
}

.mdi-air-horn::before {
  content: "ﶈ";
}

.mdi-air-humidifier::before {
  content: "\f00c4";
}

.mdi-air-purifier::before {
  content: "ﴠ";
}

.mdi-airbag::before {
  content: "﯅";
}

.mdi-airballoon::before {
  content: "\f01c";
}

.mdi-airballoon-outline::before {
  content: "\f002d";
}

.mdi-airplane::before {
  content: "\f01d";
}

.mdi-airplane-landing::before {
  content: "\f5d4";
}

.mdi-airplane-off::before {
  content: "\f01e";
}

.mdi-airplane-takeoff::before {
  content: "\f5d5";
}

.mdi-airplay::before {
  content: "\f01f";
}

.mdi-airport::before {
  content: "\f84a";
}

.mdi-alarm::before {
  content: "\f020";
}

.mdi-alarm-bell::before {
  content: "\f78d";
}

.mdi-alarm-check::before {
  content: "\f021";
}

.mdi-alarm-light::before {
  content: "\f78e";
}

.mdi-alarm-light-outline::before {
  content: "﯆";
}

.mdi-alarm-multiple::before {
  content: "\f022";
}

.mdi-alarm-note::before {
  content: "ﺎ";
}

.mdi-alarm-note-off::before {
  content: "ﺏ";
}

.mdi-alarm-off::before {
  content: "\f023";
}

.mdi-alarm-plus::before {
  content: "\f024";
}

.mdi-alarm-snooze::before {
  content: "\f68d";
}

.mdi-album::before {
  content: "\f025";
}

.mdi-alert::before {
  content: "\f026";
}

.mdi-alert-box::before {
  content: "\f027";
}

.mdi-alert-box-outline::before {
  content: "ﳀ";
}

.mdi-alert-circle::before {
  content: "\f028";
}

.mdi-alert-circle-check::before {
  content: "\f0218";
}

.mdi-alert-circle-check-outline::before {
  content: "\f0219";
}

.mdi-alert-circle-outline::before {
  content: "\f5d6";
}

.mdi-alert-decagram::before {
  content: "\f6bc";
}

.mdi-alert-decagram-outline::before {
  content: "ﳁ";
}

.mdi-alert-octagon::before {
  content: "\f029";
}

.mdi-alert-octagon-outline::before {
  content: "ﳂ";
}

.mdi-alert-octagram::before {
  content: "\f766";
}

.mdi-alert-octagram-outline::before {
  content: "ﳃ";
}

.mdi-alert-outline::before {
  content: "\f02a";
}

.mdi-alert-rhombus::before {
  content: "\f01f9";
}

.mdi-alert-rhombus-outline::before {
  content: "\f01fa";
}

.mdi-alien::before {
  content: "\f899";
}

.mdi-alien-outline::before {
  content: "\f00f6";
}

.mdi-align-horizontal-center::before {
  content: "\f01ee";
}

.mdi-align-horizontal-left::before {
  content: "\f01ed";
}

.mdi-align-horizontal-right::before {
  content: "\f01ef";
}

.mdi-align-vertical-bottom::before {
  content: "\f01f0";
}

.mdi-align-vertical-center::before {
  content: "\f01f1";
}

.mdi-align-vertical-top::before {
  content: "\f01f2";
}

.mdi-all-inclusive::before {
  content: "\f6bd";
}

.mdi-allergy::before {
  content: "\f0283";
}

.mdi-alpha::before {
  content: "\f02b";
}

.mdi-alpha-a::before {
  content: "A";
}

.mdi-alpha-a-box::before {
  content: "﫭";
}

.mdi-alpha-a-box-outline::before {
  content: "﯇";
}

.mdi-alpha-a-circle::before {
  content: "﯈";
}

.mdi-alpha-a-circle-outline::before {
  content: "﯉";
}

.mdi-alpha-b::before {
  content: "B";
}

.mdi-alpha-b-box::before {
  content: "﫮";
}

.mdi-alpha-b-box-outline::before {
  content: "﯊";
}

.mdi-alpha-b-circle::before {
  content: "﯋";
}

.mdi-alpha-b-circle-outline::before {
  content: "﯌";
}

.mdi-alpha-c::before {
  content: "C";
}

.mdi-alpha-c-box::before {
  content: "﫯";
}

.mdi-alpha-c-box-outline::before {
  content: "﯍";
}

.mdi-alpha-c-circle::before {
  content: "﯎";
}

.mdi-alpha-c-circle-outline::before {
  content: "﯏";
}

.mdi-alpha-d::before {
  content: "D";
}

.mdi-alpha-d-box::before {
  content: "﫰";
}

.mdi-alpha-d-box-outline::before {
  content: "﯐";
}

.mdi-alpha-d-circle::before {
  content: "﯑";
}

.mdi-alpha-d-circle-outline::before {
  content: "﯒";
}

.mdi-alpha-e::before {
  content: "E";
}

.mdi-alpha-e-box::before {
  content: "﫱";
}

.mdi-alpha-e-box-outline::before {
  content: "ﯓ";
}

.mdi-alpha-e-circle::before {
  content: "ﯔ";
}

.mdi-alpha-e-circle-outline::before {
  content: "ﯕ";
}

.mdi-alpha-f::before {
  content: "F";
}

.mdi-alpha-f-box::before {
  content: "﫲";
}

.mdi-alpha-f-box-outline::before {
  content: "ﯖ";
}

.mdi-alpha-f-circle::before {
  content: "ﯗ";
}

.mdi-alpha-f-circle-outline::before {
  content: "ﯘ";
}

.mdi-alpha-g::before {
  content: "G";
}

.mdi-alpha-g-box::before {
  content: "﫳";
}

.mdi-alpha-g-box-outline::before {
  content: "ﯙ";
}

.mdi-alpha-g-circle::before {
  content: "ﯚ";
}

.mdi-alpha-g-circle-outline::before {
  content: "ﯛ";
}

.mdi-alpha-h::before {
  content: "H";
}

.mdi-alpha-h-box::before {
  content: "﫴";
}

.mdi-alpha-h-box-outline::before {
  content: "ﯜ";
}

.mdi-alpha-h-circle::before {
  content: "ﯝ";
}

.mdi-alpha-h-circle-outline::before {
  content: "ﯞ";
}

.mdi-alpha-i::before {
  content: "I";
}

.mdi-alpha-i-box::before {
  content: "﫵";
}

.mdi-alpha-i-box-outline::before {
  content: "ﯟ";
}

.mdi-alpha-i-circle::before {
  content: "ﯠ";
}

.mdi-alpha-i-circle-outline::before {
  content: "ﯡ";
}

.mdi-alpha-j::before {
  content: "J";
}

.mdi-alpha-j-box::before {
  content: "﫶";
}

.mdi-alpha-j-box-outline::before {
  content: "ﯢ";
}

.mdi-alpha-j-circle::before {
  content: "ﯣ";
}

.mdi-alpha-j-circle-outline::before {
  content: "ﯤ";
}

.mdi-alpha-k::before {
  content: "K";
}

.mdi-alpha-k-box::before {
  content: "﫷";
}

.mdi-alpha-k-box-outline::before {
  content: "ﯥ";
}

.mdi-alpha-k-circle::before {
  content: "ﯦ";
}

.mdi-alpha-k-circle-outline::before {
  content: "ﯧ";
}

.mdi-alpha-l::before {
  content: "L";
}

.mdi-alpha-l-box::before {
  content: "﫸";
}

.mdi-alpha-l-box-outline::before {
  content: "ﯨ";
}

.mdi-alpha-l-circle::before {
  content: "ﯩ";
}

.mdi-alpha-l-circle-outline::before {
  content: "ﯪ";
}

.mdi-alpha-m::before {
  content: "M";
}

.mdi-alpha-m-box::before {
  content: "﫹";
}

.mdi-alpha-m-box-outline::before {
  content: "ﯫ";
}

.mdi-alpha-m-circle::before {
  content: "ﯬ";
}

.mdi-alpha-m-circle-outline::before {
  content: "ﯭ";
}

.mdi-alpha-n::before {
  content: "N";
}

.mdi-alpha-n-box::before {
  content: "﫺";
}

.mdi-alpha-n-box-outline::before {
  content: "ﯮ";
}

.mdi-alpha-n-circle::before {
  content: "ﯯ";
}

.mdi-alpha-n-circle-outline::before {
  content: "ﯰ";
}

.mdi-alpha-o::before {
  content: "O";
}

.mdi-alpha-o-box::before {
  content: "﫻";
}

.mdi-alpha-o-box-outline::before {
  content: "ﯱ";
}

.mdi-alpha-o-circle::before {
  content: "ﯲ";
}

.mdi-alpha-o-circle-outline::before {
  content: "ﯳ";
}

.mdi-alpha-p::before {
  content: "P";
}

.mdi-alpha-p-box::before {
  content: "﫼";
}

.mdi-alpha-p-box-outline::before {
  content: "ﯴ";
}

.mdi-alpha-p-circle::before {
  content: "ﯵ";
}

.mdi-alpha-p-circle-outline::before {
  content: "ﯶ";
}

.mdi-alpha-q::before {
  content: "Q";
}

.mdi-alpha-q-box::before {
  content: "﫽";
}

.mdi-alpha-q-box-outline::before {
  content: "ﯷ";
}

.mdi-alpha-q-circle::before {
  content: "ﯸ";
}

.mdi-alpha-q-circle-outline::before {
  content: "ﯹ";
}

.mdi-alpha-r::before {
  content: "R";
}

.mdi-alpha-r-box::before {
  content: "﫾";
}

.mdi-alpha-r-box-outline::before {
  content: "ﯺ";
}

.mdi-alpha-r-circle::before {
  content: "ﯻ";
}

.mdi-alpha-r-circle-outline::before {
  content: "ﯼ";
}

.mdi-alpha-s::before {
  content: "S";
}

.mdi-alpha-s-box::before {
  content: "﫿";
}

.mdi-alpha-s-box-outline::before {
  content: "ﯽ";
}

.mdi-alpha-s-circle::before {
  content: "ﯾ";
}

.mdi-alpha-s-circle-outline::before {
  content: "ﯿ";
}

.mdi-alpha-t::before {
  content: "T";
}

.mdi-alpha-t-box::before {
  content: "ﬀ";
}

.mdi-alpha-t-box-outline::before {
  content: "ﰀ";
}

.mdi-alpha-t-circle::before {
  content: "ﰁ";
}

.mdi-alpha-t-circle-outline::before {
  content: "ﰂ";
}

.mdi-alpha-u::before {
  content: "U";
}

.mdi-alpha-u-box::before {
  content: "ﬁ";
}

.mdi-alpha-u-box-outline::before {
  content: "ﰃ";
}

.mdi-alpha-u-circle::before {
  content: "ﰄ";
}

.mdi-alpha-u-circle-outline::before {
  content: "ﰅ";
}

.mdi-alpha-v::before {
  content: "V";
}

.mdi-alpha-v-box::before {
  content: "ﬂ";
}

.mdi-alpha-v-box-outline::before {
  content: "ﰆ";
}

.mdi-alpha-v-circle::before {
  content: "ﰇ";
}

.mdi-alpha-v-circle-outline::before {
  content: "ﰈ";
}

.mdi-alpha-w::before {
  content: "W";
}

.mdi-alpha-w-box::before {
  content: "ﬃ";
}

.mdi-alpha-w-box-outline::before {
  content: "ﰉ";
}

.mdi-alpha-w-circle::before {
  content: "ﰊ";
}

.mdi-alpha-w-circle-outline::before {
  content: "ﰋ";
}

.mdi-alpha-x::before {
  content: "X";
}

.mdi-alpha-x-box::before {
  content: "ﬄ";
}

.mdi-alpha-x-box-outline::before {
  content: "ﰌ";
}

.mdi-alpha-x-circle::before {
  content: "ﰍ";
}

.mdi-alpha-x-circle-outline::before {
  content: "ﰎ";
}

.mdi-alpha-y::before {
  content: "Y";
}

.mdi-alpha-y-box::before {
  content: "ﬅ";
}

.mdi-alpha-y-box-outline::before {
  content: "ﰏ";
}

.mdi-alpha-y-circle::before {
  content: "ﰐ";
}

.mdi-alpha-y-circle-outline::before {
  content: "ﰑ";
}

.mdi-alpha-z::before {
  content: "Z";
}

.mdi-alpha-z-box::before {
  content: "ﬆ";
}

.mdi-alpha-z-box-outline::before {
  content: "ﰒ";
}

.mdi-alpha-z-circle::before {
  content: "ﰓ";
}

.mdi-alpha-z-circle-outline::before {
  content: "ﰔ";
}

.mdi-alphabet-aurebesh::before {
  content: "\f0357";
}

.mdi-alphabet-cyrillic::before {
  content: "\f0358";
}

.mdi-alphabet-greek::before {
  content: "\f0359";
}

.mdi-alphabet-latin::before {
  content: "\f035a";
}

.mdi-alphabet-piqad::before {
  content: "\f035b";
}

.mdi-alphabet-tengwar::before {
  content: "\f0362";
}

.mdi-alphabetical::before {
  content: "\f02c";
}

.mdi-alphabetical-off::before {
  content: "\f002e";
}

.mdi-alphabetical-variant::before {
  content: "\f002f";
}

.mdi-alphabetical-variant-off::before {
  content: "\f0030";
}

.mdi-altimeter::before {
  content: "\f5d7";
}

.mdi-amazon::before {
  content: "\f02d";
}

.mdi-amazon-alexa::before {
  content: "\f8c5";
}

.mdi-amazon-drive::before {
  content: "\f02e";
}

.mdi-ambulance::before {
  content: "\f02f";
}

.mdi-ammunition::before {
  content: "ﳄ";
}

.mdi-ampersand::before {
  content: "戴";
}

.mdi-amplifier::before {
  content: "\f030";
}

.mdi-amplifier-off::before {
  content: "\f01e0";
}

.mdi-anchor::before {
  content: "\f031";
}

.mdi-android::before {
  content: "\f032";
}

.mdi-android-auto::before {
  content: "揄";
}

.mdi-android-debug-bridge::before {
  content: "\f033";
}

.mdi-android-head::before {
  content: "\f78f";
}

.mdi-android-messages::before {
  content: "ﴡ";
}

.mdi-android-studio::before {
  content: "\f034";
}

.mdi-angle-acute::before {
  content: "虜";
}

.mdi-angle-obtuse::before {
  content: "路";
}

.mdi-angle-right::before {
  content: "露";
}

.mdi-angular::before {
  content: "\f6b1";
}

.mdi-angularjs::before {
  content: "\f6be";
}

.mdi-animation::before {
  content: "\f5d8";
}

.mdi-animation-outline::before {
  content: "搜";
}

.mdi-animation-play::before {
  content: "魯";
}

.mdi-animation-play-outline::before {
  content: "摒";
}

.mdi-ansible::before {
  content: "\f00c5";
}

.mdi-antenna::before {
  content: "\f0144";
}

.mdi-anvil::before {
  content: "\f89a";
}

.mdi-apache-kafka::before {
  content: "\f0031";
}

.mdi-api::before {
  content: "\f00c6";
}

.mdi-api-off::before {
  content: "\f0282";
}

.mdi-apple::before {
  content: "\f035";
}

.mdi-apple-finder::before {
  content: "\f036";
}

.mdi-apple-icloud::before {
  content: "\f038";
}

.mdi-apple-ios::before {
  content: "\f037";
}

.mdi-apple-keyboard-caps::before {
  content: "\f632";
}

.mdi-apple-keyboard-command::before {
  content: "\f633";
}

.mdi-apple-keyboard-control::before {
  content: "\f634";
}

.mdi-apple-keyboard-option::before {
  content: "\f635";
}

.mdi-apple-keyboard-shift::before {
  content: "\f636";
}

.mdi-apple-safari::before {
  content: "\f039";
}

.mdi-application::before {
  content: "\f614";
}

.mdi-application-export::before {
  content: "ﶉ";
}

.mdi-application-import::before {
  content: "ﶊ";
}

.mdi-approximately-equal::before {
  content: "ﾾ";
}

.mdi-approximately-equal-box::before {
  content: "﾿";
}

.mdi-apps::before {
  content: "\f03b";
}

.mdi-apps-box::before {
  content: "ﴢ";
}

.mdi-arch::before {
  content: "\f8c6";
}

.mdi-archive::before {
  content: "\f03c";
}

.mdi-archive-arrow-down::before {
  content: "\f0284";
}

.mdi-archive-arrow-down-outline::before {
  content: "\f0285";
}

.mdi-archive-arrow-up::before {
  content: "\f0286";
}

.mdi-archive-arrow-up-outline::before {
  content: "\f0287";
}

.mdi-archive-outline::before {
  content: "\f0239";
}

.mdi-arm-flex::before {
  content: "\f008f";
}

.mdi-arm-flex-outline::before {
  content: "\f0090";
}

.mdi-arrange-bring-forward::before {
  content: "\f03d";
}

.mdi-arrange-bring-to-front::before {
  content: "\f03e";
}

.mdi-arrange-send-backward::before {
  content: "\f03f";
}

.mdi-arrange-send-to-back::before {
  content: "\f040";
}

.mdi-arrow-all::before {
  content: "\f041";
}

.mdi-arrow-bottom-left::before {
  content: "\f042";
}

.mdi-arrow-bottom-left-bold-outline::before {
  content: "禮";
}

.mdi-arrow-bottom-left-thick::before {
  content: "醴";
}

.mdi-arrow-bottom-right::before {
  content: "\f043";
}

.mdi-arrow-bottom-right-bold-outline::before {
  content: "隸";
}

.mdi-arrow-bottom-right-thick::before {
  content: "惡";
}

.mdi-arrow-collapse::before {
  content: "\f615";
}

.mdi-arrow-collapse-all::before {
  content: "\f044";
}

.mdi-arrow-collapse-down::before {
  content: "\f791";
}

.mdi-arrow-collapse-horizontal::before {
  content: "\f84b";
}

.mdi-arrow-collapse-left::before {
  content: "\f792";
}

.mdi-arrow-collapse-right::before {
  content: "\f793";
}

.mdi-arrow-collapse-up::before {
  content: "\f794";
}

.mdi-arrow-collapse-vertical::before {
  content: "\f84c";
}

.mdi-arrow-decision::before {
  content: "了";
}

.mdi-arrow-decision-auto::before {
  content: "僚";
}

.mdi-arrow-decision-auto-outline::before {
  content: "寮";
}

.mdi-arrow-decision-outline::before {
  content: "尿";
}

.mdi-arrow-down::before {
  content: "\f045";
}

.mdi-arrow-down-bold::before {
  content: "\f72d";
}

.mdi-arrow-down-bold-box::before {
  content: "\f72e";
}

.mdi-arrow-down-bold-box-outline::before {
  content: "\f72f";
}

.mdi-arrow-down-bold-circle::before {
  content: "\f047";
}

.mdi-arrow-down-bold-circle-outline::before {
  content: "\f048";
}

.mdi-arrow-down-bold-hexagon-outline::before {
  content: "\f049";
}

.mdi-arrow-down-bold-outline::before {
  content: "料";
}

.mdi-arrow-down-box::before {
  content: "\f6bf";
}

.mdi-arrow-down-circle::before {
  content: "ﲷ";
}

.mdi-arrow-down-circle-outline::before {
  content: "ﲸ";
}

.mdi-arrow-down-drop-circle::before {
  content: "\f04a";
}

.mdi-arrow-down-drop-circle-outline::before {
  content: "\f04b";
}

.mdi-arrow-down-thick::before {
  content: "\f046";
}

.mdi-arrow-expand::before {
  content: "\f616";
}

.mdi-arrow-expand-all::before {
  content: "\f04c";
}

.mdi-arrow-expand-down::before {
  content: "\f795";
}

.mdi-arrow-expand-horizontal::before {
  content: "\f84d";
}

.mdi-arrow-expand-left::before {
  content: "\f796";
}

.mdi-arrow-expand-right::before {
  content: "\f797";
}

.mdi-arrow-expand-up::before {
  content: "\f798";
}

.mdi-arrow-expand-vertical::before {
  content: "\f84e";
}

.mdi-arrow-horizontal-lock::before {
  content: "\f0186";
}

.mdi-arrow-left::before {
  content: "\f04d";
}

.mdi-arrow-left-bold::before {
  content: "\f730";
}

.mdi-arrow-left-bold-box::before {
  content: "\f731";
}

.mdi-arrow-left-bold-box-outline::before {
  content: "\f732";
}

.mdi-arrow-left-bold-circle::before {
  content: "\f04f";
}

.mdi-arrow-left-bold-circle-outline::before {
  content: "\f050";
}

.mdi-arrow-left-bold-hexagon-outline::before {
  content: "\f051";
}

.mdi-arrow-left-bold-outline::before {
  content: "樂";
}

.mdi-arrow-left-box::before {
  content: "\f6c0";
}

.mdi-arrow-left-circle::before {
  content: "ﲹ";
}

.mdi-arrow-left-circle-outline::before {
  content: "ﲺ";
}

.mdi-arrow-left-drop-circle::before {
  content: "\f052";
}

.mdi-arrow-left-drop-circle-outline::before {
  content: "\f053";
}

.mdi-arrow-left-right::before {
  content: "ﺐ";
}

.mdi-arrow-left-right-bold::before {
  content: "ﺑ";
}

.mdi-arrow-left-right-bold-outline::before {
  content: "燎";
}

.mdi-arrow-left-thick::before {
  content: "\f04e";
}

.mdi-arrow-right::before {
  content: "\f054";
}

.mdi-arrow-right-bold::before {
  content: "\f733";
}

.mdi-arrow-right-bold-box::before {
  content: "\f734";
}

.mdi-arrow-right-bold-box-outline::before {
  content: "\f735";
}

.mdi-arrow-right-bold-circle::before {
  content: "\f056";
}

.mdi-arrow-right-bold-circle-outline::before {
  content: "\f057";
}

.mdi-arrow-right-bold-hexagon-outline::before {
  content: "\f058";
}

.mdi-arrow-right-bold-outline::before {
  content: "療";
}

.mdi-arrow-right-box::before {
  content: "\f6c1";
}

.mdi-arrow-right-circle::before {
  content: "ﲻ";
}

.mdi-arrow-right-circle-outline::before {
  content: "ﲼ";
}

.mdi-arrow-right-drop-circle::before {
  content: "\f059";
}

.mdi-arrow-right-drop-circle-outline::before {
  content: "\f05a";
}

.mdi-arrow-right-thick::before {
  content: "\f055";
}

.mdi-arrow-split-horizontal::before {
  content: "鷺";
}

.mdi-arrow-split-vertical::before {
  content: "碌";
}

.mdi-arrow-top-left::before {
  content: "\f05b";
}

.mdi-arrow-top-left-bold-outline::before {
  content: "蓼";
}

.mdi-arrow-top-left-bottom-right::before {
  content: "ﺒ";
}

.mdi-arrow-top-left-bottom-right-bold::before {
  content: "ﺓ";
}

.mdi-arrow-top-left-thick::before {
  content: "遼";
}

.mdi-arrow-top-right::before {
  content: "\f05c";
}

.mdi-arrow-top-right-bold-outline::before {
  content: "龍";
}

.mdi-arrow-top-right-bottom-left::before {
  content: "ﺔ";
}

.mdi-arrow-top-right-bottom-left-bold::before {
  content: "ﺕ";
}

.mdi-arrow-top-right-thick::before {
  content: "暈";
}

.mdi-arrow-up::before {
  content: "\f05d";
}

.mdi-arrow-up-bold::before {
  content: "\f736";
}

.mdi-arrow-up-bold-box::before {
  content: "\f737";
}

.mdi-arrow-up-bold-box-outline::before {
  content: "\f738";
}

.mdi-arrow-up-bold-circle::before {
  content: "\f05f";
}

.mdi-arrow-up-bold-circle-outline::before {
  content: "\f060";
}

.mdi-arrow-up-bold-hexagon-outline::before {
  content: "\f061";
}

.mdi-arrow-up-bold-outline::before {
  content: "阮";
}

.mdi-arrow-up-box::before {
  content: "\f6c2";
}

.mdi-arrow-up-circle::before {
  content: "ﲽ";
}

.mdi-arrow-up-circle-outline::before {
  content: "ﲾ";
}

.mdi-arrow-up-down::before {
  content: "ﺖ";
}

.mdi-arrow-up-down-bold::before {
  content: "ﺗ";
}

.mdi-arrow-up-down-bold-outline::before {
  content: "劉";
}

.mdi-arrow-up-drop-circle::before {
  content: "\f062";
}

.mdi-arrow-up-drop-circle-outline::before {
  content: "\f063";
}

.mdi-arrow-up-thick::before {
  content: "\f05e";
}

.mdi-arrow-vertical-lock::before {
  content: "\f0187";
}

.mdi-artist::before {
  content: "\f802";
}

.mdi-artist-outline::before {
  content: "ﳅ";
}

.mdi-artstation::before {
  content: "﬷";
}

.mdi-aspect-ratio::before {
  content: "﨣";
}

.mdi-assistant::before {
  content: "\f064";
}

.mdi-asterisk::before {
  content: "\f6c3";
}

.mdi-at::before {
  content: "\f065";
}

.mdi-atlassian::before {
  content: "\f803";
}

.mdi-atm::before {
  content: "ﴣ";
}

.mdi-atom::before {
  content: "\f767";
}

.mdi-atom-variant::before {
  content: "ﺘ";
}

.mdi-attachment::before {
  content: "\f066";
}

.mdi-audio-video::before {
  content: "祿";
}

.mdi-audio-video-off::before {
  content: "\f01e1";
}

.mdi-audiobook::before {
  content: "\f067";
}

.mdi-augmented-reality::before {
  content: "\f84f";
}

.mdi-auto-download::before {
  content: "\f03a9";
}

.mdi-auto-fix::before {
  content: "\f068";
}

.mdi-auto-upload::before {
  content: "\f069";
}

.mdi-autorenew::before {
  content: "\f06a";
}

.mdi-av-timer::before {
  content: "\f06b";
}

.mdi-aws::before {
  content: "ﷲ";
}

.mdi-axe::before {
  content: "\f8c7";
}

.mdi-axis::before {
  content: "ﴤ";
}

.mdi-axis-arrow::before {
  content: "ﴥ";
}

.mdi-axis-arrow-lock::before {
  content: "ﴦ";
}

.mdi-axis-lock::before {
  content: "ﴧ";
}

.mdi-axis-x-arrow::before {
  content: "ﴨ";
}

.mdi-axis-x-arrow-lock::before {
  content: "ﴩ";
}

.mdi-axis-x-rotate-clockwise::before {
  content: "ﴪ";
}

.mdi-axis-x-rotate-counterclockwise::before {
  content: "ﴫ";
}

.mdi-axis-x-y-arrow-lock::before {
  content: "ﴬ";
}

.mdi-axis-y-arrow::before {
  content: "ﴭ";
}

.mdi-axis-y-arrow-lock::before {
  content: "ﴮ";
}

.mdi-axis-y-rotate-clockwise::before {
  content: "ﴯ";
}

.mdi-axis-y-rotate-counterclockwise::before {
  content: "ﴰ";
}

.mdi-axis-z-arrow::before {
  content: "ﴱ";
}

.mdi-axis-z-arrow-lock::before {
  content: "ﴲ";
}

.mdi-axis-z-rotate-clockwise::before {
  content: "ﴳ";
}

.mdi-axis-z-rotate-counterclockwise::before {
  content: "ﴴ";
}

.mdi-azure::before {
  content: "\f804";
}

.mdi-azure-devops::before {
  content: "\f0091";
}

.mdi-babel::before {
  content: "﨤";
}

.mdi-baby::before {
  content: "\f06c";
}

.mdi-baby-bottle::before {
  content: "ｖ";
}

.mdi-baby-bottle-outline::before {
  content: "ｗ";
}

.mdi-baby-carriage::before {
  content: "\f68e";
}

.mdi-baby-carriage-off::before {
  content: "￀";
}

.mdi-baby-face::before {
  content: "ﺙ";
}

.mdi-baby-face-outline::before {
  content: "ﺚ";
}

.mdi-backburger::before {
  content: "\f06d";
}

.mdi-backspace::before {
  content: "\f06e";
}

.mdi-backspace-outline::before {
  content: "טּ";
}

.mdi-backspace-reverse::before {
  content: "ﺛ";
}

.mdi-backspace-reverse-outline::before {
  content: "ﺜ";
}

.mdi-backup-restore::before {
  content: "\f06f";
}

.mdi-bacteria::before {
  content: "ﻲ";
}

.mdi-bacteria-outline::before {
  content: "ﻳ";
}

.mdi-badminton::before {
  content: "\f850";
}

.mdi-bag-carry-on::before {
  content: "ｘ";
}

.mdi-bag-carry-on-check::before {
  content: "﵁";
}

.mdi-bag-carry-on-off::before {
  content: "ｙ";
}

.mdi-bag-checked::before {
  content: "ｚ";
}

.mdi-bag-personal::before {
  content: "ﷳ";
}

.mdi-bag-personal-off::before {
  content: "ﷴ";
}

.mdi-bag-personal-off-outline::before {
  content: "ﷵ";
}

.mdi-bag-personal-outline::before {
  content: "ﷶ";
}

.mdi-baguette::before {
  content: "｛";
}

.mdi-balloon::before {
  content: "逸";
}

.mdi-ballot::before {
  content: "杻";
}

.mdi-ballot-outline::before {
  content: "柳";
}

.mdi-ballot-recount::before {
  content: "ﰕ";
}

.mdi-ballot-recount-outline::before {
  content: "ﰖ";
}

.mdi-bandage::before {
  content: "ﶋ";
}

.mdi-bandcamp::before {
  content: "\f674";
}

.mdi-bank::before {
  content: "\f070";
}

.mdi-bank-minus::before {
  content: "ﶌ";
}

.mdi-bank-outline::before {
  content: "ﺝ";
}

.mdi-bank-plus::before {
  content: "ﶍ";
}

.mdi-bank-remove::before {
  content: "ﶎ";
}

.mdi-bank-transfer::before {
  content: "都";
}

.mdi-bank-transfer-in::before {
  content: "﨧";
}

.mdi-bank-transfer-out::before {
  content: "﨨";
}

.mdi-barcode::before {
  content: "\f071";
}

.mdi-barcode-off::before {
  content: "\f0261";
}

.mdi-barcode-scan::before {
  content: "\f072";
}

.mdi-barley::before {
  content: "\f073";
}

.mdi-barley-off::before {
  content: "יּ";
}

.mdi-barn::before {
  content: "ךּ";
}

.mdi-barrel::before {
  content: "\f074";
}

.mdi-baseball::before {
  content: "\f851";
}

.mdi-baseball-bat::before {
  content: "\f852";
}

.mdi-basecamp::before {
  content: "\f075";
}

.mdi-bash::before {
  content: "\f01ae";
}

.mdi-basket::before {
  content: "\f076";
}

.mdi-basket-fill::before {
  content: "\f077";
}

.mdi-basket-outline::before {
  content: "\f01ac";
}

.mdi-basket-unfill::before {
  content: "\f078";
}

.mdi-basketball::before {
  content: "\f805";
}

.mdi-basketball-hoop::before {
  content: "ﰗ";
}

.mdi-basketball-hoop-outline::before {
  content: "ﰘ";
}

.mdi-bat::before {
  content: "כּ";
}

.mdi-battery::before {
  content: "\f079";
}

.mdi-battery-10::before {
  content: "\f07a";
}

.mdi-battery-10-bluetooth::before {
  content: "綠";
}

.mdi-battery-20::before {
  content: "\f07b";
}

.mdi-battery-20-bluetooth::before {
  content: "菉";
}

.mdi-battery-30::before {
  content: "\f07c";
}

.mdi-battery-30-bluetooth::before {
  content: "錄";
}

.mdi-battery-40::before {
  content: "\f07d";
}

.mdi-battery-40-bluetooth::before {
  content: "鹿";
}

.mdi-battery-50::before {
  content: "\f07e";
}

.mdi-battery-50-bluetooth::before {
  content: "論";
}

.mdi-battery-60::before {
  content: "\f07f";
}

.mdi-battery-60-bluetooth::before {
  content: "壟";
}

.mdi-battery-70::before {
  content: "\f080";
}

.mdi-battery-70-bluetooth::before {
  content: "弄";
}

.mdi-battery-80::before {
  content: "\f081";
}

.mdi-battery-80-bluetooth::before {
  content: "籠";
}

.mdi-battery-90::before {
  content: "\f082";
}

.mdi-battery-90-bluetooth::before {
  content: "聾";
}

.mdi-battery-alert::before {
  content: "\f083";
}

.mdi-battery-alert-bluetooth::before {
  content: "牢";
}

.mdi-battery-alert-variant::before {
  content: "\f00f7";
}

.mdi-battery-alert-variant-outline::before {
  content: "\f00f8";
}

.mdi-battery-bluetooth::before {
  content: "磊";
}

.mdi-battery-bluetooth-variant::before {
  content: "賂";
}

.mdi-battery-charging::before {
  content: "\f084";
}

.mdi-battery-charging-10::before {
  content: "\f89b";
}

.mdi-battery-charging-100::before {
  content: "\f085";
}

.mdi-battery-charging-20::before {
  content: "\f086";
}

.mdi-battery-charging-30::before {
  content: "\f087";
}

.mdi-battery-charging-40::before {
  content: "\f088";
}

.mdi-battery-charging-50::before {
  content: "\f89c";
}

.mdi-battery-charging-60::before {
  content: "\f089";
}

.mdi-battery-charging-70::before {
  content: "\f89d";
}

.mdi-battery-charging-80::before {
  content: "\f08a";
}

.mdi-battery-charging-90::before {
  content: "\f08b";
}

.mdi-battery-charging-high::before {
  content: "\f02d1";
}

.mdi-battery-charging-low::before {
  content: "\f02cf";
}

.mdi-battery-charging-medium::before {
  content: "\f02d0";
}

.mdi-battery-charging-outline::before {
  content: "\f89e";
}

.mdi-battery-charging-wireless::before {
  content: "\f806";
}

.mdi-battery-charging-wireless-10::before {
  content: "\f807";
}

.mdi-battery-charging-wireless-20::before {
  content: "\f808";
}

.mdi-battery-charging-wireless-30::before {
  content: "\f809";
}

.mdi-battery-charging-wireless-40::before {
  content: "\f80a";
}

.mdi-battery-charging-wireless-50::before {
  content: "\f80b";
}

.mdi-battery-charging-wireless-60::before {
  content: "\f80c";
}

.mdi-battery-charging-wireless-70::before {
  content: "\f80d";
}

.mdi-battery-charging-wireless-80::before {
  content: "\f80e";
}

.mdi-battery-charging-wireless-90::before {
  content: "\f80f";
}

.mdi-battery-charging-wireless-alert::before {
  content: "\f810";
}

.mdi-battery-charging-wireless-outline::before {
  content: "\f811";
}

.mdi-battery-heart::before {
  content: "\f023a";
}

.mdi-battery-heart-outline::before {
  content: "\f023b";
}

.mdi-battery-heart-variant::before {
  content: "\f023c";
}

.mdi-battery-high::before {
  content: "\f02ce";
}

.mdi-battery-low::before {
  content: "\f02cc";
}

.mdi-battery-medium::before {
  content: "\f02cd";
}

.mdi-battery-minus::before {
  content: "\f08c";
}

.mdi-battery-negative::before {
  content: "\f08d";
}

.mdi-battery-off::before {
  content: "\f0288";
}

.mdi-battery-off-outline::before {
  content: "\f0289";
}

.mdi-battery-outline::before {
  content: "\f08e";
}

.mdi-battery-plus::before {
  content: "\f08f";
}

.mdi-battery-positive::before {
  content: "\f090";
}

.mdi-battery-unknown::before {
  content: "\f091";
}

.mdi-battery-unknown-bluetooth::before {
  content: "雷";
}

.mdi-battlenet::before {
  content: "לּ";
}

.mdi-beach::before {
  content: "\f092";
}

.mdi-beaker::before {
  content: "ﳆ";
}

.mdi-beaker-alert::before {
  content: "\f0254";
}

.mdi-beaker-alert-outline::before {
  content: "\f0255";
}

.mdi-beaker-check::before {
  content: "\f0256";
}

.mdi-beaker-check-outline::before {
  content: "\f0257";
}

.mdi-beaker-minus::before {
  content: "\f0258";
}

.mdi-beaker-minus-outline::before {
  content: "\f0259";
}

.mdi-beaker-outline::before {
  content: "\f68f";
}

.mdi-beaker-plus::before {
  content: "\f025a";
}

.mdi-beaker-plus-outline::before {
  content: "\f025b";
}

.mdi-beaker-question::before {
  content: "\f025c";
}

.mdi-beaker-question-outline::before {
  content: "\f025d";
}

.mdi-beaker-remove::before {
  content: "\f025e";
}

.mdi-beaker-remove-outline::before {
  content: "\f025f";
}

.mdi-beats::before {
  content: "\f097";
}

.mdi-bed-double::before {
  content: "\f0092";
}

.mdi-bed-double-outline::before {
  content: "\f0093";
}

.mdi-bed-empty::before {
  content: "\f89f";
}

.mdi-bed-king::before {
  content: "\f0094";
}

.mdi-bed-king-outline::before {
  content: "\f0095";
}

.mdi-bed-queen::before {
  content: "\f0096";
}

.mdi-bed-queen-outline::before {
  content: "\f0097";
}

.mdi-bed-single::before {
  content: "\f0098";
}

.mdi-bed-single-outline::before {
  content: "\f0099";
}

.mdi-bee::before {
  content: "￁";
}

.mdi-bee-flower::before {
  content: "ￂ";
}

.mdi-beehive-outline::before {
  content: "\f00f9";
}

.mdi-beer::before {
  content: "\f098";
}

.mdi-beer-outline::before {
  content: "\f0337";
}

.mdi-behance::before {
  content: "\f099";
}

.mdi-bell::before {
  content: "\f09a";
}

.mdi-bell-alert::before {
  content: "ﴵ";
}

.mdi-bell-alert-outline::before {
  content: "ﺞ";
}

.mdi-bell-check::before {
  content: "\f0210";
}

.mdi-bell-check-outline::before {
  content: "\f0211";
}

.mdi-bell-circle::before {
  content: "ﴶ";
}

.mdi-bell-circle-outline::before {
  content: "ﴷ";
}

.mdi-bell-off::before {
  content: "\f09b";
}

.mdi-bell-off-outline::before {
  content: "敖";
}

.mdi-bell-outline::before {
  content: "\f09c";
}

.mdi-bell-plus::before {
  content: "\f09d";
}

.mdi-bell-plus-outline::before {
  content: "晴";
}

.mdi-bell-ring::before {
  content: "\f09e";
}

.mdi-bell-ring-outline::before {
  content: "\f09f";
}

.mdi-bell-sleep::before {
  content: "\f0a0";
}

.mdi-bell-sleep-outline::before {
  content: "朗";
}

.mdi-beta::before {
  content: "\f0a1";
}

.mdi-betamax::before {
  content: "流";
}

.mdi-biathlon::before {
  content: "ﷷ";
}

.mdi-bible::before {
  content: "\f0a2";
}

.mdi-bicycle::before {
  content: "\f00c7";
}

.mdi-bicycle-basket::before {
  content: "\f0260";
}

.mdi-bike::before {
  content: "\f0a3";
}

.mdi-bike-fast::before {
  content: "\f014a";
}

.mdi-billboard::before {
  content: "\f0032";
}

.mdi-billiards::before {
  content: "﬽";
}

.mdi-billiards-rack::before {
  content: "מּ";
}

.mdi-bing::before {
  content: "\f0a4";
}

.mdi-binoculars::before {
  content: "\f0a5";
}

.mdi-bio::before {
  content: "\f0a6";
}

.mdi-biohazard::before {
  content: "\f0a7";
}

.mdi-bitbucket::before {
  content: "\f0a8";
}

.mdi-bitcoin::before {
  content: "\f812";
}

.mdi-black-mesa::before {
  content: "\f0a9";
}

.mdi-blackberry::before {
  content: "\f0aa";
}

.mdi-blender::before {
  content: "ﳇ";
}

.mdi-blender-software::before {
  content: "\f0ab";
}

.mdi-blinds::before {
  content: "\f0ac";
}

.mdi-blinds-open::before {
  content: "\f0033";
}

.mdi-block-helper::before {
  content: "\f0ad";
}

.mdi-blogger::before {
  content: "\f0ae";
}

.mdi-blood-bag::before {
  content: "ﳈ";
}

.mdi-bluetooth::before {
  content: "\f0af";
}

.mdi-bluetooth-audio::before {
  content: "\f0b0";
}

.mdi-bluetooth-connect::before {
  content: "\f0b1";
}

.mdi-bluetooth-off::before {
  content: "\f0b2";
}

.mdi-bluetooth-settings::before {
  content: "\f0b3";
}

.mdi-bluetooth-transfer::before {
  content: "\f0b4";
}

.mdi-blur::before {
  content: "\f0b5";
}

.mdi-blur-linear::before {
  content: "\f0b6";
}

.mdi-blur-off::before {
  content: "\f0b7";
}

.mdi-blur-radial::before {
  content: "\f0b8";
}

.mdi-bolnisi-cross::before {
  content: "ﳉ";
}

.mdi-bolt::before {
  content: "ﶏ";
}

.mdi-bomb::before {
  content: "\f690";
}

.mdi-bomb-off::before {
  content: "\f6c4";
}

.mdi-bone::before {
  content: "\f0b9";
}

.mdi-book::before {
  content: "\f0ba";
}

.mdi-book-information-variant::before {
  content: "\f009a";
}

.mdi-book-lock::before {
  content: "\f799";
}

.mdi-book-lock-open::before {
  content: "\f79a";
}

.mdi-book-minus::before {
  content: "\f5d9";
}

.mdi-book-minus-multiple::before {
  content: "望";
}

.mdi-book-multiple::before {
  content: "\f0bb";
}

.mdi-book-open::before {
  content: "\f0bd";
}

.mdi-book-open-outline::before {
  content: "﬿";
}

.mdi-book-open-page-variant::before {
  content: "\f5da";
}

.mdi-book-open-variant::before {
  content: "\f0be";
}

.mdi-book-outline::before {
  content: "נּ";
}

.mdi-book-play::before {
  content: "ﺟ";
}

.mdi-book-play-outline::before {
  content: "ﺠ";
}

.mdi-book-plus::before {
  content: "\f5db";
}

.mdi-book-plus-multiple::before {
  content: "杖";
}

.mdi-book-remove::before {
  content: "殺";
}

.mdi-book-remove-multiple::before {
  content: "歹";
}

.mdi-book-search::before {
  content: "ﺡ";
}

.mdi-book-search-outline::before {
  content: "ﺢ";
}

.mdi-book-variant::before {
  content: "\f0bf";
}

.mdi-book-variant-multiple::before {
  content: "\f0bc";
}

.mdi-bookmark::before {
  content: "\f0c0";
}

.mdi-bookmark-check::before {
  content: "\f0c1";
}

.mdi-bookmark-check-outline::before {
  content: "\f03a6";
}

.mdi-bookmark-minus::before {
  content: "溜";
}

.mdi-bookmark-minus-outline::before {
  content: "琉";
}

.mdi-bookmark-multiple::before {
  content: "ﷸ";
}

.mdi-bookmark-multiple-outline::before {
  content: "ﷹ";
}

.mdi-bookmark-music::before {
  content: "\f0c2";
}

.mdi-bookmark-music-outline::before {
  content: "\f03a4";
}

.mdi-bookmark-off::before {
  content: "留";
}

.mdi-bookmark-off-outline::before {
  content: "硫";
}

.mdi-bookmark-outline::before {
  content: "\f0c3";
}

.mdi-bookmark-plus::before {
  content: "\f0c5";
}

.mdi-bookmark-plus-outline::before {
  content: "\f0c4";
}

.mdi-bookmark-remove::before {
  content: "\f0c6";
}

.mdi-bookmark-remove-outline::before {
  content: "\f03a5";
}

.mdi-bookshelf::before {
  content: "\f028a";
}

.mdi-boom-gate::before {
  content: "ﺣ";
}

.mdi-boom-gate-alert::before {
  content: "ﺤ";
}

.mdi-boom-gate-alert-outline::before {
  content: "ﺥ";
}

.mdi-boom-gate-down::before {
  content: "ﺦ";
}

.mdi-boom-gate-down-outline::before {
  content: "ﺧ";
}

.mdi-boom-gate-outline::before {
  content: "ﺨ";
}

.mdi-boom-gate-up::before {
  content: "ﺩ";
}

.mdi-boom-gate-up-outline::before {
  content: "ﺪ";
}

.mdi-boombox::before {
  content: "\f5dc";
}

.mdi-boomerang::before {
  content: "\f00fa";
}

.mdi-bootstrap::before {
  content: "\f6c5";
}

.mdi-border-all::before {
  content: "\f0c7";
}

.mdi-border-all-variant::before {
  content: "\f8a0";
}

.mdi-border-bottom::before {
  content: "\f0c8";
}

.mdi-border-bottom-variant::before {
  content: "\f8a1";
}

.mdi-border-color::before {
  content: "\f0c9";
}

.mdi-border-horizontal::before {
  content: "\f0ca";
}

.mdi-border-inside::before {
  content: "\f0cb";
}

.mdi-border-left::before {
  content: "\f0cc";
}

.mdi-border-left-variant::before {
  content: "\f8a2";
}

.mdi-border-none::before {
  content: "\f0cd";
}

.mdi-border-none-variant::before {
  content: "\f8a3";
}

.mdi-border-outside::before {
  content: "\f0ce";
}

.mdi-border-right::before {
  content: "\f0cf";
}

.mdi-border-right-variant::before {
  content: "\f8a4";
}

.mdi-border-style::before {
  content: "\f0d0";
}

.mdi-border-top::before {
  content: "\f0d1";
}

.mdi-border-top-variant::before {
  content: "\f8a5";
}

.mdi-border-vertical::before {
  content: "\f0d2";
}

.mdi-bottle-soda::before {
  content: "\f009b";
}

.mdi-bottle-soda-classic::before {
  content: "\f009c";
}

.mdi-bottle-soda-classic-outline::before {
  content: "\f038e";
}

.mdi-bottle-soda-outline::before {
  content: "\f009d";
}

.mdi-bottle-tonic::before {
  content: "\f0159";
}

.mdi-bottle-tonic-outline::before {
  content: "\f015a";
}

.mdi-bottle-tonic-plus::before {
  content: "\f015b";
}

.mdi-bottle-tonic-plus-outline::before {
  content: "\f015c";
}

.mdi-bottle-tonic-skull::before {
  content: "\f015d";
}

.mdi-bottle-tonic-skull-outline::before {
  content: "\f015e";
}

.mdi-bottle-wine::before {
  content: "\f853";
}

.mdi-bottle-wine-outline::before {
  content: "\f033b";
}

.mdi-bow-tie::before {
  content: "\f677";
}

.mdi-bowl::before {
  content: "\f617";
}

.mdi-bowling::before {
  content: "\f0d3";
}

.mdi-box::before {
  content: "\f0d4";
}

.mdi-box-cutter::before {
  content: "\f0d5";
}

.mdi-box-shadow::before {
  content: "\f637";
}

.mdi-boxing-glove::before {
  content: "סּ";
}

.mdi-braille::before {
  content: "紐";
}

.mdi-brain::before {
  content: "類";
}

.mdi-bread-slice::before {
  content: "ﳊ";
}

.mdi-bread-slice-outline::before {
  content: "ﳋ";
}

.mdi-bridge::before {
  content: "\f618";
}

.mdi-briefcase::before {
  content: "\f0d6";
}

.mdi-briefcase-account::before {
  content: "ﳌ";
}

.mdi-briefcase-account-outline::before {
  content: "ﳍ";
}

.mdi-briefcase-check::before {
  content: "\f0d7";
}

.mdi-briefcase-check-outline::before {
  content: "\f0349";
}

.mdi-briefcase-clock::before {
  content: "\f00fb";
}

.mdi-briefcase-clock-outline::before {
  content: "\f00fc";
}

.mdi-briefcase-download::before {
  content: "\f0d8";
}

.mdi-briefcase-download-outline::before {
  content: "ﰙ";
}

.mdi-briefcase-edit::before {
  content: "流";
}

.mdi-briefcase-edit-outline::before {
  content: "ﰚ";
}

.mdi-briefcase-minus::before {
  content: "﨩";
}

.mdi-briefcase-minus-outline::before {
  content: "ﰛ";
}

.mdi-briefcase-outline::before {
  content: "\f813";
}

.mdi-briefcase-plus::before {
  content: "飯";
}

.mdi-briefcase-plus-outline::before {
  content: "ﰜ";
}

.mdi-briefcase-remove::before {
  content: "飼";
}

.mdi-briefcase-remove-outline::before {
  content: "ﰝ";
}

.mdi-briefcase-search::before {
  content: "館";
}

.mdi-briefcase-search-outline::before {
  content: "ﰞ";
}

.mdi-briefcase-upload::before {
  content: "\f0d9";
}

.mdi-briefcase-upload-outline::before {
  content: "ﰟ";
}

.mdi-brightness-1::before {
  content: "\f0da";
}

.mdi-brightness-2::before {
  content: "\f0db";
}

.mdi-brightness-3::before {
  content: "\f0dc";
}

.mdi-brightness-4::before {
  content: "\f0dd";
}

.mdi-brightness-5::before {
  content: "\f0de";
}

.mdi-brightness-6::before {
  content: "\f0df";
}

.mdi-brightness-7::before {
  content: "\f0e0";
}

.mdi-brightness-auto::before {
  content: "\f0e1";
}

.mdi-brightness-percent::before {
  content: "ﳎ";
}

.mdi-broom::before {
  content: "\f0e2";
}

.mdi-brush::before {
  content: "\f0e3";
}

.mdi-buddhism::before {
  content: "壘";
}

.mdi-buffer::before {
  content: "\f619";
}

.mdi-bug::before {
  content: "\f0e4";
}

.mdi-bug-check::before {
  content: "鶴";
}

.mdi-bug-check-outline::before {
  content: "郞";
}

.mdi-bug-outline::before {
  content: "隷";
}

.mdi-bugle::before {
  content: "﶐";
}

.mdi-bulldozer::before {
  content: "﬇";
}

.mdi-bullet::before {
  content: "ﳏ";
}

.mdi-bulletin-board::before {
  content: "\f0e5";
}

.mdi-bullhorn::before {
  content: "\f0e6";
}

.mdi-bullhorn-outline::before {
  content: "﬈";
}

.mdi-bullseye::before {
  content: "\f5dd";
}

.mdi-bullseye-arrow::before {
  content: "\f8c8";
}

.mdi-bulma::before {
  content: "\f0312";
}

.mdi-bunk-bed::before {
  content: "\f032d";
}

.mdi-bus::before {
  content: "\f0e7";
}

.mdi-bus-alert::before {
  content: "滛";
}

.mdi-bus-articulated-end::before {
  content: "\f79b";
}

.mdi-bus-articulated-front::before {
  content: "\f79c";
}

.mdi-bus-clock::before {
  content: "\f8c9";
}

.mdi-bus-double-decker::before {
  content: "\f79d";
}

.mdi-bus-marker::before {
  content: "\f023d";
}

.mdi-bus-multiple::before {
  content: "｜";
}

.mdi-bus-school::before {
  content: "\f79e";
}

.mdi-bus-side::before {
  content: "\f79f";
}

.mdi-bus-stop::before {
  content: "\f0034";
}

.mdi-bus-stop-covered::before {
  content: "\f0035";
}

.mdi-bus-stop-uncovered::before {
  content: "\f0036";
}

.mdi-cached::before {
  content: "\f0e8";
}

.mdi-cactus::before {
  content: "﶑";
}

.mdi-cake::before {
  content: "\f0e9";
}

.mdi-cake-layered::before {
  content: "\f0ea";
}

.mdi-cake-variant::before {
  content: "\f0eb";
}

.mdi-calculator::before {
  content: "\f0ec";
}

.mdi-calculator-variant::before {
  content: "滋";
}

.mdi-calendar::before {
  content: "\f0ed";
}

.mdi-calendar-account::before {
  content: "ﻴ";
}

.mdi-calendar-account-outline::before {
  content: "ﻵ";
}

.mdi-calendar-alert::before {
  content: "侮";
}

.mdi-calendar-arrow-left::before {
  content: "\f015f";
}

.mdi-calendar-arrow-right::before {
  content: "\f0160";
}

.mdi-calendar-blank::before {
  content: "\f0ee";
}

.mdi-calendar-blank-multiple::before {
  content: "\f009e";
}

.mdi-calendar-blank-outline::before {
  content: "﭂";
}

.mdi-calendar-check::before {
  content: "\f0ef";
}

.mdi-calendar-check-outline::before {
  content: "ﰠ";
}

.mdi-calendar-clock::before {
  content: "\f0f0";
}

.mdi-calendar-edit::before {
  content: "\f8a6";
}

.mdi-calendar-export::before {
  content: "﬉";
}

.mdi-calendar-heart::before {
  content: "六";
}

.mdi-calendar-import::before {
  content: "﬊";
}

.mdi-calendar-minus::before {
  content: "ﴸ";
}

.mdi-calendar-month::before {
  content: "ﷺ";
}

.mdi-calendar-month-outline::before {
  content: "ﷻ";
}

.mdi-calendar-multiple::before {
  content: "\f0f1";
}

.mdi-calendar-multiple-check::before {
  content: "\f0f2";
}

.mdi-calendar-multiselect::before {
  content: "僧";
}

.mdi-calendar-outline::before {
  content: "ףּ";
}

.mdi-calendar-plus::before {
  content: "\f0f3";
}

.mdi-calendar-question::before {
  content: "\f691";
}

.mdi-calendar-range::before {
  content: "\f678";
}

.mdi-calendar-range-outline::before {
  content: "פּ";
}

.mdi-calendar-remove::before {
  content: "\f0f4";
}

.mdi-calendar-remove-outline::before {
  content: "ﰡ";
}

.mdi-calendar-repeat::before {
  content: "ﺫ";
}

.mdi-calendar-repeat-outline::before {
  content: "ﺬ";
}

.mdi-calendar-search::before {
  content: "屢";
}

.mdi-calendar-star::before {
  content: "戮";
}

.mdi-calendar-text::before {
  content: "\f0f5";
}

.mdi-calendar-text-outline::before {
  content: "ﰢ";
}

.mdi-calendar-today::before {
  content: "\f0f6";
}

.mdi-calendar-week::before {
  content: "免";
}

.mdi-calendar-week-begin::before {
  content: "勉";
}

.mdi-calendar-weekend::before {
  content: "ﻶ";
}

.mdi-calendar-weekend-outline::before {
  content: "ﻷ";
}

.mdi-call-made::before {
  content: "\f0f7";
}

.mdi-call-merge::before {
  content: "\f0f8";
}

.mdi-call-missed::before {
  content: "\f0f9";
}

.mdi-call-received::before {
  content: "\f0fa";
}

.mdi-call-split::before {
  content: "\f0fb";
}

.mdi-camcorder::before {
  content: "\f0fc";
}

.mdi-camcorder-box::before {
  content: "\f0fd";
}

.mdi-camcorder-box-off::before {
  content: "\f0fe";
}

.mdi-camcorder-off::before {
  content: "\f0ff";
}

.mdi-camera::before {
  content: "\f100";
}

.mdi-camera-account::before {
  content: "\f8ca";
}

.mdi-camera-burst::before {
  content: "\f692";
}

.mdi-camera-control::before {
  content: "﭅";
}

.mdi-camera-enhance::before {
  content: "\f101";
}

.mdi-camera-enhance-outline::before {
  content: "צּ";
}

.mdi-camera-front::before {
  content: "\f102";
}

.mdi-camera-front-variant::before {
  content: "\f103";
}

.mdi-camera-gopro::before {
  content: "\f7a0";
}

.mdi-camera-image::before {
  content: "\f8cb";
}

.mdi-camera-iris::before {
  content: "\f104";
}

.mdi-camera-metering-center::before {
  content: "\f7a1";
}

.mdi-camera-metering-matrix::before {
  content: "\f7a2";
}

.mdi-camera-metering-partial::before {
  content: "\f7a3";
}

.mdi-camera-metering-spot::before {
  content: "\f7a4";
}

.mdi-camera-off::before {
  content: "\f5df";
}

.mdi-camera-outline::before {
  content: "ﴹ";
}

.mdi-camera-party-mode::before {
  content: "\f105";
}

.mdi-camera-plus::before {
  content: "ﻸ";
}

.mdi-camera-plus-outline::before {
  content: "ﻹ";
}

.mdi-camera-rear::before {
  content: "\f106";
}

.mdi-camera-rear-variant::before {
  content: "\f107";
}

.mdi-camera-retake::before {
  content: "﷼";
}

.mdi-camera-retake-outline::before {
  content: "﷽";
}

.mdi-camera-switch::before {
  content: "\f108";
}

.mdi-camera-timer::before {
  content: "\f109";
}

.mdi-camera-wireless::before {
  content: "ﶒ";
}

.mdi-camera-wireless-outline::before {
  content: "ﶓ";
}

.mdi-campfire::before {
  content: "ﻺ";
}

.mdi-cancel::before {
  content: "\f739";
}

.mdi-candle::before {
  content: "\f5e2";
}

.mdi-candycane::before {
  content: "\f10a";
}

.mdi-cannabis::before {
  content: "\f7a5";
}

.mdi-caps-lock::before {
  content: "漢";
}

.mdi-car::before {
  content: "\f10b";
}

.mdi-car-2-plus::before {
  content: "\f0037";
}

.mdi-car-3-plus::before {
  content: "\f0038";
}

.mdi-car-back::before {
  content: "﷾";
}

.mdi-car-battery::before {
  content: "\f10c";
}

.mdi-car-brake-abs::before {
  content: "ﰣ";
}

.mdi-car-brake-alert::before {
  content: "ﰤ";
}

.mdi-car-brake-hold::before {
  content: "ﴺ";
}

.mdi-car-brake-parking::before {
  content: "ﴻ";
}

.mdi-car-brake-retarder::before {
  content: "\f0039";
}

.mdi-car-child-seat::before {
  content: "ￃ";
}

.mdi-car-clutch::before {
  content: "\f003a";
}

.mdi-car-connected::before {
  content: "\f10d";
}

.mdi-car-convertible::before {
  content: "\f7a6";
}

.mdi-car-coolant-level::before {
  content: "\f003b";
}

.mdi-car-cruise-control::before {
  content: "ﴼ";
}

.mdi-car-defrost-front::before {
  content: "ﴽ";
}

.mdi-car-defrost-rear::before {
  content: "﴾";
}

.mdi-car-door::before {
  content: "קּ";
}

.mdi-car-door-lock::before {
  content: "\f00c8";
}

.mdi-car-electric::before {
  content: "רּ";
}

.mdi-car-esp::before {
  content: "ﰥ";
}

.mdi-car-estate::before {
  content: "\f7a7";
}

.mdi-car-hatchback::before {
  content: "\f7a8";
}

.mdi-car-info::before {
  content: "\f01e9";
}

.mdi-car-key::before {
  content: "שּ";
}

.mdi-car-light-dimmed::before {
  content: "ﰦ";
}

.mdi-car-light-fog::before {
  content: "ﰧ";
}

.mdi-car-light-high::before {
  content: "ﰨ";
}

.mdi-car-limousine::before {
  content: "\f8cc";
}

.mdi-car-multiple::before {
  content: "תּ";
}

.mdi-car-off::before {
  content: "﷿";
}

.mdi-car-parking-lights::before {
  content: "﴿";
}

.mdi-car-pickup::before {
  content: "\f7a9";
}

.mdi-car-seat::before {
  content: "ￄ";
}

.mdi-car-seat-cooler::before {
  content: "ￅ";
}

.mdi-car-seat-heater::before {
  content: "ￆ";
}

.mdi-car-shift-pattern::before {
  content: "｝";
}

.mdi-car-side::before {
  content: "\f7aa";
}

.mdi-car-sports::before {
  content: "\f7ab";
}

.mdi-car-tire-alert::before {
  content: "ﰩ";
}

.mdi-car-traction-control::before {
  content: "﵀";
}

.mdi-car-turbocharger::before {
  content: "\f003c";
}

.mdi-car-wash::before {
  content: "\f10e";
}

.mdi-car-windshield::before {
  content: "\f003d";
}

.mdi-car-windshield-outline::before {
  content: "\f003e";
}

.mdi-caravan::before {
  content: "\f7ac";
}

.mdi-card::before {
  content: "וֹ";
}

.mdi-card-bulleted::before {
  content: "בֿ";
}

.mdi-card-bulleted-off::before {
  content: "כֿ";
}

.mdi-card-bulleted-off-outline::before {
  content: "פֿ";
}

.mdi-card-bulleted-outline::before {
  content: "ﭏ";
}

.mdi-card-bulleted-settings::before {
  content: "ﭐ";
}

.mdi-card-bulleted-settings-outline::before {
  content: "ﭑ";
}

.mdi-card-outline::before {
  content: "ﭒ";
}

.mdi-card-plus::before {
  content: "\f022a";
}

.mdi-card-plus-outline::before {
  content: "\f022b";
}

.mdi-card-search::before {
  content: "\f009f";
}

.mdi-card-search-outline::before {
  content: "\f00a0";
}

.mdi-card-text::before {
  content: "ﭓ";
}

.mdi-card-text-outline::before {
  content: "ﭔ";
}

.mdi-cards::before {
  content: "\f638";
}

.mdi-cards-club::before {
  content: "\f8cd";
}

.mdi-cards-diamond::before {
  content: "\f8ce";
}

.mdi-cards-diamond-outline::before {
  content: "\f003f";
}

.mdi-cards-heart::before {
  content: "\f8cf";
}

.mdi-cards-outline::before {
  content: "\f639";
}

.mdi-cards-playing-outline::before {
  content: "\f63a";
}

.mdi-cards-spade::before {
  content: "\f8d0";
}

.mdi-cards-variant::before {
  content: "\f6c6";
}

.mdi-carrot::before {
  content: "\f10f";
}

.mdi-cart::before {
  content: "\f110";
}

.mdi-cart-arrow-down::before {
  content: "﵂";
}

.mdi-cart-arrow-right::before {
  content: "ﰪ";
}

.mdi-cart-arrow-up::before {
  content: "﵃";
}

.mdi-cart-minus::before {
  content: "﵄";
}

.mdi-cart-off::before {
  content: "\f66b";
}

.mdi-cart-outline::before {
  content: "\f111";
}

.mdi-cart-plus::before {
  content: "\f112";
}

.mdi-cart-remove::before {
  content: "﵅";
}

.mdi-case-sensitive-alt::before {
  content: "\f113";
}

.mdi-cash::before {
  content: "\f114";
}

.mdi-cash-100::before {
  content: "\f115";
}

.mdi-cash-marker::before {
  content: "ﶔ";
}

.mdi-cash-minus::before {
  content: "\f028b";
}

.mdi-cash-multiple::before {
  content: "\f116";
}

.mdi-cash-plus::before {
  content: "\f028c";
}

.mdi-cash-refund::before {
  content: "瀞";
}

.mdi-cash-register::before {
  content: "ﳐ";
}

.mdi-cash-remove::before {
  content: "\f028d";
}

.mdi-cash-usd::before {
  content: "\f01a1";
}

.mdi-cash-usd-outline::before {
  content: "\f117";
}

.mdi-cassette::before {
  content: "陸";
}

.mdi-cast::before {
  content: "\f118";
}

.mdi-cast-audio::before {
  content: "\f0040";
}

.mdi-cast-connected::before {
  content: "\f119";
}

.mdi-cast-education::before {
  content: "﹭";
}

.mdi-cast-off::before {
  content: "\f789";
}

.mdi-castle::before {
  content: "\f11a";
}

.mdi-cat::before {
  content: "\f11b";
}

.mdi-cctv::before {
  content: "\f7ad";
}

.mdi-ceiling-light::before {
  content: "\f768";
}

.mdi-cellphone::before {
  content: "\f11c";
}

.mdi-cellphone-android::before {
  content: "\f11d";
}

.mdi-cellphone-arrow-down::before {
  content: "倫";
}

.mdi-cellphone-basic::before {
  content: "\f11e";
}

.mdi-cellphone-dock::before {
  content: "\f11f";
}

.mdi-cellphone-erase::before {
  content: "樓";
}

.mdi-cellphone-information::before {
  content: "～";
}

.mdi-cellphone-iphone::before {
  content: "\f120";
}

.mdi-cellphone-key::before {
  content: "淚";
}

.mdi-cellphone-link::before {
  content: "\f121";
}

.mdi-cellphone-link-off::before {
  content: "\f122";
}

.mdi-cellphone-lock::before {
  content: "漏";
}

.mdi-cellphone-message::before {
  content: "\f8d2";
}

.mdi-cellphone-message-off::before {
  content: "\f00fd";
}

.mdi-cellphone-nfc::before {
  content: "ﺭ";
}

.mdi-cellphone-nfc-off::before {
  content: "\f0303";
}

.mdi-cellphone-off::before {
  content: "累";
}

.mdi-cellphone-play::before {
  content: "\f0041";
}

.mdi-cellphone-screenshot::before {
  content: "勤";
}

.mdi-cellphone-settings::before {
  content: "\f123";
}

.mdi-cellphone-settings-variant::before {
  content: "縷";
}

.mdi-cellphone-sound::before {
  content: "陋";
}

.mdi-cellphone-text::before {
  content: "\f8d1";
}

.mdi-cellphone-wireless::before {
  content: "\f814";
}

.mdi-celtic-cross::before {
  content: "ﳑ";
}

.mdi-centos::before {
  content: "\f0145";
}

.mdi-certificate::before {
  content: "\f124";
}

.mdi-certificate-outline::before {
  content: "\f01b3";
}

.mdi-chair-rolling::before {
  content: "ﾺ";
}

.mdi-chair-school::before {
  content: "\f125";
}

.mdi-charity::before {
  content: "ﰫ";
}

.mdi-chart-arc::before {
  content: "\f126";
}

.mdi-chart-areaspline::before {
  content: "\f127";
}

.mdi-chart-areaspline-variant::before {
  content: "ﺮ";
}

.mdi-chart-bar::before {
  content: "\f128";
}

.mdi-chart-bar-stacked::before {
  content: "\f769";
}

.mdi-chart-bell-curve::before {
  content: "ﰬ";
}

.mdi-chart-bell-curve-cumulative::before {
  content: "ￇ";
}

.mdi-chart-bubble::before {
  content: "\f5e3";
}

.mdi-chart-donut::before {
  content: "\f7ae";
}

.mdi-chart-donut-variant::before {
  content: "\f7af";
}

.mdi-chart-gantt::before {
  content: "\f66c";
}

.mdi-chart-histogram::before {
  content: "\f129";
}

.mdi-chart-line::before {
  content: "\f12a";
}

.mdi-chart-line-stacked::before {
  content: "\f76a";
}

.mdi-chart-line-variant::before {
  content: "\f7b0";
}

.mdi-chart-multiline::before {
  content: "\f8d3";
}

.mdi-chart-multiple::before {
  content: "\f023e";
}

.mdi-chart-pie::before {
  content: "\f12b";
}

.mdi-chart-ppf::before {
  content: "\f03ab";
}

.mdi-chart-scatter-plot::before {
  content: "ﺯ";
}

.mdi-chart-scatter-plot-hexbin::before {
  content: "\f66d";
}

.mdi-chart-snakey::before {
  content: "\f020a";
}

.mdi-chart-snakey-variant::before {
  content: "\f020b";
}

.mdi-chart-timeline::before {
  content: "\f66e";
}

.mdi-chart-timeline-variant::before {
  content: "ﺰ";
}

.mdi-chart-tree::before {
  content: "ﺱ";
}

.mdi-chat::before {
  content: "ﭕ";
}

.mdi-chat-alert::before {
  content: "ﭖ";
}

.mdi-chat-alert-outline::before {
  content: "\f02f4";
}

.mdi-chat-outline::before {
  content: "ﻻ";
}

.mdi-chat-processing::before {
  content: "ﭗ";
}

.mdi-chat-processing-outline::before {
  content: "\f02f5";
}

.mdi-chat-sleep::before {
  content: "\f02fc";
}

.mdi-chat-sleep-outline::before {
  content: "\f02fd";
}

.mdi-check::before {
  content: "\f12c";
}

.mdi-check-all::before {
  content: "\f12d";
}

.mdi-check-bold::before {
  content: "﹮";
}

.mdi-check-box-multiple-outline::before {
  content: "ﰭ";
}

.mdi-check-box-outline::before {
  content: "ﰮ";
}

.mdi-check-circle::before {
  content: "\f5e0";
}

.mdi-check-circle-outline::before {
  content: "\f5e1";
}

.mdi-check-decagram::before {
  content: "\f790";
}

.mdi-check-network::before {
  content: "ﰯ";
}

.mdi-check-network-outline::before {
  content: "ﰰ";
}

.mdi-check-outline::before {
  content: "\f854";
}

.mdi-check-underline::before {
  content: "ﹰ";
}

.mdi-check-underline-circle::before {
  content: "ﹱ";
}

.mdi-check-underline-circle-outline::before {
  content: "ﹲ";
}

.mdi-checkbook::before {
  content: "煮";
}

.mdi-checkbox-blank::before {
  content: "\f12e";
}

.mdi-checkbox-blank-circle::before {
  content: "\f12f";
}

.mdi-checkbox-blank-circle-outline::before {
  content: "\f130";
}

.mdi-checkbox-blank-off::before {
  content: "\f0317";
}

.mdi-checkbox-blank-off-outline::before {
  content: "\f0318";
}

.mdi-checkbox-blank-outline::before {
  content: "\f131";
}

.mdi-checkbox-intermediate::before {
  content: "\f855";
}

.mdi-checkbox-marked::before {
  content: "\f132";
}

.mdi-checkbox-marked-circle::before {
  content: "\f133";
}

.mdi-checkbox-marked-circle-outline::before {
  content: "\f134";
}

.mdi-checkbox-marked-outline::before {
  content: "\f135";
}

.mdi-checkbox-multiple-blank::before {
  content: "\f136";
}

.mdi-checkbox-multiple-blank-circle::before {
  content: "\f63b";
}

.mdi-checkbox-multiple-blank-circle-outline::before {
  content: "\f63c";
}

.mdi-checkbox-multiple-blank-outline::before {
  content: "\f137";
}

.mdi-checkbox-multiple-marked::before {
  content: "\f138";
}

.mdi-checkbox-multiple-marked-circle::before {
  content: "\f63d";
}

.mdi-checkbox-multiple-marked-circle-outline::before {
  content: "\f63e";
}

.mdi-checkbox-multiple-marked-outline::before {
  content: "\f139";
}

.mdi-checkerboard::before {
  content: "\f13a";
}

.mdi-checkerboard-minus::before {
  content: "\f022d";
}

.mdi-checkerboard-plus::before {
  content: "\f022c";
}

.mdi-checkerboard-remove::before {
  content: "\f022e";
}

.mdi-cheese::before {
  content: "\f02e4";
}

.mdi-chef-hat::before {
  content: "ﭘ";
}

.mdi-chemical-weapon::before {
  content: "\f13b";
}

.mdi-chess-bishop::before {
  content: "\f85b";
}

.mdi-chess-king::before {
  content: "\f856";
}

.mdi-chess-knight::before {
  content: "\f857";
}

.mdi-chess-pawn::before {
  content: "\f858";
}

.mdi-chess-queen::before {
  content: "\f859";
}

.mdi-chess-rook::before {
  content: "\f85a";
}

.mdi-chevron-double-down::before {
  content: "\f13c";
}

.mdi-chevron-double-left::before {
  content: "\f13d";
}

.mdi-chevron-double-right::before {
  content: "\f13e";
}

.mdi-chevron-double-up::before {
  content: "\f13f";
}

.mdi-chevron-down::before {
  content: "\f140";
}

.mdi-chevron-down-box::before {
  content: "崙";
}

.mdi-chevron-down-box-outline::before {
  content: "淪";
}

.mdi-chevron-down-circle::before {
  content: "﬋";
}

.mdi-chevron-down-circle-outline::before {
  content: "﬌";
}

.mdi-chevron-left::before {
  content: "\f141";
}

.mdi-chevron-left-box::before {
  content: "輪";
}

.mdi-chevron-left-box-outline::before {
  content: "律";
}

.mdi-chevron-left-circle::before {
  content: "﬍";
}

.mdi-chevron-left-circle-outline::before {
  content: "﬎";
}

.mdi-chevron-right::before {
  content: "\f142";
}

.mdi-chevron-right-box::before {
  content: "慄";
}

.mdi-chevron-right-box-outline::before {
  content: "栗";
}

.mdi-chevron-right-circle::before {
  content: "﬏";
}

.mdi-chevron-right-circle-outline::before {
  content: "﬐";
}

.mdi-chevron-triple-down::before {
  content: "ﶕ";
}

.mdi-chevron-triple-left::before {
  content: "ﶖ";
}

.mdi-chevron-triple-right::before {
  content: "ﶗ";
}

.mdi-chevron-triple-up::before {
  content: "ﶘ";
}

.mdi-chevron-up::before {
  content: "\f143";
}

.mdi-chevron-up-box::before {
  content: "率";
}

.mdi-chevron-up-box-outline::before {
  content: "隆";
}

.mdi-chevron-up-circle::before {
  content: "﬑";
}

.mdi-chevron-up-circle-outline::before {
  content: "﬒";
}

.mdi-chili-hot::before {
  content: "\f7b1";
}

.mdi-chili-medium::before {
  content: "\f7b2";
}

.mdi-chili-mild::before {
  content: "\f7b3";
}

.mdi-chip::before {
  content: "\f61a";
}

.mdi-christianity::before {
  content: "勒";
}

.mdi-christianity-outline::before {
  content: "ﳒ";
}

.mdi-church::before {
  content: "\f144";
}

.mdi-cigar::before {
  content: "\f01b4";
}

.mdi-circle::before {
  content: "\f764";
}

.mdi-circle-double::before {
  content: "ﺲ";
}

.mdi-circle-edit-outline::before {
  content: "\f8d4";
}

.mdi-circle-expand::before {
  content: "ﺳ";
}

.mdi-circle-medium::before {
  content: "利";
}

.mdi-circle-off-outline::before {
  content: "\f00fe";
}

.mdi-circle-outline::before {
  content: "\f765";
}

.mdi-circle-slice-1::before {
  content: "瞧";
}

.mdi-circle-slice-2::before {
  content: "爵";
}

.mdi-circle-slice-3::before {
  content: "犯";
}

.mdi-circle-slice-4::before {
  content: "猪";
}

.mdi-circle-slice-5::before {
  content: "瑱";
}

.mdi-circle-slice-6::before {
  content: "甆";
}

.mdi-circle-slice-7::before {
  content: "画";
}

.mdi-circle-slice-8::before {
  content: "瘝";
}

.mdi-circle-small::before {
  content: "吏";
}

.mdi-circular-saw::before {
  content: "ﹳ";
}

.mdi-cisco-webex::before {
  content: "\f145";
}

.mdi-city::before {
  content: "\f146";
}

.mdi-city-variant::before {
  content: "卑";
}

.mdi-city-variant-outline::before {
  content: "喝";
}

.mdi-clipboard::before {
  content: "\f147";
}

.mdi-clipboard-account::before {
  content: "\f148";
}

.mdi-clipboard-account-outline::before {
  content: "ﰱ";
}

.mdi-clipboard-alert::before {
  content: "\f149";
}

.mdi-clipboard-alert-outline::before {
  content: "ﳓ";
}

.mdi-clipboard-arrow-down::before {
  content: "\f14a";
}

.mdi-clipboard-arrow-down-outline::before {
  content: "ﰲ";
}

.mdi-clipboard-arrow-left::before {
  content: "\f14b";
}

.mdi-clipboard-arrow-left-outline::before {
  content: "ﳔ";
}

.mdi-clipboard-arrow-right::before {
  content: "ﳕ";
}

.mdi-clipboard-arrow-right-outline::before {
  content: "ﳖ";
}

.mdi-clipboard-arrow-up::before {
  content: "ﰳ";
}

.mdi-clipboard-arrow-up-outline::before {
  content: "ﰴ";
}

.mdi-clipboard-check::before {
  content: "\f14c";
}

.mdi-clipboard-check-multiple::before {
  content: "\f028e";
}

.mdi-clipboard-check-multiple-outline::before {
  content: "\f028f";
}

.mdi-clipboard-check-outline::before {
  content: "\f8a7";
}

.mdi-clipboard-file::before {
  content: "\f0290";
}

.mdi-clipboard-file-outline::before {
  content: "\f0291";
}

.mdi-clipboard-flow::before {
  content: "\f6c7";
}

.mdi-clipboard-flow-outline::before {
  content: "\f0142";
}

.mdi-clipboard-list::before {
  content: "\f00ff";
}

.mdi-clipboard-list-outline::before {
  content: "\f0100";
}

.mdi-clipboard-multiple::before {
  content: "\f0292";
}

.mdi-clipboard-multiple-outline::before {
  content: "\f0293";
}

.mdi-clipboard-outline::before {
  content: "\f14d";
}

.mdi-clipboard-play::before {
  content: "ﰵ";
}

.mdi-clipboard-play-multiple::before {
  content: "\f0294";
}

.mdi-clipboard-play-multiple-outline::before {
  content: "\f0295";
}

.mdi-clipboard-play-outline::before {
  content: "ﰶ";
}

.mdi-clipboard-plus::before {
  content: "\f750";
}

.mdi-clipboard-plus-outline::before {
  content: "\f034a";
}

.mdi-clipboard-pulse::before {
  content: "\f85c";
}

.mdi-clipboard-pulse-outline::before {
  content: "\f85d";
}

.mdi-clipboard-text::before {
  content: "\f14e";
}

.mdi-clipboard-text-multiple::before {
  content: "\f0296";
}

.mdi-clipboard-text-multiple-outline::before {
  content: "\f0297";
}

.mdi-clipboard-text-outline::before {
  content: "嘆";
}

.mdi-clipboard-text-play::before {
  content: "ﰷ";
}

.mdi-clipboard-text-play-outline::before {
  content: "ﰸ";
}

.mdi-clippy::before {
  content: "\f14f";
}

.mdi-clock::before {
  content: "肋";
}

.mdi-clock-alert::before {
  content: "凜";
}

.mdi-clock-alert-outline::before {
  content: "\f5ce";
}

.mdi-clock-check::before {
  content: "￈";
}

.mdi-clock-check-outline::before {
  content: "￉";
}

.mdi-clock-digital::before {
  content: "ﺴ";
}

.mdi-clock-end::before {
  content: "\f151";
}

.mdi-clock-fast::before {
  content: "\f152";
}

.mdi-clock-in::before {
  content: "\f153";
}

.mdi-clock-out::before {
  content: "\f154";
}

.mdi-clock-outline::before {
  content: "\f150";
}

.mdi-clock-start::before {
  content: "\f155";
}

.mdi-close::before {
  content: "\f156";
}

.mdi-close-box::before {
  content: "\f157";
}

.mdi-close-box-multiple::before {
  content: "ﰹ";
}

.mdi-close-box-multiple-outline::before {
  content: "ﰺ";
}

.mdi-close-box-outline::before {
  content: "\f158";
}

.mdi-close-circle::before {
  content: "\f159";
}

.mdi-close-circle-outline::before {
  content: "\f15a";
}

.mdi-close-network::before {
  content: "\f15b";
}

.mdi-close-network-outline::before {
  content: "ﰻ";
}

.mdi-close-octagon::before {
  content: "\f15c";
}

.mdi-close-octagon-outline::before {
  content: "\f15d";
}

.mdi-close-outline::before {
  content: "\f6c8";
}

.mdi-closed-caption::before {
  content: "\f15e";
}

.mdi-closed-caption-outline::before {
  content: "ﶙ";
}

.mdi-cloud::before {
  content: "\f15f";
}

.mdi-cloud-alert::before {
  content: "履";
}

.mdi-cloud-braces::before {
  content: "\f7b4";
}

.mdi-cloud-check::before {
  content: "\f160";
}

.mdi-cloud-check-outline::before {
  content: "\f02f7";
}

.mdi-cloud-circle::before {
  content: "\f161";
}

.mdi-cloud-download::before {
  content: "\f162";
}

.mdi-cloud-download-outline::before {
  content: "ﭙ";
}

.mdi-cloud-lock::before {
  content: "\f021c";
}

.mdi-cloud-lock-outline::before {
  content: "\f021d";
}

.mdi-cloud-off-outline::before {
  content: "\f164";
}

.mdi-cloud-outline::before {
  content: "\f163";
}

.mdi-cloud-print::before {
  content: "\f165";
}

.mdi-cloud-print-outline::before {
  content: "\f166";
}

.mdi-cloud-question::before {
  content: "器";
}

.mdi-cloud-search::before {
  content: "凌";
}

.mdi-cloud-search-outline::before {
  content: "稜";
}

.mdi-cloud-sync::before {
  content: "\f63f";
}

.mdi-cloud-sync-outline::before {
  content: "\f0301";
}

.mdi-cloud-tags::before {
  content: "\f7b5";
}

.mdi-cloud-upload::before {
  content: "\f167";
}

.mdi-cloud-upload-outline::before {
  content: "ﭚ";
}

.mdi-clover::before {
  content: "\f815";
}

.mdi-coach-lamp::before {
  content: "\f0042";
}

.mdi-coat-rack::before {
  content: "\f00c9";
}

.mdi-code-array::before {
  content: "\f168";
}

.mdi-code-braces::before {
  content: "\f169";
}

.mdi-code-braces-box::before {
  content: "\f0101";
}

.mdi-code-brackets::before {
  content: "\f16a";
}

.mdi-code-equal::before {
  content: "\f16b";
}

.mdi-code-greater-than::before {
  content: "\f16c";
}

.mdi-code-greater-than-or-equal::before {
  content: "\f16d";
}

.mdi-code-less-than::before {
  content: "\f16e";
}

.mdi-code-less-than-or-equal::before {
  content: "\f16f";
}

.mdi-code-not-equal::before {
  content: "\f170";
}

.mdi-code-not-equal-variant::before {
  content: "\f171";
}

.mdi-code-parentheses::before {
  content: "\f172";
}

.mdi-code-parentheses-box::before {
  content: "\f0102";
}

.mdi-code-string::before {
  content: "\f173";
}

.mdi-code-tags::before {
  content: "\f174";
}

.mdi-code-tags-check::before {
  content: "\f693";
}

.mdi-codepen::before {
  content: "\f175";
}

.mdi-coffee::before {
  content: "\f176";
}

.mdi-coffee-maker::before {
  content: "\f00ca";
}

.mdi-coffee-off::before {
  content: "ￊ";
}

.mdi-coffee-off-outline::before {
  content: "ￋ";
}

.mdi-coffee-outline::before {
  content: "\f6c9";
}

.mdi-coffee-to-go::before {
  content: "\f177";
}

.mdi-coffee-to-go-outline::before {
  content: "\f0339";
}

.mdi-coffin::before {
  content: "ﭛ";
}

.mdi-cog-clockwise::before {
  content: "\f0208";
}

.mdi-cog-counterclockwise::before {
  content: "\f0209";
}

.mdi-cogs::before {
  content: "\f8d5";
}

.mdi-coin::before {
  content: "\f0196";
}

.mdi-coin-outline::before {
  content: "\f178";
}

.mdi-coins::before {
  content: "\f694";
}

.mdi-collage::before {
  content: "\f640";
}

.mdi-collapse-all::before {
  content: "瘟";
}

.mdi-collapse-all-outline::before {
  content: "益";
}

.mdi-color-helper::before {
  content: "\f179";
}

.mdi-comma::before {
  content: "ﹴ";
}

.mdi-comma-box::before {
  content: "﹵";
}

.mdi-comma-box-outline::before {
  content: "ﹶ";
}

.mdi-comma-circle::before {
  content: "ﹷ";
}

.mdi-comma-circle-outline::before {
  content: "ﹸ";
}

.mdi-comment::before {
  content: "\f17a";
}

.mdi-comment-account::before {
  content: "\f17b";
}

.mdi-comment-account-outline::before {
  content: "\f17c";
}

.mdi-comment-alert::before {
  content: "\f17d";
}

.mdi-comment-alert-outline::before {
  content: "\f17e";
}

.mdi-comment-arrow-left::before {
  content: "易";
}

.mdi-comment-arrow-left-outline::before {
  content: "李";
}

.mdi-comment-arrow-right::before {
  content: "梨";
}

.mdi-comment-arrow-right-outline::before {
  content: "泥";
}

.mdi-comment-check::before {
  content: "\f17f";
}

.mdi-comment-check-outline::before {
  content: "\f180";
}

.mdi-comment-edit::before {
  content: "\f01ea";
}

.mdi-comment-edit-outline::before {
  content: "\f02ef";
}

.mdi-comment-eye::before {
  content: "塀";
}

.mdi-comment-eye-outline::before {
  content: "墨";
}

.mdi-comment-multiple::before {
  content: "\f85e";
}

.mdi-comment-multiple-outline::before {
  content: "\f181";
}

.mdi-comment-outline::before {
  content: "\f182";
}

.mdi-comment-plus::before {
  content: "理";
}

.mdi-comment-plus-outline::before {
  content: "\f183";
}

.mdi-comment-processing::before {
  content: "\f184";
}

.mdi-comment-processing-outline::before {
  content: "\f185";
}

.mdi-comment-question::before {
  content: "\f816";
}

.mdi-comment-question-outline::before {
  content: "\f186";
}

.mdi-comment-quote::before {
  content: "\f0043";
}

.mdi-comment-quote-outline::before {
  content: "\f0044";
}

.mdi-comment-remove::before {
  content: "\f5de";
}

.mdi-comment-remove-outline::before {
  content: "\f187";
}

.mdi-comment-search::before {
  content: "層";
}

.mdi-comment-search-outline::before {
  content: "屮";
}

.mdi-comment-text::before {
  content: "\f188";
}

.mdi-comment-text-multiple::before {
  content: "\f85f";
}

.mdi-comment-text-multiple-outline::before {
  content: "\f860";
}

.mdi-comment-text-outline::before {
  content: "\f189";
}

.mdi-compare::before {
  content: "\f18a";
}

.mdi-compass::before {
  content: "\f18b";
}

.mdi-compass-off::before {
  content: "ﭜ";
}

.mdi-compass-off-outline::before {
  content: "ﭝ";
}

.mdi-compass-outline::before {
  content: "\f18c";
}

.mdi-compass-rose::before {
  content: "\f03ad";
}

.mdi-concourse-ci::before {
  content: "\f00cb";
}

.mdi-console::before {
  content: "\f18d";
}

.mdi-console-line::before {
  content: "\f7b6";
}

.mdi-console-network::before {
  content: "\f8a8";
}

.mdi-console-network-outline::before {
  content: "ﰼ";
}

.mdi-consolidate::before {
  content: "\f0103";
}

.mdi-contact-mail::before {
  content: "\f18e";
}

.mdi-contact-mail-outline::before {
  content: "ﺵ";
}

.mdi-contact-phone::before {
  content: "ﺶ";
}

.mdi-contact-phone-outline::before {
  content: "ﺷ";
}

.mdi-contactless-payment::before {
  content: "﵆";
}

.mdi-contacts::before {
  content: "\f6ca";
}

.mdi-contain::before {
  content: "悔";
}

.mdi-contain-end::before {
  content: "慨";
}

.mdi-contain-start::before {
  content: "憎";
}

.mdi-content-copy::before {
  content: "\f18f";
}

.mdi-content-cut::before {
  content: "\f190";
}

.mdi-content-duplicate::before {
  content: "\f191";
}

.mdi-content-paste::before {
  content: "\f192";
}

.mdi-content-save::before {
  content: "\f193";
}

.mdi-content-save-alert::before {
  content: "｟";
}

.mdi-content-save-alert-outline::before {
  content: "｠";
}

.mdi-content-save-all::before {
  content: "\f194";
}

.mdi-content-save-all-outline::before {
  content: "｡";
}

.mdi-content-save-edit::before {
  content: "ﳗ";
}

.mdi-content-save-edit-outline::before {
  content: "ﳘ";
}

.mdi-content-save-move::before {
  content: "ﹹ";
}

.mdi-content-save-move-outline::before {
  content: "ﹺ";
}

.mdi-content-save-outline::before {
  content: "\f817";
}

.mdi-content-save-settings::before {
  content: "\f61b";
}

.mdi-content-save-settings-outline::before {
  content: "ﬓ";
}

.mdi-contrast::before {
  content: "\f195";
}

.mdi-contrast-box::before {
  content: "\f196";
}

.mdi-contrast-circle::before {
  content: "\f197";
}

.mdi-controller-classic::before {
  content: "ﭞ";
}

.mdi-controller-classic-outline::before {
  content: "ﭟ";
}

.mdi-cookie::before {
  content: "\f198";
}

.mdi-coolant-temperature::before {
  content: "\f3c8";
}

.mdi-copyright::before {
  content: "\f5e6";
}

.mdi-cordova::before {
  content: "綾";
}

.mdi-corn::before {
  content: "\f7b7";
}

.mdi-counter::before {
  content: "\f199";
}

.mdi-cow::before {
  content: "\f19a";
}

.mdi-cowboy::before {
  content: "ﺸ";
}

.mdi-cpu-32-bit::before {
  content: "ﻼ";
}

.mdi-cpu-64-bit::before {
  content: "﻽";
}

.mdi-crane::before {
  content: "\f861";
}

.mdi-creation::before {
  content: "\f1c9";
}

.mdi-creative-commons::before {
  content: "﵇";
}

.mdi-credit-card::before {
  content: "\f0010";
}

.mdi-credit-card-clock::before {
  content: "﻾";
}

.mdi-credit-card-clock-outline::before {
  content: "ﾼ";
}

.mdi-credit-card-marker::before {
  content: "\f6a7";
}

.mdi-credit-card-marker-outline::before {
  content: "ﶚ";
}

.mdi-credit-card-minus::before {
  content: "ￌ";
}

.mdi-credit-card-minus-outline::before {
  content: "ￍ";
}

.mdi-credit-card-multiple::before {
  content: "\f0011";
}

.mdi-credit-card-multiple-outline::before {
  content: "\f19c";
}

.mdi-credit-card-off::before {
  content: "\f0012";
}

.mdi-credit-card-off-outline::before {
  content: "\f5e4";
}

.mdi-credit-card-outline::before {
  content: "\f19b";
}

.mdi-credit-card-plus::before {
  content: "\f0013";
}

.mdi-credit-card-plus-outline::before {
  content: "\f675";
}

.mdi-credit-card-refund::before {
  content: "\f0014";
}

.mdi-credit-card-refund-outline::before {
  content: "盛";
}

.mdi-credit-card-remove::before {
  content: "ￎ";
}

.mdi-credit-card-remove-outline::before {
  content: "ￏ";
}

.mdi-credit-card-scan::before {
  content: "\f0015";
}

.mdi-credit-card-scan-outline::before {
  content: "\f19d";
}

.mdi-credit-card-settings::before {
  content: "\f0016";
}

.mdi-credit-card-settings-outline::before {
  content: "\f8d6";
}

.mdi-credit-card-wireless::before {
  content: "\f801";
}

.mdi-credit-card-wireless-outline::before {
  content: "﵈";
}

.mdi-cricket::before {
  content: "﵉";
}

.mdi-crop::before {
  content: "\f19e";
}

.mdi-crop-free::before {
  content: "\f19f";
}

.mdi-crop-landscape::before {
  content: "\f1a0";
}

.mdi-crop-portrait::before {
  content: "\f1a1";
}

.mdi-crop-rotate::before {
  content: "\f695";
}

.mdi-crop-square::before {
  content: "\f1a2";
}

.mdi-crosshairs::before {
  content: "\f1a3";
}

.mdi-crosshairs-gps::before {
  content: "\f1a4";
}

.mdi-crosshairs-off::before {
  content: "｢";
}

.mdi-crosshairs-question::before {
  content: "\f0161";
}

.mdi-crown::before {
  content: "\f1a5";
}

.mdi-crown-outline::before {
  content: "\f01fb";
}

.mdi-cryengine::before {
  content: "菱";
}

.mdi-crystal-ball::before {
  content: "ﬔ";
}

.mdi-cube::before {
  content: "\f1a6";
}

.mdi-cube-outline::before {
  content: "\f1a7";
}

.mdi-cube-scan::before {
  content: "ﭠ";
}

.mdi-cube-send::before {
  content: "\f1a8";
}

.mdi-cube-unfolded::before {
  content: "\f1a9";
}

.mdi-cup::before {
  content: "\f1aa";
}

.mdi-cup-off::before {
  content: "\f5e5";
}

.mdi-cup-off-outline::before {
  content: "\f03a8";
}

.mdi-cup-outline::before {
  content: "\f033a";
}

.mdi-cup-water::before {
  content: "\f1ab";
}

.mdi-cupboard::before {
  content: "｣";
}

.mdi-cupboard-outline::before {
  content: "､";
}

.mdi-cupcake::before {
  content: "陵";
}

.mdi-curling::before {
  content: "\f862";
}

.mdi-currency-bdt::before {
  content: "\f863";
}

.mdi-currency-brl::before {
  content: "ﭡ";
}

.mdi-currency-btc::before {
  content: "\f1ac";
}

.mdi-currency-cny::before {
  content: "\f7b9";
}

.mdi-currency-eth::before {
  content: "\f7ba";
}

.mdi-currency-eur::before {
  content: "\f1ad";
}

.mdi-currency-eur-off::before {
  content: "\f0340";
}

.mdi-currency-gbp::before {
  content: "\f1ae";
}

.mdi-currency-ils::before {
  content: "ﰽ";
}

.mdi-currency-inr::before {
  content: "\f1af";
}

.mdi-currency-jpy::before {
  content: "\f7bb";
}

.mdi-currency-krw::before {
  content: "\f7bc";
}

.mdi-currency-kzt::before {
  content: "\f864";
}

.mdi-currency-ngn::before {
  content: "\f1b0";
}

.mdi-currency-php::before {
  content: "痢";
}

.mdi-currency-rial::before {
  content: "ﺹ";
}

.mdi-currency-rub::before {
  content: "\f1b1";
}

.mdi-currency-sign::before {
  content: "\f7bd";
}

.mdi-currency-try::before {
  content: "\f1b2";
}

.mdi-currency-twd::before {
  content: "\f7be";
}

.mdi-currency-usd::before {
  content: "\f1b3";
}

.mdi-currency-usd-off::before {
  content: "\f679";
}

.mdi-current-ac::before {
  content: "讀";
}

.mdi-current-dc::before {
  content: "拏";
}

.mdi-cursor-default::before {
  content: "\f1b4";
}

.mdi-cursor-default-click::before {
  content: "ﳙ";
}

.mdi-cursor-default-click-outline::before {
  content: "ﳚ";
}

.mdi-cursor-default-gesture::before {
  content: "\f0152";
}

.mdi-cursor-default-gesture-outline::before {
  content: "\f0153";
}

.mdi-cursor-default-outline::before {
  content: "\f1b5";
}

.mdi-cursor-move::before {
  content: "\f1b6";
}

.mdi-cursor-pointer::before {
  content: "\f1b7";
}

.mdi-cursor-text::before {
  content: "\f5e7";
}

.mdi-database::before {
  content: "\f1b8";
}

.mdi-database-check::before {
  content: "直";
}

.mdi-database-edit::before {
  content: "ﭢ";
}

.mdi-database-export::before {
  content: "諾";
}

.mdi-database-import::before {
  content: "樂";
}

.mdi-database-lock::before {
  content: "睊";
}

.mdi-database-marker::before {
  content: "\f0321";
}

.mdi-database-minus::before {
  content: "\f1b9";
}

.mdi-database-plus::before {
  content: "\f1ba";
}

.mdi-database-refresh::before {
  content: "ﳛ";
}

.mdi-database-remove::before {
  content: "ﳜ";
}

.mdi-database-search::before {
  content: "\f865";
}

.mdi-database-settings::before {
  content: "ﳝ";
}

.mdi-death-star::before {
  content: "\f8d7";
}

.mdi-death-star-variant::before {
  content: "\f8d8";
}

.mdi-deathly-hallows::before {
  content: "ﭣ";
}

.mdi-debian::before {
  content: "\f8d9";
}

.mdi-debug-step-into::before {
  content: "\f1bb";
}

.mdi-debug-step-out::before {
  content: "\f1bc";
}

.mdi-debug-step-over::before {
  content: "\f1bd";
}

.mdi-decagram::before {
  content: "\f76b";
}

.mdi-decagram-outline::before {
  content: "\f76c";
}

.mdi-decimal::before {
  content: "\f00cc";
}

.mdi-decimal-comma::before {
  content: "\f00cd";
}

.mdi-decimal-comma-decrease::before {
  content: "\f00ce";
}

.mdi-decimal-comma-increase::before {
  content: "\f00cf";
}

.mdi-decimal-decrease::before {
  content: "\f1be";
}

.mdi-decimal-increase::before {
  content: "\f1bf";
}

.mdi-delete::before {
  content: "\f1c0";
}

.mdi-delete-alert::before {
  content: "\f00d0";
}

.mdi-delete-alert-outline::before {
  content: "\f00d1";
}

.mdi-delete-circle::before {
  content: "\f682";
}

.mdi-delete-circle-outline::before {
  content: "ﭤ";
}

.mdi-delete-empty::before {
  content: "\f6cb";
}

.mdi-delete-empty-outline::before {
  content: "ﺺ";
}

.mdi-delete-forever::before {
  content: "\f5e8";
}

.mdi-delete-forever-outline::before {
  content: "ﭥ";
}

.mdi-delete-off::before {
  content: "\f00d2";
}

.mdi-delete-off-outline::before {
  content: "\f00d3";
}

.mdi-delete-outline::before {
  content: "罹";
}

.mdi-delete-restore::before {
  content: "\f818";
}

.mdi-delete-sweep::before {
  content: "\f5e9";
}

.mdi-delete-sweep-outline::before {
  content: "ﰾ";
}

.mdi-delete-variant::before {
  content: "\f1c1";
}

.mdi-delta::before {
  content: "\f1c2";
}

.mdi-desk::before {
  content: "\f0264";
}

.mdi-desk-lamp::before {
  content: "丹";
}

.mdi-deskphone::before {
  content: "\f1c3";
}

.mdi-desktop-classic::before {
  content: "\f7bf";
}

.mdi-desktop-mac::before {
  content: "\f1c4";
}

.mdi-desktop-mac-dashboard::before {
  content: "裏";
}

.mdi-desktop-tower::before {
  content: "\f1c5";
}

.mdi-desktop-tower-monitor::before {
  content: "着";
}

.mdi-details::before {
  content: "\f1c6";
}

.mdi-dev-to::before {
  content: "﵊";
}

.mdi-developer-board::before {
  content: "\f696";
}

.mdi-deviantart::before {
  content: "\f1c7";
}

.mdi-devices::before {
  content: "￐";
}

.mdi-diabetes::before {
  content: "\f0151";
}

.mdi-dialpad::before {
  content: "\f61c";
}

.mdi-diameter::before {
  content: "ﰿ";
}

.mdi-diameter-outline::before {
  content: "ﱀ";
}

.mdi-diameter-variant::before {
  content: "ﱁ";
}

.mdi-diamond::before {
  content: "ﭦ";
}

.mdi-diamond-outline::before {
  content: "ﭧ";
}

.mdi-diamond-stone::before {
  content: "\f1c8";
}

.mdi-dice-1::before {
  content: "\f1ca";
}

.mdi-dice-1-outline::before {
  content: "\f0175";
}

.mdi-dice-2::before {
  content: "\f1cb";
}

.mdi-dice-2-outline::before {
  content: "\f0176";
}

.mdi-dice-3::before {
  content: "\f1cc";
}

.mdi-dice-3-outline::before {
  content: "\f0177";
}

.mdi-dice-4::before {
  content: "\f1cd";
}

.mdi-dice-4-outline::before {
  content: "\f0178";
}

.mdi-dice-5::before {
  content: "\f1ce";
}

.mdi-dice-5-outline::before {
  content: "\f0179";
}

.mdi-dice-6::before {
  content: "\f1cf";
}

.mdi-dice-6-outline::before {
  content: "\f017a";
}

.mdi-dice-d10::before {
  content: "\f017e";
}

.mdi-dice-d10-outline::before {
  content: "\f76e";
}

.mdi-dice-d12::before {
  content: "\f017f";
}

.mdi-dice-d12-outline::before {
  content: "\f866";
}

.mdi-dice-d20::before {
  content: "\f0180";
}

.mdi-dice-d20-outline::before {
  content: "\f5ea";
}

.mdi-dice-d4::before {
  content: "\f017b";
}

.mdi-dice-d4-outline::before {
  content: "\f5eb";
}

.mdi-dice-d6::before {
  content: "\f017c";
}

.mdi-dice-d6-outline::before {
  content: "\f5ec";
}

.mdi-dice-d8::before {
  content: "\f017d";
}

.mdi-dice-d8-outline::before {
  content: "\f5ed";
}

.mdi-dice-multiple::before {
  content: "\f76d";
}

.mdi-dice-multiple-outline::before {
  content: "\f0181";
}

.mdi-dictionary::before {
  content: "\f61d";
}

.mdi-digital-ocean::before {
  content: "\f0262";
}

.mdi-dip-switch::before {
  content: "\f7c0";
}

.mdi-directions::before {
  content: "\f1d0";
}

.mdi-directions-fork::before {
  content: "\f641";
}

.mdi-disc::before {
  content: "\f5ee";
}

.mdi-disc-alert::before {
  content: "\f1d1";
}

.mdi-disc-player::before {
  content: "寧";
}

.mdi-discord::before {
  content: "\f66f";
}

.mdi-dishwasher::before {
  content: "磌";
}

.mdi-dishwasher-alert::before {
  content: "\f01e3";
}

.mdi-dishwasher-off::before {
  content: "\f01e4";
}

.mdi-disqus::before {
  content: "\f1d2";
}

.mdi-disqus-outline::before {
  content: "\f1d3";
}

.mdi-distribute-horizontal-center::before {
  content: "\f01f4";
}

.mdi-distribute-horizontal-left::before {
  content: "\f01f3";
}

.mdi-distribute-horizontal-right::before {
  content: "\f01f5";
}

.mdi-distribute-vertical-bottom::before {
  content: "\f01f6";
}

.mdi-distribute-vertical-center::before {
  content: "\f01f7";
}

.mdi-distribute-vertical-top::before {
  content: "\f01f8";
}

.mdi-diving-flippers::before {
  content: "ﶛ";
}

.mdi-diving-helmet::before {
  content: "ﶜ";
}

.mdi-diving-scuba::before {
  content: "ﶝ";
}

.mdi-diving-scuba-flag::before {
  content: "ﶞ";
}

.mdi-diving-scuba-tank::before {
  content: "ﶟ";
}

.mdi-diving-scuba-tank-multiple::before {
  content: "ﶠ";
}

.mdi-diving-snorkel::before {
  content: "ﶡ";
}

.mdi-division::before {
  content: "\f1d4";
}

.mdi-division-box::before {
  content: "\f1d5";
}

.mdi-dlna::before {
  content: "懲";
}

.mdi-dna::before {
  content: "\f683";
}

.mdi-dns::before {
  content: "\f1d6";
}

.mdi-dns-outline::before {
  content: "ﭨ";
}

.mdi-do-not-disturb::before {
  content: "\f697";
}

.mdi-do-not-disturb-off::before {
  content: "\f698";
}

.mdi-dock-bottom::before {
  content: "\f00d4";
}

.mdi-dock-left::before {
  content: "\f00d5";
}

.mdi-dock-right::before {
  content: "\f00d6";
}

.mdi-dock-window::before {
  content: "\f00d7";
}

.mdi-docker::before {
  content: "\f867";
}

.mdi-doctor::before {
  content: "敏";
}

.mdi-dog::before {
  content: "既";
}

.mdi-dog-service::before {
  content: "窱";
}

.mdi-dog-side::before {
  content: "暑";
}

.mdi-dolby::before {
  content: "\f6b2";
}

.mdi-dolly::before {
  content: "ﺻ";
}

.mdi-domain::before {
  content: "\f1d7";
}

.mdi-domain-off::before {
  content: "﵋";
}

.mdi-domain-plus::before {
  content: "\f00d8";
}

.mdi-domain-remove::before {
  content: "\f00d9";
}

.mdi-domino-mask::before {
  content: "\f0045";
}

.mdi-donkey::before {
  content: "\f7c1";
}

.mdi-door::before {
  content: "\f819";
}

.mdi-door-closed::before {
  content: "\f81a";
}

.mdi-door-closed-lock::before {
  content: "\f00da";
}

.mdi-door-open::before {
  content: "\f81b";
}

.mdi-doorbell::before {
  content: "\f0311";
}

.mdi-doorbell-video::before {
  content: "\f868";
}

.mdi-dot-net::before {
  content: "節";
}

.mdi-dots-horizontal::before {
  content: "\f1d8";
}

.mdi-dots-horizontal-circle::before {
  content: "\f7c2";
}

.mdi-dots-horizontal-circle-outline::before {
  content: "ﭩ";
}

.mdi-dots-vertical::before {
  content: "\f1d9";
}

.mdi-dots-vertical-circle::before {
  content: "\f7c3";
}

.mdi-dots-vertical-circle-outline::before {
  content: "ﭪ";
}

.mdi-douban::before {
  content: "\f699";
}

.mdi-download::before {
  content: "\f1da";
}

.mdi-download-lock::before {
  content: "\f034b";
}

.mdi-download-lock-outline::before {
  content: "\f034c";
}

.mdi-download-multiple::before {
  content: "裡";
}

.mdi-download-network::before {
  content: "\f6f3";
}

.mdi-download-network-outline::before {
  content: "ﱂ";
}

.mdi-download-off::before {
  content: "\f00db";
}

.mdi-download-off-outline::before {
  content: "\f00dc";
}

.mdi-download-outline::before {
  content: "ﭫ";
}

.mdi-drag::before {
  content: "\f1db";
}

.mdi-drag-horizontal::before {
  content: "\f1dc";
}

.mdi-drag-horizontal-variant::before {
  content: "\f031b";
}

.mdi-drag-variant::before {
  content: "ﭬ";
}

.mdi-drag-vertical::before {
  content: "\f1dd";
}

.mdi-drag-vertical-variant::before {
  content: "\f031c";
}

.mdi-drama-masks::before {
  content: "ﳞ";
}

.mdi-draw::before {
  content: "ｦ";
}

.mdi-drawing::before {
  content: "\f1de";
}

.mdi-drawing-box::before {
  content: "\f1df";
}

.mdi-dresser::before {
  content: "ｧ";
}

.mdi-dresser-outline::before {
  content: "ｨ";
}

.mdi-dribbble::before {
  content: "\f1e0";
}

.mdi-dribbble-box::before {
  content: "\f1e1";
}

.mdi-drone::before {
  content: "\f1e2";
}

.mdi-dropbox::before {
  content: "\f1e3";
}

.mdi-drupal::before {
  content: "\f1e4";
}

.mdi-duck::before {
  content: "\f1e5";
}

.mdi-dumbbell::before {
  content: "\f1e6";
}

.mdi-dump-truck::before {
  content: "ﱃ";
}

.mdi-ear-hearing::before {
  content: "\f7c4";
}

.mdi-ear-hearing-off::before {
  content: "梅";
}

.mdi-earth::before {
  content: "\f1e7";
}

.mdi-earth-arrow-right::before {
  content: "\f033c";
}

.mdi-earth-box::before {
  content: "\f6cc";
}

.mdi-earth-box-off::before {
  content: "\f6cd";
}

.mdi-earth-off::before {
  content: "\f1e8";
}

.mdi-edge::before {
  content: "\f1e9";
}

.mdi-edge-legacy::before {
  content: "\f027b";
}

.mdi-egg::before {
  content: "类";
}

.mdi-egg-easter::before {
  content: "絛";
}

.mdi-eight-track::before {
  content: "里";
}

.mdi-eject::before {
  content: "\f1ea";
}

.mdi-eject-outline::before {
  content: "ﭭ";
}

.mdi-electric-switch::before {
  content: "ﺼ";
}

.mdi-electric-switch-closed::before {
  content: "\f0104";
}

.mdi-electron-framework::before {
  content: "\f0046";
}

.mdi-elephant::before {
  content: "\f7c5";
}

.mdi-elevation-decline::before {
  content: "\f1eb";
}

.mdi-elevation-rise::before {
  content: "\f1ec";
}

.mdi-elevator::before {
  content: "\f1ed";
}

.mdi-elevator-down::before {
  content: "\f02ed";
}

.mdi-elevator-passenger::before {
  content: "\f03ac";
}

.mdi-elevator-up::before {
  content: "\f02ec";
}

.mdi-ellipse::before {
  content: "ﺽ";
}

.mdi-ellipse-outline::before {
  content: "ﺾ";
}

.mdi-email::before {
  content: "\f1ee";
}

.mdi-email-alert::before {
  content: "\f6ce";
}

.mdi-email-alert-outline::before {
  content: "ﴞ";
}

.mdi-email-box::before {
  content: "ﳟ";
}

.mdi-email-check::before {
  content: "練";
}

.mdi-email-check-outline::before {
  content: "缾";
}

.mdi-email-edit::before {
  content: "＀";
}

.mdi-email-edit-outline::before {
  content: "！";
}

.mdi-email-lock::before {
  content: "\f1f1";
}

.mdi-email-mark-as-unread::before {
  content: "ﭮ";
}

.mdi-email-minus::before {
  content: "＂";
}

.mdi-email-minus-outline::before {
  content: "＃";
}

.mdi-email-multiple::before {
  content: "＄";
}

.mdi-email-multiple-outline::before {
  content: "％";
}

.mdi-email-newsletter::before {
  content: "￑";
}

.mdi-email-open::before {
  content: "\f1ef";
}

.mdi-email-open-multiple::before {
  content: "＆";
}

.mdi-email-open-multiple-outline::before {
  content: "＇";
}

.mdi-email-open-outline::before {
  content: "\f5ef";
}

.mdi-email-outline::before {
  content: "\f1f0";
}

.mdi-email-plus::before {
  content: "離";
}

.mdi-email-plus-outline::before {
  content: "匿";
}

.mdi-email-receive::before {
  content: "\f0105";
}

.mdi-email-receive-outline::before {
  content: "\f0106";
}

.mdi-email-search::before {
  content: "怒";
}

.mdi-email-search-outline::before {
  content: "率";
}

.mdi-email-send::before {
  content: "\f0107";
}

.mdi-email-send-outline::before {
  content: "\f0108";
}

.mdi-email-sync::before {
  content: "\f02f2";
}

.mdi-email-sync-outline::before {
  content: "\f02f3";
}

.mdi-email-variant::before {
  content: "\f5f0";
}

.mdi-ember::before {
  content: "ﬕ";
}

.mdi-emby::before {
  content: "\f6b3";
}

.mdi-emoticon::before {
  content: "ﱄ";
}

.mdi-emoticon-angry::before {
  content: "ﱅ";
}

.mdi-emoticon-angry-outline::before {
  content: "ﱆ";
}

.mdi-emoticon-confused::before {
  content: "\f0109";
}

.mdi-emoticon-confused-outline::before {
  content: "\f010a";
}

.mdi-emoticon-cool::before {
  content: "ﱇ";
}

.mdi-emoticon-cool-outline::before {
  content: "\f1f3";
}

.mdi-emoticon-cry::before {
  content: "ﱈ";
}

.mdi-emoticon-cry-outline::before {
  content: "ﱉ";
}

.mdi-emoticon-dead::before {
  content: "ﱊ";
}

.mdi-emoticon-dead-outline::before {
  content: "\f69a";
}

.mdi-emoticon-devil::before {
  content: "ﱋ";
}

.mdi-emoticon-devil-outline::before {
  content: "\f1f4";
}

.mdi-emoticon-excited::before {
  content: "ﱌ";
}

.mdi-emoticon-excited-outline::before {
  content: "\f69b";
}

.mdi-emoticon-frown::before {
  content: "ｩ";
}

.mdi-emoticon-frown-outline::before {
  content: "ｪ";
}

.mdi-emoticon-happy::before {
  content: "ﱍ";
}

.mdi-emoticon-happy-outline::before {
  content: "\f1f5";
}

.mdi-emoticon-kiss::before {
  content: "ﱎ";
}

.mdi-emoticon-kiss-outline::before {
  content: "ﱏ";
}

.mdi-emoticon-lol::before {
  content: "\f023f";
}

.mdi-emoticon-lol-outline::before {
  content: "\f0240";
}

.mdi-emoticon-neutral::before {
  content: "ﱐ";
}

.mdi-emoticon-neutral-outline::before {
  content: "\f1f6";
}

.mdi-emoticon-outline::before {
  content: "\f1f2";
}

.mdi-emoticon-poop::before {
  content: "\f1f7";
}

.mdi-emoticon-poop-outline::before {
  content: "ﱑ";
}

.mdi-emoticon-sad::before {
  content: "ﱒ";
}

.mdi-emoticon-sad-outline::before {
  content: "\f1f8";
}

.mdi-emoticon-tongue::before {
  content: "\f1f9";
}

.mdi-emoticon-tongue-outline::before {
  content: "ﱓ";
}

.mdi-emoticon-wink::before {
  content: "ﱔ";
}

.mdi-emoticon-wink-outline::before {
  content: "ﱕ";
}

.mdi-engine::before {
  content: "\f1fa";
}

.mdi-engine-off::before {
  content: "海";
}

.mdi-engine-off-outline::before {
  content: "渚";
}

.mdi-engine-outline::before {
  content: "\f1fb";
}

.mdi-epsilon::before {
  content: "\f010b";
}

.mdi-equal::before {
  content: "\f1fc";
}

.mdi-equal-box::before {
  content: "\f1fd";
}

.mdi-equalizer::before {
  content: "ﺿ";
}

.mdi-equalizer-outline::before {
  content: "ﻀ";
}

.mdi-eraser::before {
  content: "\f1fe";
}

.mdi-eraser-variant::before {
  content: "\f642";
}

.mdi-escalator::before {
  content: "\f1ff";
}

.mdi-escalator-down::before {
  content: "\f02eb";
}

.mdi-escalator-up::before {
  content: "\f02ea";
}

.mdi-eslint::before {
  content: "ﱖ";
}

.mdi-et::before {
  content: "者";
}

.mdi-ethereum::before {
  content: "\f869";
}

.mdi-ethernet::before {
  content: "\f200";
}

.mdi-ethernet-cable::before {
  content: "\f201";
}

.mdi-ethernet-cable-off::before {
  content: "\f202";
}

.mdi-etsy::before {
  content: "\f203";
}

.mdi-ev-station::before {
  content: "\f5f1";
}

.mdi-eventbrite::before {
  content: "\f7c6";
}

.mdi-evernote::before {
  content: "\f204";
}

.mdi-excavator::before {
  content: "\f0047";
}

.mdi-exclamation::before {
  content: "\f205";
}

.mdi-exclamation-thick::before {
  content: "\f0263";
}

.mdi-exit-run::before {
  content: "漢";
}

.mdi-exit-to-app::before {
  content: "\f206";
}

.mdi-expand-all::before {
  content: "荒";
}

.mdi-expand-all-outline::before {
  content: "華";
}

.mdi-expansion-card::before {
  content: "\f8ad";
}

.mdi-expansion-card-variant::before {
  content: "ￒ";
}

.mdi-exponent::before {
  content: "異";
}

.mdi-exponent-box::before {
  content: "北";
}

.mdi-export::before {
  content: "\f207";
}

.mdi-export-variant::before {
  content: "ﭯ";
}

.mdi-eye::before {
  content: "\f208";
}

.mdi-eye-check::before {
  content: "ﳠ";
}

.mdi-eye-check-outline::before {
  content: "ﳡ";
}

.mdi-eye-circle::before {
  content: "ﭰ";
}

.mdi-eye-circle-outline::before {
  content: "ﭱ";
}

.mdi-eye-minus::before {
  content: "\f0048";
}

.mdi-eye-minus-outline::before {
  content: "\f0049";
}

.mdi-eye-off::before {
  content: "\f209";
}

.mdi-eye-off-outline::before {
  content: "\f6d0";
}

.mdi-eye-outline::before {
  content: "\f6cf";
}

.mdi-eye-plus::before {
  content: "\f86a";
}

.mdi-eye-plus-outline::before {
  content: "\f86b";
}

.mdi-eye-settings::before {
  content: "\f86c";
}

.mdi-eye-settings-outline::before {
  content: "\f86d";
}

.mdi-eyedropper::before {
  content: "\f20a";
}

.mdi-eyedropper-variant::before {
  content: "\f20b";
}

.mdi-face::before {
  content: "\f643";
}

.mdi-face-agent::before {
  content: "﵌";
}

.mdi-face-outline::before {
  content: "ﭲ";
}

.mdi-face-profile::before {
  content: "\f644";
}

.mdi-face-profile-woman::before {
  content: "\f00a1";
}

.mdi-face-recognition::before {
  content: "ﱗ";
}

.mdi-face-woman::before {
  content: "\f00a2";
}

.mdi-face-woman-outline::before {
  content: "\f00a3";
}

.mdi-facebook::before {
  content: "\f20c";
}

.mdi-facebook-box::before {
  content: "\f20d";
}

.mdi-facebook-messenger::before {
  content: "\f20e";
}

.mdi-facebook-workplace::before {
  content: "ﬖ";
}

.mdi-factory::before {
  content: "\f20f";
}

.mdi-fan::before {
  content: "\f210";
}

.mdi-fan-off::before {
  content: "\f81c";
}

.mdi-fast-forward::before {
  content: "\f211";
}

.mdi-fast-forward-10::before {
  content: "﵍";
}

.mdi-fast-forward-30::before {
  content: "ﳢ";
}

.mdi-fast-forward-5::before {
  content: "\f0223";
}

.mdi-fast-forward-outline::before {
  content: "\f6d1";
}

.mdi-fax::before {
  content: "\f212";
}

.mdi-feather::before {
  content: "\f6d2";
}

.mdi-feature-search::before {
  content: "煮";
}

.mdi-feature-search-outline::before {
  content: "爫";
}

.mdi-fedora::before {
  content: "\f8da";
}

.mdi-ferris-wheel::before {
  content: "ﻁ";
}

.mdi-ferry::before {
  content: "\f213";
}

.mdi-file::before {
  content: "\f214";
}

.mdi-file-account::before {
  content: "\f73a";
}

.mdi-file-account-outline::before {
  content: "\f004a";
}

.mdi-file-alert::before {
  content: "琢";
}

.mdi-file-alert-outline::before {
  content: "碑";
}

.mdi-file-cabinet::before {
  content: "蝹";
}

.mdi-file-cad::before {
  content: "（";
}

.mdi-file-cad-box::before {
  content: "）";
}

.mdi-file-cancel::before {
  content: "ﶢ";
}

.mdi-file-cancel-outline::before {
  content: "ﶣ";
}

.mdi-file-certificate::before {
  content: "\f01b1";
}

.mdi-file-certificate-outline::before {
  content: "\f01b2";
}

.mdi-file-chart::before {
  content: "\f215";
}

.mdi-file-chart-outline::before {
  content: "\f004b";
}

.mdi-file-check::before {
  content: "\f216";
}

.mdi-file-check-outline::before {
  content: "ﹻ";
}

.mdi-file-clock::before {
  content: "\f030c";
}

.mdi-file-clock-outline::before {
  content: "\f030d";
}

.mdi-file-cloud::before {
  content: "\f217";
}

.mdi-file-cloud-outline::before {
  content: "\f004c";
}

.mdi-file-code::before {
  content: "\f22e";
}

.mdi-file-code-outline::before {
  content: "\f004d";
}

.mdi-file-compare::before {
  content: "\f8a9";
}

.mdi-file-delimited::before {
  content: "\f218";
}

.mdi-file-delimited-outline::before {
  content: "ﻂ";
}

.mdi-file-document::before {
  content: "\f219";
}

.mdi-file-document-box::before {
  content: "\f21a";
}

.mdi-file-document-box-check::before {
  content: "ﻃ";
}

.mdi-file-document-box-check-outline::before {
  content: "ﻄ";
}

.mdi-file-document-box-minus::before {
  content: "ﻅ";
}

.mdi-file-document-box-minus-outline::before {
  content: "ﻆ";
}

.mdi-file-document-box-multiple::before {
  content: "襁";
}

.mdi-file-document-box-multiple-outline::before {
  content: "覆";
}

.mdi-file-document-box-outline::before {
  content: "溺";
}

.mdi-file-document-box-plus::before {
  content: "ﻇ";
}

.mdi-file-document-box-plus-outline::before {
  content: "ﻈ";
}

.mdi-file-document-box-remove::before {
  content: "ﻉ";
}

.mdi-file-document-box-remove-outline::before {
  content: "ﻊ";
}

.mdi-file-document-box-search::before {
  content: "ﻋ";
}

.mdi-file-document-box-search-outline::before {
  content: "ﻌ";
}

.mdi-file-document-edit::before {
  content: "ﶤ";
}

.mdi-file-document-edit-outline::before {
  content: "ﶥ";
}

.mdi-file-document-outline::before {
  content: "吝";
}

.mdi-file-download::before {
  content: "磻";
}

.mdi-file-download-outline::before {
  content: "便";
}

.mdi-file-edit::before {
  content: "\f0212";
}

.mdi-file-edit-outline::before {
  content: "\f0213";
}

.mdi-file-excel::before {
  content: "\f21b";
}

.mdi-file-excel-box::before {
  content: "\f21c";
}

.mdi-file-excel-box-outline::before {
  content: "\f004e";
}

.mdi-file-excel-outline::before {
  content: "\f004f";
}

.mdi-file-export::before {
  content: "\f21d";
}

.mdi-file-export-outline::before {
  content: "\f0050";
}

.mdi-file-eye::before {
  content: "ﶦ";
}

.mdi-file-eye-outline::before {
  content: "ﶧ";
}

.mdi-file-find::before {
  content: "\f21e";
}

.mdi-file-find-outline::before {
  content: "ﭳ";
}

.mdi-file-hidden::before {
  content: "\f613";
}

.mdi-file-image::before {
  content: "\f21f";
}

.mdi-file-image-outline::before {
  content: "ﻍ";
}

.mdi-file-import::before {
  content: "\f220";
}

.mdi-file-import-outline::before {
  content: "\f0051";
}

.mdi-file-key::before {
  content: "\f01af";
}

.mdi-file-key-outline::before {
  content: "\f01b0";
}

.mdi-file-link::before {
  content: "\f01a2";
}

.mdi-file-link-outline::before {
  content: "\f01a3";
}

.mdi-file-lock::before {
  content: "\f221";
}

.mdi-file-lock-outline::before {
  content: "\f0052";
}

.mdi-file-move::before {
  content: "視";
}

.mdi-file-move-outline::before {
  content: "\f0053";
}

.mdi-file-multiple::before {
  content: "\f222";
}

.mdi-file-multiple-outline::before {
  content: "\f0054";
}

.mdi-file-music::before {
  content: "\f223";
}

.mdi-file-music-outline::before {
  content: "ﹼ";
}

.mdi-file-outline::before {
  content: "\f224";
}

.mdi-file-pdf::before {
  content: "\f225";
}

.mdi-file-pdf-box::before {
  content: "\f226";
}

.mdi-file-pdf-box-outline::before {
  content: "ￓ";
}

.mdi-file-pdf-outline::before {
  content: "ﹽ";
}

.mdi-file-percent::before {
  content: "\f81d";
}

.mdi-file-percent-outline::before {
  content: "\f0055";
}

.mdi-file-phone::before {
  content: "\f01a4";
}

.mdi-file-phone-outline::before {
  content: "\f01a5";
}

.mdi-file-plus::before {
  content: "\f751";
}

.mdi-file-plus-outline::before {
  content: "＊";
}

.mdi-file-powerpoint::before {
  content: "\f227";
}

.mdi-file-powerpoint-box::before {
  content: "\f228";
}

.mdi-file-powerpoint-box-outline::before {
  content: "\f0056";
}

.mdi-file-powerpoint-outline::before {
  content: "\f0057";
}

.mdi-file-presentation-box::before {
  content: "\f229";
}

.mdi-file-question::before {
  content: "\f86e";
}

.mdi-file-question-outline::before {
  content: "\f0058";
}

.mdi-file-remove::before {
  content: "ﭴ";
}

.mdi-file-remove-outline::before {
  content: "\f0059";
}

.mdi-file-replace::before {
  content: "ﬗ";
}

.mdi-file-replace-outline::before {
  content: "﬘";
}

.mdi-file-restore::before {
  content: "\f670";
}

.mdi-file-restore-outline::before {
  content: "\f005a";
}

.mdi-file-search::before {
  content: "ﱘ";
}

.mdi-file-search-outline::before {
  content: "ﱙ";
}

.mdi-file-send::before {
  content: "\f22a";
}

.mdi-file-send-outline::before {
  content: "\f005b";
}

.mdi-file-settings::before {
  content: "\f00a4";
}

.mdi-file-settings-outline::before {
  content: "\f00a5";
}

.mdi-file-settings-variant::before {
  content: "\f00a6";
}

.mdi-file-settings-variant-outline::before {
  content: "\f00a7";
}

.mdi-file-star::before {
  content: "\f005c";
}

.mdi-file-star-outline::before {
  content: "\f005d";
}

.mdi-file-swap::before {
  content: "ￔ";
}

.mdi-file-swap-outline::before {
  content: "ￕ";
}

.mdi-file-sync::before {
  content: "\f0241";
}

.mdi-file-sync-outline::before {
  content: "\f0242";
}

.mdi-file-table::before {
  content: "ﱚ";
}

.mdi-file-table-box::before {
  content: "\f010c";
}

.mdi-file-table-box-multiple::before {
  content: "\f010d";
}

.mdi-file-table-box-multiple-outline::before {
  content: "\f010e";
}

.mdi-file-table-box-outline::before {
  content: "\f010f";
}

.mdi-file-table-outline::before {
  content: "ﱛ";
}

.mdi-file-tree::before {
  content: "\f645";
}

.mdi-file-undo::before {
  content: "\f8db";
}

.mdi-file-undo-outline::before {
  content: "\f005e";
}

.mdi-file-upload::before {
  content: "社";
}

.mdi-file-upload-outline::before {
  content: "祉";
}

.mdi-file-video::before {
  content: "\f22b";
}

.mdi-file-video-outline::before {
  content: "︐";
}

.mdi-file-word::before {
  content: "\f22c";
}

.mdi-file-word-box::before {
  content: "\f22d";
}

.mdi-file-word-box-outline::before {
  content: "\f005f";
}

.mdi-file-word-outline::before {
  content: "\f0060";
}

.mdi-film::before {
  content: "\f22f";
}

.mdi-filmstrip::before {
  content: "\f230";
}

.mdi-filmstrip-off::before {
  content: "\f231";
}

.mdi-filter::before {
  content: "\f232";
}

.mdi-filter-menu::before {
  content: "\f0110";
}

.mdi-filter-menu-outline::before {
  content: "\f0111";
}

.mdi-filter-minus::before {
  content: "＋";
}

.mdi-filter-minus-outline::before {
  content: "，";
}

.mdi-filter-outline::before {
  content: "\f233";
}

.mdi-filter-plus::before {
  content: "－";
}

.mdi-filter-plus-outline::before {
  content: "．";
}

.mdi-filter-remove::before {
  content: "\f234";
}

.mdi-filter-remove-outline::before {
  content: "\f235";
}

.mdi-filter-variant::before {
  content: "\f236";
}

.mdi-filter-variant-minus::before {
  content: "\f013d";
}

.mdi-filter-variant-plus::before {
  content: "\f013e";
}

.mdi-filter-variant-remove::before {
  content: "\f0061";
}

.mdi-finance::before {
  content: "\f81e";
}

.mdi-find-replace::before {
  content: "\f6d3";
}

.mdi-fingerprint::before {
  content: "\f237";
}

.mdi-fingerprint-off::before {
  content: "ﻎ";
}

.mdi-fire::before {
  content: "\f238";
}

.mdi-fire-extinguisher::before {
  content: "／";
}

.mdi-fire-hydrant::before {
  content: "\f0162";
}

.mdi-fire-hydrant-alert::before {
  content: "\f0163";
}

.mdi-fire-hydrant-off::before {
  content: "\f0164";
}

.mdi-fire-truck::before {
  content: "\f8aa";
}

.mdi-firebase::before {
  content: "復";
}

.mdi-firefox::before {
  content: "\f239";
}

.mdi-fireplace::before {
  content: "︑";
}

.mdi-fireplace-off::before {
  content: "︒";
}

.mdi-firework::before {
  content: "︓";
}

.mdi-fish::before {
  content: "\f23a";
}

.mdi-fishbowl::before {
  content: "０";
}

.mdi-fishbowl-outline::before {
  content: "１";
}

.mdi-fit-to-page::before {
  content: "２";
}

.mdi-fit-to-page-outline::before {
  content: "３";
}

.mdi-flag::before {
  content: "\f23b";
}

.mdi-flag-checkered::before {
  content: "\f23c";
}

.mdi-flag-minus::before {
  content: "ﭵ";
}

.mdi-flag-minus-outline::before {
  content: "\f00dd";
}

.mdi-flag-outline::before {
  content: "\f23d";
}

.mdi-flag-plus::before {
  content: "ﭶ";
}

.mdi-flag-plus-outline::before {
  content: "\f00de";
}

.mdi-flag-remove::before {
  content: "ﭷ";
}

.mdi-flag-remove-outline::before {
  content: "\f00df";
}

.mdi-flag-triangle::before {
  content: "\f23f";
}

.mdi-flag-variant::before {
  content: "\f240";
}

.mdi-flag-variant-outline::before {
  content: "\f23e";
}

.mdi-flare::before {
  content: "﵎";
}

.mdi-flash::before {
  content: "\f241";
}

.mdi-flash-alert::before {
  content: "４";
}

.mdi-flash-alert-outline::before {
  content: "５";
}

.mdi-flash-auto::before {
  content: "\f242";
}

.mdi-flash-circle::before {
  content: "\f81f";
}

.mdi-flash-off::before {
  content: "\f243";
}

.mdi-flash-outline::before {
  content: "\f6d4";
}

.mdi-flash-red-eye::before {
  content: "\f67a";
}

.mdi-flashlight::before {
  content: "\f244";
}

.mdi-flashlight-off::before {
  content: "\f245";
}

.mdi-flask::before {
  content: "\f093";
}

.mdi-flask-empty::before {
  content: "\f094";
}

.mdi-flask-empty-minus::before {
  content: "\f0265";
}

.mdi-flask-empty-minus-outline::before {
  content: "\f0266";
}

.mdi-flask-empty-outline::before {
  content: "\f095";
}

.mdi-flask-empty-plus::before {
  content: "\f0267";
}

.mdi-flask-empty-plus-outline::before {
  content: "\f0268";
}

.mdi-flask-empty-remove::before {
  content: "\f0269";
}

.mdi-flask-empty-remove-outline::before {
  content: "\f026a";
}

.mdi-flask-minus::before {
  content: "\f026b";
}

.mdi-flask-minus-outline::before {
  content: "\f026c";
}

.mdi-flask-outline::before {
  content: "\f096";
}

.mdi-flask-plus::before {
  content: "\f026d";
}

.mdi-flask-plus-outline::before {
  content: "\f026e";
}

.mdi-flask-remove::before {
  content: "\f026f";
}

.mdi-flask-remove-outline::before {
  content: "\f0270";
}

.mdi-flask-round-bottom::before {
  content: "\f0276";
}

.mdi-flask-round-bottom-empty::before {
  content: "\f0277";
}

.mdi-flask-round-bottom-empty-outline::before {
  content: "\f0278";
}

.mdi-flask-round-bottom-outline::before {
  content: "\f0279";
}

.mdi-flattr::before {
  content: "\f246";
}

.mdi-fleur-de-lis::before {
  content: "\f032e";
}

.mdi-flickr::before {
  content: "ﳣ";
}

.mdi-flip-horizontal::before {
  content: "\f0112";
}

.mdi-flip-to-back::before {
  content: "\f247";
}

.mdi-flip-to-front::before {
  content: "\f248";
}

.mdi-flip-vertical::before {
  content: "\f0113";
}

.mdi-floor-lamp::before {
  content: "\f8dc";
}

.mdi-floor-lamp-dual::before {
  content: "\f0062";
}

.mdi-floor-lamp-variant::before {
  content: "\f0063";
}

.mdi-floor-plan::before {
  content: "\f820";
}

.mdi-floppy::before {
  content: "\f249";
}

.mdi-floppy-variant::before {
  content: "燐";
}

.mdi-flower::before {
  content: "\f24a";
}

.mdi-flower-outline::before {
  content: "璘";
}

.mdi-flower-poppy::before {
  content: "ﳤ";
}

.mdi-flower-tulip::before {
  content: "藺";
}

.mdi-flower-tulip-outline::before {
  content: "隣";
}

.mdi-focus-auto::before {
  content: "ｫ";
}

.mdi-focus-field::before {
  content: "ｬ";
}

.mdi-focus-field-horizontal::before {
  content: "ｭ";
}

.mdi-focus-field-vertical::before {
  content: "ｮ";
}

.mdi-folder::before {
  content: "\f24b";
}

.mdi-folder-account::before {
  content: "\f24c";
}

.mdi-folder-account-outline::before {
  content: "ﭸ";
}

.mdi-folder-alert::before {
  content: "ﶨ";
}

.mdi-folder-alert-outline::before {
  content: "ﶩ";
}

.mdi-folder-clock::before {
  content: "調";
}

.mdi-folder-clock-outline::before {
  content: "諸";
}

.mdi-folder-download::before {
  content: "\f24d";
}

.mdi-folder-download-outline::before {
  content: "\f0114";
}

.mdi-folder-edit::before {
  content: "\f8dd";
}

.mdi-folder-edit-outline::before {
  content: "ﶪ";
}

.mdi-folder-google-drive::before {
  content: "\f24e";
}

.mdi-folder-heart::before {
  content: "\f0115";
}

.mdi-folder-heart-outline::before {
  content: "\f0116";
}

.mdi-folder-home::before {
  content: "\f00e0";
}

.mdi-folder-home-outline::before {
  content: "\f00e1";
}

.mdi-folder-image::before {
  content: "\f24f";
}

.mdi-folder-information::before {
  content: "\f00e2";
}

.mdi-folder-information-outline::before {
  content: "\f00e3";
}

.mdi-folder-key::before {
  content: "\f8ab";
}

.mdi-folder-key-network::before {
  content: "\f8ac";
}

.mdi-folder-key-network-outline::before {
  content: "ﱜ";
}

.mdi-folder-key-outline::before {
  content: "\f0117";
}

.mdi-folder-lock::before {
  content: "\f250";
}

.mdi-folder-lock-open::before {
  content: "\f251";
}

.mdi-folder-marker::before {
  content: "\f0298";
}

.mdi-folder-marker-outline::before {
  content: "\f0299";
}

.mdi-folder-move::before {
  content: "\f252";
}

.mdi-folder-move-outline::before {
  content: "\f0271";
}

.mdi-folder-multiple::before {
  content: "\f253";
}

.mdi-folder-multiple-image::before {
  content: "\f254";
}

.mdi-folder-multiple-outline::before {
  content: "\f255";
}

.mdi-folder-music::before {
  content: "\f0384";
}

.mdi-folder-music-outline::before {
  content: "\f0385";
}

.mdi-folder-network::before {
  content: "\f86f";
}

.mdi-folder-network-outline::before {
  content: "ﱝ";
}

.mdi-folder-open::before {
  content: "\f76f";
}

.mdi-folder-open-outline::before {
  content: "ﶫ";
}

.mdi-folder-outline::before {
  content: "\f256";
}

.mdi-folder-plus::before {
  content: "\f257";
}

.mdi-folder-plus-outline::before {
  content: "ﭹ";
}

.mdi-folder-pound::before {
  content: "ﳥ";
}

.mdi-folder-pound-outline::before {
  content: "ﳦ";
}

.mdi-folder-remove::before {
  content: "\f258";
}

.mdi-folder-remove-outline::before {
  content: "ﭺ";
}

.mdi-folder-search::before {
  content: "不";
}

.mdi-folder-search-outline::before {
  content: "泌";
}

.mdi-folder-settings::before {
  content: "\f00a8";
}

.mdi-folder-settings-outline::before {
  content: "\f00a9";
}

.mdi-folder-settings-variant::before {
  content: "\f00aa";
}

.mdi-folder-settings-variant-outline::before {
  content: "\f00ab";
}

.mdi-folder-star::before {
  content: "\f69c";
}

.mdi-folder-star-outline::before {
  content: "ﭻ";
}

.mdi-folder-swap::before {
  content: "ￖ";
}

.mdi-folder-swap-outline::before {
  content: "ￗ";
}

.mdi-folder-sync::before {
  content: "ﳧ";
}

.mdi-folder-sync-outline::before {
  content: "ﳨ";
}

.mdi-folder-table::before {
  content: "\f030e";
}

.mdi-folder-table-outline::before {
  content: "\f030f";
}

.mdi-folder-text::before {
  content: "ﱞ";
}

.mdi-folder-text-outline::before {
  content: "ﱟ";
}

.mdi-folder-upload::before {
  content: "\f259";
}

.mdi-folder-upload-outline::before {
  content: "\f0118";
}

.mdi-folder-zip::before {
  content: "\f6ea";
}

.mdi-folder-zip-outline::before {
  content: "\f7b8";
}

.mdi-font-awesome::before {
  content: "\f03a";
}

.mdi-food::before {
  content: "\f25a";
}

.mdi-food-apple::before {
  content: "\f25b";
}

.mdi-food-apple-outline::before {
  content: "ﱠ";
}

.mdi-food-croissant::before {
  content: "\f7c7";
}

.mdi-food-fork-drink::before {
  content: "\f5f2";
}

.mdi-food-off::before {
  content: "\f5f3";
}

.mdi-food-variant::before {
  content: "\f25c";
}

.mdi-foot-print::before {
  content: "ｯ";
}

.mdi-football::before {
  content: "\f25d";
}

.mdi-football-australian::before {
  content: "\f25e";
}

.mdi-football-helmet::before {
  content: "\f25f";
}

.mdi-forklift::before {
  content: "\f7c8";
}

.mdi-format-align-bottom::before {
  content: "\f752";
}

.mdi-format-align-center::before {
  content: "\f260";
}

.mdi-format-align-justify::before {
  content: "\f261";
}

.mdi-format-align-left::before {
  content: "\f262";
}

.mdi-format-align-middle::before {
  content: "\f753";
}

.mdi-format-align-right::before {
  content: "\f263";
}

.mdi-format-align-top::before {
  content: "\f754";
}

.mdi-format-annotation-minus::before {
  content: "請";
}

.mdi-format-annotation-plus::before {
  content: "\f646";
}

.mdi-format-bold::before {
  content: "\f264";
}

.mdi-format-clear::before {
  content: "\f265";
}

.mdi-format-color-fill::before {
  content: "\f266";
}

.mdi-format-color-highlight::before {
  content: "︔";
}

.mdi-format-color-marker-cancel::before {
  content: "\f033e";
}

.mdi-format-color-text::before {
  content: "\f69d";
}

.mdi-format-columns::before {
  content: "\f8de";
}

.mdi-format-float-center::before {
  content: "\f267";
}

.mdi-format-float-left::before {
  content: "\f268";
}

.mdi-format-float-none::before {
  content: "\f269";
}

.mdi-format-float-right::before {
  content: "\f26a";
}

.mdi-format-font::before {
  content: "\f6d5";
}

.mdi-format-font-size-decrease::before {
  content: "鱗";
}

.mdi-format-font-size-increase::before {
  content: "麟";
}

.mdi-format-header-1::before {
  content: "\f26b";
}

.mdi-format-header-2::before {
  content: "\f26c";
}

.mdi-format-header-3::before {
  content: "\f26d";
}

.mdi-format-header-4::before {
  content: "\f26e";
}

.mdi-format-header-5::before {
  content: "\f26f";
}

.mdi-format-header-6::before {
  content: "\f270";
}

.mdi-format-header-decrease::before {
  content: "\f271";
}

.mdi-format-header-equal::before {
  content: "\f272";
}

.mdi-format-header-increase::before {
  content: "\f273";
}

.mdi-format-header-pound::before {
  content: "\f274";
}

.mdi-format-horizontal-align-center::before {
  content: "\f61e";
}

.mdi-format-horizontal-align-left::before {
  content: "\f61f";
}

.mdi-format-horizontal-align-right::before {
  content: "\f620";
}

.mdi-format-indent-decrease::before {
  content: "\f275";
}

.mdi-format-indent-increase::before {
  content: "\f276";
}

.mdi-format-italic::before {
  content: "\f277";
}

.mdi-format-letter-case::before {
  content: "﬙";
}

.mdi-format-letter-case-lower::before {
  content: "﬚";
}

.mdi-format-letter-case-upper::before {
  content: "﬛";
}

.mdi-format-letter-ends-with::before {
  content: "￘";
}

.mdi-format-letter-matches::before {
  content: "￙";
}

.mdi-format-letter-starts-with::before {
  content: "ￚ";
}

.mdi-format-line-spacing::before {
  content: "\f278";
}

.mdi-format-line-style::before {
  content: "\f5c8";
}

.mdi-format-line-weight::before {
  content: "\f5c9";
}

.mdi-format-list-bulleted::before {
  content: "\f279";
}

.mdi-format-list-bulleted-square::before {
  content: "ﶬ";
}

.mdi-format-list-bulleted-triangle::before {
  content: "ﻏ";
}

.mdi-format-list-bulleted-type::before {
  content: "\f27a";
}

.mdi-format-list-checkbox::before {
  content: "數";
}

.mdi-format-list-checks::before {
  content: "\f755";
}

.mdi-format-list-numbered::before {
  content: "\f27b";
}

.mdi-format-list-numbered-rtl::before {
  content: "ﳩ";
}

.mdi-format-list-text::before {
  content: "\f029a";
}

.mdi-format-overline::before {
  content: "ﻐ";
}

.mdi-format-page-break::before {
  content: "\f6d6";
}

.mdi-format-paint::before {
  content: "\f27c";
}

.mdi-format-paragraph::before {
  content: "\f27d";
}

.mdi-format-pilcrow::before {
  content: "\f6d7";
}

.mdi-format-quote-close::before {
  content: "\f27e";
}

.mdi-format-quote-close-outline::before {
  content: "\f01d3";
}

.mdi-format-quote-open::before {
  content: "\f756";
}

.mdi-format-quote-open-outline::before {
  content: "\f01d2";
}

.mdi-format-rotate-90::before {
  content: "\f6a9";
}

.mdi-format-section::before {
  content: "\f69e";
}

.mdi-format-size::before {
  content: "\f27f";
}

.mdi-format-strikethrough::before {
  content: "\f280";
}

.mdi-format-strikethrough-variant::before {
  content: "\f281";
}

.mdi-format-subscript::before {
  content: "\f282";
}

.mdi-format-superscript::before {
  content: "\f283";
}

.mdi-format-text::before {
  content: "\f284";
}

.mdi-format-text-rotation-angle-down::before {
  content: "ￛ";
}

.mdi-format-text-rotation-angle-up::before {
  content: "ￜ";
}

.mdi-format-text-rotation-down::before {
  content: "﵏";
}

.mdi-format-text-rotation-down-vertical::before {
  content: "￝";
}

.mdi-format-text-rotation-none::before {
  content: "ﵐ";
}

.mdi-format-text-rotation-up::before {
  content: "￞";
}

.mdi-format-text-rotation-vertical::before {
  content: "￟";
}

.mdi-format-text-variant::before {
  content: "︕";
}

.mdi-format-text-wrapping-clip::before {
  content: "ﳪ";
}

.mdi-format-text-wrapping-overflow::before {
  content: "ﳫ";
}

.mdi-format-text-wrapping-wrap::before {
  content: "ﳬ";
}

.mdi-format-textbox::before {
  content: "ﳭ";
}

.mdi-format-textdirection-l-to-r::before {
  content: "\f285";
}

.mdi-format-textdirection-r-to-l::before {
  content: "\f286";
}

.mdi-format-title::before {
  content: "\f5f4";
}

.mdi-format-underline::before {
  content: "\f287";
}

.mdi-format-vertical-align-bottom::before {
  content: "\f621";
}

.mdi-format-vertical-align-center::before {
  content: "\f622";
}

.mdi-format-vertical-align-top::before {
  content: "\f623";
}

.mdi-format-wrap-inline::before {
  content: "\f288";
}

.mdi-format-wrap-square::before {
  content: "\f289";
}

.mdi-format-wrap-tight::before {
  content: "\f28a";
}

.mdi-format-wrap-top-bottom::before {
  content: "\f28b";
}

.mdi-forum::before {
  content: "\f28c";
}

.mdi-forum-outline::before {
  content: "\f821";
}

.mdi-forward::before {
  content: "\f28d";
}

.mdi-forwardburger::before {
  content: "ﵑ";
}

.mdi-fountain::before {
  content: "索";
}

.mdi-fountain-pen::before {
  content: "ﳮ";
}

.mdi-fountain-pen-tip::before {
  content: "ﳯ";
}

.mdi-foursquare::before {
  content: "\f28e";
}

.mdi-freebsd::before {
  content: "\f8df";
}

.mdi-frequently-asked-questions::before {
  content: "ﻑ";
}

.mdi-fridge::before {
  content: "\f290";
}

.mdi-fridge-alert::before {
  content: "\f01dc";
}

.mdi-fridge-alert-outline::before {
  content: "\f01dd";
}

.mdi-fridge-bottom::before {
  content: "\f292";
}

.mdi-fridge-off::before {
  content: "\f01da";
}

.mdi-fridge-off-outline::before {
  content: "\f01db";
}

.mdi-fridge-outline::before {
  content: "\f28f";
}

.mdi-fridge-top::before {
  content: "\f291";
}

.mdi-fruit-cherries::before {
  content: "\f0064";
}

.mdi-fruit-citrus::before {
  content: "\f0065";
}

.mdi-fruit-grapes::before {
  content: "\f0066";
}

.mdi-fruit-grapes-outline::before {
  content: "\f0067";
}

.mdi-fruit-pineapple::before {
  content: "\f0068";
}

.mdi-fruit-watermelon::before {
  content: "\f0069";
}

.mdi-fuel::before {
  content: "\f7c9";
}

.mdi-fullscreen::before {
  content: "\f293";
}

.mdi-fullscreen-exit::before {
  content: "\f294";
}

.mdi-function::before {
  content: "\f295";
}

.mdi-function-variant::before {
  content: "\f870";
}

.mdi-furigana-horizontal::before {
  content: "\f00ac";
}

.mdi-furigana-vertical::before {
  content: "\f00ad";
}

.mdi-fuse::before {
  content: "ﱡ";
}

.mdi-fuse-blade::before {
  content: "ﱢ";
}

.mdi-gamepad::before {
  content: "\f296";
}

.mdi-gamepad-circle::before {
  content: "︖";
}

.mdi-gamepad-circle-down::before {
  content: "︗";
}

.mdi-gamepad-circle-left::before {
  content: "︘";
}

.mdi-gamepad-circle-outline::before {
  content: "︙";
}

.mdi-gamepad-circle-right::before {
  content: "︚";
}

.mdi-gamepad-circle-up::before {
  content: "︛";
}

.mdi-gamepad-down::before {
  content: "︜";
}

.mdi-gamepad-left::before {
  content: "︝";
}

.mdi-gamepad-right::before {
  content: "︞";
}

.mdi-gamepad-round::before {
  content: "︟";
}

.mdi-gamepad-round-down::before {
  content: "ﹾ";
}

.mdi-gamepad-round-left::before {
  content: "ﹿ";
}

.mdi-gamepad-round-outline::before {
  content: "ﺀ";
}

.mdi-gamepad-round-right::before {
  content: "ﺁ";
}

.mdi-gamepad-round-up::before {
  content: "ﺂ";
}

.mdi-gamepad-square::before {
  content: "ﻒ";
}

.mdi-gamepad-square-outline::before {
  content: "ﻓ";
}

.mdi-gamepad-up::before {
  content: "ﺃ";
}

.mdi-gamepad-variant::before {
  content: "\f297";
}

.mdi-gamepad-variant-outline::before {
  content: "ﻔ";
}

.mdi-gamma::before {
  content: "\f0119";
}

.mdi-gantry-crane::before {
  content: "ﶭ";
}

.mdi-garage::before {
  content: "\f6d8";
}

.mdi-garage-alert::before {
  content: "\f871";
}

.mdi-garage-alert-variant::before {
  content: "\f0300";
}

.mdi-garage-open::before {
  content: "\f6d9";
}

.mdi-garage-open-variant::before {
  content: "\f02ff";
}

.mdi-garage-variant::before {
  content: "\f02fe";
}

.mdi-gas-cylinder::before {
  content: "\f647";
}

.mdi-gas-station::before {
  content: "\f298";
}

.mdi-gas-station-outline::before {
  content: "ﻕ";
}

.mdi-gate::before {
  content: "\f299";
}

.mdi-gate-and::before {
  content: "\f8e0";
}

.mdi-gate-arrow-right::before {
  content: "\f0194";
}

.mdi-gate-nand::before {
  content: "\f8e1";
}

.mdi-gate-nor::before {
  content: "\f8e2";
}

.mdi-gate-not::before {
  content: "\f8e3";
}

.mdi-gate-open::before {
  content: "\f0195";
}

.mdi-gate-or::before {
  content: "\f8e4";
}

.mdi-gate-xnor::before {
  content: "\f8e5";
}

.mdi-gate-xor::before {
  content: "\f8e6";
}

.mdi-gatsby::before {
  content: "ﺄ";
}

.mdi-gauge::before {
  content: "\f29a";
}

.mdi-gauge-empty::before {
  content: "\f872";
}

.mdi-gauge-full::before {
  content: "\f873";
}

.mdi-gauge-low::before {
  content: "\f874";
}

.mdi-gavel::before {
  content: "\f29b";
}

.mdi-gender-female::before {
  content: "\f29c";
}

.mdi-gender-male::before {
  content: "\f29d";
}

.mdi-gender-male-female::before {
  content: "\f29e";
}

.mdi-gender-male-female-variant::before {
  content: "\f016a";
}

.mdi-gender-non-binary::before {
  content: "\f016b";
}

.mdi-gender-transgender::before {
  content: "\f29f";
}

.mdi-gentoo::before {
  content: "\f8e7";
}

.mdi-gesture::before {
  content: "\f7ca";
}

.mdi-gesture-double-tap::before {
  content: "\f73b";
}

.mdi-gesture-pinch::before {
  content: "謁";
}

.mdi-gesture-spread::before {
  content: "諾";
}

.mdi-gesture-swipe::before {
  content: "ﵒ";
}

.mdi-gesture-swipe-down::before {
  content: "\f73c";
}

.mdi-gesture-swipe-horizontal::before {
  content: "諭";
}

.mdi-gesture-swipe-left::before {
  content: "\f73d";
}

.mdi-gesture-swipe-right::before {
  content: "\f73e";
}

.mdi-gesture-swipe-up::before {
  content: "\f73f";
}

.mdi-gesture-swipe-vertical::before {
  content: "謹";
}

.mdi-gesture-tap::before {
  content: "\f740";
}

.mdi-gesture-tap-box::before {
  content: "\f02d4";
}

.mdi-gesture-tap-button::before {
  content: "\f02d3";
}

.mdi-gesture-tap-hold::before {
  content: "ﵓ";
}

.mdi-gesture-two-double-tap::before {
  content: "\f741";
}

.mdi-gesture-two-tap::before {
  content: "\f742";
}

.mdi-ghost::before {
  content: "\f2a0";
}

.mdi-ghost-off::before {
  content: "林";
}

.mdi-gif::before {
  content: "ﵔ";
}

.mdi-gift::before {
  content: "ﺅ";
}

.mdi-gift-outline::before {
  content: "\f2a1";
}

.mdi-git::before {
  content: "\f2a2";
}

.mdi-github-box::before {
  content: "\f2a3";
}

.mdi-github-circle::before {
  content: "\f2a4";
}

.mdi-github-face::before {
  content: "\f6da";
}

.mdi-gitlab::before {
  content: "ﭼ";
}

.mdi-glass-cocktail::before {
  content: "\f356";
}

.mdi-glass-flute::before {
  content: "\f2a5";
}

.mdi-glass-mug::before {
  content: "\f2a6";
}

.mdi-glass-mug-variant::before {
  content: "\f0141";
}

.mdi-glass-pint-outline::before {
  content: "\f0338";
}

.mdi-glass-stange::before {
  content: "\f2a7";
}

.mdi-glass-tulip::before {
  content: "\f2a8";
}

.mdi-glass-wine::before {
  content: "\f875";
}

.mdi-glassdoor::before {
  content: "\f2a9";
}

.mdi-glasses::before {
  content: "\f2aa";
}

.mdi-globe-light::before {
  content: "\f0302";
}

.mdi-globe-model::before {
  content: "\f8e8";
}

.mdi-gmail::before {
  content: "\f2ab";
}

.mdi-gnome::before {
  content: "\f2ac";
}

.mdi-go-kart::before {
  content: "ﵕ";
}

.mdi-go-kart-track::before {
  content: "ﵖ";
}

.mdi-gog::before {
  content: "ﭽ";
}

.mdi-gold::before {
  content: "\f027a";
}

.mdi-golf::before {
  content: "\f822";
}

.mdi-golf-cart::before {
  content: "\f01cf";
}

.mdi-golf-tee::before {
  content: "\f00ae";
}

.mdi-gondola::before {
  content: "\f685";
}

.mdi-goodreads::before {
  content: "ﵗ";
}

.mdi-google::before {
  content: "\f2ad";
}

.mdi-google-adwords::before {
  content: "ﱣ";
}

.mdi-google-analytics::before {
  content: "\f7cb";
}

.mdi-google-assistant::before {
  content: "\f7cc";
}

.mdi-google-cardboard::before {
  content: "\f2ae";
}

.mdi-google-chrome::before {
  content: "\f2af";
}

.mdi-google-circles::before {
  content: "\f2b0";
}

.mdi-google-circles-communities::before {
  content: "\f2b1";
}

.mdi-google-circles-extended::before {
  content: "\f2b2";
}

.mdi-google-circles-group::before {
  content: "\f2b3";
}

.mdi-google-classroom::before {
  content: "\f2c0";
}

.mdi-google-cloud::before {
  content: "\f0221";
}

.mdi-google-controller::before {
  content: "\f2b4";
}

.mdi-google-controller-off::before {
  content: "\f2b5";
}

.mdi-google-downasaur::before {
  content: "\f038d";
}

.mdi-google-drive::before {
  content: "\f2b6";
}

.mdi-google-earth::before {
  content: "\f2b7";
}

.mdi-google-fit::before {
  content: "參";
}

.mdi-google-glass::before {
  content: "\f2b8";
}

.mdi-google-hangouts::before {
  content: "\f2c9";
}

.mdi-google-home::before {
  content: "\f823";
}

.mdi-google-keep::before {
  content: "\f6db";
}

.mdi-google-lens::before {
  content: "淋";
}

.mdi-google-maps::before {
  content: "\f5f5";
}

.mdi-google-my-business::before {
  content: "\f006a";
}

.mdi-google-nearby::before {
  content: "\f2b9";
}

.mdi-google-pages::before {
  content: "\f2ba";
}

.mdi-google-photos::before {
  content: "\f6dc";
}

.mdi-google-physical-web::before {
  content: "\f2bb";
}

.mdi-google-play::before {
  content: "\f2bc";
}

.mdi-google-plus::before {
  content: "\f2bd";
}

.mdi-google-plus-box::before {
  content: "\f2be";
}

.mdi-google-podcast::before {
  content: "ﻖ";
}

.mdi-google-spreadsheet::before {
  content: "臨";
}

.mdi-google-street-view::before {
  content: "ﱤ";
}

.mdi-google-translate::before {
  content: "\f2bf";
}

.mdi-gradient::before {
  content: "\f69f";
}

.mdi-grain::before {
  content: "ﵘ";
}

.mdi-graph::before {
  content: "\f006b";
}

.mdi-graph-outline::before {
  content: "\f006c";
}

.mdi-graphql::before {
  content: "\f876";
}

.mdi-grave-stone::before {
  content: "ﭾ";
}

.mdi-grease-pencil::before {
  content: "\f648";
}

.mdi-greater-than::before {
  content: "塞";
}

.mdi-greater-than-or-equal::before {
  content: "省";
}

.mdi-grid::before {
  content: "\f2c1";
}

.mdi-grid-large::before {
  content: "\f757";
}

.mdi-grid-off::before {
  content: "\f2c2";
}

.mdi-grill::before {
  content: "ﺆ";
}

.mdi-grill-outline::before {
  content: "\f01b5";
}

.mdi-group::before {
  content: "\f2c3";
}

.mdi-guitar-acoustic::before {
  content: "\f770";
}

.mdi-guitar-electric::before {
  content: "\f2c4";
}

.mdi-guitar-pick::before {
  content: "\f2c5";
}

.mdi-guitar-pick-outline::before {
  content: "\f2c6";
}

.mdi-guy-fawkes-mask::before {
  content: "\f824";
}

.mdi-hackernews::before {
  content: "\f624";
}

.mdi-hail::before {
  content: "變";
}

.mdi-hair-dryer::before {
  content: "\f011a";
}

.mdi-hair-dryer-outline::before {
  content: "\f011b";
}

.mdi-halloween::before {
  content: "ﭿ";
}

.mdi-hamburger::before {
  content: "\f684";
}

.mdi-hammer::before {
  content: "\f8e9";
}

.mdi-hammer-screwdriver::before {
  content: "\f034d";
}

.mdi-hammer-wrench::before {
  content: "\f034e";
}

.mdi-hand::before {
  content: "祈";
}

.mdi-hand-heart::before {
  content: "\f011c";
}

.mdi-hand-left::before {
  content: "ﺇ";
}

.mdi-hand-okay::before {
  content: "祐";
}

.mdi-hand-peace::before {
  content: "祖";
}

.mdi-hand-peace-variant::before {
  content: "祝";
}

.mdi-hand-pointing-down::before {
  content: "禍";
}

.mdi-hand-pointing-left::before {
  content: "禎";
}

.mdi-hand-pointing-right::before {
  content: "\f2c7";
}

.mdi-hand-pointing-up::before {
  content: "穀";
}

.mdi-hand-right::before {
  content: "ﺈ";
}

.mdi-hand-saw::before {
  content: "ﺉ";
}

.mdi-handball::before {
  content: "ｰ";
}

.mdi-handcuffs::before {
  content: "\f0169";
}

.mdi-handshake::before {
  content: "\f0243";
}

.mdi-hanger::before {
  content: "\f2c8";
}

.mdi-hard-hat::before {
  content: "葉";
}

.mdi-harddisk::before {
  content: "\f2ca";
}

.mdi-harddisk-plus::before {
  content: "\f006d";
}

.mdi-harddisk-remove::before {
  content: "\f006e";
}

.mdi-hat-fedora::before {
  content: "ﮀ";
}

.mdi-hazard-lights::before {
  content: "ﱥ";
}

.mdi-hdr::before {
  content: "ﵙ";
}

.mdi-hdr-off::before {
  content: "ﵚ";
}

.mdi-head::before {
  content: "\f0389";
}

.mdi-head-alert::before {
  content: "\f0363";
}

.mdi-head-alert-outline::before {
  content: "\f0364";
}

.mdi-head-check::before {
  content: "\f0365";
}

.mdi-head-check-outline::before {
  content: "\f0366";
}

.mdi-head-cog::before {
  content: "\f0367";
}

.mdi-head-cog-outline::before {
  content: "\f0368";
}

.mdi-head-dots-horizontal::before {
  content: "\f0369";
}

.mdi-head-dots-horizontal-outline::before {
  content: "\f036a";
}

.mdi-head-flash::before {
  content: "\f036b";
}

.mdi-head-flash-outline::before {
  content: "\f036c";
}

.mdi-head-heart::before {
  content: "\f036d";
}

.mdi-head-heart-outline::before {
  content: "\f036e";
}

.mdi-head-lightbulb::before {
  content: "\f036f";
}

.mdi-head-lightbulb-outline::before {
  content: "\f0370";
}

.mdi-head-minus::before {
  content: "\f0371";
}

.mdi-head-minus-outline::before {
  content: "\f0372";
}

.mdi-head-outline::before {
  content: "\f038a";
}

.mdi-head-plus::before {
  content: "\f0373";
}

.mdi-head-plus-outline::before {
  content: "\f0374";
}

.mdi-head-question::before {
  content: "\f0375";
}

.mdi-head-question-outline::before {
  content: "\f0376";
}

.mdi-head-remove::before {
  content: "\f0377";
}

.mdi-head-remove-outline::before {
  content: "\f0378";
}

.mdi-head-snowflake::before {
  content: "\f0379";
}

.mdi-head-snowflake-outline::before {
  content: "\f037a";
}

.mdi-head-sync::before {
  content: "\f037b";
}

.mdi-head-sync-outline::before {
  content: "\f037c";
}

.mdi-headphones::before {
  content: "\f2cb";
}

.mdi-headphones-bluetooth::before {
  content: "說";
}

.mdi-headphones-box::before {
  content: "\f2cc";
}

.mdi-headphones-off::before {
  content: "\f7cd";
}

.mdi-headphones-settings::before {
  content: "\f2cd";
}

.mdi-headset::before {
  content: "\f2ce";
}

.mdi-headset-dock::before {
  content: "\f2cf";
}

.mdi-headset-off::before {
  content: "\f2d0";
}

.mdi-heart::before {
  content: "\f2d1";
}

.mdi-heart-box::before {
  content: "\f2d2";
}

.mdi-heart-box-outline::before {
  content: "\f2d3";
}

.mdi-heart-broken::before {
  content: "\f2d4";
}

.mdi-heart-broken-outline::before {
  content: "ﳰ";
}

.mdi-heart-circle::before {
  content: "殺";
}

.mdi-heart-circle-outline::before {
  content: "辰";
}

.mdi-heart-flash::before {
  content: "６";
}

.mdi-heart-half::before {
  content: "\f6de";
}

.mdi-heart-half-full::before {
  content: "\f6dd";
}

.mdi-heart-half-outline::before {
  content: "\f6df";
}

.mdi-heart-multiple::before {
  content: "突";
}

.mdi-heart-multiple-outline::before {
  content: "節";
}

.mdi-heart-off::before {
  content: "\f758";
}

.mdi-heart-outline::before {
  content: "\f2d5";
}

.mdi-heart-pulse::before {
  content: "\f5f6";
}

.mdi-helicopter::before {
  content: "贈";
}

.mdi-help::before {
  content: "\f2d6";
}

.mdi-help-box::before {
  content: "\f78a";
}

.mdi-help-circle::before {
  content: "\f2d7";
}

.mdi-help-circle-outline::before {
  content: "\f625";
}

.mdi-help-network::before {
  content: "\f6f4";
}

.mdi-help-network-outline::before {
  content: "ﱦ";
}

.mdi-help-rhombus::before {
  content: "ﮁ";
}

.mdi-help-rhombus-outline::before {
  content: "ﮂ";
}

.mdi-hexadecimal::before {
  content: "\f02d2";
}

.mdi-hexagon::before {
  content: "\f2d8";
}

.mdi-hexagon-multiple::before {
  content: "\f6e0";
}

.mdi-hexagon-multiple-outline::before {
  content: "\f011d";
}

.mdi-hexagon-outline::before {
  content: "\f2d9";
}

.mdi-hexagon-slice-1::before {
  content: "輸";
}

.mdi-hexagon-slice-2::before {
  content: "遲";
}

.mdi-hexagon-slice-3::before {
  content: "醙";
}

.mdi-hexagon-slice-4::before {
  content: "鉶";
}

.mdi-hexagon-slice-5::before {
  content: "陼";
}

.mdi-hexagon-slice-6::before {
  content: "難";
}

.mdi-hexagram::before {
  content: "靖";
}

.mdi-hexagram-outline::before {
  content: "韛";
}

.mdi-high-definition::before {
  content: "\f7ce";
}

.mdi-high-definition-box::before {
  content: "\f877";
}

.mdi-highway::before {
  content: "\f5f7";
}

.mdi-hiking::before {
  content: "ﵛ";
}

.mdi-hinduism::before {
  content: "沈";
}

.mdi-history::before {
  content: "\f2da";
}

.mdi-hockey-puck::before {
  content: "\f878";
}

.mdi-hockey-sticks::before {
  content: "\f879";
}

.mdi-hololens::before {
  content: "\f2db";
}

.mdi-home::before {
  content: "\f2dc";
}

.mdi-home-account::before {
  content: "\f825";
}

.mdi-home-alert::before {
  content: "\f87a";
}

.mdi-home-analytics::before {
  content: "ﻗ";
}

.mdi-home-assistant::before {
  content: "\f7cf";
}

.mdi-home-automation::before {
  content: "\f7d0";
}

.mdi-home-circle::before {
  content: "\f7d1";
}

.mdi-home-circle-outline::before {
  content: "\f006f";
}

.mdi-home-city::before {
  content: "ﳱ";
}

.mdi-home-city-outline::before {
  content: "ﳲ";
}

.mdi-home-currency-usd::before {
  content: "\f8ae";
}

.mdi-home-edit::before {
  content: "\f0184";
}

.mdi-home-edit-outline::before {
  content: "\f0185";
}

.mdi-home-export-outline::before {
  content: "ﾸ";
}

.mdi-home-flood::before {
  content: "７";
}

.mdi-home-floor-0::before {
  content: "ﶮ";
}

.mdi-home-floor-1::before {
  content: "ﵜ";
}

.mdi-home-floor-2::before {
  content: "ﵝ";
}

.mdi-home-floor-3::before {
  content: "ﵞ";
}

.mdi-home-floor-a::before {
  content: "ﵟ";
}

.mdi-home-floor-b::before {
  content: "ﵠ";
}

.mdi-home-floor-g::before {
  content: "ﵡ";
}

.mdi-home-floor-l::before {
  content: "ﵢ";
}

.mdi-home-floor-negative-1::before {
  content: "ﶯ";
}

.mdi-home-group::before {
  content: "ﶰ";
}

.mdi-home-heart::before {
  content: "\f826";
}

.mdi-home-import-outline::before {
  content: "ﾹ";
}

.mdi-home-lightbulb::before {
  content: "\f027c";
}

.mdi-home-lightbulb-outline::before {
  content: "\f027d";
}

.mdi-home-lock::before {
  content: "\f8ea";
}

.mdi-home-lock-open::before {
  content: "\f8eb";
}

.mdi-home-map-marker::before {
  content: "\f5f8";
}

.mdi-home-minus::before {
  content: "拾";
}

.mdi-home-modern::before {
  content: "\f2dd";
}

.mdi-home-outline::before {
  content: "\f6a0";
}

.mdi-home-plus::before {
  content: "若";
}

.mdi-home-remove::before {
  content: "\f0272";
}

.mdi-home-roof::before {
  content: "\f0156";
}

.mdi-home-thermometer::before {
  content: "ｱ";
}

.mdi-home-thermometer-outline::before {
  content: "ｲ";
}

.mdi-home-variant::before {
  content: "\f2de";
}

.mdi-home-variant-outline::before {
  content: "ﮃ";
}

.mdi-hook::before {
  content: "\f6e1";
}

.mdi-hook-off::before {
  content: "\f6e2";
}

.mdi-hops::before {
  content: "\f2df";
}

.mdi-horizontal-rotate-clockwise::before {
  content: "\f011e";
}

.mdi-horizontal-rotate-counterclockwise::before {
  content: "\f011f";
}

.mdi-horseshoe::before {
  content: "練";
}

.mdi-hospital::before {
  content: "\f0017";
}

.mdi-hospital-box::before {
  content: "\f2e0";
}

.mdi-hospital-box-outline::before {
  content: "\f0018";
}

.mdi-hospital-building::before {
  content: "\f2e1";
}

.mdi-hospital-marker::before {
  content: "\f2e2";
}

.mdi-hot-tub::before {
  content: "\f827";
}

.mdi-hotel::before {
  content: "\f2e3";
}

.mdi-houzz::before {
  content: "\f2e4";
}

.mdi-houzz-box::before {
  content: "\f2e5";
}

.mdi-hubspot::before {
  content: "ﳳ";
}

.mdi-hulu::before {
  content: "\f828";
}

.mdi-human::before {
  content: "\f2e6";
}

.mdi-human-child::before {
  content: "\f2e7";
}

.mdi-human-female::before {
  content: "\f649";
}

.mdi-human-female-boy::before {
  content: "縉";
}

.mdi-human-female-female::before {
  content: "繁";
}

.mdi-human-female-girl::before {
  content: "署";
}

.mdi-human-greeting::before {
  content: "\f64a";
}

.mdi-human-handsdown::before {
  content: "\f64b";
}

.mdi-human-handsup::before {
  content: "\f64c";
}

.mdi-human-male::before {
  content: "\f64d";
}

.mdi-human-male-boy::before {
  content: "者";
}

.mdi-human-male-female::before {
  content: "\f2e8";
}

.mdi-human-male-girl::before {
  content: "臭";
}

.mdi-human-male-height::before {
  content: "８";
}

.mdi-human-male-height-variant::before {
  content: "９";
}

.mdi-human-male-male::before {
  content: "艹";
}

.mdi-human-pregnant::before {
  content: "\f5cf";
}

.mdi-humble-bundle::before {
  content: "\f743";
}

.mdi-hvac::before {
  content: "\f037d";
}

.mdi-hydraulic-oil-level::before {
  content: "\f034f";
}

.mdi-hydraulic-oil-temperature::before {
  content: "\f0350";
}

.mdi-hydro-power::before {
  content: "\f0310";
}

.mdi-ice-cream::before {
  content: "\f829";
}

.mdi-ice-pop::before {
  content: "：";
}

.mdi-id-card::before {
  content: "￠";
}

.mdi-identifier::before {
  content: "；";
}

.mdi-ideogram-cjk::before {
  content: "\f035c";
}

.mdi-ideogram-cjk-variant::before {
  content: "\f035d";
}

.mdi-iframe::before {
  content: "ﱧ";
}

.mdi-iframe-array::before {
  content: "\f0120";
}

.mdi-iframe-array-outline::before {
  content: "\f0121";
}

.mdi-iframe-braces::before {
  content: "\f0122";
}

.mdi-iframe-braces-outline::before {
  content: "\f0123";
}

.mdi-iframe-outline::before {
  content: "ﱨ";
}

.mdi-iframe-parentheses::before {
  content: "\f0124";
}

.mdi-iframe-parentheses-outline::before {
  content: "\f0125";
}

.mdi-iframe-variable::before {
  content: "\f0126";
}

.mdi-iframe-variable-outline::before {
  content: "\f0127";
}

.mdi-image::before {
  content: "\f2e9";
}

.mdi-image-album::before {
  content: "\f2ea";
}

.mdi-image-area::before {
  content: "\f2eb";
}

.mdi-image-area-close::before {
  content: "\f2ec";
}

.mdi-image-auto-adjust::before {
  content: "￡";
}

.mdi-image-broken::before {
  content: "\f2ed";
}

.mdi-image-broken-variant::before {
  content: "\f2ee";
}

.mdi-image-edit::before {
  content: "\f020e";
}

.mdi-image-edit-outline::before {
  content: "\f020f";
}

.mdi-image-filter::before {
  content: "\f2ef";
}

.mdi-image-filter-black-white::before {
  content: "\f2f0";
}

.mdi-image-filter-center-focus::before {
  content: "\f2f1";
}

.mdi-image-filter-center-focus-strong::before {
  content: "＜";
}

.mdi-image-filter-center-focus-strong-outline::before {
  content: "＝";
}

.mdi-image-filter-center-focus-weak::before {
  content: "\f2f2";
}

.mdi-image-filter-drama::before {
  content: "\f2f3";
}

.mdi-image-filter-frames::before {
  content: "\f2f4";
}

.mdi-image-filter-hdr::before {
  content: "\f2f5";
}

.mdi-image-filter-none::before {
  content: "\f2f6";
}

.mdi-image-filter-tilt-shift::before {
  content: "\f2f7";
}

.mdi-image-filter-vintage::before {
  content: "\f2f8";
}

.mdi-image-frame::before {
  content: "ﺊ";
}

.mdi-image-move::before {
  content: "立";
}

.mdi-image-multiple::before {
  content: "\f2f9";
}

.mdi-image-off::before {
  content: "\f82a";
}

.mdi-image-off-outline::before {
  content: "\f01fc";
}

.mdi-image-outline::before {
  content: "掠";
}

.mdi-image-plus::before {
  content: "\f87b";
}

.mdi-image-search::before {
  content: "略";
}

.mdi-image-search-outline::before {
  content: "亮";
}

.mdi-image-size-select-actual::before {
  content: "ﱩ";
}

.mdi-image-size-select-large::before {
  content: "ﱪ";
}

.mdi-image-size-select-small::before {
  content: "ﱫ";
}

.mdi-import::before {
  content: "\f2fa";
}

.mdi-inbox::before {
  content: "\f686";
}

.mdi-inbox-arrow-down::before {
  content: "\f2fb";
}

.mdi-inbox-arrow-down-outline::before {
  content: "\f029b";
}

.mdi-inbox-arrow-up::before {
  content: "\f3d1";
}

.mdi-inbox-arrow-up-outline::before {
  content: "\f029c";
}

.mdi-inbox-full::before {
  content: "\f029d";
}

.mdi-inbox-full-outline::before {
  content: "\f029e";
}

.mdi-inbox-multiple::before {
  content: "\f8af";
}

.mdi-inbox-multiple-outline::before {
  content: "ﮄ";
}

.mdi-inbox-outline::before {
  content: "\f029f";
}

.mdi-incognito::before {
  content: "\f5f9";
}

.mdi-infinity::before {
  content: "\f6e3";
}

.mdi-information::before {
  content: "\f2fc";
}

.mdi-information-outline::before {
  content: "\f2fd";
}

.mdi-information-variant::before {
  content: "\f64e";
}

.mdi-instagram::before {
  content: "\f2fe";
}

.mdi-instapaper::before {
  content: "\f2ff";
}

.mdi-instrument-triangle::before {
  content: "\f0070";
}

.mdi-internet-explorer::before {
  content: "\f300";
}

.mdi-invert-colors::before {
  content: "\f301";
}

.mdi-invert-colors-off::before {
  content: "ﺋ";
}

.mdi-iobroker::before {
  content: "\f0313";
}

.mdi-ip::before {
  content: "艹";
}

.mdi-ip-network::before {
  content: "著";
}

.mdi-ip-network-outline::before {
  content: "ﱬ";
}

.mdi-ipod::before {
  content: "ﱭ";
}

.mdi-islam::before {
  content: "兩";
}

.mdi-island::before {
  content: "\f0071";
}

.mdi-itunes::before {
  content: "\f676";
}

.mdi-iv-bag::before {
  content: "\f00e4";
}

.mdi-jabber::before {
  content: "ﶱ";
}

.mdi-jeepney::before {
  content: "\f302";
}

.mdi-jellyfish::before {
  content: "＞";
}

.mdi-jellyfish-outline::before {
  content: "？";
}

.mdi-jira::before {
  content: "\f303";
}

.mdi-jquery::before {
  content: "\f87c";
}

.mdi-jsfiddle::before {
  content: "\f304";
}

.mdi-json::before {
  content: "\f626";
}

.mdi-judaism::before {
  content: "凉";
}

.mdi-jump-rope::before {
  content: "\f032a";
}

.mdi-kabaddi::before {
  content: "ﵣ";
}

.mdi-karate::before {
  content: "\f82b";
}

.mdi-keg::before {
  content: "\f305";
}

.mdi-kettle::before {
  content: "\f5fa";
}

.mdi-kettle-alert::before {
  content: "\f0342";
}

.mdi-kettle-alert-outline::before {
  content: "\f0343";
}

.mdi-kettle-off::before {
  content: "\f0346";
}

.mdi-kettle-off-outline::before {
  content: "\f0347";
}

.mdi-kettle-outline::before {
  content: "ｳ";
}

.mdi-kettle-steam::before {
  content: "\f0344";
}

.mdi-kettle-steam-outline::before {
  content: "\f0345";
}

.mdi-kettlebell::before {
  content: "\f032b";
}

.mdi-key::before {
  content: "\f306";
}

.mdi-key-arrow-right::before {
  content: "\f033d";
}

.mdi-key-change::before {
  content: "\f307";
}

.mdi-key-link::before {
  content: "\f01ca";
}

.mdi-key-minus::before {
  content: "\f308";
}

.mdi-key-outline::before {
  content: "ﶲ";
}

.mdi-key-plus::before {
  content: "\f309";
}

.mdi-key-remove::before {
  content: "\f30a";
}

.mdi-key-star::before {
  content: "\f01c9";
}

.mdi-key-variant::before {
  content: "\f30b";
}

.mdi-key-wireless::before {
  content: "￢";
}

.mdi-keyboard::before {
  content: "\f30c";
}

.mdi-keyboard-backspace::before {
  content: "\f30d";
}

.mdi-keyboard-caps::before {
  content: "\f30e";
}

.mdi-keyboard-close::before {
  content: "\f30f";
}

.mdi-keyboard-esc::before {
  content: "\f02e2";
}

.mdi-keyboard-f1::before {
  content: "\f02d6";
}

.mdi-keyboard-f10::before {
  content: "\f02df";
}

.mdi-keyboard-f11::before {
  content: "\f02e0";
}

.mdi-keyboard-f12::before {
  content: "\f02e1";
}

.mdi-keyboard-f2::before {
  content: "\f02d7";
}

.mdi-keyboard-f3::before {
  content: "\f02d8";
}

.mdi-keyboard-f4::before {
  content: "\f02d9";
}

.mdi-keyboard-f5::before {
  content: "\f02da";
}

.mdi-keyboard-f6::before {
  content: "\f02db";
}

.mdi-keyboard-f7::before {
  content: "\f02dc";
}

.mdi-keyboard-f8::before {
  content: "\f02dd";
}

.mdi-keyboard-f9::before {
  content: "\f02de";
}

.mdi-keyboard-off::before {
  content: "\f310";
}

.mdi-keyboard-off-outline::before {
  content: "ﺌ";
}

.mdi-keyboard-outline::before {
  content: "梁";
}

.mdi-keyboard-return::before {
  content: "\f311";
}

.mdi-keyboard-settings::before {
  content: "笠";
}

.mdi-keyboard-settings-outline::before {
  content: "粒";
}

.mdi-keyboard-space::before {
  content: "\f0072";
}

.mdi-keyboard-tab::before {
  content: "\f312";
}

.mdi-keyboard-variant::before {
  content: "\f313";
}

.mdi-khanda::before {
  content: "\f0128";
}

.mdi-kickstarter::before {
  content: "\f744";
}

.mdi-klingon::before {
  content: "\f0386";
}

.mdi-knife::before {
  content: "狀";
}

.mdi-knife-military::before {
  content: "炙";
}

.mdi-kodi::before {
  content: "\f314";
}

.mdi-kotlin::before {
  content: "\f0244";
}

.mdi-kubernetes::before {
  content: "\f0129";
}

.mdi-label::before {
  content: "\f315";
}

.mdi-label-multiple::before {
  content: "\f03a0";
}

.mdi-label-multiple-outline::before {
  content: "\f03a1";
}

.mdi-label-off::before {
  content: "響";
}

.mdi-label-off-outline::before {
  content: "頋";
}

.mdi-label-outline::before {
  content: "\f316";
}

.mdi-label-percent::before {
  content: "\f0315";
}

.mdi-label-percent-outline::before {
  content: "\f0316";
}

.mdi-label-variant::before {
  content: "頻";
}

.mdi-label-variant-outline::before {
  content: "鬒";
}

.mdi-ladybug::before {
  content: "\f82c";
}

.mdi-lambda::before {
  content: "\f627";
}

.mdi-lamp::before {
  content: "\f6b4";
}

.mdi-lan::before {
  content: "\f317";
}

.mdi-lan-check::before {
  content: "\f02d5";
}

.mdi-lan-connect::before {
  content: "\f318";
}

.mdi-lan-disconnect::before {
  content: "\f319";
}

.mdi-lan-pending::before {
  content: "\f31a";
}

.mdi-language-c::before {
  content: "\f671";
}

.mdi-language-cpp::before {
  content: "\f672";
}

.mdi-language-csharp::before {
  content: "\f31b";
}

.mdi-language-css3::before {
  content: "\f31c";
}

.mdi-language-fortran::before {
  content: "\f0245";
}

.mdi-language-go::before {
  content: "\f7d2";
}

.mdi-language-haskell::before {
  content: "ﱮ";
}

.mdi-language-html5::before {
  content: "\f31d";
}

.mdi-language-java::before {
  content: "﬜";
}

.mdi-language-javascript::before {
  content: "\f31e";
}

.mdi-language-lua::before {
  content: "\f8b0";
}

.mdi-language-php::before {
  content: "\f31f";
}

.mdi-language-python::before {
  content: "\f320";
}

.mdi-language-python-text::before {
  content: "\f321";
}

.mdi-language-r::before {
  content: "\f7d3";
}

.mdi-language-ruby-on-rails::before {
  content: "龜";
}

.mdi-language-swift::before {
  content: "\f6e4";
}

.mdi-language-typescript::before {
  content: "\f6e5";
}

.mdi-laptop::before {
  content: "\f322";
}

.mdi-laptop-chromebook::before {
  content: "\f323";
}

.mdi-laptop-mac::before {
  content: "\f324";
}

.mdi-laptop-off::before {
  content: "\f6e6";
}

.mdi-laptop-windows::before {
  content: "\f325";
}

.mdi-laravel::before {
  content: "𢡊";
}

.mdi-lasso::before {
  content: "＠";
}

.mdi-lastfm::before {
  content: "\f326";
}

.mdi-lastpass::before {
  content: "\f446";
}

.mdi-latitude::before {
  content: "ｴ";
}

.mdi-launch::before {
  content: "\f327";
}

.mdi-lava-lamp::before {
  content: "\f7d4";
}

.mdi-layers::before {
  content: "\f328";
}

.mdi-layers-minus::before {
  content: "ﺍ";
}

.mdi-layers-off::before {
  content: "\f329";
}

.mdi-layers-off-outline::before {
  content: "識";
}

.mdi-layers-outline::before {
  content: "什";
}

.mdi-layers-plus::before {
  content: "︰";
}

.mdi-layers-remove::before {
  content: "︱";
}

.mdi-layers-search::before {
  content: "\f0231";
}

.mdi-layers-search-outline::before {
  content: "\f0232";
}

.mdi-layers-triple::before {
  content: "ｵ";
}

.mdi-layers-triple-outline::before {
  content: "ｶ";
}

.mdi-lead-pencil::before {
  content: "\f64f";
}

.mdi-leaf::before {
  content: "\f32a";
}

.mdi-leaf-maple::before {
  content: "ﱯ";
}

.mdi-leaf-maple-off::before {
  content: "\f0305";
}

.mdi-leaf-off::before {
  content: "\f0304";
}

.mdi-leak::before {
  content: "ﶳ";
}

.mdi-leak-off::before {
  content: "ﶴ";
}

.mdi-led-off::before {
  content: "\f32b";
}

.mdi-led-on::before {
  content: "\f32c";
}

.mdi-led-outline::before {
  content: "\f32d";
}

.mdi-led-strip::before {
  content: "\f7d5";
}

.mdi-led-strip-variant::before {
  content: "\f0073";
}

.mdi-led-variant-off::before {
  content: "\f32e";
}

.mdi-led-variant-on::before {
  content: "\f32f";
}

.mdi-led-variant-outline::before {
  content: "\f330";
}

.mdi-leek::before {
  content: "\f01a8";
}

.mdi-less-than::before {
  content: "糧";
}

.mdi-less-than-or-equal::before {
  content: "良";
}

.mdi-library::before {
  content: "\f331";
}

.mdi-library-books::before {
  content: "\f332";
}

.mdi-library-movie::before {
  content: "ﳴ";
}

.mdi-library-music::before {
  content: "\f333";
}

.mdi-library-music-outline::before {
  content: "Ａ";
}

.mdi-library-shelves::before {
  content: "ﮅ";
}

.mdi-library-video::before {
  content: "ﳵ";
}

.mdi-license::before {
  content: "￣";
}

.mdi-lifebuoy::before {
  content: "\f87d";
}

.mdi-light-switch::before {
  content: "諒";
}

.mdi-lightbulb::before {
  content: "\f335";
}

.mdi-lightbulb-cfl::before {
  content: "\f0233";
}

.mdi-lightbulb-cfl-off::before {
  content: "\f0234";
}

.mdi-lightbulb-cfl-spiral::before {
  content: "\f02a0";
}

.mdi-lightbulb-cfl-spiral-off::before {
  content: "\f02ee";
}

.mdi-lightbulb-group::before {
  content: "\f027e";
}

.mdi-lightbulb-group-off::before {
  content: "\f02f8";
}

.mdi-lightbulb-group-off-outline::before {
  content: "\f02f9";
}

.mdi-lightbulb-group-outline::before {
  content: "\f027f";
}

.mdi-lightbulb-multiple::before {
  content: "\f0280";
}

.mdi-lightbulb-multiple-off::before {
  content: "\f02fa";
}

.mdi-lightbulb-multiple-off-outline::before {
  content: "\f02fb";
}

.mdi-lightbulb-multiple-outline::before {
  content: "\f0281";
}

.mdi-lightbulb-off::before {
  content: "︲";
}

.mdi-lightbulb-off-outline::before {
  content: "︳";
}

.mdi-lightbulb-on::before {
  content: "\f6e7";
}

.mdi-lightbulb-on-outline::before {
  content: "\f6e8";
}

.mdi-lightbulb-outline::before {
  content: "\f336";
}

.mdi-lighthouse::before {
  content: "茶";
}

.mdi-lighthouse-on::before {
  content: "刺";
}

.mdi-link::before {
  content: "\f337";
}

.mdi-link-box::before {
  content: "ﳶ";
}

.mdi-link-box-outline::before {
  content: "ﳷ";
}

.mdi-link-box-variant::before {
  content: "ﳸ";
}

.mdi-link-box-variant-outline::before {
  content: "ﳹ";
}

.mdi-link-lock::before {
  content: "\f00e5";
}

.mdi-link-off::before {
  content: "\f338";
}

.mdi-link-plus::before {
  content: "ﱰ";
}

.mdi-link-variant::before {
  content: "\f339";
}

.mdi-link-variant-minus::before {
  content: "\f012a";
}

.mdi-link-variant-off::before {
  content: "\f33a";
}

.mdi-link-variant-plus::before {
  content: "\f012b";
}

.mdi-link-variant-remove::before {
  content: "\f012c";
}

.mdi-linkedin::before {
  content: "\f33b";
}

.mdi-linkedin-box::before {
  content: "\f33c";
}

.mdi-linux::before {
  content: "\f33d";
}

.mdi-linux-mint::before {
  content: "\f8ec";
}

.mdi-litecoin::before {
  content: "褐";
}

.mdi-loading::before {
  content: "\f771";
}

.mdi-location-enter::before {
  content: "￤";
}

.mdi-location-exit::before {
  content: "￥";
}

.mdi-lock::before {
  content: "\f33e";
}

.mdi-lock-alert::before {
  content: "\f8ed";
}

.mdi-lock-clock::before {
  content: "量";
}

.mdi-lock-open::before {
  content: "\f33f";
}

.mdi-lock-open-outline::before {
  content: "\f340";
}

.mdi-lock-open-variant::before {
  content: "￦";
}

.mdi-lock-open-variant-outline::before {
  content: "￧";
}

.mdi-lock-outline::before {
  content: "\f341";
}

.mdi-lock-pattern::before {
  content: "\f6e9";
}

.mdi-lock-plus::before {
  content: "\f5fb";
}

.mdi-lock-question::before {
  content: "\f8ee";
}

.mdi-lock-reset::before {
  content: "\f772";
}

.mdi-lock-smart::before {
  content: "\f8b1";
}

.mdi-locker::before {
  content: "\f7d6";
}

.mdi-locker-multiple::before {
  content: "\f7d7";
}

.mdi-login::before {
  content: "\f342";
}

.mdi-login-variant::before {
  content: "\f5fc";
}

.mdi-logout::before {
  content: "\f343";
}

.mdi-logout-variant::before {
  content: "\f5fd";
}

.mdi-longitude::before {
  content: "ｷ";
}

.mdi-looks::before {
  content: "\f344";
}

.mdi-loupe::before {
  content: "\f345";
}

.mdi-lumx::before {
  content: "\f346";
}

.mdi-lungs::before {
  content: "\f00af";
}

.mdi-lyft::before {
  content: "יִ";
}

.mdi-magnet::before {
  content: "\f347";
}

.mdi-magnet-on::before {
  content: "\f348";
}

.mdi-magnify::before {
  content: "\f349";
}

.mdi-magnify-close::before {
  content: "勵";
}

.mdi-magnify-minus::before {
  content: "\f34a";
}

.mdi-magnify-minus-cursor::before {
  content: "視";
}

.mdi-magnify-minus-outline::before {
  content: "\f6eb";
}

.mdi-magnify-plus::before {
  content: "\f34b";
}

.mdi-magnify-plus-cursor::before {
  content: "謁";
}

.mdi-magnify-plus-outline::before {
  content: "\f6ec";
}

.mdi-magnify-remove-cursor::before {
  content: "\f0237";
}

.mdi-magnify-remove-outline::before {
  content: "\f0238";
}

.mdi-magnify-scan::before {
  content: "\f02a1";
}

.mdi-mail::before {
  content: "ﻘ";
}

.mdi-mail-ru::before {
  content: "\f34c";
}

.mdi-mailbox::before {
  content: "\f6ed";
}

.mdi-mailbox-open::before {
  content: "ﵤ";
}

.mdi-mailbox-open-outline::before {
  content: "ﵥ";
}

.mdi-mailbox-open-up::before {
  content: "ﵦ";
}

.mdi-mailbox-open-up-outline::before {
  content: "ﵧ";
}

.mdi-mailbox-outline::before {
  content: "ﵨ";
}

.mdi-mailbox-up::before {
  content: "ﵩ";
}

.mdi-mailbox-up-outline::before {
  content: "ﵪ";
}

.mdi-map::before {
  content: "\f34d";
}

.mdi-map-check::before {
  content: "ﻙ";
}

.mdi-map-check-outline::before {
  content: "ﻚ";
}

.mdi-map-clock::before {
  content: "ﳺ";
}

.mdi-map-clock-outline::before {
  content: "ﳻ";
}

.mdi-map-legend::before {
  content: "切";
}

.mdi-map-marker::before {
  content: "\f34e";
}

.mdi-map-marker-alert::before {
  content: "Ｂ";
}

.mdi-map-marker-alert-outline::before {
  content: "Ｃ";
}

.mdi-map-marker-check::before {
  content: "ﱱ";
}

.mdi-map-marker-check-outline::before {
  content: "\f0326";
}

.mdi-map-marker-circle::before {
  content: "\f34f";
}

.mdi-map-marker-distance::before {
  content: "\f8ef";
}

.mdi-map-marker-down::before {
  content: "\f012d";
}

.mdi-map-marker-left::before {
  content: "\f0306";
}

.mdi-map-marker-left-outline::before {
  content: "\f0308";
}

.mdi-map-marker-minus::before {
  content: "\f650";
}

.mdi-map-marker-minus-outline::before {
  content: "\f0324";
}

.mdi-map-marker-multiple::before {
  content: "\f350";
}

.mdi-map-marker-multiple-outline::before {
  content: "\f02a2";
}

.mdi-map-marker-off::before {
  content: "\f351";
}

.mdi-map-marker-off-outline::before {
  content: "\f0328";
}

.mdi-map-marker-outline::before {
  content: "\f7d8";
}

.mdi-map-marker-path::before {
  content: "ﳼ";
}

.mdi-map-marker-plus::before {
  content: "\f651";
}

.mdi-map-marker-plus-outline::before {
  content: "\f0323";
}

.mdi-map-marker-question::before {
  content: "Ｄ";
}

.mdi-map-marker-question-outline::before {
  content: "Ｅ";
}

.mdi-map-marker-radius::before {
  content: "\f352";
}

.mdi-map-marker-radius-outline::before {
  content: "\f0327";
}

.mdi-map-marker-remove::before {
  content: "Ｆ";
}

.mdi-map-marker-remove-outline::before {
  content: "\f0325";
}

.mdi-map-marker-remove-variant::before {
  content: "Ｇ";
}

.mdi-map-marker-right::before {
  content: "\f0307";
}

.mdi-map-marker-right-outline::before {
  content: "\f0309";
}

.mdi-map-marker-up::before {
  content: "\f012e";
}

.mdi-map-minus::before {
  content: "呂";
}

.mdi-map-outline::before {
  content: "女";
}

.mdi-map-plus::before {
  content: "廬";
}

.mdi-map-search::before {
  content: "旅";
}

.mdi-map-search-outline::before {
  content: "濾";
}

.mdi-mapbox::before {
  content: "ﮆ";
}

.mdi-margin::before {
  content: "\f353";
}

.mdi-markdown::before {
  content: "\f354";
}

.mdi-markdown-outline::before {
  content: "ｸ";
}

.mdi-marker::before {
  content: "\f652";
}

.mdi-marker-cancel::before {
  content: "ﶵ";
}

.mdi-marker-check::before {
  content: "\f355";
}

.mdi-mastodon::before {
  content: "𢡄";
}

.mdi-mastodon-variant::before {
  content: "𣏕";
}

.mdi-material-design::before {
  content: "礪";
}

.mdi-material-ui::before {
  content: "\f357";
}

.mdi-math-compass::before {
  content: "\f358";
}

.mdi-math-cos::before {
  content: "ﱲ";
}

.mdi-math-integral::before {
  content: "￨";
}

.mdi-math-integral-box::before {
  content: "￩";
}

.mdi-math-log::before {
  content: "\f00b0";
}

.mdi-math-norm::before {
  content: "￪";
}

.mdi-math-norm-box::before {
  content: "￫";
}

.mdi-math-sin::before {
  content: "ﱳ";
}

.mdi-math-tan::before {
  content: "ﱴ";
}

.mdi-matrix::before {
  content: "\f628";
}

.mdi-medal::before {
  content: "閭";
}

.mdi-medal-outline::before {
  content: "\f0351";
}

.mdi-medical-bag::before {
  content: "\f6ee";
}

.mdi-meditation::before {
  content: "\f01a6";
}

.mdi-medium::before {
  content: "\f35a";
}

.mdi-meetup::before {
  content: "㮝";
}

.mdi-memory::before {
  content: "\f35b";
}

.mdi-menu::before {
  content: "\f35c";
}

.mdi-menu-down::before {
  content: "\f35d";
}

.mdi-menu-down-outline::before {
  content: "\f6b5";
}

.mdi-menu-left::before {
  content: "\f35e";
}

.mdi-menu-left-outline::before {
  content: "度";
}

.mdi-menu-open::before {
  content: "ﮇ";
}

.mdi-menu-right::before {
  content: "\f35f";
}

.mdi-menu-right-outline::before {
  content: "拓";
}

.mdi-menu-swap::before {
  content: "謹";
}

.mdi-menu-swap-outline::before {
  content: "賓";
}

.mdi-menu-up::before {
  content: "\f360";
}

.mdi-menu-up-outline::before {
  content: "\f6b6";
}

.mdi-merge::before {
  content: "ｹ";
}

.mdi-message::before {
  content: "\f361";
}

.mdi-message-alert::before {
  content: "\f362";
}

.mdi-message-alert-outline::before {
  content: "糖";
}

.mdi-message-arrow-left::before {
  content: "\f031d";
}

.mdi-message-arrow-left-outline::before {
  content: "\f031e";
}

.mdi-message-arrow-right::before {
  content: "\f031f";
}

.mdi-message-arrow-right-outline::before {
  content: "\f0320";
}

.mdi-message-bulleted::before {
  content: "\f6a1";
}

.mdi-message-bulleted-off::before {
  content: "\f6a2";
}

.mdi-message-draw::before {
  content: "\f363";
}

.mdi-message-image::before {
  content: "\f364";
}

.mdi-message-image-outline::before {
  content: "\f0197";
}

.mdi-message-lock::before {
  content: "￬";
}

.mdi-message-lock-outline::before {
  content: "\f0198";
}

.mdi-message-minus::before {
  content: "\f0199";
}

.mdi-message-minus-outline::before {
  content: "\f019a";
}

.mdi-message-outline::before {
  content: "\f365";
}

.mdi-message-plus::before {
  content: "\f653";
}

.mdi-message-plus-outline::before {
  content: "\f00e6";
}

.mdi-message-processing::before {
  content: "\f366";
}

.mdi-message-processing-outline::before {
  content: "\f019b";
}

.mdi-message-reply::before {
  content: "\f367";
}

.mdi-message-reply-text::before {
  content: "\f368";
}

.mdi-message-settings::before {
  content: "\f6ef";
}

.mdi-message-settings-outline::before {
  content: "\f019c";
}

.mdi-message-settings-variant::before {
  content: "\f6f0";
}

.mdi-message-settings-variant-outline::before {
  content: "\f019d";
}

.mdi-message-text::before {
  content: "\f369";
}

.mdi-message-text-clock::before {
  content: "\f019e";
}

.mdi-message-text-clock-outline::before {
  content: "\f019f";
}

.mdi-message-text-lock::before {
  content: "￭";
}

.mdi-message-text-lock-outline::before {
  content: "\f01a0";
}

.mdi-message-text-outline::before {
  content: "\f36a";
}

.mdi-message-video::before {
  content: "\f36b";
}

.mdi-meteor::before {
  content: "\f629";
}

.mdi-metronome::before {
  content: "\f7d9";
}

.mdi-metronome-tick::before {
  content: "\f7da";
}

.mdi-micro-sd::before {
  content: "\f7db";
}

.mdi-microphone::before {
  content: "\f36c";
}

.mdi-microphone-minus::before {
  content: "\f8b2";
}

.mdi-microphone-off::before {
  content: "\f36d";
}

.mdi-microphone-outline::before {
  content: "\f36e";
}

.mdi-microphone-plus::before {
  content: "\f8b3";
}

.mdi-microphone-settings::before {
  content: "\f36f";
}

.mdi-microphone-variant::before {
  content: "\f370";
}

.mdi-microphone-variant-off::before {
  content: "\f371";
}

.mdi-microscope::before {
  content: "\f654";
}

.mdi-microsoft::before {
  content: "\f372";
}

.mdi-microsoft-dynamics::before {
  content: "驪";
}

.mdi-microwave::before {
  content: "ﱵ";
}

.mdi-middleware::before {
  content: "ｺ";
}

.mdi-middleware-outline::before {
  content: "ｻ";
}

.mdi-midi::before {
  content: "\f8f0";
}

.mdi-midi-port::before {
  content: "\f8f1";
}

.mdi-mine::before {
  content: "ﶶ";
}

.mdi-minecraft::before {
  content: "\f373";
}

.mdi-mini-sd::before {
  content: "宅";
}

.mdi-minidisc::before {
  content: "洞";
}

.mdi-minus::before {
  content: "\f374";
}

.mdi-minus-box::before {
  content: "\f375";
}

.mdi-minus-box-multiple::before {
  content: "\f016c";
}

.mdi-minus-box-multiple-outline::before {
  content: "\f016d";
}

.mdi-minus-box-outline::before {
  content: "\f6f1";
}

.mdi-minus-circle::before {
  content: "\f376";
}

.mdi-minus-circle-outline::before {
  content: "\f377";
}

.mdi-minus-network::before {
  content: "\f378";
}

.mdi-minus-network-outline::before {
  content: "ﱶ";
}

.mdi-mirror::before {
  content: "\f0228";
}

.mdi-mixcloud::before {
  content: "\f62a";
}

.mdi-mixed-martial-arts::before {
  content: "ﵫ";
}

.mdi-mixed-reality::before {
  content: "\f87e";
}

.mdi-mixer::before {
  content: "\f7dc";
}

.mdi-molecule::before {
  content: "ﮈ";
}

.mdi-monitor::before {
  content: "\f379";
}

.mdi-monitor-cellphone::before {
  content: "麗";
}

.mdi-monitor-cellphone-star::before {
  content: "黎";
}

.mdi-monitor-clean::before {
  content: "\f012f";
}

.mdi-monitor-dashboard::before {
  content: "暴";
}

.mdi-monitor-edit::before {
  content: "\f02f1";
}

.mdi-monitor-lock::before {
  content: "ﶷ";
}

.mdi-monitor-multiple::before {
  content: "\f37a";
}

.mdi-monitor-off::before {
  content: "ﵬ";
}

.mdi-monitor-screenshot::before {
  content: "︴";
}

.mdi-monitor-speaker::before {
  content: "ｼ";
}

.mdi-monitor-speaker-off::before {
  content: "ｽ";
}

.mdi-monitor-star::before {
  content: "ﶸ";
}

.mdi-moon-first-quarter::before {
  content: "ｾ";
}

.mdi-moon-full::before {
  content: "ｿ";
}

.mdi-moon-last-quarter::before {
  content: "ﾀ";
}

.mdi-moon-new::before {
  content: "ﾁ";
}

.mdi-moon-waning-crescent::before {
  content: "ﾂ";
}

.mdi-moon-waning-gibbous::before {
  content: "ﾃ";
}

.mdi-moon-waxing-crescent::before {
  content: "ﾄ";
}

.mdi-moon-waxing-gibbous::before {
  content: "ﾅ";
}

.mdi-moped::before {
  content: "\f00b1";
}

.mdi-more::before {
  content: "\f37b";
}

.mdi-mother-heart::before {
  content: "\f033f";
}

.mdi-mother-nurse::before {
  content: "ﳽ";
}

.mdi-motion-sensor::before {
  content: "ﵭ";
}

.mdi-motorbike::before {
  content: "\f37c";
}

.mdi-mouse::before {
  content: "\f37d";
}

.mdi-mouse-bluetooth::before {
  content: "力";
}

.mdi-mouse-off::before {
  content: "\f37e";
}

.mdi-mouse-variant::before {
  content: "\f37f";
}

.mdi-mouse-variant-off::before {
  content: "\f380";
}

.mdi-move-resize::before {
  content: "\f655";
}

.mdi-move-resize-variant::before {
  content: "\f656";
}

.mdi-movie::before {
  content: "\f381";
}

.mdi-movie-edit::before {
  content: "\f014d";
}

.mdi-movie-edit-outline::before {
  content: "\f014e";
}

.mdi-movie-filter::before {
  content: "\f014f";
}

.mdi-movie-filter-outline::before {
  content: "\f0150";
}

.mdi-movie-open::before {
  content: "￮";
}

.mdi-movie-open-outline::before {
  content: "￯";
}

.mdi-movie-outline::before {
  content: "ﶹ";
}

.mdi-movie-roll::before {
  content: "\f7dd";
}

.mdi-movie-search::before {
  content: "\f01fd";
}

.mdi-movie-search-outline::before {
  content: "\f01fe";
}

.mdi-muffin::before {
  content: "曆";
}

.mdi-multiplication::before {
  content: "\f382";
}

.mdi-multiplication-box::before {
  content: "\f383";
}

.mdi-mushroom::before {
  content: "\f7de";
}

.mdi-mushroom-outline::before {
  content: "\f7df";
}

.mdi-music::before {
  content: "\f759";
}

.mdi-music-accidental-double-flat::before {
  content: "ﾆ";
}

.mdi-music-accidental-double-sharp::before {
  content: "ﾇ";
}

.mdi-music-accidental-flat::before {
  content: "ﾈ";
}

.mdi-music-accidental-natural::before {
  content: "ﾉ";
}

.mdi-music-accidental-sharp::before {
  content: "ﾊ";
}

.mdi-music-box::before {
  content: "\f384";
}

.mdi-music-box-outline::before {
  content: "\f385";
}

.mdi-music-circle::before {
  content: "\f386";
}

.mdi-music-circle-outline::before {
  content: "䀘";
}

.mdi-music-clef-alto::before {
  content: "ﾋ";
}

.mdi-music-clef-bass::before {
  content: "ﾌ";
}

.mdi-music-clef-treble::before {
  content: "ﾍ";
}

.mdi-music-note::before {
  content: "\f387";
}

.mdi-music-note-bluetooth::before {
  content: "\f5fe";
}

.mdi-music-note-bluetooth-off::before {
  content: "\f5ff";
}

.mdi-music-note-eighth::before {
  content: "\f388";
}

.mdi-music-note-eighth-dotted::before {
  content: "ﾎ";
}

.mdi-music-note-half::before {
  content: "\f389";
}

.mdi-music-note-half-dotted::before {
  content: "ﾏ";
}

.mdi-music-note-off::before {
  content: "\f38a";
}

.mdi-music-note-off-outline::before {
  content: "ﾐ";
}

.mdi-music-note-outline::before {
  content: "ﾑ";
}

.mdi-music-note-plus::before {
  content: "ﶺ";
}

.mdi-music-note-quarter::before {
  content: "\f38b";
}

.mdi-music-note-quarter-dotted::before {
  content: "ﾒ";
}

.mdi-music-note-sixteenth::before {
  content: "\f38c";
}

.mdi-music-note-sixteenth-dotted::before {
  content: "ﾓ";
}

.mdi-music-note-whole::before {
  content: "\f38d";
}

.mdi-music-note-whole-dotted::before {
  content: "ﾔ";
}

.mdi-music-off::before {
  content: "\f75a";
}

.mdi-music-rest-eighth::before {
  content: "ﾕ";
}

.mdi-music-rest-half::before {
  content: "ﾖ";
}

.mdi-music-rest-quarter::before {
  content: "ﾗ";
}

.mdi-music-rest-sixteenth::before {
  content: "ﾘ";
}

.mdi-music-rest-whole::before {
  content: "ﾙ";
}

.mdi-nail::before {
  content: "ﶻ";
}

.mdi-nas::before {
  content: "\f8f2";
}

.mdi-nativescript::before {
  content: "\f87f";
}

.mdi-nature::before {
  content: "\f38e";
}

.mdi-nature-people::before {
  content: "\f38f";
}

.mdi-navigation::before {
  content: "\f390";
}

.mdi-near-me::before {
  content: "\f5cd";
}

.mdi-necklace::before {
  content: "Ｈ";
}

.mdi-needle::before {
  content: "\f391";
}

.mdi-netflix::before {
  content: "\f745";
}

.mdi-network::before {
  content: "\f6f2";
}

.mdi-network-off::before {
  content: "ﱷ";
}

.mdi-network-off-outline::before {
  content: "ﱸ";
}

.mdi-network-outline::before {
  content: "ﱹ";
}

.mdi-network-router::before {
  content: "\f00b2";
}

.mdi-network-strength-1::before {
  content: "\f8f3";
}

.mdi-network-strength-1-alert::before {
  content: "\f8f4";
}

.mdi-network-strength-2::before {
  content: "\f8f5";
}

.mdi-network-strength-2-alert::before {
  content: "\f8f6";
}

.mdi-network-strength-3::before {
  content: "\f8f7";
}

.mdi-network-strength-3-alert::before {
  content: "\f8f8";
}

.mdi-network-strength-4::before {
  content: "\f8f9";
}

.mdi-network-strength-4-alert::before {
  content: "\f8fa";
}

.mdi-network-strength-off::before {
  content: "\f8fb";
}

.mdi-network-strength-off-outline::before {
  content: "\f8fc";
}

.mdi-network-strength-outline::before {
  content: "\f8fd";
}

.mdi-new-box::before {
  content: "\f394";
}

.mdi-newspaper::before {
  content: "\f395";
}

.mdi-newspaper-minus::before {
  content: "Ｉ";
}

.mdi-newspaper-plus::before {
  content: "Ｊ";
}

.mdi-newspaper-variant::before {
  content: "\f0023";
}

.mdi-newspaper-variant-multiple::before {
  content: "\f0024";
}

.mdi-newspaper-variant-multiple-outline::before {
  content: "\f0025";
}

.mdi-newspaper-variant-outline::before {
  content: "\f0026";
}

.mdi-nfc::before {
  content: "\f396";
}

.mdi-nfc-off::before {
  content: "︵";
}

.mdi-nfc-search-variant::before {
  content: "︶";
}

.mdi-nfc-tap::before {
  content: "\f397";
}

.mdi-nfc-variant::before {
  content: "\f398";
}

.mdi-nfc-variant-off::before {
  content: "︷";
}

.mdi-ninja::before {
  content: "\f773";
}

.mdi-nintendo-switch::before {
  content: "\f7e0";
}

.mdi-nix::before {
  content: "\f0130";
}

.mdi-nodejs::before {
  content: "\f399";
}

.mdi-noodles::before {
  content: "\f01a9";
}

.mdi-not-equal::before {
  content: "歷";
}

.mdi-not-equal-variant::before {
  content: "轢";
}

.mdi-note::before {
  content: "\f39a";
}

.mdi-note-multiple::before {
  content: "\f6b7";
}

.mdi-note-multiple-outline::before {
  content: "\f6b8";
}

.mdi-note-outline::before {
  content: "\f39b";
}

.mdi-note-plus::before {
  content: "\f39c";
}

.mdi-note-plus-outline::before {
  content: "\f39d";
}

.mdi-note-text::before {
  content: "\f39e";
}

.mdi-note-text-outline::before {
  content: "\f0202";
}

.mdi-notebook::before {
  content: "\f82d";
}

.mdi-notebook-multiple::before {
  content: "︸";
}

.mdi-notebook-outline::before {
  content: "ﻜ";
}

.mdi-notification-clear-all::before {
  content: "\f39f";
}

.mdi-npm::before {
  content: "\f6f6";
}

.mdi-npm-variant::before {
  content: "年";
}

.mdi-npm-variant-outline::before {
  content: "憐";
}

.mdi-nuke::before {
  content: "\f6a3";
}

.mdi-null::before {
  content: "\f7e1";
}

.mdi-numeric::before {
  content: "\f3a0";
}

.mdi-numeric-0::before {
  content: "0";
}

.mdi-numeric-0-box::before {
  content: "\f3a1";
}

.mdi-numeric-0-box-multiple::before {
  content: "Ｋ";
}

.mdi-numeric-0-box-multiple-outline::before {
  content: "\f3a2";
}

.mdi-numeric-0-box-outline::before {
  content: "\f3a3";
}

.mdi-numeric-0-circle::before {
  content: "ﱺ";
}

.mdi-numeric-0-circle-outline::before {
  content: "ﱻ";
}

.mdi-numeric-1::before {
  content: "1";
}

.mdi-numeric-1-box::before {
  content: "\f3a4";
}

.mdi-numeric-1-box-multiple::before {
  content: "Ｌ";
}

.mdi-numeric-1-box-multiple-outline::before {
  content: "\f3a5";
}

.mdi-numeric-1-box-outline::before {
  content: "\f3a6";
}

.mdi-numeric-1-circle::before {
  content: "ﱼ";
}

.mdi-numeric-1-circle-outline::before {
  content: "ﱽ";
}

.mdi-numeric-10::before {
  content: "\f000a";
}

.mdi-numeric-10-box::before {
  content: "ﾚ";
}

.mdi-numeric-10-box-multiple::before {
  content: "\f000b";
}

.mdi-numeric-10-box-multiple-outline::before {
  content: "\f000c";
}

.mdi-numeric-10-box-outline::before {
  content: "ﾛ";
}

.mdi-numeric-10-circle::before {
  content: "\f000d";
}

.mdi-numeric-10-circle-outline::before {
  content: "\f000e";
}

.mdi-numeric-2::before {
  content: "2";
}

.mdi-numeric-2-box::before {
  content: "\f3a7";
}

.mdi-numeric-2-box-multiple::before {
  content: "Ｍ";
}

.mdi-numeric-2-box-multiple-outline::before {
  content: "\f3a8";
}

.mdi-numeric-2-box-outline::before {
  content: "\f3a9";
}

.mdi-numeric-2-circle::before {
  content: "ﱾ";
}

.mdi-numeric-2-circle-outline::before {
  content: "ﱿ";
}

.mdi-numeric-3::before {
  content: "3";
}

.mdi-numeric-3-box::before {
  content: "\f3aa";
}

.mdi-numeric-3-box-multiple::before {
  content: "Ｎ";
}

.mdi-numeric-3-box-multiple-outline::before {
  content: "\f3ab";
}

.mdi-numeric-3-box-outline::before {
  content: "\f3ac";
}

.mdi-numeric-3-circle::before {
  content: "ﲀ";
}

.mdi-numeric-3-circle-outline::before {
  content: "ﲁ";
}

.mdi-numeric-4::before {
  content: "4";
}

.mdi-numeric-4-box::before {
  content: "\f3ad";
}

.mdi-numeric-4-box-multiple::before {
  content: "Ｏ";
}

.mdi-numeric-4-box-multiple-outline::before {
  content: "\f3ae";
}

.mdi-numeric-4-box-outline::before {
  content: "\f3af";
}

.mdi-numeric-4-circle::before {
  content: "ﲂ";
}

.mdi-numeric-4-circle-outline::before {
  content: "ﲃ";
}

.mdi-numeric-5::before {
  content: "5";
}

.mdi-numeric-5-box::before {
  content: "\f3b0";
}

.mdi-numeric-5-box-multiple::before {
  content: "Ｐ";
}

.mdi-numeric-5-box-multiple-outline::before {
  content: "\f3b1";
}

.mdi-numeric-5-box-outline::before {
  content: "\f3b2";
}

.mdi-numeric-5-circle::before {
  content: "ﲄ";
}

.mdi-numeric-5-circle-outline::before {
  content: "ﲅ";
}

.mdi-numeric-6::before {
  content: "6";
}

.mdi-numeric-6-box::before {
  content: "\f3b3";
}

.mdi-numeric-6-box-multiple::before {
  content: "Ｑ";
}

.mdi-numeric-6-box-multiple-outline::before {
  content: "\f3b4";
}

.mdi-numeric-6-box-outline::before {
  content: "\f3b5";
}

.mdi-numeric-6-circle::before {
  content: "ﲆ";
}

.mdi-numeric-6-circle-outline::before {
  content: "ﲇ";
}

.mdi-numeric-7::before {
  content: "7";
}

.mdi-numeric-7-box::before {
  content: "\f3b6";
}

.mdi-numeric-7-box-multiple::before {
  content: "Ｒ";
}

.mdi-numeric-7-box-multiple-outline::before {
  content: "\f3b7";
}

.mdi-numeric-7-box-outline::before {
  content: "\f3b8";
}

.mdi-numeric-7-circle::before {
  content: "ﲈ";
}

.mdi-numeric-7-circle-outline::before {
  content: "ﲉ";
}

.mdi-numeric-8::before {
  content: "8";
}

.mdi-numeric-8-box::before {
  content: "\f3b9";
}

.mdi-numeric-8-box-multiple::before {
  content: "Ｓ";
}

.mdi-numeric-8-box-multiple-outline::before {
  content: "\f3ba";
}

.mdi-numeric-8-box-outline::before {
  content: "\f3bb";
}

.mdi-numeric-8-circle::before {
  content: "ﲊ";
}

.mdi-numeric-8-circle-outline::before {
  content: "ﲋ";
}

.mdi-numeric-9::before {
  content: "9";
}

.mdi-numeric-9-box::before {
  content: "\f3bc";
}

.mdi-numeric-9-box-multiple::before {
  content: "Ｔ";
}

.mdi-numeric-9-box-multiple-outline::before {
  content: "\f3bd";
}

.mdi-numeric-9-box-outline::before {
  content: "\f3be";
}

.mdi-numeric-9-circle::before {
  content: "ﲌ";
}

.mdi-numeric-9-circle-outline::before {
  content: "ﲍ";
}

.mdi-numeric-9-plus::before {
  content: "\f000f";
}

.mdi-numeric-9-plus-box::before {
  content: "\f3bf";
}

.mdi-numeric-9-plus-box-multiple::before {
  content: "Ｕ";
}

.mdi-numeric-9-plus-box-multiple-outline::before {
  content: "\f3c0";
}

.mdi-numeric-9-plus-box-outline::before {
  content: "\f3c1";
}

.mdi-numeric-9-plus-circle::before {
  content: "ﲎ";
}

.mdi-numeric-9-plus-circle-outline::before {
  content: "ﲏ";
}

.mdi-numeric-negative-1::before {
  content: "\f0074";
}

.mdi-nut::before {
  content: "\f6f7";
}

.mdi-nutrition::before {
  content: "\f3c2";
}

.mdi-nuxt::before {
  content: "\f0131";
}

.mdi-oar::before {
  content: "\f67b";
}

.mdi-ocarina::before {
  content: "ﶼ";
}

.mdi-oci::before {
  content: "\f0314";
}

.mdi-ocr::before {
  content: "\f0165";
}

.mdi-octagon::before {
  content: "\f3c3";
}

.mdi-octagon-outline::before {
  content: "\f3c4";
}

.mdi-octagram::before {
  content: "\f6f8";
}

.mdi-octagram-outline::before {
  content: "\f774";
}

.mdi-odnoklassniki::before {
  content: "\f3c5";
}

.mdi-offer::before {
  content: "\f0246";
}

.mdi-office::before {
  content: "\f3c6";
}

.mdi-office-building::before {
  content: "戀";
}

.mdi-oil::before {
  content: "\f3c7";
}

.mdi-oil-lamp::before {
  content: "Ｖ";
}

.mdi-oil-level::before {
  content: "\f0075";
}

.mdi-oil-temperature::before {
  content: "\f0019";
}

.mdi-omega::before {
  content: "\f3c9";
}

.mdi-one-up::before {
  content: "ﮉ";
}

.mdi-onedrive::before {
  content: "\f3ca";
}

.mdi-onenote::before {
  content: "\f746";
}

.mdi-onepassword::before {
  content: "\f880";
}

.mdi-opacity::before {
  content: "\f5cc";
}

.mdi-open-in-app::before {
  content: "\f3cb";
}

.mdi-open-in-new::before {
  content: "\f3cc";
}

.mdi-open-source-initiative::before {
  content: "ﮊ";
}

.mdi-openid::before {
  content: "\f3cd";
}

.mdi-opera::before {
  content: "\f3ce";
}

.mdi-orbit::before {
  content: "\f018";
}

.mdi-origin::before {
  content: "שׂ";
}

.mdi-ornament::before {
  content: "\f3cf";
}

.mdi-ornament-variant::before {
  content: "\f3d0";
}

.mdi-outdoor-lamp::before {
  content: "\f0076";
}

.mdi-outlook::before {
  content: "ﳾ";
}

.mdi-overscan::before {
  content: "\f0027";
}

.mdi-owl::before {
  content: "\f3d2";
}

.mdi-pac-man::before {
  content: "ﮋ";
}

.mdi-package::before {
  content: "\f3d3";
}

.mdi-package-down::before {
  content: "\f3d4";
}

.mdi-package-up::before {
  content: "\f3d5";
}

.mdi-package-variant::before {
  content: "\f3d6";
}

.mdi-package-variant-closed::before {
  content: "\f3d7";
}

.mdi-page-first::before {
  content: "\f600";
}

.mdi-page-last::before {
  content: "\f601";
}

.mdi-page-layout-body::before {
  content: "\f6f9";
}

.mdi-page-layout-footer::before {
  content: "\f6fa";
}

.mdi-page-layout-header::before {
  content: "\f6fb";
}

.mdi-page-layout-header-footer::before {
  content: "ﾜ";
}

.mdi-page-layout-sidebar-left::before {
  content: "\f6fc";
}

.mdi-page-layout-sidebar-right::before {
  content: "\f6fd";
}

.mdi-page-next::before {
  content: "ﮌ";
}

.mdi-page-next-outline::before {
  content: "ﮍ";
}

.mdi-page-previous::before {
  content: "ﮎ";
}

.mdi-page-previous-outline::before {
  content: "ﮏ";
}

.mdi-palette::before {
  content: "\f3d8";
}

.mdi-palette-advanced::before {
  content: "\f3d9";
}

.mdi-palette-outline::before {
  content: "﹬";
}

.mdi-palette-swatch::before {
  content: "\f8b4";
}

.mdi-palette-swatch-outline::before {
  content: "\f0387";
}

.mdi-palm-tree::before {
  content: "\f0077";
}

.mdi-pan::before {
  content: "ﮐ";
}

.mdi-pan-bottom-left::before {
  content: "ﮑ";
}

.mdi-pan-bottom-right::before {
  content: "ﮒ";
}

.mdi-pan-down::before {
  content: "ﮓ";
}

.mdi-pan-horizontal::before {
  content: "ﮔ";
}

.mdi-pan-left::before {
  content: "ﮕ";
}

.mdi-pan-right::before {
  content: "ﮖ";
}

.mdi-pan-top-left::before {
  content: "ﮗ";
}

.mdi-pan-top-right::before {
  content: "ﮘ";
}

.mdi-pan-up::before {
  content: "ﮙ";
}

.mdi-pan-vertical::before {
  content: "ﮚ";
}

.mdi-panda::before {
  content: "\f3da";
}

.mdi-pandora::before {
  content: "\f3db";
}

.mdi-panorama::before {
  content: "\f3dc";
}

.mdi-panorama-fisheye::before {
  content: "\f3dd";
}

.mdi-panorama-horizontal::before {
  content: "\f3de";
}

.mdi-panorama-vertical::before {
  content: "\f3df";
}

.mdi-panorama-wide-angle::before {
  content: "\f3e0";
}

.mdi-paper-cut-vertical::before {
  content: "\f3e1";
}

.mdi-paper-roll::before {
  content: "\f0182";
}

.mdi-paper-roll-outline::before {
  content: "\f0183";
}

.mdi-paperclip::before {
  content: "\f3e2";
}

.mdi-parachute::before {
  content: "ﲐ";
}

.mdi-parachute-outline::before {
  content: "ﲑ";
}

.mdi-parking::before {
  content: "\f3e3";
}

.mdi-party-popper::before {
  content: "\f0078";
}

.mdi-passport::before {
  content: "\f7e2";
}

.mdi-passport-biometric::before {
  content: "ﶽ";
}

.mdi-pasta::before {
  content: "\f018b";
}

.mdi-patio-heater::before {
  content: "ﾝ";
}

.mdi-patreon::before {
  content: "\f881";
}

.mdi-pause::before {
  content: "\f3e4";
}

.mdi-pause-circle::before {
  content: "\f3e5";
}

.mdi-pause-circle-outline::before {
  content: "\f3e6";
}

.mdi-pause-octagon::before {
  content: "\f3e7";
}

.mdi-pause-octagon-outline::before {
  content: "\f3e8";
}

.mdi-paw::before {
  content: "\f3e9";
}

.mdi-paw-off::before {
  content: "\f657";
}

.mdi-paypal::before {
  content: "\f882";
}

.mdi-pdf-box::before {
  content: "︹";
}

.mdi-peace::before {
  content: "\f883";
}

.mdi-peanut::before {
  content: "\f001e";
}

.mdi-peanut-off::before {
  content: "\f001f";
}

.mdi-peanut-off-outline::before {
  content: "\f0021";
}

.mdi-peanut-outline::before {
  content: "\f0020";
}

.mdi-pen::before {
  content: "\f3ea";
}

.mdi-pen-lock::before {
  content: "ﶾ";
}

.mdi-pen-minus::before {
  content: "ﶿ";
}

.mdi-pen-off::before {
  content: "ﷀ";
}

.mdi-pen-plus::before {
  content: "ﷁ";
}

.mdi-pen-remove::before {
  content: "ﷂ";
}

.mdi-pencil::before {
  content: "\f3eb";
}

.mdi-pencil-box::before {
  content: "\f3ec";
}

.mdi-pencil-box-multiple::before {
  content: "\f016f";
}

.mdi-pencil-box-multiple-outline::before {
  content: "\f0170";
}

.mdi-pencil-box-outline::before {
  content: "\f3ed";
}

.mdi-pencil-circle::before {
  content: "\f6fe";
}

.mdi-pencil-circle-outline::before {
  content: "\f775";
}

.mdi-pencil-lock::before {
  content: "\f3ee";
}

.mdi-pencil-lock-outline::before {
  content: "ﷃ";
}

.mdi-pencil-minus::before {
  content: "ﷄ";
}

.mdi-pencil-minus-outline::before {
  content: "ﷅ";
}

.mdi-pencil-off::before {
  content: "\f3ef";
}

.mdi-pencil-off-outline::before {
  content: "ﷆ";
}

.mdi-pencil-outline::before {
  content: "ﲒ";
}

.mdi-pencil-plus::before {
  content: "ﷇ";
}

.mdi-pencil-plus-outline::before {
  content: "﷈";
}

.mdi-pencil-remove::before {
  content: "﷉";
}

.mdi-pencil-remove-outline::before {
  content: "﷊";
}

.mdi-pencil-ruler::before {
  content: "\f037e";
}

.mdi-penguin::before {
  content: "ﻝ";
}

.mdi-pentagon::before {
  content: "\f6ff";
}

.mdi-pentagon-outline::before {
  content: "\f700";
}

.mdi-percent::before {
  content: "\f3f0";
}

.mdi-percent-outline::before {
  content: "\f02a3";
}

.mdi-periodic-table::before {
  content: "\f8b5";
}

.mdi-periodic-table-co::before {
  content: "\f0329";
}

.mdi-periodic-table-co2::before {
  content: "\f7e3";
}

.mdi-periscope::before {
  content: "\f747";
}

.mdi-perspective-less::before {
  content: "ﳿ";
}

.mdi-perspective-more::before {
  content: "ﴀ";
}

.mdi-pharmacy::before {
  content: "\f3f1";
}

.mdi-phone::before {
  content: "\f3f2";
}

.mdi-phone-alert::before {
  content: "Ｗ";
}

.mdi-phone-alert-outline::before {
  content: "\f01b9";
}

.mdi-phone-bluetooth::before {
  content: "\f3f3";
}

.mdi-phone-bluetooth-outline::before {
  content: "\f01ba";
}

.mdi-phone-cancel::before {
  content: "\f00e7";
}

.mdi-phone-cancel-outline::before {
  content: "\f01bb";
}

.mdi-phone-check::before {
  content: "\f01d4";
}

.mdi-phone-check-outline::before {
  content: "\f01d5";
}

.mdi-phone-classic::before {
  content: "\f602";
}

.mdi-phone-classic-off::before {
  content: "\f02a4";
}

.mdi-phone-forward::before {
  content: "\f3f4";
}

.mdi-phone-forward-outline::before {
  content: "\f01bc";
}

.mdi-phone-hangup::before {
  content: "\f3f5";
}

.mdi-phone-hangup-outline::before {
  content: "\f01bd";
}

.mdi-phone-in-talk::before {
  content: "\f3f6";
}

.mdi-phone-in-talk-outline::before {
  content: "\f01ad";
}

.mdi-phone-incoming::before {
  content: "\f3f7";
}

.mdi-phone-incoming-outline::before {
  content: "\f01be";
}

.mdi-phone-lock::before {
  content: "\f3f8";
}

.mdi-phone-lock-outline::before {
  content: "\f01bf";
}

.mdi-phone-log::before {
  content: "\f3f9";
}

.mdi-phone-log-outline::before {
  content: "\f01c0";
}

.mdi-phone-message::before {
  content: "\f01c1";
}

.mdi-phone-message-outline::before {
  content: "\f01c2";
}

.mdi-phone-minus::before {
  content: "\f658";
}

.mdi-phone-minus-outline::before {
  content: "\f01c3";
}

.mdi-phone-missed::before {
  content: "\f3fa";
}

.mdi-phone-missed-outline::before {
  content: "\f01d0";
}

.mdi-phone-off::before {
  content: "﷋";
}

.mdi-phone-off-outline::before {
  content: "\f01d1";
}

.mdi-phone-outgoing::before {
  content: "\f3fb";
}

.mdi-phone-outgoing-outline::before {
  content: "\f01c4";
}

.mdi-phone-outline::before {
  content: "﷌";
}

.mdi-phone-paused::before {
  content: "\f3fc";
}

.mdi-phone-paused-outline::before {
  content: "\f01c5";
}

.mdi-phone-plus::before {
  content: "\f659";
}

.mdi-phone-plus-outline::before {
  content: "\f01c6";
}

.mdi-phone-return::before {
  content: "\f82e";
}

.mdi-phone-return-outline::before {
  content: "\f01c7";
}

.mdi-phone-ring::before {
  content: "\f01d6";
}

.mdi-phone-ring-outline::before {
  content: "\f01d7";
}

.mdi-phone-rotate-landscape::before {
  content: "\f884";
}

.mdi-phone-rotate-portrait::before {
  content: "\f885";
}

.mdi-phone-settings::before {
  content: "\f3fd";
}

.mdi-phone-settings-outline::before {
  content: "\f01c8";
}

.mdi-phone-voip::before {
  content: "\f3fe";
}

.mdi-pi::before {
  content: "\f3ff";
}

.mdi-pi-box::before {
  content: "\f400";
}

.mdi-pi-hole::before {
  content: "﷍";
}

.mdi-piano::before {
  content: "\f67c";
}

.mdi-pickaxe::before {
  content: "\f8b6";
}

.mdi-picture-in-picture-bottom-right::before {
  content: "︺";
}

.mdi-picture-in-picture-bottom-right-outline::before {
  content: "︻";
}

.mdi-picture-in-picture-top-right::before {
  content: "︼";
}

.mdi-picture-in-picture-top-right-outline::before {
  content: "︽";
}

.mdi-pier::before {
  content: "\f886";
}

.mdi-pier-crane::before {
  content: "\f887";
}

.mdi-pig::before {
  content: "\f401";
}

.mdi-pig-variant::before {
  content: "\f0028";
}

.mdi-piggy-bank::before {
  content: "\f0029";
}

.mdi-pill::before {
  content: "\f402";
}

.mdi-pillar::before {
  content: "\f701";
}

.mdi-pin::before {
  content: "\f403";
}

.mdi-pin-off::before {
  content: "\f404";
}

.mdi-pin-off-outline::before {
  content: "勞";
}

.mdi-pin-outline::before {
  content: "擄";
}

.mdi-pine-tree::before {
  content: "\f405";
}

.mdi-pine-tree-box::before {
  content: "\f406";
}

.mdi-pinterest::before {
  content: "\f407";
}

.mdi-pinterest-box::before {
  content: "\f408";
}

.mdi-pinwheel::before {
  content: "䀹";
}

.mdi-pinwheel-outline::before {
  content: "𥉉";
}

.mdi-pipe::before {
  content: "\f7e4";
}

.mdi-pipe-disconnected::before {
  content: "\f7e5";
}

.mdi-pipe-leak::before {
  content: "\f888";
}

.mdi-pipe-wrench::before {
  content: "\f037f";
}

.mdi-pirate::before {
  content: "輻";
}

.mdi-pistol::before {
  content: "\f702";
}

.mdi-piston::before {
  content: "\f889";
}

.mdi-pizza::before {
  content: "\f409";
}

.mdi-play::before {
  content: "\f40a";
}

.mdi-play-box::before {
  content: "\f02a5";
}

.mdi-play-box-outline::before {
  content: "\f40b";
}

.mdi-play-circle::before {
  content: "\f40c";
}

.mdi-play-circle-outline::before {
  content: "\f40d";
}

.mdi-play-network::before {
  content: "\f88a";
}

.mdi-play-network-outline::before {
  content: "ﲓ";
}

.mdi-play-outline::before {
  content: "Ｘ";
}

.mdi-play-pause::before {
  content: "\f40e";
}

.mdi-play-protected-content::before {
  content: "\f40f";
}

.mdi-play-speed::before {
  content: "\f8fe";
}

.mdi-playlist-check::before {
  content: "\f5c7";
}

.mdi-playlist-edit::before {
  content: "\f8ff";
}

.mdi-playlist-minus::before {
  content: "\f410";
}

.mdi-playlist-music::before {
  content: "ﲔ";
}

.mdi-playlist-music-outline::before {
  content: "ﲕ";
}

.mdi-playlist-play::before {
  content: "\f411";
}

.mdi-playlist-plus::before {
  content: "\f412";
}

.mdi-playlist-remove::before {
  content: "\f413";
}

.mdi-playlist-star::before {
  content: "﷎";
}

.mdi-playstation::before {
  content: "\f414";
}

.mdi-plex::before {
  content: "\f6b9";
}

.mdi-plus::before {
  content: "\f415";
}

.mdi-plus-box::before {
  content: "\f416";
}

.mdi-plus-box-multiple::before {
  content: "\f334";
}

.mdi-plus-box-multiple-outline::before {
  content: "\f016e";
}

.mdi-plus-box-outline::before {
  content: "\f703";
}

.mdi-plus-circle::before {
  content: "\f417";
}

.mdi-plus-circle-multiple-outline::before {
  content: "\f418";
}

.mdi-plus-circle-outline::before {
  content: "\f419";
}

.mdi-plus-minus::before {
  content: "撚";
}

.mdi-plus-minus-box::before {
  content: "漣";
}

.mdi-plus-network::before {
  content: "\f41a";
}

.mdi-plus-network-outline::before {
  content: "ﲖ";
}

.mdi-plus-one::before {
  content: "\f41b";
}

.mdi-plus-outline::before {
  content: "\f704";
}

.mdi-plus-thick::before {
  content: "\f0217";
}

.mdi-pocket::before {
  content: "\f41c";
}

.mdi-podcast::before {
  content: "煉";
}

.mdi-podium::before {
  content: "ﴁ";
}

.mdi-podium-bronze::before {
  content: "ﴂ";
}

.mdi-podium-gold::before {
  content: "ﴃ";
}

.mdi-podium-silver::before {
  content: "ﴄ";
}

.mdi-point-of-sale::before {
  content: "ﵮ";
}

.mdi-pokeball::before {
  content: "\f41d";
}

.mdi-pokemon-go::before {
  content: "行";
}

.mdi-poker-chip::before {
  content: "\f82f";
}

.mdi-polaroid::before {
  content: "\f41e";
}

.mdi-police-badge::before {
  content: "\f0192";
}

.mdi-police-badge-outline::before {
  content: "\f0193";
}

.mdi-poll::before {
  content: "\f41f";
}

.mdi-poll-box::before {
  content: "\f420";
}

.mdi-poll-box-outline::before {
  content: "\f02a6";
}

.mdi-polymer::before {
  content: "\f421";
}

.mdi-pool::before {
  content: "\f606";
}

.mdi-popcorn::before {
  content: "\f422";
}

.mdi-post::before {
  content: "\f002a";
}

.mdi-post-outline::before {
  content: "\f002b";
}

.mdi-postage-stamp::before {
  content: "ﲗ";
}

.mdi-pot::before {
  content: "\f65a";
}

.mdi-pot-mix::before {
  content: "\f65b";
}

.mdi-pound::before {
  content: "\f423";
}

.mdi-pound-box::before {
  content: "\f424";
}

.mdi-pound-box-outline::before {
  content: "\f01aa";
}

.mdi-power::before {
  content: "\f425";
}

.mdi-power-cycle::before {
  content: "豈";
}

.mdi-power-off::before {
  content: "更";
}

.mdi-power-on::before {
  content: "車";
}

.mdi-power-plug::before {
  content: "\f6a4";
}

.mdi-power-plug-off::before {
  content: "\f6a5";
}

.mdi-power-settings::before {
  content: "\f426";
}

.mdi-power-sleep::before {
  content: "賈";
}

.mdi-power-socket::before {
  content: "\f427";
}

.mdi-power-socket-au::before {
  content: "滑";
}

.mdi-power-socket-de::before {
  content: "\f0132";
}

.mdi-power-socket-eu::before {
  content: "\f7e6";
}

.mdi-power-socket-fr::before {
  content: "\f0133";
}

.mdi-power-socket-jp::before {
  content: "\f0134";
}

.mdi-power-socket-uk::before {
  content: "\f7e7";
}

.mdi-power-socket-us::before {
  content: "\f7e8";
}

.mdi-power-standby::before {
  content: "串";
}

.mdi-powershell::before {
  content: "降";
}

.mdi-prescription::before {
  content: "\f705";
}

.mdi-presentation::before {
  content: "\f428";
}

.mdi-presentation-play::before {
  content: "\f429";
}

.mdi-printer::before {
  content: "\f42a";
}

.mdi-printer-3d::before {
  content: "\f42b";
}

.mdi-printer-3d-nozzle::before {
  content: "︾";
}

.mdi-printer-3d-nozzle-alert::before {
  content: "\f01eb";
}

.mdi-printer-3d-nozzle-alert-outline::before {
  content: "\f01ec";
}

.mdi-printer-3d-nozzle-outline::before {
  content: "︿";
}

.mdi-printer-alert::before {
  content: "\f42c";
}

.mdi-printer-check::before {
  content: "\f0171";
}

.mdi-printer-off::before {
  content: "﹀";
}

.mdi-printer-pos::before {
  content: "\f0079";
}

.mdi-printer-settings::before {
  content: "\f706";
}

.mdi-printer-wireless::before {
  content: "見";
}

.mdi-priority-high::before {
  content: "\f603";
}

.mdi-priority-low::before {
  content: "\f604";
}

.mdi-professional-hexagon::before {
  content: "\f42d";
}

.mdi-progress-alert::before {
  content: "ﲘ";
}

.mdi-progress-check::before {
  content: "璉";
}

.mdi-progress-clock::before {
  content: "秊";
}

.mdi-progress-close::before {
  content: "\f0135";
}

.mdi-progress-download::before {
  content: "練";
}

.mdi-progress-upload::before {
  content: "聯";
}

.mdi-progress-wrench::before {
  content: "ﲙ";
}

.mdi-projector::before {
  content: "\f42e";
}

.mdi-projector-screen::before {
  content: "\f42f";
}

.mdi-propane-tank::before {
  content: "\f0382";
}

.mdi-propane-tank-outline::before {
  content: "\f0383";
}

.mdi-protocol::before {
  content: "￹";
}

.mdi-publish::before {
  content: "\f6a6";
}

.mdi-pulse::before {
  content: "\f430";
}

.mdi-pumpkin::before {
  content: "ﮛ";
}

.mdi-purse::before {
  content: "Ｙ";
}

.mdi-purse-outline::before {
  content: "Ｚ";
}

.mdi-puzzle::before {
  content: "\f431";
}

.mdi-puzzle-outline::before {
  content: "贈";
}

.mdi-qi::before {
  content: "輦";
}

.mdi-qqchat::before {
  content: "\f605";
}

.mdi-qrcode::before {
  content: "\f432";
}

.mdi-qrcode-edit::before {
  content: "\f8b7";
}

.mdi-qrcode-minus::before {
  content: "\f01b7";
}

.mdi-qrcode-plus::before {
  content: "\f01b6";
}

.mdi-qrcode-remove::before {
  content: "\f01b8";
}

.mdi-qrcode-scan::before {
  content: "\f433";
}

.mdi-quadcopter::before {
  content: "\f434";
}

.mdi-quality-high::before {
  content: "\f435";
}

.mdi-quality-low::before {
  content: "廓";
}

.mdi-quality-medium::before {
  content: "兀";
}

.mdi-quicktime::before {
  content: "\f436";
}

.mdi-quora::before {
  content: "ﴅ";
}

.mdi-rabbit::before {
  content: "句";
}

.mdi-racing-helmet::before {
  content: "ﵯ";
}

.mdi-racquetball::before {
  content: "ﵰ";
}

.mdi-radar::before {
  content: "\f437";
}

.mdi-radiator::before {
  content: "\f438";
}

.mdi-radiator-disabled::before {
  content: "𥳐";
}

.mdi-radiator-off::before {
  content: "𧻓";
}

.mdi-radio::before {
  content: "\f439";
}

.mdi-radio-am::before {
  content: "ﲚ";
}

.mdi-radio-fm::before {
  content: "ﲛ";
}

.mdi-radio-handheld::before {
  content: "\f43a";
}

.mdi-radio-off::before {
  content: "\f0247";
}

.mdi-radio-tower::before {
  content: "\f43b";
}

.mdi-radioactive::before {
  content: "\f43c";
}

.mdi-radioactive-off::before {
  content: "ﻞ";
}

.mdi-radiobox-blank::before {
  content: "\f43d";
}

.mdi-radiobox-marked::before {
  content: "\f43e";
}

.mdi-radius::before {
  content: "ﲜ";
}

.mdi-radius-outline::before {
  content: "ﲝ";
}

.mdi-railroad-light::before {
  content: "［";
}

.mdi-raspberry-pi::before {
  content: "\f43f";
}

.mdi-ray-end::before {
  content: "\f440";
}

.mdi-ray-end-arrow::before {
  content: "\f441";
}

.mdi-ray-start::before {
  content: "\f442";
}

.mdi-ray-start-arrow::before {
  content: "\f443";
}

.mdi-ray-start-end::before {
  content: "\f444";
}

.mdi-ray-vertex::before {
  content: "\f445";
}

.mdi-react::before {
  content: "\f707";
}

.mdi-read::before {
  content: "\f447";
}

.mdi-receipt::before {
  content: "\f449";
}

.mdi-record::before {
  content: "\f44a";
}

.mdi-record-circle::before {
  content: "ﻟ";
}

.mdi-record-circle-outline::before {
  content: "ﻠ";
}

.mdi-record-player::before {
  content: "蓮";
}

.mdi-record-rec::before {
  content: "\f44b";
}

.mdi-rectangle::before {
  content: "﹁";
}

.mdi-rectangle-outline::before {
  content: "﹂";
}

.mdi-recycle::before {
  content: "\f44c";
}

.mdi-reddit::before {
  content: "\f44d";
}

.mdi-redhat::before {
  content: "\f0146";
}

.mdi-redo::before {
  content: "\f44e";
}

.mdi-redo-variant::before {
  content: "\f44f";
}

.mdi-reflect-horizontal::before {
  content: "嗀";
}

.mdi-reflect-vertical::before {
  content: "﨎";
}

.mdi-refresh::before {
  content: "\f450";
}

.mdi-refresh-circle::before {
  content: "\f03a2";
}

.mdi-regex::before {
  content: "\f451";
}

.mdi-registered-trademark::before {
  content: "辶";
}

.mdi-relative-scale::before {
  content: "\f452";
}

.mdi-reload::before {
  content: "\f453";
}

.mdi-reload-alert::before {
  content: "\f0136";
}

.mdi-reminder::before {
  content: "\f88b";
}

.mdi-remote::before {
  content: "\f454";
}

.mdi-remote-desktop::before {
  content: "\f8b8";
}

.mdi-remote-off::before {
  content: "ﻡ";
}

.mdi-remote-tv::before {
  content: "ﻢ";
}

.mdi-remote-tv-off::before {
  content: "ﻣ";
}

.mdi-rename-box::before {
  content: "\f455";
}

.mdi-reorder-horizontal::before {
  content: "\f687";
}

.mdi-reorder-vertical::before {
  content: "\f688";
}

.mdi-repeat::before {
  content: "\f456";
}

.mdi-repeat-off::before {
  content: "\f457";
}

.mdi-repeat-once::before {
  content: "\f458";
}

.mdi-replay::before {
  content: "\f459";
}

.mdi-reply::before {
  content: "\f45a";
}

.mdi-reply-all::before {
  content: "\f45b";
}

.mdi-reply-all-outline::before {
  content: "＼";
}

.mdi-reply-circle::before {
  content: "\f01d9";
}

.mdi-reply-outline::before {
  content: "］";
}

.mdi-reproduction::before {
  content: "\f45c";
}

.mdi-resistor::before {
  content: "ײַ";
}

.mdi-resistor-nodes::before {
  content: "ﬠ";
}

.mdi-resize::before {
  content: "逸";
}

.mdi-resize-bottom-right::before {
  content: "\f45d";
}

.mdi-responsive::before {
  content: "\f45e";
}

.mdi-restart::before {
  content: "\f708";
}

.mdi-restart-alert::before {
  content: "\f0137";
}

.mdi-restart-off::before {
  content: "ﵱ";
}

.mdi-restore::before {
  content: "連";
}

.mdi-restore-alert::before {
  content: "\f0138";
}

.mdi-rewind::before {
  content: "\f45f";
}

.mdi-rewind-10::before {
  content: "ﴆ";
}

.mdi-rewind-30::before {
  content: "ﵲ";
}

.mdi-rewind-5::before {
  content: "\f0224";
}

.mdi-rewind-outline::before {
  content: "\f709";
}

.mdi-rhombus::before {
  content: "\f70a";
}

.mdi-rhombus-medium::before {
  content: "﨏";
}

.mdi-rhombus-outline::before {
  content: "\f70b";
}

.mdi-rhombus-split::before {
  content: "塚";
}

.mdi-ribbon::before {
  content: "\f460";
}

.mdi-rice::before {
  content: "\f7e9";
}

.mdi-ring::before {
  content: "\f7ea";
}

.mdi-rivet::before {
  content: "﹃";
}

.mdi-road::before {
  content: "\f461";
}

.mdi-road-variant::before {
  content: "\f462";
}

.mdi-robber::before {
  content: "\f007a";
}

.mdi-robot::before {
  content: "\f6a8";
}

.mdi-robot-industrial::before {
  content: "ﬡ";
}

.mdi-robot-mower::before {
  content: "\f0222";
}

.mdi-robot-mower-outline::before {
  content: "\f021e";
}

.mdi-robot-vacuum::before {
  content: "\f70c";
}

.mdi-robot-vacuum-variant::before {
  content: "龜";
}

.mdi-rocket::before {
  content: "\f463";
}

.mdi-rodent::before {
  content: "\f0352";
}

.mdi-roller-skate::before {
  content: "ﴇ";
}

.mdi-rollerblade::before {
  content: "ﴈ";
}

.mdi-rollupjs::before {
  content: "ﮜ";
}

.mdi-roman-numeral-1::before {
  content: "\f00b3";
}

.mdi-roman-numeral-10::before {
  content: "\f00bc";
}

.mdi-roman-numeral-2::before {
  content: "\f00b4";
}

.mdi-roman-numeral-3::before {
  content: "\f00b5";
}

.mdi-roman-numeral-4::before {
  content: "\f00b6";
}

.mdi-roman-numeral-5::before {
  content: "\f00b7";
}

.mdi-roman-numeral-6::before {
  content: "\f00b8";
}

.mdi-roman-numeral-7::before {
  content: "\f00b9";
}

.mdi-roman-numeral-8::before {
  content: "\f00ba";
}

.mdi-roman-numeral-9::before {
  content: "\f00bb";
}

.mdi-room-service::before {
  content: "\f88c";
}

.mdi-room-service-outline::before {
  content: "ﵳ";
}

.mdi-rotate-3d::before {
  content: "ﻤ";
}

.mdi-rotate-3d-variant::before {
  content: "\f464";
}

.mdi-rotate-left::before {
  content: "\f465";
}

.mdi-rotate-left-variant::before {
  content: "\f466";
}

.mdi-rotate-orbit::before {
  content: "ﵴ";
}

.mdi-rotate-right::before {
  content: "\f467";
}

.mdi-rotate-right-variant::before {
  content: "\f468";
}

.mdi-rounded-corner::before {
  content: "\f607";
}

.mdi-router::before {
  content: "\f020d";
}

.mdi-router-wireless::before {
  content: "\f469";
}

.mdi-router-wireless-settings::before {
  content: "難";
}

.mdi-routes::before {
  content: "\f46a";
}

.mdi-routes-clock::before {
  content: "\f007b";
}

.mdi-rowing::before {
  content: "\f608";
}

.mdi-rss::before {
  content: "\f46b";
}

.mdi-rss-box::before {
  content: "\f46c";
}

.mdi-rss-off::before {
  content: "＾";
}

.mdi-ruby::before {
  content: "ﴉ";
}

.mdi-rugby::before {
  content: "ﵵ";
}

.mdi-ruler::before {
  content: "\f46d";
}

.mdi-ruler-square::before {
  content: "ﲞ";
}

.mdi-ruler-square-compass::before {
  content: "ﻛ";
}

.mdi-run::before {
  content: "\f70d";
}

.mdi-run-fast::before {
  content: "\f46e";
}

.mdi-rv-truck::before {
  content: "\f01ff";
}

.mdi-sack::before {
  content: "ﴊ";
}

.mdi-sack-percent::before {
  content: "ﴋ";
}

.mdi-safe::before {
  content: "響";
}

.mdi-safe-square::before {
  content: "\f02a7";
}

.mdi-safe-square-outline::before {
  content: "\f02a8";
}

.mdi-safety-goggles::before {
  content: "ﴌ";
}

.mdi-sailing::before {
  content: "ﻥ";
}

.mdi-sale::before {
  content: "\f46f";
}

.mdi-salesforce::before {
  content: "\f88d";
}

.mdi-sass::before {
  content: "\f7eb";
}

.mdi-satellite::before {
  content: "\f470";
}

.mdi-satellite-uplink::before {
  content: "龜";
}

.mdi-satellite-variant::before {
  content: "\f471";
}

.mdi-sausage::before {
  content: "\f8b9";
}

.mdi-saw-blade::before {
  content: "﹄";
}

.mdi-saxophone::before {
  content: "\f609";
}

.mdi-scale::before {
  content: "\f472";
}

.mdi-scale-balance::before {
  content: "\f5d1";
}

.mdi-scale-bathroom::before {
  content: "\f473";
}

.mdi-scale-off::before {
  content: "\f007c";
}

.mdi-scanner::before {
  content: "\f6aa";
}

.mdi-scanner-off::before {
  content: "契";
}

.mdi-scatter-plot::before {
  content: "ﻦ";
}

.mdi-scatter-plot-outline::before {
  content: "ﻧ";
}

.mdi-school::before {
  content: "\f474";
}

.mdi-school-outline::before {
  content: "\f01ab";
}

.mdi-scissors-cutting::before {
  content: "頻";
}

.mdi-scooter::before {
  content: "\f0214";
}

.mdi-scoreboard::before {
  content: "\f02a9";
}

.mdi-scoreboard-outline::before {
  content: "\f02aa";
}

.mdi-screen-rotation::before {
  content: "\f475";
}

.mdi-screen-rotation-lock::before {
  content: "\f476";
}

.mdi-screw-flat-top::before {
  content: "﷏";
}

.mdi-screw-lag::before {
  content: "﹔";
}

.mdi-screw-machine-flat-top::before {
  content: "﹕";
}

.mdi-screw-machine-round-top::before {
  content: "﹖";
}

.mdi-screw-round-top::before {
  content: "﹗";
}

.mdi-screwdriver::before {
  content: "\f477";
}

.mdi-script::before {
  content: "ﮝ";
}

.mdi-script-outline::before {
  content: "\f478";
}

.mdi-script-text::before {
  content: "ﮞ";
}

.mdi-script-text-outline::before {
  content: "ﮟ";
}

.mdi-sd::before {
  content: "\f479";
}

.mdi-seal::before {
  content: "\f47a";
}

.mdi-seal-variant::before {
  content: "￺";
}

.mdi-search-web::before {
  content: "\f70e";
}

.mdi-seat::before {
  content: "ﲟ";
}

.mdi-seat-flat::before {
  content: "\f47b";
}

.mdi-seat-flat-angled::before {
  content: "\f47c";
}

.mdi-seat-individual-suite::before {
  content: "\f47d";
}

.mdi-seat-legroom-extra::before {
  content: "\f47e";
}

.mdi-seat-legroom-normal::before {
  content: "\f47f";
}

.mdi-seat-legroom-reduced::before {
  content: "\f480";
}

.mdi-seat-outline::before {
  content: "ﲠ";
}

.mdi-seat-passenger::before {
  content: "\f0274";
}

.mdi-seat-recline-extra::before {
  content: "\f481";
}

.mdi-seat-recline-normal::before {
  content: "\f482";
}

.mdi-seatbelt::before {
  content: "ﲡ";
}

.mdi-security::before {
  content: "\f483";
}

.mdi-security-network::before {
  content: "\f484";
}

.mdi-seed::before {
  content: "﹅";
}

.mdi-seed-outline::before {
  content: "﹆";
}

.mdi-segment::before {
  content: "ﻨ";
}

.mdi-select::before {
  content: "\f485";
}

.mdi-select-all::before {
  content: "\f486";
}

.mdi-select-color::before {
  content: "ﴍ";
}

.mdi-select-compare::before {
  content: "齃";
}

.mdi-select-drag::before {
  content: "恵";
}

.mdi-select-group::before {
  content: "ﾟ";
}

.mdi-select-inverse::before {
  content: "\f487";
}

.mdi-select-marker::before {
  content: "\f02ab";
}

.mdi-select-multiple::before {
  content: "\f02ac";
}

.mdi-select-multiple-marker::before {
  content: "\f02ad";
}

.mdi-select-off::before {
  content: "\f488";
}

.mdi-select-place::before {
  content: "￻";
}

.mdi-select-search::before {
  content: "\f022f";
}

.mdi-selection::before {
  content: "\f489";
}

.mdi-selection-drag::before {
  content: "𤋮";
}

.mdi-selection-ellipse::before {
  content: "ﴎ";
}

.mdi-selection-ellipse-arrow-inside::before {
  content: "＿";
}

.mdi-selection-marker::before {
  content: "\f02ae";
}

.mdi-selection-multiple-marker::before {
  content: "\f02af";
}

.mdi-selection-mutliple::before {
  content: "\f02b0";
}

.mdi-selection-off::before {
  content: "\f776";
}

.mdi-selection-search::before {
  content: "\f0230";
}

.mdi-semantic-web::before {
  content: "\f0341";
}

.mdi-send::before {
  content: "\f48a";
}

.mdi-send-check::before {
  content: "\f018c";
}

.mdi-send-check-outline::before {
  content: "\f018d";
}

.mdi-send-circle::before {
  content: "﹘";
}

.mdi-send-circle-outline::before {
  content: "﹙";
}

.mdi-send-clock::before {
  content: "\f018e";
}

.mdi-send-clock-outline::before {
  content: "\f018f";
}

.mdi-send-lock::before {
  content: "\f7ec";
}

.mdi-send-lock-outline::before {
  content: "\f0191";
}

.mdi-send-outline::before {
  content: "\f0190";
}

.mdi-serial-port::before {
  content: "\f65c";
}

.mdi-server::before {
  content: "\f48b";
}

.mdi-server-minus::before {
  content: "\f48c";
}

.mdi-server-network::before {
  content: "\f48d";
}

.mdi-server-network-off::before {
  content: "\f48e";
}

.mdi-server-off::before {
  content: "\f48f";
}

.mdi-server-plus::before {
  content: "\f490";
}

.mdi-server-remove::before {
  content: "\f491";
}

.mdi-server-security::before {
  content: "\f492";
}

.mdi-set-all::before {
  content: "\f777";
}

.mdi-set-center::before {
  content: "\f778";
}

.mdi-set-center-right::before {
  content: "\f779";
}

.mdi-set-left::before {
  content: "\f77a";
}

.mdi-set-left-center::before {
  content: "\f77b";
}

.mdi-set-left-right::before {
  content: "\f77c";
}

.mdi-set-none::before {
  content: "\f77d";
}

.mdi-set-right::before {
  content: "\f77e";
}

.mdi-set-top-box::before {
  content: "咽";
}

.mdi-settings::before {
  content: "\f493";
}

.mdi-settings-box::before {
  content: "\f494";
}

.mdi-settings-helper::before {
  content: "舘";
}

.mdi-settings-outline::before {
  content: "\f8ba";
}

.mdi-settings-transfer::before {
  content: "\f007d";
}

.mdi-settings-transfer-outline::before {
  content: "\f007e";
}

.mdi-shaker::before {
  content: "\f0139";
}

.mdi-shaker-outline::before {
  content: "\f013a";
}

.mdi-shape::before {
  content: "\f830";
}

.mdi-shape-circle-plus::before {
  content: "\f65d";
}

.mdi-shape-outline::before {
  content: "\f831";
}

.mdi-shape-oval-plus::before {
  content: "\f0225";
}

.mdi-shape-plus::before {
  content: "\f495";
}

.mdi-shape-polygon-plus::before {
  content: "\f65e";
}

.mdi-shape-rectangle-plus::before {
  content: "\f65f";
}

.mdi-shape-square-plus::before {
  content: "\f660";
}

.mdi-share::before {
  content: "\f496";
}

.mdi-share-all::before {
  content: "\f021f";
}

.mdi-share-all-outline::before {
  content: "\f0220";
}

.mdi-share-circle::before {
  content: "\f01d8";
}

.mdi-share-off::before {
  content: "｀";
}

.mdi-share-off-outline::before {
  content: "ａ";
}

.mdi-share-outline::before {
  content: "櫓";
}

.mdi-share-variant::before {
  content: "\f497";
}

.mdi-sheep::before {
  content: "ﲢ";
}

.mdi-shield::before {
  content: "\f498";
}

.mdi-shield-account::before {
  content: "\f88e";
}

.mdi-shield-account-outline::before {
  content: "﨑";
}

.mdi-shield-airplane::before {
  content: "\f6ba";
}

.mdi-shield-airplane-outline::before {
  content: "ﲣ";
}

.mdi-shield-alert::before {
  content: "ﻩ";
}

.mdi-shield-alert-outline::before {
  content: "ﻪ";
}

.mdi-shield-car::before {
  content: "ﾠ";
}

.mdi-shield-check::before {
  content: "\f565";
}

.mdi-shield-check-outline::before {
  content: "ﲤ";
}

.mdi-shield-cross::before {
  content: "ﲥ";
}

.mdi-shield-cross-outline::before {
  content: "ﲦ";
}

.mdi-shield-edit::before {
  content: "\f01cb";
}

.mdi-shield-edit-outline::before {
  content: "\f01cc";
}

.mdi-shield-half::before {
  content: "\f038b";
}

.mdi-shield-half-full::before {
  content: "\f77f";
}

.mdi-shield-home::before {
  content: "\f689";
}

.mdi-shield-home-outline::before {
  content: "ﲧ";
}

.mdi-shield-key::before {
  content: "ﮠ";
}

.mdi-shield-key-outline::before {
  content: "ﮡ";
}

.mdi-shield-link-variant::before {
  content: "ﴏ";
}

.mdi-shield-link-variant-outline::before {
  content: "ﴐ";
}

.mdi-shield-lock::before {
  content: "列";
}

.mdi-shield-lock-outline::before {
  content: "ﲨ";
}

.mdi-shield-off::before {
  content: "劣";
}

.mdi-shield-off-outline::before {
  content: "鍊";
}

.mdi-shield-outline::before {
  content: "\f499";
}

.mdi-shield-plus::before {
  content: "龎";
}

.mdi-shield-plus-outline::before {
  content: "﫚";
}

.mdi-shield-refresh::before {
  content: "\f01cd";
}

.mdi-shield-refresh-outline::before {
  content: "\f01ce";
}

.mdi-shield-remove::before {
  content: "﫛";
}

.mdi-shield-remove-outline::before {
  content: "﫜";
}

.mdi-shield-search::before {
  content: "ﵶ";
}

.mdi-shield-star::before {
  content: "\f0166";
}

.mdi-shield-star-outline::before {
  content: "\f0167";
}

.mdi-shield-sun::before {
  content: "\f007f";
}

.mdi-shield-sun-outline::before {
  content: "\f0080";
}

.mdi-ship-wheel::before {
  content: "\f832";
}

.mdi-shoe-formal::before {
  content: "ﬢ";
}

.mdi-shoe-heel::before {
  content: "ﬣ";
}

.mdi-shoe-print::before {
  content: "﹚";
}

.mdi-shopify::before {
  content: "﫝";
}

.mdi-shopping::before {
  content: "\f49a";
}

.mdi-shopping-music::before {
  content: "\f49b";
}

.mdi-shopping-outline::before {
  content: "\f0200";
}

.mdi-shopping-search::before {
  content: "ﾡ";
}

.mdi-shovel::before {
  content: "\f70f";
}

.mdi-shovel-off::before {
  content: "\f710";
}

.mdi-shower::before {
  content: "烈";
}

.mdi-shower-head::before {
  content: "裂";
}

.mdi-shredder::before {
  content: "\f49c";
}

.mdi-shuffle::before {
  content: "\f49d";
}

.mdi-shuffle-disabled::before {
  content: "\f49e";
}

.mdi-shuffle-variant::before {
  content: "\f49f";
}

.mdi-shuriken::before {
  content: "\f03aa";
}

.mdi-sigma::before {
  content: "\f4a0";
}

.mdi-sigma-lower::before {
  content: "\f62b";
}

.mdi-sign-caution::before {
  content: "\f4a1";
}

.mdi-sign-direction::before {
  content: "\f780";
}

.mdi-sign-direction-minus::before {
  content: "\f0022";
}

.mdi-sign-direction-plus::before {
  content: "�";
}

.mdi-sign-direction-remove::before {
  content: "￾";
}

.mdi-sign-real-estate::before {
  content: "\f0143";
}

.mdi-sign-text::before {
  content: "\f781";
}

.mdi-signal::before {
  content: "\f4a2";
}

.mdi-signal-2g::before {
  content: "\f711";
}

.mdi-signal-3g::before {
  content: "\f712";
}

.mdi-signal-4g::before {
  content: "\f713";
}

.mdi-signal-5g::before {
  content: "﩮";
}

.mdi-signal-cellular-1::before {
  content: "\f8bb";
}

.mdi-signal-cellular-2::before {
  content: "\f8bc";
}

.mdi-signal-cellular-3::before {
  content: "\f8bd";
}

.mdi-signal-cellular-outline::before {
  content: "\f8be";
}

.mdi-signal-distance-variant::before {
  content: "﹇";
}

.mdi-signal-hspa::before {
  content: "\f714";
}

.mdi-signal-hspa-plus::before {
  content: "\f715";
}

.mdi-signal-off::before {
  content: "\f782";
}

.mdi-signal-variant::before {
  content: "\f60a";
}

.mdi-signature::before {
  content: "﹛";
}

.mdi-signature-freehand::before {
  content: "﹜";
}

.mdi-signature-image::before {
  content: "﹝";
}

.mdi-signature-text::before {
  content: "﹞";
}

.mdi-silo::before {
  content: "ﬤ";
}

.mdi-silverware::before {
  content: "\f4a3";
}

.mdi-silverware-clean::before {
  content: "￿";
}

.mdi-silverware-fork::before {
  content: "\f4a4";
}

.mdi-silverware-fork-knife::before {
  content: "﩯";
}

.mdi-silverware-spoon::before {
  content: "\f4a5";
}

.mdi-silverware-variant::before {
  content: "\f4a6";
}

.mdi-sim::before {
  content: "\f4a7";
}

.mdi-sim-alert::before {
  content: "\f4a8";
}

.mdi-sim-off::before {
  content: "\f4a9";
}

.mdi-simple-icons::before {
  content: "\f0348";
}

.mdi-sina-weibo::before {
  content: "﫞";
}

.mdi-sitemap::before {
  content: "\f4aa";
}

.mdi-skate::before {
  content: "ﴑ";
}

.mdi-skew-less::before {
  content: "ﴒ";
}

.mdi-skew-more::before {
  content: "ﴓ";
}

.mdi-ski::before {
  content: "\f032f";
}

.mdi-ski-cross-country::before {
  content: "\f0330";
}

.mdi-ski-water::before {
  content: "\f0331";
}

.mdi-skip-backward::before {
  content: "\f4ab";
}

.mdi-skip-backward-outline::before {
  content: "ｂ";
}

.mdi-skip-forward::before {
  content: "\f4ac";
}

.mdi-skip-forward-outline::before {
  content: "ｃ";
}

.mdi-skip-next::before {
  content: "\f4ad";
}

.mdi-skip-next-circle::before {
  content: "\f661";
}

.mdi-skip-next-circle-outline::before {
  content: "\f662";
}

.mdi-skip-next-outline::before {
  content: "ｄ";
}

.mdi-skip-previous::before {
  content: "\f4ae";
}

.mdi-skip-previous-circle::before {
  content: "\f663";
}

.mdi-skip-previous-circle-outline::before {
  content: "\f664";
}

.mdi-skip-previous-outline::before {
  content: "ｅ";
}

.mdi-skull::before {
  content: "\f68b";
}

.mdi-skull-crossbones::before {
  content: "ﮢ";
}

.mdi-skull-crossbones-outline::before {
  content: "ﮣ";
}

.mdi-skull-outline::before {
  content: "ﮤ";
}

.mdi-skype::before {
  content: "\f4af";
}

.mdi-skype-business::before {
  content: "\f4b0";
}

.mdi-slack::before {
  content: "\f4b1";
}

.mdi-slackware::before {
  content: "金";
}

.mdi-slash-forward::before {
  content: "\f0000";
}

.mdi-slash-forward-box::before {
  content: "\f0001";
}

.mdi-sleep::before {
  content: "\f4b2";
}

.mdi-sleep-off::before {
  content: "\f4b3";
}

.mdi-slope-downhill::before {
  content: "﹟";
}

.mdi-slope-uphill::before {
  content: "﹠";
}

.mdi-slot-machine::before {
  content: "\f013f";
}

.mdi-slot-machine-outline::before {
  content: "\f0140";
}

.mdi-smart-card::before {
  content: "\f00e8";
}

.mdi-smart-card-outline::before {
  content: "\f00e9";
}

.mdi-smart-card-reader::before {
  content: "\f00ea";
}

.mdi-smart-card-reader-outline::before {
  content: "\f00eb";
}

.mdi-smog::before {
  content: "並";
}

.mdi-smoke-detector::before {
  content: "\f392";
}

.mdi-smoking::before {
  content: "\f4b4";
}

.mdi-smoking-off::before {
  content: "\f4b5";
}

.mdi-snapchat::before {
  content: "\f4b6";
}

.mdi-snowboard::before {
  content: "\f0332";
}

.mdi-snowflake::before {
  content: "\f716";
}

.mdi-snowflake-alert::before {
  content: "ｆ";
}

.mdi-snowflake-melt::before {
  content: "\f02f6";
}

.mdi-snowflake-variant::before {
  content: "ｇ";
}

.mdi-snowman::before {
  content: "\f4b7";
}

.mdi-soccer::before {
  content: "\f4b8";
}

.mdi-soccer-field::before {
  content: "\f833";
}

.mdi-sofa::before {
  content: "\f4b9";
}

.mdi-solar-panel::before {
  content: "ﵷ";
}

.mdi-solar-panel-large::before {
  content: "ﵸ";
}

.mdi-solar-power::before {
  content: "况";
}

.mdi-soldering-iron::before {
  content: "\f00bd";
}

.mdi-solid::before {
  content: "\f68c";
}

.mdi-sort::before {
  content: "\f4ba";
}

.mdi-sort-alphabetical::before {
  content: "\f4bb";
}

.mdi-sort-alphabetical-ascending::before {
  content: "\f0173";
}

.mdi-sort-alphabetical-descending::before {
  content: "\f0174";
}

.mdi-sort-ascending::before {
  content: "\f4bc";
}

.mdi-sort-descending::before {
  content: "\f4bd";
}

.mdi-sort-numeric::before {
  content: "\f4be";
}

.mdi-sort-variant::before {
  content: "\f4bf";
}

.mdi-sort-variant-lock::before {
  content: "ﲩ";
}

.mdi-sort-variant-lock-open::before {
  content: "ﲪ";
}

.mdi-sort-variant-remove::before {
  content: "\f0172";
}

.mdi-soundcloud::before {
  content: "\f4c0";
}

.mdi-source-branch::before {
  content: "\f62c";
}

.mdi-source-commit::before {
  content: "\f717";
}

.mdi-source-commit-end::before {
  content: "\f718";
}

.mdi-source-commit-end-local::before {
  content: "\f719";
}

.mdi-source-commit-local::before {
  content: "\f71a";
}

.mdi-source-commit-next-local::before {
  content: "\f71b";
}

.mdi-source-commit-start::before {
  content: "\f71c";
}

.mdi-source-commit-start-next-local::before {
  content: "\f71d";
}

.mdi-source-fork::before {
  content: "\f4c1";
}

.mdi-source-merge::before {
  content: "\f62d";
}

.mdi-source-pull::before {
  content: "\f4c2";
}

.mdi-source-repository::before {
  content: "ﲫ";
}

.mdi-source-repository-multiple::before {
  content: "ﲬ";
}

.mdi-soy-sauce::before {
  content: "\f7ed";
}

.mdi-spa::before {
  content: "ﲭ";
}

.mdi-spa-outline::before {
  content: "ﲮ";
}

.mdi-space-invaders::before {
  content: "ﮥ";
}

.mdi-space-station::before {
  content: "\f03ae";
}

.mdi-spade::before {
  content: "﹈";
}

.mdi-speaker::before {
  content: "\f4c3";
}

.mdi-speaker-bluetooth::before {
  content: "說";
}

.mdi-speaker-multiple::before {
  content: "ﴔ";
}

.mdi-speaker-off::before {
  content: "\f4c4";
}

.mdi-speaker-wireless::before {
  content: "\f71e";
}

.mdi-speedometer::before {
  content: "\f4c5";
}

.mdi-speedometer-medium::before {
  content: "ﾢ";
}

.mdi-speedometer-slow::before {
  content: "ﾣ";
}

.mdi-spellcheck::before {
  content: "\f4c6";
}

.mdi-spider::before {
  content: "\f0215";
}

.mdi-spider-thread::before {
  content: "\f0216";
}

.mdi-spider-web::before {
  content: "ﮦ";
}

.mdi-spotify::before {
  content: "\f4c7";
}

.mdi-spotlight::before {
  content: "\f4c8";
}

.mdi-spotlight-beam::before {
  content: "\f4c9";
}

.mdi-spray::before {
  content: "\f665";
}

.mdi-spray-bottle::before {
  content: "﫟";
}

.mdi-sprinkler::before {
  content: "\f0081";
}

.mdi-sprinkler-variant::before {
  content: "\f0082";
}

.mdi-sprout::before {
  content: "﹉";
}

.mdi-sprout-outline::before {
  content: "﹊";
}

.mdi-square::before {
  content: "\f763";
}

.mdi-square-edit-outline::before {
  content: "喇";
}

.mdi-square-inc::before {
  content: "\f4ca";
}

.mdi-square-inc-cash::before {
  content: "\f4cb";
}

.mdi-square-medium::before {
  content: "晴";
}

.mdi-square-medium-outline::before {
  content: "﨓";
}

.mdi-square-off::before {
  content: "\f0319";
}

.mdi-square-off-outline::before {
  content: "\f031a";
}

.mdi-square-outline::before {
  content: "\f762";
}

.mdi-square-root::before {
  content: "\f783";
}

.mdi-square-root-box::before {
  content: "廉";
}

.mdi-square-small::before {
  content: "﨔";
}

.mdi-squeegee::before {
  content: "﫠";
}

.mdi-ssh::before {
  content: "\f8bf";
}

.mdi-stack-exchange::before {
  content: "\f60b";
}

.mdi-stack-overflow::before {
  content: "\f4cc";
}

.mdi-stackpath::before {
  content: "\f359";
}

.mdi-stadium::before {
  content: "\f001a";
}

.mdi-stadium-variant::before {
  content: "\f71f";
}

.mdi-stairs::before {
  content: "\f4cd";
}

.mdi-stairs-down::before {
  content: "\f02e9";
}

.mdi-stairs-up::before {
  content: "\f02e8";
}

.mdi-stamper::before {
  content: "ﴕ";
}

.mdi-standard-definition::before {
  content: "\f7ee";
}

.mdi-star::before {
  content: "\f4ce";
}

.mdi-star-box::before {
  content: "全";
}

.mdi-star-box-multiple::before {
  content: "\f02b1";
}

.mdi-star-box-multiple-outline::before {
  content: "\f02b2";
}

.mdi-star-box-outline::before {
  content: "侀";
}

.mdi-star-circle::before {
  content: "\f4cf";
}

.mdi-star-circle-outline::before {
  content: "念";
}

.mdi-star-face::before {
  content: "捻";
}

.mdi-star-four-points::before {
  content: "﫡";
}

.mdi-star-four-points-outline::before {
  content: "﫢";
}

.mdi-star-half::before {
  content: "\f4d0";
}

.mdi-star-off::before {
  content: "\f4d1";
}

.mdi-star-outline::before {
  content: "\f4d2";
}

.mdi-star-three-points::before {
  content: "﫣";
}

.mdi-star-three-points-outline::before {
  content: "﫤";
}

.mdi-state-machine::before {
  content: "\f021a";
}

.mdi-steam::before {
  content: "\f4d3";
}

.mdi-steam-box::before {
  content: "奈";
}

.mdi-steering::before {
  content: "\f4d4";
}

.mdi-steering-off::before {
  content: "懶";
}

.mdi-step-backward::before {
  content: "\f4d5";
}

.mdi-step-backward-2::before {
  content: "\f4d6";
}

.mdi-step-forward::before {
  content: "\f4d7";
}

.mdi-step-forward-2::before {
  content: "\f4d8";
}

.mdi-stethoscope::before {
  content: "\f4d9";
}

.mdi-sticker::before {
  content: "\f038f";
}

.mdi-sticker-alert::before {
  content: "\f0390";
}

.mdi-sticker-alert-outline::before {
  content: "\f0391";
}

.mdi-sticker-check::before {
  content: "\f0392";
}

.mdi-sticker-check-outline::before {
  content: "\f0393";
}

.mdi-sticker-circle-outline::before {
  content: "\f5d0";
}

.mdi-sticker-emoji::before {
  content: "\f784";
}

.mdi-sticker-minus::before {
  content: "\f0394";
}

.mdi-sticker-minus-outline::before {
  content: "\f0395";
}

.mdi-sticker-outline::before {
  content: "\f0396";
}

.mdi-sticker-plus::before {
  content: "\f0397";
}

.mdi-sticker-plus-outline::before {
  content: "\f0398";
}

.mdi-sticker-remove::before {
  content: "\f0399";
}

.mdi-sticker-remove-outline::before {
  content: "\f039a";
}

.mdi-stocking::before {
  content: "\f4da";
}

.mdi-stomach::before {
  content: "\f00be";
}

.mdi-stop::before {
  content: "\f4db";
}

.mdi-stop-circle::before {
  content: "\f666";
}

.mdi-stop-circle-outline::before {
  content: "\f667";
}

.mdi-store::before {
  content: "\f4dc";
}

.mdi-store-24-hour::before {
  content: "\f4dd";
}

.mdi-store-outline::before {
  content: "\f038c";
}

.mdi-storefront::before {
  content: "\f00ec";
}

.mdi-stove::before {
  content: "\f4de";
}

.mdi-strategy::before {
  content: "\f0201";
}

.mdi-strava::before {
  content: "ﬥ";
}

.mdi-stretch-to-page::before {
  content: "ｈ";
}

.mdi-stretch-to-page-outline::before {
  content: "ｉ";
}

.mdi-string-lights::before {
  content: "\f02e5";
}

.mdi-string-lights-off::before {
  content: "\f02e6";
}

.mdi-subdirectory-arrow-left::before {
  content: "\f60c";
}

.mdi-subdirectory-arrow-right::before {
  content: "\f60d";
}

.mdi-subtitles::before {
  content: "凞";
}

.mdi-subtitles-outline::before {
  content: "猪";
}

.mdi-subway::before {
  content: "\f6ab";
}

.mdi-subway-alert-variant::before {
  content: "ﵹ";
}

.mdi-subway-variant::before {
  content: "\f4df";
}

.mdi-summit::before {
  content: "\f785";
}

.mdi-sunglasses::before {
  content: "\f4e0";
}

.mdi-surround-sound::before {
  content: "\f5c5";
}

.mdi-surround-sound-2-0::before {
  content: "\f7ef";
}

.mdi-surround-sound-3-1::before {
  content: "\f7f0";
}

.mdi-surround-sound-5-1::before {
  content: "\f7f1";
}

.mdi-surround-sound-7-1::before {
  content: "\f7f2";
}

.mdi-svg::before {
  content: "\f720";
}

.mdi-swap-horizontal::before {
  content: "\f4e1";
}

.mdi-swap-horizontal-bold::before {
  content: "ﮩ";
}

.mdi-swap-horizontal-circle::before {
  content: "\f0002";
}

.mdi-swap-horizontal-circle-outline::before {
  content: "\f0003";
}

.mdi-swap-horizontal-variant::before {
  content: "\f8c0";
}

.mdi-swap-vertical::before {
  content: "\f4e2";
}

.mdi-swap-vertical-bold::before {
  content: "ﮪ";
}

.mdi-swap-vertical-circle::before {
  content: "\f0004";
}

.mdi-swap-vertical-circle-outline::before {
  content: "\f0005";
}

.mdi-swap-vertical-variant::before {
  content: "\f8c1";
}

.mdi-swim::before {
  content: "\f4e3";
}

.mdi-switch::before {
  content: "\f4e4";
}

.mdi-sword::before {
  content: "\f4e5";
}

.mdi-sword-cross::before {
  content: "\f786";
}

.mdi-syllabary-hangul::before {
  content: "\f035e";
}

.mdi-syllabary-hiragana::before {
  content: "\f035f";
}

.mdi-syllabary-katakana::before {
  content: "\f0360";
}

.mdi-syllabary-katakana-half-width::before {
  content: "\f0361";
}

.mdi-symfony::before {
  content: "﫥";
}

.mdi-sync::before {
  content: "\f4e6";
}

.mdi-sync-alert::before {
  content: "\f4e7";
}

.mdi-sync-circle::before {
  content: "\f03a3";
}

.mdi-sync-off::before {
  content: "\f4e8";
}

.mdi-tab::before {
  content: "\f4e9";
}

.mdi-tab-minus::before {
  content: "ﬦ";
}

.mdi-tab-plus::before {
  content: "\f75b";
}

.mdi-tab-remove::before {
  content: "ﬧ";
}

.mdi-tab-unselected::before {
  content: "\f4ea";
}

.mdi-table::before {
  content: "\f4eb";
}

.mdi-table-border::before {
  content: "益";
}

.mdi-table-chair::before {
  content: "\f0083";
}

.mdi-table-column::before {
  content: "\f834";
}

.mdi-table-column-plus-after::before {
  content: "\f4ec";
}

.mdi-table-column-plus-before::before {
  content: "\f4ed";
}

.mdi-table-column-remove::before {
  content: "\f4ee";
}

.mdi-table-column-width::before {
  content: "\f4ef";
}

.mdi-table-edit::before {
  content: "\f4f0";
}

.mdi-table-eye::before {
  content: "\f00bf";
}

.mdi-table-headers-eye::before {
  content: "\f0248";
}

.mdi-table-headers-eye-off::before {
  content: "\f0249";
}

.mdi-table-large::before {
  content: "\f4f1";
}

.mdi-table-large-plus::before {
  content: "ﾤ";
}

.mdi-table-large-remove::before {
  content: "ﾥ";
}

.mdi-table-merge-cells::before {
  content: "殮";
}

.mdi-table-of-contents::before {
  content: "\f835";
}

.mdi-table-plus::before {
  content: "充";
}

.mdi-table-remove::before {
  content: "冀";
}

.mdi-table-row::before {
  content: "\f836";
}

.mdi-table-row-height::before {
  content: "\f4f2";
}

.mdi-table-row-plus-after::before {
  content: "\f4f3";
}

.mdi-table-row-plus-before::before {
  content: "\f4f4";
}

.mdi-table-row-remove::before {
  content: "\f4f5";
}

.mdi-table-search::before {
  content: "癩";
}

.mdi-table-settings::before {
  content: "\f837";
}

.mdi-table-tennis::before {
  content: "﹋";
}

.mdi-tablet::before {
  content: "\f4f6";
}

.mdi-tablet-android::before {
  content: "\f4f7";
}

.mdi-tablet-cellphone::before {
  content: "簾";
}

.mdi-tablet-dashboard::before {
  content: "ﻫ";
}

.mdi-tablet-ipad::before {
  content: "\f4f8";
}

.mdi-taco::before {
  content: "\f761";
}

.mdi-tag::before {
  content: "\f4f9";
}

.mdi-tag-faces::before {
  content: "\f4fa";
}

.mdi-tag-heart::before {
  content: "\f68a";
}

.mdi-tag-heart-outline::before {
  content: "ﮫ";
}

.mdi-tag-minus::before {
  content: "羅";
}

.mdi-tag-minus-outline::before {
  content: "\f024a";
}

.mdi-tag-multiple::before {
  content: "\f4fb";
}

.mdi-tag-multiple-outline::before {
  content: "\f0322";
}

.mdi-tag-off::before {
  content: "\f024b";
}

.mdi-tag-off-outline::before {
  content: "\f024c";
}

.mdi-tag-outline::before {
  content: "\f4fc";
}

.mdi-tag-plus::before {
  content: "\f721";
}

.mdi-tag-plus-outline::before {
  content: "\f024d";
}

.mdi-tag-remove::before {
  content: "\f722";
}

.mdi-tag-remove-outline::before {
  content: "\f024e";
}

.mdi-tag-text::before {
  content: "\f024f";
}

.mdi-tag-text-outline::before {
  content: "\f4fd";
}

.mdi-tank::before {
  content: "ﴖ";
}

.mdi-tanker-truck::before {
  content: "\f0006";
}

.mdi-tape-measure::before {
  content: "ﬨ";
}

.mdi-target::before {
  content: "\f4fe";
}

.mdi-target-account::before {
  content: "ﮬ";
}

.mdi-target-variant::before {
  content: "勇";
}

.mdi-taxi::before {
  content: "\f4ff";
}

.mdi-tea::before {
  content: "ﵺ";
}

.mdi-tea-outline::before {
  content: "ﵻ";
}

.mdi-teach::before {
  content: "\f88f";
}

.mdi-teamviewer::before {
  content: "\f500";
}

.mdi-telegram::before {
  content: "\f501";
}

.mdi-telescope::before {
  content: "﬩";
}

.mdi-television::before {
  content: "\f502";
}

.mdi-television-ambient-light::before {
  content: "\f0381";
}

.mdi-television-box::before {
  content: "\f838";
}

.mdi-television-classic::before {
  content: "\f7f3";
}

.mdi-television-classic-off::before {
  content: "\f839";
}

.mdi-television-clean::before {
  content: "\f013b";
}

.mdi-television-guide::before {
  content: "\f503";
}

.mdi-television-off::before {
  content: "\f83a";
}

.mdi-television-pause::before {
  content: "ﾦ";
}

.mdi-television-play::before {
  content: "ﻬ";
}

.mdi-television-stop::before {
  content: "ﾧ";
}

.mdi-temperature-celsius::before {
  content: "\f504";
}

.mdi-temperature-fahrenheit::before {
  content: "\f505";
}

.mdi-temperature-kelvin::before {
  content: "\f506";
}

.mdi-tennis::before {
  content: "ﵼ";
}

.mdi-tennis-ball::before {
  content: "\f507";
}

.mdi-tent::before {
  content: "\f508";
}

.mdi-terraform::before {
  content: "\f0084";
}

.mdi-terrain::before {
  content: "\f509";
}

.mdi-test-tube::before {
  content: "\f668";
}

.mdi-test-tube-empty::before {
  content: "蘿";
}

.mdi-test-tube-off::before {
  content: "螺";
}

.mdi-text::before {
  content: "獵";
}

.mdi-text-recognition::before {
  content: "\f0168";
}

.mdi-text-shadow::before {
  content: "\f669";
}

.mdi-text-short::before {
  content: "令";
}

.mdi-text-subject::before {
  content: "囹";
}

.mdi-text-to-speech::before {
  content: "\f50a";
}

.mdi-text-to-speech-off::before {
  content: "\f50b";
}

.mdi-textarea::before {
  content: "\f00c0";
}

.mdi-textbox::before {
  content: "\f60e";
}

.mdi-textbox-lock::before {
  content: "\f0388";
}

.mdi-textbox-password::before {
  content: "\f7f4";
}

.mdi-texture::before {
  content: "\f50c";
}

.mdi-texture-box::before {
  content: "\f0007";
}

.mdi-theater::before {
  content: "\f50d";
}

.mdi-theme-light-dark::before {
  content: "\f50e";
}

.mdi-thermometer::before {
  content: "\f50f";
}

.mdi-thermometer-alert::before {
  content: "﹡";
}

.mdi-thermometer-chevron-down::before {
  content: "﹢";
}

.mdi-thermometer-chevron-up::before {
  content: "﹣";
}

.mdi-thermometer-high::before {
  content: "\f00ed";
}

.mdi-thermometer-lines::before {
  content: "\f510";
}

.mdi-thermometer-low::before {
  content: "\f00ee";
}

.mdi-thermometer-minus::before {
  content: "﹤";
}

.mdi-thermometer-plus::before {
  content: "﹥";
}

.mdi-thermostat::before {
  content: "\f393";
}

.mdi-thermostat-box::before {
  content: "\f890";
}

.mdi-thought-bubble::before {
  content: "\f7f5";
}

.mdi-thought-bubble-outline::before {
  content: "\f7f6";
}

.mdi-thumb-down::before {
  content: "\f511";
}

.mdi-thumb-down-outline::before {
  content: "\f512";
}

.mdi-thumb-up::before {
  content: "\f513";
}

.mdi-thumb-up-outline::before {
  content: "\f514";
}

.mdi-thumbs-up-down::before {
  content: "\f515";
}

.mdi-ticket::before {
  content: "\f516";
}

.mdi-ticket-account::before {
  content: "\f517";
}

.mdi-ticket-confirmation::before {
  content: "\f518";
}

.mdi-ticket-outline::before {
  content: "裸";
}

.mdi-ticket-percent::before {
  content: "\f723";
}

.mdi-tie::before {
  content: "\f519";
}

.mdi-tilde::before {
  content: "\f724";
}

.mdi-timelapse::before {
  content: "\f51a";
}

.mdi-timeline::before {
  content: "ﮭ";
}

.mdi-timeline-alert::before {
  content: "ﾲ";
}

.mdi-timeline-alert-outline::before {
  content: "ﾵ";
}

.mdi-timeline-clock::before {
  content: "\f0226";
}

.mdi-timeline-clock-outline::before {
  content: "\f0227";
}

.mdi-timeline-help::before {
  content: "ﾶ";
}

.mdi-timeline-help-outline::before {
  content: "ﾷ";
}

.mdi-timeline-outline::before {
  content: "ﮮ";
}

.mdi-timeline-plus::before {
  content: "ﾳ";
}

.mdi-timeline-plus-outline::before {
  content: "ﾴ";
}

.mdi-timeline-text::before {
  content: "ﮯ";
}

.mdi-timeline-text-outline::before {
  content: "ﮰ";
}

.mdi-timer::before {
  content: "\f51b";
}

.mdi-timer-10::before {
  content: "\f51c";
}

.mdi-timer-3::before {
  content: "\f51d";
}

.mdi-timer-off::before {
  content: "\f51e";
}

.mdi-timer-sand::before {
  content: "\f51f";
}

.mdi-timer-sand-empty::before {
  content: "\f6ac";
}

.mdi-timer-sand-full::before {
  content: "\f78b";
}

.mdi-timetable::before {
  content: "\f520";
}

.mdi-toaster::before {
  content: "\f0085";
}

.mdi-toaster-off::before {
  content: "\f01e2";
}

.mdi-toaster-oven::before {
  content: "ﲯ";
}

.mdi-toggle-switch::before {
  content: "\f521";
}

.mdi-toggle-switch-off::before {
  content: "\f522";
}

.mdi-toggle-switch-off-outline::before {
  content: "礼";
}

.mdi-toggle-switch-outline::before {
  content: "神";
}

.mdi-toilet::before {
  content: "寧";
}

.mdi-toolbox::before {
  content: "嶺";
}

.mdi-toolbox-outline::before {
  content: "怜";
}

.mdi-tools::before {
  content: "\f0086";
}

.mdi-tooltip::before {
  content: "\f523";
}

.mdi-tooltip-account::before {
  content: "\f00c";
}

.mdi-tooltip-edit::before {
  content: "\f524";
}

.mdi-tooltip-edit-outline::before {
  content: "\f02f0";
}

.mdi-tooltip-image::before {
  content: "\f525";
}

.mdi-tooltip-image-outline::before {
  content: "ﮱ";
}

.mdi-tooltip-outline::before {
  content: "\f526";
}

.mdi-tooltip-plus::before {
  content: "﮲";
}

.mdi-tooltip-plus-outline::before {
  content: "\f527";
}

.mdi-tooltip-text::before {
  content: "\f528";
}

.mdi-tooltip-text-outline::before {
  content: "﮳";
}

.mdi-tooth::before {
  content: "\f8c2";
}

.mdi-tooth-outline::before {
  content: "\f529";
}

.mdi-toothbrush::before {
  content: "\f0154";
}

.mdi-toothbrush-electric::before {
  content: "\f0157";
}

.mdi-toothbrush-paste::before {
  content: "\f0155";
}

.mdi-tor::before {
  content: "\f52a";
}

.mdi-tortoise::before {
  content: "ﴗ";
}

.mdi-toslink::before {
  content: "\f02e3";
}

.mdi-tournament::before {
  content: "玲";
}

.mdi-tower-beach::before {
  content: "\f680";
}

.mdi-tower-fire::before {
  content: "\f681";
}

.mdi-towing::before {
  content: "\f83b";
}

.mdi-toy-brick::before {
  content: "\f02b3";
}

.mdi-toy-brick-marker::before {
  content: "\f02b4";
}

.mdi-toy-brick-marker-outline::before {
  content: "\f02b5";
}

.mdi-toy-brick-minus::before {
  content: "\f02b6";
}

.mdi-toy-brick-minus-outline::before {
  content: "\f02b7";
}

.mdi-toy-brick-outline::before {
  content: "\f02b8";
}

.mdi-toy-brick-plus::before {
  content: "\f02b9";
}

.mdi-toy-brick-plus-outline::before {
  content: "\f02ba";
}

.mdi-toy-brick-remove::before {
  content: "\f02bb";
}

.mdi-toy-brick-remove-outline::before {
  content: "\f02bc";
}

.mdi-toy-brick-search::before {
  content: "\f02bd";
}

.mdi-toy-brick-search-outline::before {
  content: "\f02be";
}

.mdi-track-light::before {
  content: "邏";
}

.mdi-trackpad::before {
  content: "\f7f7";
}

.mdi-trackpad-lock::before {
  content: "爐";
}

.mdi-tractor::before {
  content: "\f891";
}

.mdi-trademark::before {
  content: "勺";
}

.mdi-traffic-cone::before {
  content: "\f03a7";
}

.mdi-traffic-light::before {
  content: "\f52b";
}

.mdi-train::before {
  content: "\f52c";
}

.mdi-train-car::before {
  content: "﮴";
}

.mdi-train-variant::before {
  content: "\f8c3";
}

.mdi-tram::before {
  content: "\f52d";
}

.mdi-tram-side::before {
  content: "\f0008";
}

.mdi-transcribe::before {
  content: "\f52e";
}

.mdi-transcribe-close::before {
  content: "\f52f";
}

.mdi-transfer::before {
  content: "\f0087";
}

.mdi-transfer-down::before {
  content: "ﵽ";
}

.mdi-transfer-left::before {
  content: "ﵾ";
}

.mdi-transfer-right::before {
  content: "\f530";
}

.mdi-transfer-up::before {
  content: "ﵿ";
}

.mdi-transit-connection::before {
  content: "ﴘ";
}

.mdi-transit-connection-variant::before {
  content: "ﴙ";
}

.mdi-transit-detour::before {
  content: "ﾨ";
}

.mdi-transit-transfer::before {
  content: "\f6ad";
}

.mdi-transition::before {
  content: "樂";
}

.mdi-transition-masked::before {
  content: "洛";
}

.mdi-translate::before {
  content: "\f5ca";
}

.mdi-translate-off::before {
  content: "﹦";
}

.mdi-transmission-tower::before {
  content: "ﴚ";
}

.mdi-trash-can::before {
  content: "喝";
}

.mdi-trash-can-outline::before {
  content: "啕";
}

.mdi-tray::before {
  content: "\f02bf";
}

.mdi-tray-alert::before {
  content: "\f02c0";
}

.mdi-tray-full::before {
  content: "\f02c1";
}

.mdi-tray-minus::before {
  content: "\f02c2";
}

.mdi-tray-plus::before {
  content: "\f02c3";
}

.mdi-tray-remove::before {
  content: "\f02c4";
}

.mdi-treasure-chest::before {
  content: "\f725";
}

.mdi-tree::before {
  content: "\f531";
}

.mdi-tree-outline::before {
  content: "﹌";
}

.mdi-trello::before {
  content: "\f532";
}

.mdi-trending-down::before {
  content: "\f533";
}

.mdi-trending-neutral::before {
  content: "\f534";
}

.mdi-trending-up::before {
  content: "\f535";
}

.mdi-triangle::before {
  content: "\f536";
}

.mdi-triangle-outline::before {
  content: "\f537";
}

.mdi-triforce::before {
  content: "﮵";
}

.mdi-trophy::before {
  content: "\f538";
}

.mdi-trophy-award::before {
  content: "\f539";
}

.mdi-trophy-broken::before {
  content: "ﶀ";
}

.mdi-trophy-outline::before {
  content: "\f53a";
}

.mdi-trophy-variant::before {
  content: "\f53b";
}

.mdi-trophy-variant-outline::before {
  content: "\f53c";
}

.mdi-truck::before {
  content: "\f53d";
}

.mdi-truck-check::before {
  content: "ﲰ";
}

.mdi-truck-check-outline::before {
  content: "\f02c5";
}

.mdi-truck-delivery::before {
  content: "\f53e";
}

.mdi-truck-delivery-outline::before {
  content: "\f02c6";
}

.mdi-truck-fast::before {
  content: "\f787";
}

.mdi-truck-fast-outline::before {
  content: "\f02c7";
}

.mdi-truck-outline::before {
  content: "\f02c8";
}

.mdi-truck-trailer::before {
  content: "\f726";
}

.mdi-trumpet::before {
  content: "\f00c1";
}

.mdi-tshirt-crew::before {
  content: "喙";
}

.mdi-tshirt-crew-outline::before {
  content: "\f53f";
}

.mdi-tshirt-v::before {
  content: "嗢";
}

.mdi-tshirt-v-outline::before {
  content: "\f540";
}

.mdi-tumble-dryer::before {
  content: "烙";
}

.mdi-tumble-dryer-alert::before {
  content: "\f01e5";
}

.mdi-tumble-dryer-off::before {
  content: "\f01e6";
}

.mdi-tumblr::before {
  content: "\f541";
}

.mdi-tumblr-box::before {
  content: "珞";
}

.mdi-tumblr-reblog::before {
  content: "\f542";
}

.mdi-tune::before {
  content: "\f62e";
}

.mdi-tune-vertical::before {
  content: "\f66a";
}

.mdi-turnstile::before {
  content: "ﲱ";
}

.mdi-turnstile-outline::before {
  content: "ﲲ";
}

.mdi-turtle::before {
  content: "ﲳ";
}

.mdi-twitch::before {
  content: "\f543";
}

.mdi-twitter::before {
  content: "\f544";
}

.mdi-twitter-box::before {
  content: "\f545";
}

.mdi-twitter-circle::before {
  content: "\f546";
}

.mdi-twitter-retweet::before {
  content: "\f547";
}

.mdi-two-factor-authentication::before {
  content: "瑩";
}

.mdi-typewriter::before {
  content: "ｊ";
}

.mdi-uber::before {
  content: "\f748";
}

.mdi-ubisoft::before {
  content: "﮶";
}

.mdi-ubuntu::before {
  content: "\f548";
}

.mdi-ufo::before {
  content: "\f00ef";
}

.mdi-ufo-outline::before {
  content: "\f00f0";
}

.mdi-ultra-high-definition::before {
  content: "\f7f8";
}

.mdi-umbraco::before {
  content: "\f549";
}

.mdi-umbrella::before {
  content: "\f54a";
}

.mdi-umbrella-closed::before {
  content: "羚";
}

.mdi-umbrella-outline::before {
  content: "\f54b";
}

.mdi-undo::before {
  content: "\f54c";
}

.mdi-undo-variant::before {
  content: "\f54d";
}

.mdi-unfold-less-horizontal::before {
  content: "\f54e";
}

.mdi-unfold-less-vertical::before {
  content: "\f75f";
}

.mdi-unfold-more-horizontal::before {
  content: "\f54f";
}

.mdi-unfold-more-vertical::before {
  content: "\f760";
}

.mdi-ungroup::before {
  content: "\f550";
}

.mdi-unicode::before {
  content: "ﻭ";
}

.mdi-unity::before {
  content: "\f6ae";
}

.mdi-unreal::before {
  content: "聆";
}

.mdi-untappd::before {
  content: "\f551";
}

.mdi-update::before {
  content: "\f6af";
}

.mdi-upload::before {
  content: "\f552";
}

.mdi-upload-lock::before {
  content: "\f039e";
}

.mdi-upload-lock-outline::before {
  content: "\f039f";
}

.mdi-upload-multiple::before {
  content: "\f83c";
}

.mdi-upload-network::before {
  content: "\f6f5";
}

.mdi-upload-network-outline::before {
  content: "ﲴ";
}

.mdi-upload-off::before {
  content: "\f00f1";
}

.mdi-upload-off-outline::before {
  content: "\f00f2";
}

.mdi-upload-outline::before {
  content: "﹧";
}

.mdi-usb::before {
  content: "\f553";
}

.mdi-usb-flash-drive::before {
  content: "\f02c9";
}

.mdi-usb-flash-drive-outline::before {
  content: "\f02ca";
}

.mdi-usb-port::before {
  content: "\f021b";
}

.mdi-valve::before {
  content: "\f0088";
}

.mdi-valve-closed::before {
  content: "\f0089";
}

.mdi-valve-open::before {
  content: "\f008a";
}

.mdi-van-passenger::before {
  content: "\f7f9";
}

.mdi-van-utility::before {
  content: "\f7fa";
}

.mdi-vanish::before {
  content: "\f7fb";
}

.mdi-vanity-light::before {
  content: "\f020c";
}

.mdi-variable::before {
  content: "﫦";
}

.mdi-variable-box::before {
  content: "\f013c";
}

.mdi-vector-arrange-above::before {
  content: "\f554";
}

.mdi-vector-arrange-below::before {
  content: "\f555";
}

.mdi-vector-bezier::before {
  content: "﫧";
}

.mdi-vector-circle::before {
  content: "\f556";
}

.mdi-vector-circle-variant::before {
  content: "\f557";
}

.mdi-vector-combine::before {
  content: "\f558";
}

.mdi-vector-curve::before {
  content: "\f559";
}

.mdi-vector-difference::before {
  content: "\f55a";
}

.mdi-vector-difference-ab::before {
  content: "\f55b";
}

.mdi-vector-difference-ba::before {
  content: "\f55c";
}

.mdi-vector-ellipse::before {
  content: "\f892";
}

.mdi-vector-intersection::before {
  content: "\f55d";
}

.mdi-vector-line::before {
  content: "\f55e";
}

.mdi-vector-link::before {
  content: "\f0009";
}

.mdi-vector-point::before {
  content: "\f55f";
}

.mdi-vector-polygon::before {
  content: "\f560";
}

.mdi-vector-polyline::before {
  content: "\f561";
}

.mdi-vector-polyline-edit::before {
  content: "\f0250";
}

.mdi-vector-polyline-minus::before {
  content: "\f0251";
}

.mdi-vector-polyline-plus::before {
  content: "\f0252";
}

.mdi-vector-polyline-remove::before {
  content: "\f0253";
}

.mdi-vector-radius::before {
  content: "\f749";
}

.mdi-vector-rectangle::before {
  content: "\f5c6";
}

.mdi-vector-selection::before {
  content: "\f562";
}

.mdi-vector-square::before {
  content: "\f001";
}

.mdi-vector-triangle::before {
  content: "\f563";
}

.mdi-vector-union::before {
  content: "\f564";
}

.mdi-venmo::before {
  content: "\f578";
}

.mdi-vhs::before {
  content: "祥";
}

.mdi-vibrate::before {
  content: "\f566";
}

.mdi-vibrate-off::before {
  content: "ﲵ";
}

.mdi-video::before {
  content: "\f567";
}

.mdi-video-3d::before {
  content: "\f7fc";
}

.mdi-video-3d-variant::before {
  content: "ﻮ";
}

.mdi-video-4k-box::before {
  content: "\f83d";
}

.mdi-video-account::before {
  content: "落";
}

.mdi-video-check::before {
  content: "\f008b";
}

.mdi-video-check-outline::before {
  content: "\f008c";
}

.mdi-video-image::before {
  content: "酪";
}

.mdi-video-input-antenna::before {
  content: "\f83e";
}

.mdi-video-input-component::before {
  content: "\f83f";
}

.mdi-video-input-hdmi::before {
  content: "\f840";
}

.mdi-video-input-scart::before {
  content: "ﾩ";
}

.mdi-video-input-svideo::before {
  content: "\f841";
}

.mdi-video-minus::before {
  content: "鈴";
}

.mdi-video-off::before {
  content: "\f568";
}

.mdi-video-off-outline::before {
  content: "﮷";
}

.mdi-video-outline::before {
  content: "﮸";
}

.mdi-video-plus::before {
  content: "零";
}

.mdi-video-stabilization::before {
  content: "駱";
}

.mdi-video-switch::before {
  content: "\f569";
}

.mdi-video-vintage::before {
  content: "福";
}

.mdi-video-wireless::before {
  content: "ﻯ";
}

.mdi-video-wireless-outline::before {
  content: "ﻰ";
}

.mdi-view-agenda::before {
  content: "\f56a";
}

.mdi-view-agenda-outline::before {
  content: "\f0203";
}

.mdi-view-array::before {
  content: "\f56b";
}

.mdi-view-carousel::before {
  content: "\f56c";
}

.mdi-view-column::before {
  content: "\f56d";
}

.mdi-view-comfy::before {
  content: "﹍";
}

.mdi-view-compact::before {
  content: "﹎";
}

.mdi-view-compact-outline::before {
  content: "﹏";
}

.mdi-view-dashboard::before {
  content: "\f56e";
}

.mdi-view-dashboard-outline::before {
  content: "靖";
}

.mdi-view-dashboard-variant::before {
  content: "\f842";
}

.mdi-view-day::before {
  content: "\f56f";
}

.mdi-view-grid::before {
  content: "\f570";
}

.mdi-view-grid-outline::before {
  content: "\f0204";
}

.mdi-view-grid-plus::before {
  content: "ﾪ";
}

.mdi-view-grid-plus-outline::before {
  content: "\f0205";
}

.mdi-view-headline::before {
  content: "\f571";
}

.mdi-view-list::before {
  content: "\f572";
}

.mdi-view-module::before {
  content: "\f573";
}

.mdi-view-parallel::before {
  content: "\f727";
}

.mdi-view-quilt::before {
  content: "\f574";
}

.mdi-view-sequential::before {
  content: "\f728";
}

.mdi-view-split-horizontal::before {
  content: "ﮧ";
}

.mdi-view-split-vertical::before {
  content: "ﮨ";
}

.mdi-view-stream::before {
  content: "\f575";
}

.mdi-view-week::before {
  content: "\f576";
}

.mdi-vimeo::before {
  content: "\f577";
}

.mdi-violin::before {
  content: "\f60f";
}

.mdi-virtual-reality::before {
  content: "\f893";
}

.mdi-visual-studio::before {
  content: "\f610";
}

.mdi-visual-studio-code::before {
  content: "精";
}

.mdi-vk::before {
  content: "\f579";
}

.mdi-vk-box::before {
  content: "\f57a";
}

.mdi-vk-circle::before {
  content: "\f57b";
}

.mdi-vlc::before {
  content: "\f57c";
}

.mdi-voice::before {
  content: "\f5cb";
}

.mdi-voice-off::before {
  content: "ﻱ";
}

.mdi-voicemail::before {
  content: "\f57d";
}

.mdi-volleyball::before {
  content: "靈";
}

.mdi-volume-high::before {
  content: "\f57e";
}

.mdi-volume-low::before {
  content: "\f57f";
}

.mdi-volume-medium::before {
  content: "\f580";
}

.mdi-volume-minus::before {
  content: "\f75d";
}

.mdi-volume-mute::before {
  content: "\f75e";
}

.mdi-volume-off::before {
  content: "\f581";
}

.mdi-volume-plus::before {
  content: "\f75c";
}

.mdi-volume-source::before {
  content: "\f014b";
}

.mdi-volume-variant-off::before {
  content: "﹨";
}

.mdi-volume-vibrate::before {
  content: "\f014c";
}

.mdi-vote::before {
  content: "羽";
}

.mdi-vote-outline::before {
  content: "﨟";
}

.mdi-vpn::before {
  content: "\f582";
}

.mdi-vuejs::before {
  content: "\f843";
}

.mdi-vuetify::before {
  content: "﹐";
}

.mdi-walk::before {
  content: "\f583";
}

.mdi-wall::before {
  content: "\f7fd";
}

.mdi-wall-sconce::before {
  content: "亂";
}

.mdi-wall-sconce-flat::before {
  content: "卵";
}

.mdi-wall-sconce-variant::before {
  content: "欄";
}

.mdi-wallet::before {
  content: "\f584";
}

.mdi-wallet-giftcard::before {
  content: "\f585";
}

.mdi-wallet-membership::before {
  content: "\f586";
}

.mdi-wallet-outline::before {
  content: "﮹";
}

.mdi-wallet-plus::before {
  content: "ﾫ";
}

.mdi-wallet-plus-outline::before {
  content: "ﾬ";
}

.mdi-wallet-travel::before {
  content: "\f587";
}

.mdi-wallpaper::before {
  content: "﹩";
}

.mdi-wan::before {
  content: "\f588";
}

.mdi-wardrobe::before {
  content: "ﾭ";
}

.mdi-wardrobe-outline::before {
  content: "ﾮ";
}

.mdi-warehouse::before {
  content: "ﾻ";
}

.mdi-washing-machine::before {
  content: "\f729";
}

.mdi-washing-machine-alert::before {
  content: "\f01e7";
}

.mdi-washing-machine-off::before {
  content: "\f01e8";
}

.mdi-watch::before {
  content: "\f589";
}

.mdi-watch-export::before {
  content: "\f58a";
}

.mdi-watch-export-variant::before {
  content: "\f894";
}

.mdi-watch-import::before {
  content: "\f58b";
}

.mdi-watch-import-variant::before {
  content: "\f895";
}

.mdi-watch-variant::before {
  content: "\f896";
}

.mdi-watch-vibrate::before {
  content: "\f6b0";
}

.mdi-watch-vibrate-off::before {
  content: "ﲶ";
}

.mdi-water::before {
  content: "\f58c";
}

.mdi-water-boiler::before {
  content: "ﾯ";
}

.mdi-water-boiler-alert::before {
  content: "\f01de";
}

.mdi-water-boiler-off::before {
  content: "\f01df";
}

.mdi-water-off::before {
  content: "\f58d";
}

.mdi-water-outline::before {
  content: "﹪";
}

.mdi-water-percent::before {
  content: "\f58e";
}

.mdi-water-polo::before {
  content: "\f02cb";
}

.mdi-water-pump::before {
  content: "\f58f";
}

.mdi-water-pump-off::before {
  content: "ﾰ";
}

.mdi-water-well::before {
  content: "\f008d";
}

.mdi-water-well-outline::before {
  content: "\f008e";
}

.mdi-watermark::before {
  content: "\f612";
}

.mdi-wave::before {
  content: "ｋ";
}

.mdi-waves::before {
  content: "\f78c";
}

.mdi-waze::before {
  content: "﮺";
}

.mdi-weather-cloudy::before {
  content: "\f590";
}

.mdi-weather-cloudy-alert::before {
  content: "ｌ";
}

.mdi-weather-cloudy-arrow-right::before {
  content: "﹑";
}

.mdi-weather-fog::before {
  content: "\f591";
}

.mdi-weather-hail::before {
  content: "\f592";
}

.mdi-weather-hazy::before {
  content: "ｍ";
}

.mdi-weather-hurricane::before {
  content: "\f897";
}

.mdi-weather-lightning::before {
  content: "\f593";
}

.mdi-weather-lightning-rainy::before {
  content: "\f67d";
}

.mdi-weather-night::before {
  content: "\f594";
}

.mdi-weather-night-partly-cloudy::before {
  content: "ｎ";
}

.mdi-weather-partly-cloudy::before {
  content: "\f595";
}

.mdi-weather-partly-lightning::before {
  content: "ｏ";
}

.mdi-weather-partly-rainy::before {
  content: "ｐ";
}

.mdi-weather-partly-snowy::before {
  content: "ｑ";
}

.mdi-weather-partly-snowy-rainy::before {
  content: "ｒ";
}

.mdi-weather-pouring::before {
  content: "\f596";
}

.mdi-weather-rainy::before {
  content: "\f597";
}

.mdi-weather-snowy::before {
  content: "\f598";
}

.mdi-weather-snowy-heavy::before {
  content: "ｓ";
}

.mdi-weather-snowy-rainy::before {
  content: "\f67e";
}

.mdi-weather-sunny::before {
  content: "\f599";
}

.mdi-weather-sunny-alert::before {
  content: "ｔ";
}

.mdi-weather-sunset::before {
  content: "\f59a";
}

.mdi-weather-sunset-down::before {
  content: "\f59b";
}

.mdi-weather-sunset-up::before {
  content: "\f59c";
}

.mdi-weather-tornado::before {
  content: "ｕ";
}

.mdi-weather-windy::before {
  content: "\f59d";
}

.mdi-weather-windy-variant::before {
  content: "\f59e";
}

.mdi-web::before {
  content: "\f59f";
}

.mdi-web-box::before {
  content: "ﾱ";
}

.mdi-web-clock::before {
  content: "\f0275";
}

.mdi-webcam::before {
  content: "\f5a0";
}

.mdi-webhook::before {
  content: "\f62f";
}

.mdi-webpack::before {
  content: "\f72a";
}

.mdi-webrtc::before {
  content: "\f0273";
}

.mdi-wechat::before {
  content: "\f611";
}

.mdi-weight::before {
  content: "\f5a1";
}

.mdi-weight-gram::before {
  content: "ﴛ";
}

.mdi-weight-kilogram::before {
  content: "\f5a2";
}

.mdi-weight-lifter::before {
  content: "\f0188";
}

.mdi-weight-pound::before {
  content: "領";
}

.mdi-whatsapp::before {
  content: "\f5a3";
}

.mdi-wheelchair-accessibility::before {
  content: "\f5a4";
}

.mdi-whistle::before {
  content: "例";
}

.mdi-whistle-outline::before {
  content: "\f02e7";
}

.mdi-white-balance-auto::before {
  content: "\f5a5";
}

.mdi-white-balance-incandescent::before {
  content: "\f5a6";
}

.mdi-white-balance-iridescent::before {
  content: "\f5a7";
}

.mdi-white-balance-sunny::before {
  content: "\f5a8";
}

.mdi-widgets::before {
  content: "\f72b";
}

.mdi-widgets-outline::before {
  content: "\f0380";
}

.mdi-wifi::before {
  content: "\f5a9";
}

.mdi-wifi-off::before {
  content: "\f5aa";
}

.mdi-wifi-star::before {
  content: "﹫";
}

.mdi-wifi-strength-1::before {
  content: "爛";
}

.mdi-wifi-strength-1-alert::before {
  content: "蘭";
}

.mdi-wifi-strength-1-lock::before {
  content: "鸞";
}

.mdi-wifi-strength-2::before {
  content: "嵐";
}

.mdi-wifi-strength-2-alert::before {
  content: "濫";
}

.mdi-wifi-strength-2-lock::before {
  content: "藍";
}

.mdi-wifi-strength-3::before {
  content: "襤";
}

.mdi-wifi-strength-3-alert::before {
  content: "拉";
}

.mdi-wifi-strength-3-lock::before {
  content: "臘";
}

.mdi-wifi-strength-4::before {
  content: "蠟";
}

.mdi-wifi-strength-4-alert::before {
  content: "廊";
}

.mdi-wifi-strength-4-lock::before {
  content: "朗";
}

.mdi-wifi-strength-alert-outline::before {
  content: "浪";
}

.mdi-wifi-strength-lock-outline::before {
  content: "狼";
}

.mdi-wifi-strength-off::before {
  content: "郎";
}

.mdi-wifi-strength-off-outline::before {
  content: "來";
}

.mdi-wifi-strength-outline::before {
  content: "冷";
}

.mdi-wii::before {
  content: "\f5ab";
}

.mdi-wiiu::before {
  content: "\f72c";
}

.mdi-wikipedia::before {
  content: "\f5ac";
}

.mdi-wind-turbine::before {
  content: "ﶁ";
}

.mdi-window-close::before {
  content: "\f5ad";
}

.mdi-window-closed::before {
  content: "\f5ae";
}

.mdi-window-closed-variant::before {
  content: "\f0206";
}

.mdi-window-maximize::before {
  content: "\f5af";
}

.mdi-window-minimize::before {
  content: "\f5b0";
}

.mdi-window-open::before {
  content: "\f5b1";
}

.mdi-window-open-variant::before {
  content: "\f0207";
}

.mdi-window-restore::before {
  content: "\f5b2";
}

.mdi-window-shutter::before {
  content: "\f0147";
}

.mdi-window-shutter-alert::before {
  content: "\f0148";
}

.mdi-window-shutter-open::before {
  content: "\f0149";
}

.mdi-windows::before {
  content: "\f5b3";
}

.mdi-windows-classic::before {
  content: "蘒";
}

.mdi-wiper::before {
  content: "﫨";
}

.mdi-wiper-wash::before {
  content: "ﶂ";
}

.mdi-wordpress::before {
  content: "\f5b4";
}

.mdi-worker::before {
  content: "\f5b5";
}

.mdi-wrap::before {
  content: "\f5b6";
}

.mdi-wrap-disabled::before {
  content: "﮻";
}

.mdi-wrench::before {
  content: "\f5b7";
}

.mdi-wrench-outline::before {
  content: "﮼";
}

.mdi-wunderlist::before {
  content: "\f5b8";
}

.mdi-xamarin::before {
  content: "\f844";
}

.mdi-xamarin-outline::before {
  content: "\f845";
}

.mdi-xaml::before {
  content: "\f673";
}

.mdi-xbox::before {
  content: "\f5b9";
}

.mdi-xbox-controller::before {
  content: "\f5ba";
}

.mdi-xbox-controller-battery-alert::before {
  content: "\f74a";
}

.mdi-xbox-controller-battery-charging::before {
  content: "﨡";
}

.mdi-xbox-controller-battery-empty::before {
  content: "\f74b";
}

.mdi-xbox-controller-battery-full::before {
  content: "\f74c";
}

.mdi-xbox-controller-battery-low::before {
  content: "\f74d";
}

.mdi-xbox-controller-battery-medium::before {
  content: "\f74e";
}

.mdi-xbox-controller-battery-unknown::before {
  content: "\f74f";
}

.mdi-xbox-controller-menu::before {
  content: "﹒";
}

.mdi-xbox-controller-off::before {
  content: "\f5bb";
}

.mdi-xbox-controller-view::before {
  content: "﹓";
}

.mdi-xda::before {
  content: "\f5bc";
}

.mdi-xing::before {
  content: "\f5bd";
}

.mdi-xing-box::before {
  content: "\f5be";
}

.mdi-xing-circle::before {
  content: "\f5bf";
}

.mdi-xml::before {
  content: "\f5c0";
}

.mdi-xmpp::before {
  content: "\f7fe";
}

.mdi-yahoo::before {
  content: "שׁ";
}

.mdi-yammer::before {
  content: "\f788";
}

.mdi-yeast::before {
  content: "\f5c1";
}

.mdi-yelp::before {
  content: "\f5c2";
}

.mdi-yin-yang::before {
  content: "\f67f";
}

.mdi-yoga::before {
  content: "\f01a7";
}

.mdi-youtube::before {
  content: "\f5c3";
}

.mdi-youtube-creator-studio::before {
  content: "\f846";
}

.mdi-youtube-gaming::before {
  content: "\f847";
}

.mdi-youtube-subscription::before {
  content: "ﴜ";
}

.mdi-youtube-tv::before {
  content: "\f448";
}

.mdi-z-wave::before {
  content: "﫩";
}

.mdi-zend::before {
  content: "﫪";
}

.mdi-zigbee::before {
  content: "ﴝ";
}

.mdi-zip-box::before {
  content: "\f5c4";
}

.mdi-zip-box-outline::before {
  content: "\f001b";
}

.mdi-zip-disk::before {
  content: "諸";
}

.mdi-zodiac-aquarius::before {
  content: "塚";
}

.mdi-zodiac-aries::before {
  content: "墳";
}

.mdi-zodiac-cancer::before {
  content: "奄";
}

.mdi-zodiac-capricorn::before {
  content: "奔";
}

.mdi-zodiac-gemini::before {
  content: "婢";
}

.mdi-zodiac-leo::before {
  content: "嬨";
}

.mdi-zodiac-libra::before {
  content: "廒";
}

.mdi-zodiac-pisces::before {
  content: "廙";
}

.mdi-zodiac-sagittarius::before {
  content: "彩";
}

.mdi-zodiac-scorpio::before {
  content: "徭";
}

.mdi-zodiac-taurus::before {
  content: "惘";
}

.mdi-zodiac-virgo::before {
  content: "慎";
}

.mdi-blank::before {
  content: "\f68c";
  visibility: hidden;
}

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px;
}

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px;
}

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px;
}

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px;
}

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54);
}

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26);
}

.mdi-light:before {
  color: #fff;
}

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3);
}

.mdi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mdi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mdi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.mdi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.mdi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

.mdi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.mdi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear;
}

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
